import HomePage from '../pages/home.vue';
import Perfil from '../pages/perfil.vue';
import Coreprojects from '../pages/core_projects.vue';
import Corecuentas from '../pages/core_cuentas.vue';
import CIELOConfigurations from '../pages/cielo_configurations.vue';
import Cuenta from '../pages/cuenta.vue';

import NotFoundPage from '../pages/404.vue';
import Projectadmin from '../pages/project_admin.vue';
import ProjectMenu from '../components/projectMenu.vue';
import termsConds from '../pages/termsConds.vue';
import Aboutprojects from '../pages/aboutproject.vue';

import Allprojects from '../pages/allprojects.vue';
import About from '../pages/about.vue';
import Dashboard from '../pages/dashboard.vue';
import Prolog from '../pages/prolog.vue';
import Projectdata from '../pages/projectdata.vue';
import queries from '../pages/queries.vue';
import SearchOnCSV from '../pages/SearchOnCSV.vue';
import filterData_nueo from '../pages/filterData_nueo.vue';
import searchResults from '../pages/searchResults.vue';
import Entity from '../pages/entity.vue';
import support from '../pages/support.vue';
import dataimport from '../pages/dataimport.vue';
import redcap from '../pages/redcap.vue';
import bulkupload from '../pages/bulkUpload.vue';
import BundlesHistory from '../pages/BundlesHistory.vue';

import UserGuideGettingStarted from '../pages/user-guide/getting-started.vue';
import UserGuideAccessAndExit from '../pages/user-guide/access-and-exit-project.vue';
import UserGuideManageViaProjectData from '../pages/user-guide/manage-project-via-project-data.vue';

var routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/about/',
        component: About,
    },
    // User guide
    {
        path: '/about/guide/getting-started',
        component: UserGuideGettingStarted,
    },
    {
        path: '/about/guide/access-and-exit',
        component: UserGuideAccessAndExit,
    },
    {
        path: '/about/guide/manage-via-project-data',
        component: UserGuideManageViaProjectData,
    },
    {
        path: '/termsConds/project/',
        component: termsConds,
    },
    {
        path: '/about/project/',
        component: Aboutprojects,
    },
    {
        path: '/projectMenu/',
        component: ProjectMenu,
    },
    {
        path: '/entity/',
        component: Entity,
    },
    {
        path: '/support/',
        component: support,
    },
    {
        path: '/dashboard/',
        component: Dashboard,
    },
    {
        path: '/projectdata/',
        component: Projectdata,
    },
    {
        path: '/searchResults/',
        component: searchResults,
    },
    {
        path: '/queries/',
        component: queries,
    },
    {
        path: '/search-on-csv/',
        component: SearchOnCSV,
    },
    {
        path: '/filterData_nueo/',
        component: filterData_nueo,
    },
    {
        path: '/perfil/',
        component: Perfil,
    },
    {
        path: '/prolog/',
        component: Prolog,
    },
    {
        path: '/allprojects/',
        component: Allprojects,
    },
    {
        path: '/project_admin/',
        component: Projectadmin,
    },
    {
        path: '/dataimport/',
        component: dataimport,
    },
    {
        path: '/redcap/',
        component: redcap,
    },
    {
        path: '/bulkupload/',
        component: bulkupload,
    },
    {
        path: '/bundles-history/',
        component: BundlesHistory,
    },
    {
        path: '/core_projects/',
        component: Coreprojects,
    },
    {
        path: '/cuentas/:cual',
        component: Corecuentas,
    },
    {
        path: '/cielo-config/',
        component: CIELOConfigurations,
    },
    {
        path: '/cuenta/:cual',
        component: Cuenta,
    },

    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;
