var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-view',[_c('f7-page',[_c('f7-navbar',{attrs:{"title":"Add user to project","subtitle":_vm.forceProject ? _vm.projectInFocusInfo.name : null}},[_c('f7-nav-right',[_c('f7-link',{attrs:{"popup-close":""}},[_vm._v("Cancel")])],1)],1),_vm._v(" "),_c('f7-list',{staticClass:"no-margin-top"},[_c('f7-list-item',{attrs:{"divider":"","title":`1.- ${_vm.forceProject ? 'Project' : 'Select project'}`}}),_vm._v(" "),(!_vm.forceProject)?_c('f7-list-item',{attrs:{"title":"Project","smart-select":"","smart-select-params":{
                    searchbar: { removeDiacritics: true },
                    closeOnSelect: true,
                    virtualList: true,
                    searchbarPlaceholder: 'Search project',
                }}},[_c('select',{attrs:{"name":"projectox"},on:{"change":function($event){return _vm.setNewProject($event.target.value)}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select a project")]),_vm._v(" "),_vm._l((_vm.projectsToShow),function(project){return _c('option',{key:project.id,domProps:{"value":project.id}},[_vm._v("\n                        "+_vm._s(project.name)+"\n                        "+_vm._s(project.cemail ? `(${project.cemail})` : "")+"\n                    ")])})],2)]):_c('f7-list-item',{attrs:{"title":_vm.projectInFocusInfo.name}}),_vm._v(" "),(_vm.newPrivsProject.projectid)?[_c('f7-list-item',{attrs:{"divider":"","title":"2.- Indicate the origin"}}),_vm._v(" "),_c('f7-list-item',{ref:"estesmartref",attrs:{"title":_vm.projectInFocusInfo.grouper || 'Institutions',"smart-select":"","smart-select-params":{
                        searchbar: { removeDiacritics: true },
                        closeOnSelect: true,
                        virtualList: true,
                        searchbarPlaceholder: `Search ${_vm.projectInFocusInfo.grouper || 'Institutions'}`,
                    }}},[_c('select',{attrs:{"name":"institutionx"},on:{"change":function($event){_vm.newPrivsProject.institutionid = $event.target.value}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select origin")]),_vm._v(" "),_vm._l((_vm.getInstitutions()),function(oneinstit){return _c('option',{key:oneinstit.id,domProps:{"value":oneinstit.id}},[_vm._v("\n                            "+_vm._s(oneinstit.name)+"\n                        ")])})],2)]),_vm._v(" "),(_vm.newPrivsProject.institutionid)?[_c('f7-list-item',{attrs:{"divider":"","title":"3.- Set Project level privileges (optional)"}}),_vm._v(" "),_vm._l((_vm.projectprofiles),function(unpriv,index){return _c('f7-list-item',{key:`${unpriv.id}_${index}`,class:{
                            disabled:
                                (unpriv.reqby && _vm.newPrivsProject.projectPrivs[unpriv.reqby]) ||
                                (unpriv.reqs && !_vm.newPrivsProject.projectPrivs[unpriv.reqs]) ||
                                (unpriv.id === 'phi' &&
                                    _vm.newPrivsProject.projectPrivs.admin &&
                                    (_vm.newPrivsProject.projectPrivs.admin['project-admin'] === true ||
                                        _vm.newPrivsProject.projectPrivs.admin.full === true)) ||
                                (unpriv.id !== 'project-admin' &&
                                    _vm.newPrivsProject.projectPrivs['project-admin'] === true),
                        },attrs:{"title":unpriv.id === 'full' ? 'write' : unpriv.id,"footer":unpriv.descr}},[_c('f7-toggle',{attrs:{"slot":"after","color":"teal","checked":_vm.newPrivsProject.projectPrivs[unpriv.id]},on:{"toggle:change":function($event){return _vm.creaPrivsEnProject($event, unpriv.id)}},slot:"after"})],1)}),_vm._v(" "),_c('f7-list-item',{attrs:{"divider":"","title":`4.- Set ${
                            _vm.projectInFocusInfo.grouper || 'institutions'
                        } level privileges (optional)`}}),_vm._v(" "),_c('f7-list-item',{attrs:{"title":"read","footer":"User can read entities from this institution"}},[_c('f7-toggle',{attrs:{"slot":"after","color":"purple","checked":"","disabled":""},slot:"after"})],1),_vm._v(" "),_vm._l((_vm.institutionProfiles),function(unpriv,index){return _c('f7-list-item',{key:`${unpriv.id}_${index}`,attrs:{"title":unpriv.id === 'create' ? 'write' : unpriv.id,"footer":unpriv.descr}},[_c('f7-toggle',{attrs:{"slot":"after","color":"purple","checked":_vm.newPrivsProject.instiPrivs[unpriv.id],"disabled":(unpriv.id === 'create' && _vm.newPrivsProject.projectPrivs.full) ||
                                (unpriv.id === 'download' && _vm.newPrivsProject.projectPrivs.download) ||
                                (unpriv.id === 'phi' &&
                                    (_vm.newPrivsProject.instiPrivs.update || _vm.newPrivsProject.instiPrivs.create)) ||
                                (unpriv.id === 'phi' && _vm.newPrivsProject.projectPrivs.phi) ||
                                _vm.newPrivsProject.projectPrivs['project-admin'] === true},on:{"toggle:change":function($event){return _vm.creaPrivenInstitution($event, unpriv.id)}},slot:"after"})],1)})]:_vm._e()]:_vm._e()],2),_vm._v(" "),_c('f7-block',[_c('f7-row',[_c('f7-col',[_c('f7-button',{staticClass:"cancelButton",attrs:{"popup-close":"","large":""}},[_vm._v("Cancel")])],1),_vm._v(" "),_c('f7-col',[_c('f7-button',{attrs:{"color":"teal","large":"","raised":"","fill":""},on:{"click":function($event){return _vm.darprivilegios()}}},[_vm._v("Grant Privileges")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }