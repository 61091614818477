<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>
                Search on CSV files
                <span class="subtitle">Across entities in the project</span>
            </f7-nav-title>
            <f7-nav-right>
                <f7-link
                    v-if="userHasDownloadPrivileges"
                    @click="downloadEntities"
                    :disabled="selectedEntities.length === 0"
                    tooltip="Download selected entities"
                >
                    <font-awesome-icon class="" :icon="['fad', 'cloud-arrow-down']"></font-awesome-icon>
                </f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block-title v-if="fileFields.length == 0">No uploaded CSV files to search</f7-block-title>
        <f7-list v-else>
            <f7-list-input
                label="Entity File Field"
                type="select"
                :value="search.fileFieldId"
                @change="handleEntityFileFieldChanged($event.target.value)"
            >
                <option value="">Select an Entity File</option>
                <option v-for="fileField in fileFields" :value="fileField.id" :key="fileField.id">
                    {{ fileField.encabezado }}
                </option>
            </f7-list-input>

            <f7-list-input
                v-if="possibleHeaders.length"
                label="Column Header"
                type="select"
                :value="search.header"
                @change="handleHeaderChanged($event.target.value)"
            >
                <option value="">Select a CSV column header</option>
                <option v-for="header in possibleHeaders" :value="header.id" :key="header.id">{{ header.id }}</option>
            </f7-list-input>

            <f7-list-input
                v-if="headerHasLessThanMaxDropdown(search.header) && possibleValues.length"
                label="Value equal to"
                type="select"
                :value="search.value"
                @change="search.value = $event.target.value"
            >
                <option value="">Select a value</option>
                <option v-for="(value, index) in possibleValues" :value="value" :key="index">{{ value }}</option>
            </f7-list-input>
            <f7-list-input
                v-else-if="search.header"
                label="Value"
                type="text"
                @input="search.value = $event.target.value"
            ></f7-list-input>
        </f7-list>

        <f7-button :disabled="!isSearchValid" @click="searchOnCSVFiles" fill large class="margin">
            Search for entities
        </f7-button>

        <template v-if="entities.length">
            <f7-block-title>Displaying {{ entities.length }} entities</f7-block-title>
            <f7-list media-list>
                <f7-list-item
                    v-if="userHasDownloadPrivileges"
                    subtitle="Select all"
                    checkbox
                    @change="handleSelectAll"
                ></f7-list-item>
                <f7-list-item
                    v-for="entity in entities"
                    :checkbox="userHasDownloadPrivileges"
                    :checked="selectedEntities.includes(entity.id)"
                    @change="handleSelectEntity(entity.id)"
                    :key="entity.id"
                    :header="getEntityInstitutionName(entity)"
                    :title="entity.id"
                    :footer="`Created on ${$dayjs.unix(entity.createdAd).format('MM-DD-YYYY')}`"
                >
                    <f7-button @click="viewEntity(entity.id)" small slot="after">View</f7-button>
                </f7-list-item>
            </f7-list>
        </template>
    </f7-page>
</template>

<script>
    import { commonmixin } from "../mixins/common";
    export default {
        mixins: [commonmixin],

        data() {
            return {
                search: {
                    fileFieldId: "",
                    header: "",
                    value: "",
                },

                possibleHeaders: [],
                possibleValues: [],

                entities: [],
                selectedEntities: [],

                MAX_NUMBER_OF_POSSIBLE_VALUES: 30,
            };
        },

        computed: {
            fileFields() {
                const entityFiles = this.$store.getters.projectInFocusInfo.entityFiles;
                return Object.values(entityFiles || {}).filter(
                    (entityFile) => !entityFile.disabled && (entityFile.tipo === "a" || entityFile.tipo === "csv"),
                );
            },

            userCanDownloadDeidentifiedBundles() {
                if (!this.$store.getters.projectInFocusInfo.isDeidentificationEnabled) {
                    return false;
                }

                return true;
            },

            userHasDownloadPrivileges() {
                return this.$store.getters.userHasPrivileges({
                    requiredInstitutionPrivs: ["download"],
                });
            },

            isSearchValid() {
                return this.search.fileFieldId && this.search.header && this.search.value;
            },
        },

        methods: {
            async searchOnCSVFiles() {
                console.log("search", this.search);

                try {
                    this.$f7.dialog.preloader("Searching on CSV files...");
                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus, search: this.search },
                        "v2_searchOnCSVFiles",
                    );
                    console.log("response", response);

                    if (response.error) throw new Error(response.error.message);

                    this.entities = response.entities;
                    this.$f7.dialog.close();

                    if (!this.entities.length) this.$f7.dialog.alert("No results found");
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },

            async getPossibleHeaders() {
                console.log("search", this.search);

                try {
                    this.$f7.dialog.preloader("Searching possible headers...");
                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus, search: this.search },
                        "v2_getSearchOnCSVPossibleHeaders",
                    );
                    console.log("response get possible headers", response);

                    if (response.error) throw new Error(response.error.message);

                    this.possibleHeaders = response.headers;

                    if (!this.possibleHeaders.length)
                        this.$f7.dialog.alert(
                            "Couldn't find any possible headers for the specified Entity File Field. Please choose another Entity File Field.",
                        );

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },

            async getPossibleValues() {
                console.log("search", this.search);

                try {
                    this.$f7.dialog.preloader("Searching possible values...");
                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus, search: this.search },
                        "v2_getSearchOnCSVPossibleValues",
                    );
                    console.log("response get possible values", response);

                    if (response.error) throw new Error(response.error.message);

                    this.possibleValues = response.values;

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },

            async downloadEntities() {
                const entities = this.selectedEntities;

                if (!entities.length) {
                    this.$f7.dialog.alert("No entities selected");
                    return;
                }

                if (this.$store.getters.projectInFocusInfo.isDeidentificationEnabled) {
                    if (!this.userCanDownloadNonDeidentifiedBundles(this.selectedEntities)) {
                        this.makeDownloadCall({
                            entities,
                            downloadPHIInfo: true,
                            deidentify: true,
                        });
                    } else if (this.userCanDownloadDeidentifiedBundles) {
                        this.$f7.dialog
                            .create({
                                text: "Do you want to download the<br>de-identified version of the bundle(s)?",
                                title: "De-identification",
                                buttons: [
                                    {
                                        text: "Cancel",
                                        bold: false,
                                    },
                                    {
                                        text: "Yes",
                                        bold: false,
                                        onClick: () => {
                                            this.makeDownloadCall({
                                                entities,
                                                downloadPHIInfo: true,
                                                deidentify: true,
                                            });
                                        },
                                    },
                                    {
                                        text: "No",
                                        bold: true,
                                        onClick: () => {
                                            this.makeDownloadCall({
                                                entities,
                                                downloadPHIInfo: true,
                                                deidentify: false,
                                            });
                                        },
                                    },
                                ],
                            })
                            .open();
                    }
                } else {
                    this.makeDownloadCall({ entities, downloadPHIInfo: true, deidentify: false });
                }
            },

            async makeDownloadCall({ entities, downloadPHIInfo, deidentify = false }) {
                try {
                    this.$f7.dialog.preloader(
                        "Generating ZIP file. This action may take a few minutes depending on the size of the bundle...",
                    );
                    let datous = await this.commonExecute(
                        {
                            projectid: this.$store.getters.projectInFocusInfo.id,
                            entities: entities ? entities : this.selectedEntities,
                            downloadPHIInfo,
                            deidentify,
                        },
                        "v2_generaetMultipleZip",
                        false,
                    );
                    console.log(datous);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }

                    if (datous.asyncProcessWasTriggered) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(
                            "The ZIP file is being generated. You will receive an email with the ZIP file when it is ready.",
                        );
                        return;
                    }

                    console.log(datous.payload);
                    this.openDownloadedFile({
                        downloadname: datous.name,
                        url: datous.payload,
                    });

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },

            openDownloadedFile({ downloadname, url }) {
                let fileLink = document.createElement("a");
                fileLink.href = url;
                fileLink.classList.add("external");
                fileLink.setAttribute("download", downloadname);
                // fileLink.download=downloadname;
                fileLink.setAttribute("target", "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$f7.dialog.close();
            },

            userCanDownloadNonDeidentifiedBundles(entities) {
                if (this.$store.getters.projectInFocusInfo.isDeidentificationEnabled) {
                    const myInstitutionId =
                        Object.keys(this.$store.getters.userPrivsSelectedProject?.prv || {})[0] || null;
                    const areAllEntitiesFromMyInstitution = entities.every((entityId) => {
                        const entity = this.entities.find((e) => e.id === entityId);

                        if (!entity) {
                            return false;
                        }

                        return entity.instid === myInstitutionId;
                    });

                    if (areAllEntitiesFromMyInstitution) {
                        return this.$store.getters.userHasPrivileges({
                            requiredInstitutionPrivs: ["phi"],
                        });
                    }

                    const isUserAllowed = this.$store.getters.userHasPrivileges({
                        requiredAdminPrivs: ["phi"],
                    });

                    return isUserAllowed;
                }

                return true;
            },

            getEntityInstitutionName(entity) {
                if (entity.isDeidentified) {
                    const institution = this.$store.getters.projectInFocusInfo?.institutions?.[entity?.instid] || {};

                    if (institution) {
                        return institution?.name || entity?.instid;
                    } else {
                        return entity?.instid;
                    }
                }

                const institution = this.$store.getters.projectInFocusInfo?.institutions?.[entity?.instid] || {};
                return institution?.name || entity?.instid;
            },

            handleSelectEntity(entityId) {
                if (this.selectedEntities.includes(entityId)) {
                    this.selectedEntities = this.selectedEntities.filter((id) => id !== entityId);
                } else {
                    this.selectedEntities.push(entityId);
                }
            },

            handleSelectAll() {
                if (this.selectedEntities.length === this.entities.length) {
                    this.selectedEntities = [];
                } else {
                    this.selectedEntities = this.entities.map((entity) => entity.id);
                }
            },

            handleEntityFileFieldChanged(fileFieldId) {
                this.search.fileFieldId = fileFieldId;
                this.search.header = "";
                this.search.value = "";
                this.possibleHeaders = [];
                this.possibleValues = [];
                this.entities = [];
                this.selectedEntities = [];

                if (fileFieldId) this.getPossibleHeaders();
            },

            handleHeaderChanged(header) {
                this.search.header = header;
                this.search.value = "";
                this.entities = [];

                const possibleHeader = this.possibleHeaders.find((possibleHeader) => possibleHeader.id === header);

                if (possibleHeader && possibleHeader.nalts <= this.MAX_NUMBER_OF_POSSIBLE_VALUES) {
                    this.getPossibleValues();
                }
            },

            async viewEntity(entityId) {
                try {
                    this.$f7.dialog.preloader("Loading...");

                    const response = await this.commonExecute(
                        {
                            projectId: this.$store.state.projectInFocus,
                            entityIds: [entityId],
                            sortBy: "id",
                            orderDirection: "ASC",
                            limit: 10000,
                        },
                        "v2_getEntities",
                    );

                    if (response.error) throw new Error(response.error.message);

                    const entity = response.entities[0];

                    if (!Object.keys(this.$store.state.forms_logic).length) {
                        await this.getMeThese(["forms_logic"]);
                    }

                    this.$f7.views.main.router.navigate(`/entity/`, { props: { entity } });

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },

            headerHasLessThanMaxDropdown(header) {
                const foundHeader = this.possibleHeaders.find((h) => h.id === header);
                return foundHeader?.nalts <= this.MAX_NUMBER_OF_POSSIBLE_VALUES;
            },
        },
    };
</script>
