<template>
    <f7-popup :opened="opened" @popup:opened="handlePopupOpened" @popup:closed="handlePopupClosed">
        <f7-page>
            <f7-navbar :title="(editingEntry ? 'Edit' : 'Create') + ' PHI dictionary entry'">
                <f7-nav-right>
                    <f7-link popup-close>Close</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <f7-list>
                    <f7-list-input
                        label="Column Name (required)"
                        placeholder="Enter the column name. If it's a multiple header CSV file, separate the headers with a semicolon (i.e: Column1;Column2)."
                        @input="(e) => (entry.columnName = e.target.value)"
                        :value="entry.columnName"
                        class="column-name-input"
                    ></f7-list-input>
                    <f7-list-input
                        label="De-Identification Type (required)"
                        type="select"
                        :value="entry.deidentificationType"
                        @change="(e) => (entry.deidentificationType = e.target.value)"
                    >
                        <option value="Kept">Kept</option>
                        <option value="[redacted]">[redacted]</option>
                        <option value="Date Shifted">Date Shifted</option>
                        <option value="Replace">Replace</option>
                    </f7-list-input>
                    <f7-list-input
                        label="Tag (optional)"
                        type="select"
                        :value="entry.tagId"
                        @change="(e) => (entry.tagId = e.target.value)"
                    >
                        <option :value="null"></option>
                        <option v-for="tag in tags" :value="tag.id" :key="tag.id">{{ tag.tx }}</option>
                    </f7-list-input>
                </f7-list>

                <f7-button v-if="!editingEntry" fill @click="create" class="margin-bottom-half">Create</f7-button>
                <template v-else>
                    <f7-button fill @click="edit" class="margin-bottom-half">Edit</f7-button>
                    <f7-button outline @click="remove" color="red" class="margin-bottom-half">
                        <font-awesome-icon :icon="['fad', 'trash']" class="margin-right-half"></font-awesome-icon>
                        Delete
                    </f7-button>
                </template>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
    import { commonmixin } from "../mixins/common";
    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            editingEntry: {
                type: Object || null,
                default: null,
            },
        },
        mixins: [commonmixin],
        computed: {
            tags() {
                return Object.values(this.$store.getters.projectInFocusInfo.fileTags || {})
                    .filter((t) => !t.inct)
                    .sort((a, b) => {
                        if (a.orden > b.orden) return 1;
                        else if (a.orden < b.orden) return -1;
                        else return 0;
                    });
            },
        },
        data() {
            return {
                entry: {
                    columnName: "",
                    deidentificationType: "Kept",
                    tagId: null,
                },
            };
        },
        methods: {
            async create() {
                try {
                    this.$f7.dialog.preloader("Creating PHI dictionary entry...");

                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus, entry: this.entry },
                        "v2_createPHIDictionaryEntry",
                    );
                    console.log("Response", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    this.$emit("created", response.entry);

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error, "There was an error while creating the dictionary entry");
                }
            },
            async edit() {
                this.$f7.dialog.confirm("Are you sure you want to edit this entry?", "Edit entry", async () => {
                    try {
                        this.$f7.dialog.preloader("Editing PHI dictionary entry...");

                        const response = await this.commonExecute(
                            { projectId: this.$store.state.projectInFocus, entry: this.entry },
                            "v2_editPHIDictionaryEntry",
                        );
                        console.log("Response", response);

                        if (response.error) {
                            throw new Error(response.error.message);
                        }

                        this.$emit("edited", { editedEntry: response.entry, originalEntryId: this.editingEntry.id });

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error, "There was an error while editing the dictionary entry");
                    }
                });
            },
            async remove() {
                this.$f7.dialog.confirm("Are you sure you want to delete this entry?", "Delete entry", async () => {
                    try {
                        this.$f7.dialog.preloader("Deleting PHI dictionary entry...");

                        const response = await this.commonExecute(
                            { projectId: this.$store.state.projectInFocus, entryId: this.entry.id },
                            "v2_deletePHIDictionaryEntry",
                        );
                        console.log("Response", response);

                        if (response.error) {
                            throw new Error(response.error.message);
                        }

                        this.$emit("deleted", this.entry.id);

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error, "There was an error while deleting the dictionary entry");
                    }
                });
            },
            handlePopupOpened() {
                if (this.editingEntry) {
                    this.entry = this.editingEntry;
                }
            },
            handlePopupClosed() {
                this.entry = {
                    columnName: "",
                    deidentificationType: "Kept",
                    tagId: null,
                };
                this.$emit("closed");
            },
        },
    };
</script>
<style scoped>
    .column-name-input {
        --f7-input-font-size: 12px;
    }
</style>
