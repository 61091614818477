<template>
    <f7-page name="project_admin" @page:beforein="traeAbout()" :page-content="false" @page:afterout="removeAdminPage()">
        <!-- Top Navbar -->
        <f7-navbar :back-link="projectInFocus != adminprojectFocus ? 'Back' : null">
            <f7-nav-left v-if="projectInFocus == adminprojectFocus">
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title
                :title="projectinfo.name"
                :subtitle="`Status: ${projectinfo.published ? 'Active' : 'Inactive'}`"
            ></f7-nav-title>

            <f7-nav-right>
                <f7-block>
                    <f7-preloader class="display-inline-block" v-if="guardandoeste == `core_published`"></f7-preloader>
                    <f7-button
                        class="display-inline-block"
                        @click="
                            modificaneoCore({
                                where: 'published',
                                valor: null,
                                subAction: 'inactivate project',
                            })
                        "
                        v-if="projectinfo.published"
                        outline
                        color="red"
                    >
                        Inactivate
                    </f7-button>
                    <f7-button
                        class="display-inline-block"
                        @click="
                            modificaneoCore({
                                where: 'published',
                                valor: true,
                                subAction: 'activate project',
                            })
                        "
                        v-else
                        raised
                        fill
                    >
                        Activate
                    </f7-button>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>
        <f7-toolbar tabbar top>
            <f7-link
                @click="activateTab('metaproject')"
                tab-link="#metaproject"
                :tab-link-active="toolbarActiveTab == 'metaproject'"
            >
                Metadata
            </f7-link>
            <f7-link
                @click="activateTab('entitydata')"
                tab-link="#entitydata"
                :tab-link-active="toolbarActiveTab == 'entitydata'"
            >
                {{ projectinfo.entity || "(entity)" }} Data
            </f7-link>
            <f7-link
                @click="activateTab('entityfiles')"
                tab-link="#entityfiles"
                :tab-link-active="toolbarActiveTab == 'entityfiles'"
            >
                {{ projectinfo.entity || "(entity)" }} Files
            </f7-link>
            <f7-link
                @click="activateTab('instituaccess')"
                tab-link="#instituaccess"
                :tab-link-active="toolbarActiveTab == 'instituaccess'"
            >
                {{ projectinfo.grouper || "Institutions" }}
            </f7-link>
            <f7-link
                v-if="myAdminPrivsHere.accounts || suprivx.admin"
                @click="activateTab('accountspro')"
                tab-link="#accountspro"
                :tab-link-active="toolbarActiveTab == 'accountspro'"
            >
                Accounts
            </f7-link>
            <f7-link
                v-if="projectInFocusInfo.isDeidentificationEnabled"
                @click="activateTab('phidictionary')"
                tab-link="#phidictionary"
                :tab-link-active="toolbarActiveTab == 'phidictionary'"
            >
                PHI Dictionary
            </f7-link>
        </f7-toolbar>
        <f7-tabs animated>
            <f7-tab v-if="toolbarActiveTab == 'metaproject'" id="metaproject" class="page-content" tab-active>
                <f7-block>
                    The project status is
                    {{
                        projectinfo.published
                            ? "ACTIVE. This means new data can be contributed by members"
                            : "INACTIVE. New data cannot be contributed"
                    }}.
                </f7-block>
                <f7-list media-list class="no-margin-vertical">
                    <f7-list-item
                        @click="openditer({ where: 'name', subAction: 'update name' })"
                        header="Project name"
                        link="#"
                        :title="projectinfo.name"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == `core_name`"
                            slot="after"
                        ></f7-preloader>
                        <font-awesome-icon slot="after" :icon="['fad', 'pen']"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-item
                        @click="openditer({ where: 'cemail', subAction: 'update contact email' })"
                        header="contact email"
                        footer="email will be publicly visible"
                        :class="{ 'text-color-red': !projectinfo.cemail }"
                        link="#"
                        :title="projectinfo.cemail || 'not set'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == `core_cemail`"
                            slot="after"
                        ></f7-preloader>
                        <font-awesome-icon slot="after" :icon="['fad', 'pen']"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-item
                        tooltip="This is the project description, public and visible inside the project box for all CIELO users if the project is visible."
                        @click="openditer({ where: 'descr', subAction: 'update project description' })"
                        class="nowhitespacetitle"
                        header="Project description"
                        link="#"
                        :class="{ 'text-color-red': !projectinfo.descr }"
                        :title="projectinfo.descr || 'not set'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == `core_descr`"
                            slot="after"
                        ></f7-preloader>
                        <font-awesome-icon slot="after" :icon="['fad', 'pen']"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-item
                        @click="openditer({ where: 'entity', subAction: 'update entity name' })"
                        header="Entity under study"
                        :class="{ 'text-color-red': !projectinfo.entity }"
                        footer="Use singular (i.e. Patient, not Patients)"
                        link="#"
                        :title="projectinfo.entity || 'not set'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == `core_entity`"
                            slot="after"
                        ></f7-preloader>
                        <font-awesome-icon slot="after" :icon="['fad', 'pen']"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-item
                        @click="openditer({ where: 'grouper', subAction: 'update project grouper' })"
                        header="User affiliation (e.g. Institution (default), Department)"
                        link="#"
                        :title="projectinfo.grouper || 'Institutions'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == `core_grouper`"
                            slot="after"
                        ></f7-preloader>
                        <font-awesome-icon slot="after" :icon="['fad', 'pen']"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-item divider title="Configuration"></f7-list-item>
                    <f7-list-item
                        title="Make project visible"
                        :footer="`Status: ${projectinfo.visible ? 'Visible' : 'Hidden'}`"
                        :class="!projectinfo.allowAccess ? '' : 'disabled'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_visible'"
                            slot="after"
                        ></f7-preloader>
                        <div
                            v-else-if="projectinfo.allowAccess"
                            slot="after"
                            class="margin-right-half fa-lg checkedColor"
                        >
                            <font-awesome-icon :icon="['fad', 'check']" />
                        </div>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="
                                modificaneoCore({
                                    where: 'visible',
                                    valor: $event,
                                    subAction: $event ? 'project visible' : 'project not visible',
                                })
                            "
                            :checked="projectinfo.visible"
                        ></f7-toggle>
                    </f7-list-item>
                    <f7-list-item
                        title="Allow access request"
                        :footer="`Status: ${projectinfo.allowAccess ? 'Joinable' : 'Closed'}`"
                        :class="projectinfo.visible ? '' : 'disabled'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_allowAccess'"
                            slot="after"
                        ></f7-preloader>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="
                                modificaneoCore({
                                    where: 'allowAccess',
                                    valor: $event,
                                    subAction: $event ? 'Allow access request' : 'access request not allowed',
                                })
                            "
                            :checked="projectinfo.allowAccess"
                        ></f7-toggle>
                    </f7-list-item>
                    <f7-list-item
                        title="Allow members to browse files' history"
                        :subtitle="`Status: ${projectinfo.history ? 'ON' : 'OFF'}`"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_history'"
                            slot="after"
                        ></f7-preloader>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="
                                modificaneoCore({
                                    where: 'history',
                                    valor: $event,
                                    subAction: $event
                                        ? `allow members to browse files' history`
                                        : `members not allowed to browse files' history`,
                                })
                            "
                            :checked="projectinfo.history"
                        ></f7-toggle>
                    </f7-list-item>
                    <f7-list-item
                        title="Activate terms and conditions"
                        :subtitle="`Status: ${projectinfo.terms ? 'ON' : 'OFF'}`"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_terms'"
                            slot="after"
                        ></f7-preloader>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="
                                modificaneoCore({
                                    where: 'terms',
                                    valor: $event,
                                    subAction: $event
                                        ? 'activate terms and conditions'
                                        : 'inactivate terms and conditions',
                                })
                            "
                            :checked="projectinfo.terms"
                        ></f7-toggle>
                    </f7-list-item>
                    <f7-list-item
                        title="Activate two step authentication"
                        :subtitle="`Status: ${projectinfo.twoSteps ? 'ON' : 'OFF'}`"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_twoSteps'"
                            slot="after"
                        ></f7-preloader>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="
                                modificaneoCore({
                                    where: 'twoSteps',
                                    valor: $event,
                                    subAction: $event
                                        ? 'activate two step authentication'
                                        : 'inactivate two step authentication',
                                })
                            "
                            :checked="projectinfo.twoSteps"
                        ></f7-toggle>
                    </f7-list-item>
                    <f7-list-item :subtitle="`Status: ${projectinfo.isPublicDashboardEnabled ? 'ON' : 'OFF'}`">
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_isPublicDashboardEnabled'"
                            slot="after"
                        ></f7-preloader>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="changePublicDashboardStatus($event)"
                            :checked="projectinfo.isPublicDashboardEnabled"
                        ></f7-toggle>

                        <span slot="title" class="display-flex">
                            Activate public dashboard
                            <span class="margin-left-half">&bull;</span>
                            <f7-link
                                slot="footer"
                                @click="isPublicDashboardConfigPopupOpened = true"
                                tooltip="Public dashboard settings"
                            >
                                <font-awesome-icon
                                    class="text-color-primary fa-sm margin-horizontal-half"
                                    :icon="['fad', 'gear']"
                                />
                            </f7-link>
                            <f7-link
                                v-if="publicDashboardURL"
                                slot="footer"
                                @click="copyURLToClipboard"
                                tooltip="Copy public dashboard URL to clipboard"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'clipboard']"
                                    class="fa-sm margin-right-half"
                                ></font-awesome-icon>
                            </f7-link>
                        </span>
                    </f7-list-item>
                    <f7-list-item :subtitle="`Status: ${projectinfo.isDeidentificationEnabled ? 'ON' : 'OFF'}`">
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == 'core_isDeidentificationEnabled'"
                            slot="after"
                        ></f7-preloader>
                        <f7-toggle
                            v-else
                            slot="after"
                            @toggle:change="
                                modificaneoCore({
                                    where: 'isDeidentificationEnabled',
                                    valor: $event,
                                    subAction: $event ? 'activate de-identification' : 'inactivate de-identification',
                                })
                            "
                            :checked="projectinfo.isDeidentificationEnabled"
                        ></f7-toggle>

                        <span slot="title" class="display-flex">Activate de-identification</span>
                    </f7-list-item>
                </f7-list>

                <f7-block>
                    <f7-block-title class="bold">Terms and conditions</f7-block-title>
                    <f7-button @click="termsPopupOpened = true" fill>Modify terms and conditions</f7-button>
                </f7-block>
                <f7-block>
                    <f7-list media-list>
                        <f7-list-item divider title="API keys"></f7-list-item>
                        <f7-list-item v-if="!sortedApiKeys.length">There are no API keys created</f7-list-item>
                        <f7-list-item
                            v-for="apikey in sortedApiKeys"
                            :key="apikey.id"
                            :title="apikey.name"
                            @click="openApiKeyPopup(apikey)"
                            link="#"
                        >
                            <div :style="getApikeyStatus(apikey) != 'ACTIVE' ? 'color:red' : ''" slot="footer">
                                {{ getApikeyStatus(apikey) }}
                            </div>
                            <div slot="after">
                                <f7-link class="margin-right">
                                    <font-awesome-icon :icon="['fad', 'pen']"></font-awesome-icon>
                                </f7-link>
                            </div>
                        </f7-list-item>
                    </f7-list>
                    <f7-button @click="newApikeyPopup = true" fill>
                        <font-awesome-icon :icon="['fad', 'plus']" />
                        Add new API key
                    </f7-button>
                    <br />
                    <f7-link
                        href="../static/api/CIELO_API_Documentation_v1.pdf"
                        target="_blank"
                        class="external no-ripple"
                    >
                        <font-awesome-icon :icon="['fad', 'download']" class="margin-right-half" />
                        Download API documentation
                    </f7-link>
                </f7-block>
                <f7-popup :opened="apiKeyPopup" @popup:closed="apiKeyPopup = false">
                    <f7-page>
                        <f7-navbar :title="focusedApikey.name" :subtitle="`API key ${getApikeyStatus(focusedApikey)}`">
                            <f7-nav-right>
                                <f7-link popup-close>Close</f7-link>
                            </f7-nav-right>
                        </f7-navbar>
                        <f7-list>
                            <f7-list-item
                                v-if="getApikeyStatus(focusedApikey) == 'EXPIRED'"
                                title="Api Key Status:"
                                disabled
                            >
                                <div slot="after" style="color: red">Expired</div>
                            </f7-list-item>
                            <f7-list-item v-else :title="`Api Key Status: ${getApikeyStatus(focusedApikey)}`">
                                <f7-preloader
                                    class="margin-right-half"
                                    v-if="guardandoeste == `${focusedApikey.id}inactive`"
                                    slot="after"
                                ></f7-preloader>
                                <f7-toggle
                                    v-else
                                    slot="after"
                                    @toggle:change="
                                        modificaneoPregunta({
                                            preguntaid: focusedApikey.id,
                                            where: 'inactive',
                                            valor: !$event,
                                            newQuestiontype: 'apiKeys',
                                            subAction: !$event ? 'inactivate api key' : 'activate api key',
                                        }),
                                            (focusedApikey.inactive = !$event)
                                    "
                                    :checked="!focusedApikey.inactive"
                                ></f7-toggle>
                            </f7-list-item>
                            <f7-list-item title="Daily limit" :after="focusedApikey.dailyLimit || '200'"></f7-list-item>
                        </f7-list>
                        <f7-list v-if="apiKeyPopup" class="no-margin-top" media-list>
                            <f7-list-item header="Name" :title="focusedApikey.name"></f7-list-item>
                            <f7-list-item header="API key" :title="focusedApikey.id">
                                <f7-link
                                    class="margin-left"
                                    @click="copyToClipboard(focusedApikey.id)"
                                    tooltip="Copy to clipboard"
                                    slot="title"
                                >
                                    <font-awesome-icon :icon="['fad', 'clipboard']" />
                                </f7-link>
                            </f7-list-item>
                            <f7-list-item header="API Secret" :title="focusedApikey.apiSecret">
                                <f7-link
                                    slot="title"
                                    class="margin-left"
                                    @click="copyToClipboard(focusedApikey.apiSecret)"
                                    tooltip="Copy to clipboard"
                                >
                                    <font-awesome-icon :icon="['fad', 'clipboard']" />
                                </f7-link>
                            </f7-list-item>
                            <f7-list-item divider>Expiration date</f7-list-item>
                            <f7-list-item
                                radio
                                name="expDate"
                                :checked="focusedApikey.expDateNever"
                                @change="
                                    modificaneoPregunta({
                                        preguntaid: focusedApikey.id,
                                        where: 'expDateNever',
                                        valor: true,
                                        newQuestiontype: 'apiKeys',
                                        ubAction: 'enable expiration date',
                                    }),
                                        (focusedApikey.expDateNever = true)
                                "
                                :style="!focusedApikey.expDateNever ? 'color:grey' : ''"
                            >
                                Never
                            </f7-list-item>
                            <f7-list-input
                                v-if="apiKeyPopup"
                                label="select an expiration date"
                                outline
                                type="datepicker"
                                class="align-self-auto"
                                :class="{
                                    expDateDisabled: focusedApikey.expDateNever,
                                }"
                                :calendar-params="{
                                    value: focusedApikey.expDate ? [focusedApikey.expDate] : [],
                                    dateFormat: 'mm/dd/yyyy',
                                    locale: 'en-US',
                                    closeOnSelect: true,
                                    disabled: [
                                        {
                                            to: $dayjs().subtract(1, 'day').toDate(),
                                        },
                                    ],
                                }"
                                @calendar:change="
                                    modificaneoPregunta({
                                        preguntaid: focusedApikey.id,
                                        where: 'expDate',
                                        valor: $dayjs($event[0]).format('MM/DD/YYYY'),
                                        newQuestiontype: 'apiKeys',
                                        subAction: 'update expiration date',
                                    }),
                                        (focusedApikey.expDate = $dayjs($event[0]).format('MM/DD/YYYY'))
                                "
                            >
                                <f7-radio
                                    name="expDate"
                                    slot="media"
                                    :checked="!focusedApikey.expDateNever"
                                    @change="
                                        modificaneoPregunta({
                                            preguntaid: focusedApikey.id,
                                            where: 'expDateNever',
                                            valor: null,
                                            newQuestiontype: 'apiKeys',
                                            subAction: 'delete expiration date',
                                        }),
                                            (focusedApikey.expDateNever = null)
                                    "
                                ></f7-radio>
                            </f7-list-input>
                            <f7-list-item divider>Institutions</f7-list-item>
                            <f7-list-item v-for="inst in instituionsSorted" :key="inst.id" :title="inst.name">
                                <f7-preloader
                                    class="margin-right-half"
                                    v-if="guardandoeste == inst.id"
                                    slot="after"
                                ></f7-preloader>
                                <f7-toggle
                                    v-else
                                    slot="after"
                                    @toggle:change="
                                        selectInstitution({
                                            institid: inst.id,
                                            valor: $event,
                                            preguntaid: focusedApikey.id,
                                            action: 'Institution toggle',
                                            newQuestiontype: 'apiKeys',
                                        }),
                                            (focusedApikey.institutions[inst.id] = $event ? true : null)
                                    "
                                    :checked="focusedApikey.institutions && focusedApikey.institutions[inst.id]"
                                ></f7-toggle>
                            </f7-list-item>
                        </f7-list>
                    </f7-page>
                </f7-popup>
                <f7-popup :opened="newApikeyPopup" @popup:closed="closeNewApikeyPopup()">
                    <f7-page>
                        <f7-navbar title="Creating new a API key">
                            <f7-nav-right>
                                <f7-link popup-close>Close</f7-link>
                            </f7-nav-right>
                        </f7-navbar>
                        <f7-list v-if="newApikeyPopup" class="no-margin-top">
                            <f7-list-item divider>API key Name</f7-list-item>
                            <f7-list-input
                                outline
                                :value="newApikey.name"
                                @input="newApikey.name = $event.target.value"
                            />
                            <f7-list-item divider>Expiration date</f7-list-item>
                            <f7-list-item
                                radio
                                name="newExpDate"
                                :checked="newApikey.expDateNever"
                                @change="newApikey.expDateNever = true"
                                :style="!newApikey.expDateNever ? 'color:grey' : ''"
                            >
                                Never
                            </f7-list-item>
                            <f7-list-input
                                v-if="newApikeyPopup"
                                label="select an expiration date"
                                outline
                                type="datepicker"
                                class="align-self-auto"
                                :class="{
                                    expDateDisabled: newApikey.expDateNever,
                                }"
                                :calendar-params="{
                                    value: newApikey.expDate ? [newApikey.expDate] : [],
                                    dateFormat: 'mm/dd/yyyy',
                                    locale: 'en-US',
                                    closeOnSelect: true,
                                    disabled: [
                                        {
                                            to: $dayjs().subtract(1, 'day').toDate(),
                                        },
                                    ],
                                }"
                                @calendar:change="newApikey.expDate = $dayjs($event[0]).format('MM/DD/YYYY')"
                            >
                                <f7-radio
                                    name="newExpDate"
                                    slot="media"
                                    :checked="!newApikey.expDateNever"
                                    @change="newApikey.expDateNever = null"
                                ></f7-radio>
                            </f7-list-input>

                            <f7-list-item divider>Institutions</f7-list-item>
                            <f7-list-item v-for="inst in instituionsSorted" :key="inst.id" :title="inst.name">
                                <f7-preloader
                                    class="margin-right-half"
                                    v-if="guardandoeste == inst.id"
                                    slot="after"
                                ></f7-preloader>
                                <f7-toggle
                                    v-else
                                    slot="after"
                                    @toggle:change="
                                        newApikey.institutions[inst.id] = $event
                                            ? true
                                            : delete newApikey.institutions[inst.id]
                                    "
                                    :checked="newApikey.institutions && newApikey.institutions[inst.id]"
                                ></f7-toggle>
                            </f7-list-item>
                            <f7-list-item divider>Daily Limit</f7-list-item>
                            <f7-list-item :title="`Limit: ${newApikey.dailyLimit}`"></f7-list-item>
                        </f7-list>
                        <f7-button @click="saveApikey()" fill>Save API key</f7-button>
                    </f7-page>
                </f7-popup>

                <f7-row>
                    <f7-col width="100" medium="50">
                        <f7-block-title class="bold">Project Banner</f7-block-title>
                        <f7-block-header>Upload a descriptive banner for your project (public)</f7-block-header>
                        <f7-card class="card-project-pic">
                            <f7-card-header
                                class="no-border"
                                valign="bottom"
                                :style="`background-image:url(${
                                    projects[adminprojectFocus].banner || '../static/heros/loginback_1.jpg'
                                })`"
                                :key="projects[adminprojectFocus].banner"
                            >
                                Project banner
                            </f7-card-header>
                            <f7-card-footer>
                                <span>1000px x 600px JPG or similar aspect ratio</span>
                            </f7-card-footer>
                            <f7-card-footer>
                                <span></span>
                                <f7-button @click="preuploadImage()" fill raised>Upload new</f7-button>
                            </f7-card-footer>
                        </f7-card>
                    </f7-col>
                    <f7-col width="100" medium="50">
                        <f7-block-title class="bold">Project 'About' Menu</f7-block-title>
                        <f7-block-header>
                            Provide further private information for your project members, available on the Project left
                            menu.
                        </f7-block-header>
                        <f7-block
                            class="linfoabount margin-horizontal no-margin-vertical"
                            v-html="infoaboutoriginal || 'Loading...'"
                        ></f7-block>
                        <f7-block class="margin-top text-align-right">
                            <f7-link @click="leaboutOpened = true">
                                <font-awesome-icon
                                    slot="after"
                                    class="margin-horizontal"
                                    :icon="['fad', 'pen']"
                                ></font-awesome-icon>
                                Edit section
                            </f7-link>
                        </f7-block>
                    </f7-col>
                </f7-row>

                <input type="file" id="projectavatar-input" @change="filesChange($event)" accept="image/*" />
                <f7-block>
                    <f7-link
                        download="config.json"
                        :external="true"
                        :href="`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(projectinfo))}`"
                        @click="exportProjectConfigSetNewLog()"
                    >
                        Export project configuration
                        <font-awesome-icon
                            class="margin-left-half fa-lg"
                            :icon="['fad', 'cloud-arrow-down']"
                        ></font-awesome-icon>
                    </f7-link>
                </f7-block>
            </f7-tab>
            <f7-tab v-if="toolbarActiveTab == 'entitydata'" id="entitydata" class="page-content">
                <f7-list class="no-margin-bottom">
                    <f7-list-item
                        @click="openditer({ where: 'primaryid', subAction: 'update primary id' })"
                        header="Name of primary ID"
                        :class="{ 'text-color-red': !projectinfo.primaryid }"
                        link="#"
                        :title="projectinfo.primaryid || 'not set'"
                    >
                        <f7-preloader
                            class="margin-right-half"
                            v-if="guardandoeste == `core_primaryid`"
                            slot="after"
                        ></f7-preloader>
                        <font-awesome-icon slot="after" :icon="['fad', 'pen']"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-input
                        label="Data type for primary ID"
                        type="select"
                        outline
                        :value="projectinfo.primaryidType || ''"
                        placeholder="Please choose..."
                        @change="
                            modificaneoCore({
                                where: 'primaryidType',
                                valor: $event.target.value,
                                subAction: 'update data type for primary ID',
                            })
                        "
                    >
                        <option value="">Alphanumeric</option>
                        <option value="num">Numerical only (integer)</option>
                        <option value="let">Letters only</option>
                    </f7-list-input>
                </f7-list>
                <f7-row no-gap>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input
                                label="Minimun length of primary ID"
                                type="select"
                                outline
                                :value="projectinfo.primaryidMinLen || ''"
                                placeholder="Please choose..."
                                @change="
                                    changeLimitLength({
                                        where: 'primaryidMinLen',
                                        valor: $event.target.value,
                                    })
                                "
                            >
                                <option value="">Not set</option>
                                <option v-for="varlorx in lenghlimits.min" :key="varlorx" :value="varlorx">
                                    {{ varlorx }}
                                </option>
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input
                                label="Maximum length of primary ID"
                                type="select"
                                outline
                                :value="projectinfo.primaryidMaxLen || ''"
                                placeholder="Please choose..."
                                @change="
                                    changeLimitLength({
                                        where: 'primaryidMaxLen',
                                        valor: $event.target.value,
                                    })
                                "
                            >
                                <option value="">Not set</option>
                                <option v-for="varlorx in lenghlimits.max" :key="varlorx" :value="varlorx">
                                    {{ varlorx }}
                                </option>
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-block>
                    All entities will require a unique identifier for this project created outside CIELO. Here you can
                    define what other entity metadata you will capture.
                </f7-block>
                <f7-block-title>{{ projectinfo.entity }} Data</f7-block-title>
                <f7-block v-if="projectinfo.entityData" class="no-margin-vertical">
                    <f7-preloader v-if="updt == 'sort_pat_data'"></f7-preloader>
                    <f7-link v-else sortable-toggle=".headpreg">
                        <font-awesome-icon
                            :icon="['fad', 'arrow-up-1-9']"
                            class="fa-lg margin-right-half"
                        ></font-awesome-icon>
                        Sort {{ projectinfo.entity }} Properties
                    </f7-link>
                </f7-block>
                <f7-block v-else>No fields created</f7-block>
                <f7-list
                    class="no-margin-top headpreg fixfamarg"
                    inset
                    sortable
                    sortable-opposite
                    @sortable:sort="onSort"
                    accordion-list
                >
                    <f7-list-item
                        v-for="(unapregunta, idx) in pdataSorted"
                        :key="unapregunta.id"
                        accordion-item
                        @accordion:open="(viewingPreguntaId = unapregunta.id), (arrayToDelete = [])"
                        :header="`${forms_logic.tipos_preg[unapregunta.tipo].nm} - ${
                            unapregunta.req ? 'Required' : 'Optional'
                        }`"
                    >
                        <div slot="title" class="bloqueamultilinea" v-html="unapregunta.encabezado"></div>
                        <div slot="after">
                            <f7-link v-if="unapregunta.depde" class="margin-right-half">
                                <font-awesome-icon
                                    :icon="['fad', 'code-branch']"
                                    class="fa-lg fa-rotate-180"
                                ></font-awesome-icon>
                            </f7-link>
                            <f7-link
                                class="text-color-red"
                                v-if="warnings_by_preg[unapregunta.id]"
                                :tooltip="warnings_by_preg[unapregunta.id]"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'triangle-exclamation']"
                                    class="fa-lg"
                                ></font-awesome-icon>
                            </f7-link>
                        </div>
                        <f7-accordion-content>
                            <f7-list media-list class="margin" inset>
                                <f7-list-item
                                    header="Header"
                                    :title="unapregunta.encabezado"
                                    no-chevron
                                    link="#"
                                    @click="abreEditorEnunciado(unapregunta.id, 'entityData')"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'encabezado'"
                                        slot="after"
                                    ></f7-preloader>
                                    <font-awesome-icon
                                        :icon="['fad', 'pen']"
                                        slot="after"
                                        class="fa-lg"
                                    ></font-awesome-icon>
                                </f7-list-item>
                                <f7-list-item
                                    header="Information"
                                    :text="unapregunta.linfo"
                                    no-chevron
                                    link="#"
                                    @click="abreEditorLinfo(unapregunta.id, 'entityData')"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'linfo'"
                                        slot="after"
                                    ></f7-preloader>
                                    <div slot="after">
                                        <font-awesome-icon :icon="['fad', 'pen']" class="fa-lg" />
                                        <a
                                            v-if="unapregunta.linfo"
                                            @click.stop="eliminarLinfo(unapregunta.id, 'entityData')"
                                        >
                                            <font-awesome-icon
                                                color="gray"
                                                class="fa-lg margin-left"
                                                :icon="['fad', 'trash']"
                                            />
                                        </a>
                                    </div>
                                    <i slot="footer" v-if="!unapregunta.linfo">(Here you can set the info box)</i>
                                </f7-list-item>

                                <f7-list-item
                                    v-if="
                                        unapregunta.depde &&
                                        (!projectinfo.entityData[unapregunta.depde.pregid] ||
                                            projectinfo.entityData[unapregunta.depde.pregid].disabled)
                                    "
                                    class="text-color-red"
                                    header="Branching logic: triggered by"
                                    title="PROPERTY WAS DELETED"
                                    subtitle="DELETE THIS RULE"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'depde'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-link
                                        @click="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'depde',
                                                valor: null,
                                                newQuestiontype: 'entityData',
                                                subAction: 'do not depends on other field',
                                            })
                                        "
                                        color="gray"
                                        slot="after"
                                    >
                                        <font-awesome-icon :icon="['fad', 'trash']"></font-awesome-icon>
                                    </f7-link>
                                </f7-list-item>
                                <f7-list-item
                                    v-else-if="unapregunta.depde && projectinfo.entityData[unapregunta.depde.pregid]"
                                    header="Branching logic: triggered by"
                                    :title="striphtml(projectinfo.entityData[unapregunta.depde.pregid].encabezado)"
                                    :subtitle="forms_logic.retrix_types[unapregunta.depde.tipo].nm"
                                    :text="
                                        getAmostrar(unapregunta.depde[unapregunta.depde.tipo], unapregunta.depde.pregid)
                                    "
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'depde'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-link
                                        @click="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'depde',
                                                valor: null,
                                                newQuestiontype: 'entityData',
                                                subAction: 'do not depends on other field',
                                            })
                                        "
                                        color="gray"
                                        slot="after"
                                    >
                                        <font-awesome-icon :icon="['fad', 'trash']"></font-awesome-icon>
                                    </f7-link>
                                </f7-list-item>
                                <f7-list-item v-else header="Branching logic" title="Always visible">
                                    <f7-button
                                        v-if="idx > 0"
                                        @click="editaReglaPoppOpened = idx"
                                        color="teal"
                                        outline
                                        slot="after"
                                    >
                                        Create rule
                                    </f7-button>
                                    <span v-else slot="after">Rules from property #2 onwards</span>
                                </f7-list-item>

                                <f7-list-item
                                    title="Mandatory field"
                                    footer="Only affects properties that are visible"
                                    :class="{
                                        disabled:
                                            (unapregunta.tipo == 'elige_una' || unapregunta.tipo == 'elige_many') &&
                                            (!unapregunta.alternativas ||
                                                !Object.keys(unapregunta.alternativas).length),
                                    }"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'req'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        v-else
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'req',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                                subAction: $event ? 'mandatory field' : 'not mandatory field',
                                            })
                                        "
                                        :checked="unapregunta.req"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item title="Display in table snapshot">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'visible'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        v-else
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'visible',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                                subAction: $event
                                                    ? 'visible in table snapshot'
                                                    : 'not visible in table snapshot',
                                            })
                                        "
                                        :checked="unapregunta.visible"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item
                                    :class="{
                                        disabled: unapregunta.tipo != 'elige_una' && unapregunta.tipo != 'input_numero',
                                    }"
                                    title="Chart in private dashboard"
                                    footer="Only available for numerical inputs and multiple choice with select one"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'charteable'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'charteable',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                                subAction: $event
                                                    ? 'visible in private dashboard'
                                                    : 'not visible in private dashboard',
                                            })
                                        "
                                        :checked="unapregunta.charteable"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item
                                    :class="{
                                        disabled: unapregunta.tipo != 'elige_una' && unapregunta.tipo != 'input_numero',
                                    }"
                                    title="Chart in public dashboard"
                                    footer="Only available for numerical inputs and multiple choice with select one"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'charteablePublic'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'charteablePublic',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                                subAction: $event
                                                    ? 'visible in public dashboard'
                                                    : 'not visible in public dashboard',
                                            })
                                        "
                                        :checked="unapregunta.charteablePublic"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item
                                    v-if="unapregunta.tipo == 'input_fecha'"
                                    title="Mark as Duration Field"
                                    footer="Marking this field as a Duration type calculates the time from the date in this field to the current date. Specify a name for this duration (e.g., 'Age') for relevant charts in the dashboard, which will display mean and standard deviation"
                                    :disabled="!unapregunta.isDuration && isDurationFieldAlreadySet"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'isDuration'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'isDuration',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                            })
                                        "
                                        :checked="unapregunta.isDuration"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item
                                    v-if="unapregunta.tipo == 'input_fecha' && unapregunta.isDuration"
                                    header="Specify a name for this duration"
                                    :title="unapregunta.durationName"
                                    no-chevron
                                    link="#"
                                    @click="editDurationName(unapregunta.id)"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'durationName'"
                                        slot="after"
                                    ></f7-preloader>
                                    <font-awesome-icon
                                        :icon="['fad', 'pen']"
                                        slot="after"
                                        class="fa-lg"
                                    ></font-awesome-icon>
                                </f7-list-item>
                                <f7-list-item
                                    v-if="unapregunta.tipo == 'elige_una'"
                                    title="Use this field to filter charts in the dashboard"
                                    footer="When you mark this field as a filter, it enables filtering on Categorical type fields in the dashboard (only one filter can be active at a time)"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'isFilterInDashboard'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        :disabled="otherFieldIsFilterInDashboard(unapregunta.id)"
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'isFilterInDashboard',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                                subAction: $event
                                                    ? 'Is filter in dashboard'
                                                    : 'Is not filter in dashboard',
                                            })
                                        "
                                        :checked="unapregunta.isFilterInDashboard"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item
                                    v-if="
                                        (unapregunta.tipo == 'elige_una' || unapregunta.tipo == 'elige_many') &&
                                        (unapregunta.charteable || unapregunta.charteablePublic)
                                    "
                                    title="Sort in dashboard alphabetically instead of by frequency"
                                    footer="By default the dashboard sorts the options by frequency, this option will sort them alphabetically"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'sortAlphabetically'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'sortAlphabetically',
                                                valor: $event,
                                                newQuestiontype: 'entityData',
                                            })
                                        "
                                        :checked="unapregunta.sortAlphabetically"
                                    ></f7-toggle>
                                </f7-list-item>
                                <template v-if="unapregunta.tipo == 'elige_una' || unapregunta.tipo == 'elige_many'">
                                    <f7-list-item class="altoalternativa" divider title="Options"></f7-list-item>
                                    <div class="fozaltox">
                                        <f7-list-item
                                            class="text-color-red"
                                            v-if="
                                                !unapregunta.alternativas ||
                                                !Object.keys(unapregunta.alternativas).length
                                            "
                                            title="There are no options for this property"
                                        ></f7-list-item>
                                        <template
                                            v-if="
                                                filtrameAlternativas(unapregunta.alternativas).length <
                                                MAX_ALTERNATIVES_TO_SHOW
                                            "
                                        >
                                            <f7-block
                                                v-if="filtrameAlternativas(unapregunta.alternativas).length"
                                                class="no-margin padding-bottom padding-top lesticky deleteItem"
                                            >
                                                <f7-row>
                                                    <f7-col>
                                                        <f7-checkbox
                                                            :disabled="
                                                                filtrameAlternativas(unapregunta.alternativas).length ==
                                                                1
                                                            "
                                                            :checked="
                                                                arrayToDelete.length > 0 &&
                                                                arrayToDelete.length ==
                                                                    filtrameAlternativas(unapregunta.alternativas)
                                                                        .length
                                                            "
                                                            @change="
                                                                selectAllToDelete(
                                                                    $event.target.checked,
                                                                    filtrameAlternativas(unapregunta.alternativas),
                                                                )
                                                            "
                                                        />
                                                        <span class="margin-left">Select all</span>
                                                    </f7-col>
                                                    <f7-col class="text-align-right">
                                                        <f7-link
                                                            @click="deleteSelected(unapregunta)"
                                                            :class="{
                                                                disabled: !arrayToDelete.length,
                                                            }"
                                                        >
                                                            Delete Selected
                                                            <font-awesome-icon
                                                                class="margin-left"
                                                                :icon="['fad', 'trash']"
                                                            />
                                                        </f7-link>
                                                    </f7-col>
                                                </f7-row>
                                            </f7-block>
                                            <f7-list-item
                                                v-for="alternativa in filtrameAlternativas(unapregunta.alternativas)"
                                                :key="alternativa.id"
                                                :title="alternativa.tx"
                                            >
                                                <div slot="media">
                                                    <f7-checkbox
                                                        :disabled="
                                                            filtrameAlternativas(unapregunta.alternativas).length == 1
                                                        "
                                                        :checked="arrayToDelete.includes(alternativa.id)"
                                                        @change="selectTodelete($event.target.checked, alternativa.id)"
                                                    />
                                                </div>
                                                <f7-preloader
                                                    class="margin-right-half"
                                                    v-if="
                                                        guardandoeste ==
                                                        unapregunta.id + 'elimina_alternativa' + alternativa.id
                                                    "
                                                    slot="after"
                                                ></f7-preloader>
                                                <f7-link
                                                    :class="{
                                                        disabled:
                                                            filtrameAlternativas(unapregunta.alternativas).length == 1,
                                                    }"
                                                    color="gray"
                                                    @click="
                                                        eliminaAlternativa(unapregunta.id, 'entityData', alternativa.id)
                                                    "
                                                    slot="after"
                                                >
                                                    <font-awesome-icon
                                                        :icon="['fad', 'trash']"
                                                        class="fa-lg"
                                                    ></font-awesome-icon>
                                                </f7-link>
                                            </f7-list-item>
                                        </template>
                                        <template v-else>
                                            <f7-link
                                                class="margin no-ripple"
                                                @click="seeAlternatives(unapregunta.alternativas, unapregunta.id)"
                                            >
                                                There are more than 25 alternatives to display. Click here to show them
                                                all.
                                            </f7-link>
                                            <f7-button
                                                v-if="selectedAlts.length"
                                                fill
                                                color="red"
                                                class="margin"
                                                @click="deleteSelectedAlts(unapregunta.id)"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fad', 'trash']"
                                                    class="margin-right-half"
                                                ></font-awesome-icon>
                                                Delete
                                                {{ selectedAlts.length }}
                                                selected alternative{{ selectedAlts.length > 1 ? "s" : "" }}
                                            </f7-button>
                                        </template>
                                    </div>
                                    <f7-list-item>
                                        <f7-row>
                                            <f7-col>
                                                <f7-button
                                                    @click="creaAlternativa(unapregunta.id, 'entityData')"
                                                    outline
                                                    color="teal"
                                                >
                                                    <font-awesome-icon :icon="['fad', 'plus']"></font-awesome-icon>
                                                    Add option
                                                </f7-button>
                                            </f7-col>
                                            <f7-col>
                                                <f7-button @click="openDelimiter(unapregunta.id)">
                                                    Parse options from delimited text
                                                </f7-button>
                                            </f7-col>
                                            <f7-col>
                                                <f7-button @click="importCSV(unapregunta.id)">
                                                    Parse options from a CSV file
                                                </f7-button>
                                                <input
                                                    id="csv-input"
                                                    type="file"
                                                    @change="csvFileChanged($event, unapregunta.id)"
                                                    accept=".csv"
                                                    style="display: none"
                                                />
                                            </f7-col>
                                        </f7-row>
                                    </f7-list-item>
                                </template>
                                <template v-else-if="unapregunta.tipo == 'input_texto'">
                                    <f7-list-item class="altoalternativa" divider title="Settings"></f7-list-item>
                                    <f7-list-item title="Allow multiple lines of text (textarea)">
                                        <f7-preloader
                                            class="margin-right-half"
                                            v-if="guardandoeste == unapregunta.id + 'txtarea'"
                                            slot="after"
                                        ></f7-preloader>
                                        <f7-toggle
                                            v-else
                                            slot="after"
                                            @toggle:change="
                                                modificaneoPregunta({
                                                    preguntaid: unapregunta.id,
                                                    where: 'txtarea',
                                                    valor: $event,
                                                    newQuestiontype: 'entityData',
                                                    subAction: $event
                                                        ? 'Allow multiple lines of text'
                                                        : 'denied multiple lines of text',
                                                })
                                            "
                                            :checked="unapregunta.txtarea"
                                        ></f7-toggle>
                                    </f7-list-item>
                                </template>
                                <template v-else-if="unapregunta.tipo == 'input_numero'">
                                    <f7-list-item
                                        class="altoalternativa"
                                        divider
                                        title="Configuraciones"
                                    ></f7-list-item>
                                    <f7-list-item header="Set lower limit">
                                        <f7-segmented slot="title" class="setwithseg" strong>
                                            <f7-button
                                                @click="
                                                    modificaneoPregunta({
                                                        preguntaid: unapregunta.id,
                                                        where: 'lowerLimit',
                                                        valor: null,
                                                        newQuestiontype: 'entityData',
                                                        subAction: 'no lower limit',
                                                    })
                                                "
                                                :active="unapregunta.lowerLimit == null"
                                            >
                                                No min
                                            </f7-button>
                                            <f7-button
                                                @click="setLimiteNum(unapregunta.id, 'lower')"
                                                :active="unapregunta.lowerLimit != null"
                                            >
                                                Set min
                                            </f7-button>
                                        </f7-segmented>
                                        <f7-preloader
                                            slot="after"
                                            v-if="guardandoeste == unapregunta.id + 'lowerLimit'"
                                        ></f7-preloader>
                                        <f7-chip
                                            :text="unapregunta.lowerLimit == null ? '--' : unapregunta.lowerLimit"
                                            slot="after"
                                        ></f7-chip>
                                    </f7-list-item>
                                    <f7-list-item header="Set upper limit">
                                        <f7-segmented slot="title" class="setwithseg" strong>
                                            <f7-button
                                                @click="
                                                    modificaneoPregunta({
                                                        preguntaid: unapregunta.id,
                                                        where: 'upperLimit',
                                                        valor: null,
                                                        newQuestiontype: 'entityData',
                                                        subAction: 'no upper limit',
                                                    })
                                                "
                                                :active="unapregunta.upperLimit == null"
                                            >
                                                No max
                                            </f7-button>
                                            <f7-button
                                                @click="setLimiteNum(unapregunta.id, 'upper')"
                                                :active="unapregunta.upperLimit != null"
                                            >
                                                Set max
                                            </f7-button>
                                        </f7-segmented>
                                        <f7-preloader
                                            slot="after"
                                            v-if="guardandoeste == unapregunta.id + 'upperLimit'"
                                        ></f7-preloader>
                                        <f7-chip
                                            :text="unapregunta.upperLimit == null ? '--' : unapregunta.upperLimit"
                                            slot="after"
                                        ></f7-chip>
                                    </f7-list-item>
                                </template>
                            </f7-list>
                            <f7-block class="margin-bottom">
                                <f7-button @click="eliminaPregunta(unapregunta.id, 'entityData')" color="red">
                                    <font-awesome-icon :icon="['fad', 'folder-minus']"></font-awesome-icon>
                                    Delete
                                    {{ projectinfo.entity }} Property
                                </f7-button>
                            </f7-block>
                        </f7-accordion-content>
                        <font-awesome-icon
                            :icon="forms_logic.tipos_preg[unapregunta.tipo].iko"
                            class="fa-lg text-color-deeppurple"
                            slot="media"
                        ></font-awesome-icon>
                    </f7-list-item>
                </f7-list>
                <f7-block><f7-button @click="abrePopTypes($event)" fill raised>Add new field</f7-button></f7-block>
            </f7-tab>
            <f7-tab v-if="toolbarActiveTab == 'entityfiles'" id="entityfiles" class="page-content">
                <f7-block>
                    Here you can specify data files that can or should be uploaded for
                    {{ projectinfo.entity ? `a ${projectinfo.entity}` : "an entity" }}.
                </f7-block>
                <f7-block v-if="projectinfo.entityFiles" class="no-margin-vertical">
                    <f7-preloader v-if="updt == 'sort_entityFiles'"></f7-preloader>
                    <f7-link v-else sortable-toggle=".headpregdos">
                        <font-awesome-icon
                            :icon="['fad', 'arrow-up-1-9']"
                            class="fa-lg margin-right-half"
                        ></font-awesome-icon>
                        Sort {{ projectinfo.entity }} Files
                    </f7-link>
                </f7-block>
                <f7-block v-else>No files created</f7-block>
                <f7-list
                    class="no-margin-top headpregdos fixfamarg"
                    inset
                    sortable
                    sortable-opposite
                    @sortable:sort="onSortFiles"
                    accordion-list
                >
                    <f7-list-item
                        v-for="unapregunta in pfilesSorted"
                        :key="unapregunta.id"
                        accordion-item
                        :header="`${unapregunta.tipo == 'a' ? 'any' : forms_logic.tipos_files[unapregunta.tipo].id} - ${
                            unapregunta.req ? 'Required' : 'Optional'
                        }`"
                    >
                        <div slot="title" class="bloqueamultilinea" v-html="unapregunta.encabezado"></div>
                        <f7-accordion-content>
                            <f7-list media-list class="margin" inset>
                                <f7-list-item
                                    header="Header"
                                    :title="unapregunta.encabezado"
                                    no-chevron
                                    link="#"
                                    @click="abreEditorEnunciado(unapregunta.id, 'entityFiles')"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'encabezado'"
                                        slot="after"
                                    ></f7-preloader>
                                    <font-awesome-icon
                                        :icon="['fad', 'pen']"
                                        slot="after"
                                        class="fa-lg"
                                    ></font-awesome-icon>
                                </f7-list-item>
                                <f7-list-item
                                    header="Information"
                                    :text="unapregunta.linfo"
                                    no-chevron
                                    link="#"
                                    @click="abreEditorLinfo(unapregunta.id, 'entityFiles')"
                                >
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'linfo'"
                                        slot="after"
                                    ></f7-preloader>
                                    <div slot="after">
                                        <font-awesome-icon :icon="['fad', 'pen']" class="fa-lg" />
                                        <a
                                            v-if="unapregunta.linfo"
                                            @click.stop="eliminarLinfo(unapregunta.id, 'entityFiles')"
                                        >
                                            <font-awesome-icon
                                                color="gray"
                                                class="fa-lg margin-left"
                                                :icon="['fad', 'trash']"
                                            />
                                        </a>
                                    </div>
                                    <i slot="footer" v-if="!unapregunta.linfo">(Here you can set the info box)</i>
                                </f7-list-item>
                                <f7-list-item title="Mandatory field">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'req'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        v-else
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'req',
                                                valor: $event,
                                                newQuestiontype: 'entityFiles',
                                                subAction: $event ? 'mandatory field' : 'not mandatory field',
                                            })
                                        "
                                        :checked="unapregunta.req"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item title="Can be multiple?">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'many'"
                                        slot="after"
                                    ></f7-preloader>
                                    <div slot="after">
                                        {{ unapregunta.many ? "YES" : "NO" }}
                                    </div>
                                </f7-list-item>
                                <f7-list-item title="Display in table snapshot">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'visible'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        v-else
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'visible',
                                                valor: $event,
                                                newQuestiontype: 'entityFiles',
                                                subAction: $event
                                                    ? 'visible in table snapshot'
                                                    : 'not visible in table snapshot',
                                            })
                                        "
                                        :checked="unapregunta.visible"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item title="Visible in private dashboard">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'charteable'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        v-else
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'charteable',
                                                valor: $event,
                                                newQuestiontype: 'entityFiles',
                                                subAction: $event
                                                    ? 'visible in private dashboard'
                                                    : 'not visible in private dashboard',
                                            })
                                        "
                                        :checked="unapregunta.charteable"
                                    ></f7-toggle>
                                </f7-list-item>
                                <f7-list-item title="Visible in public dashboard">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'charteablePublic'"
                                        slot="after"
                                    ></f7-preloader>
                                    <f7-toggle
                                        v-else
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'charteablePublic',
                                                valor: $event,
                                                newQuestiontype: 'entityFiles',
                                                subAction: $event
                                                    ? 'visible in public dashboard'
                                                    : 'not visible in public dashboard',
                                            })
                                        "
                                        :checked="unapregunta.charteablePublic"
                                    ></f7-toggle>
                                </f7-list-item>
                                <!-- <f7-list-item title="This field contains PHI (Personal Health Information)">
                                    <f7-preloader
                                        class="margin-right-half"
                                        v-if="guardandoeste == unapregunta.id + 'notPhi'"
                                        slot="after"></f7-preloader>
                                    <f7-toggle
                                        slot="after"
                                        @toggle:change="
                                            modificaneoPregunta({
                                                preguntaid: unapregunta.id,
                                                where: 'notPhi',
                                                valor: !$event,
                                                newQuestiontype: 'entityFiles',
                                                subAction: !$event ? 'enable PHI' : 'disable PHI',
                                            })
                                        "
                                        :checked="!unapregunta.notPhi"></f7-toggle>
                                </f7-list-item> -->
                            </f7-list>
                            <f7-block class="margin-bottom">
                                <f7-button @click="eliminaPregunta(unapregunta.id, 'entityFiles')" color="red">
                                    <font-awesome-icon :icon="['fad', 'folder-minus']"></font-awesome-icon>
                                    Delete
                                    {{ projectinfo.entity }} File
                                </f7-button>
                            </f7-block>
                        </f7-accordion-content>
                        <font-awesome-icon
                            :icon="forms_logic.tipos_files[unapregunta.tipo].iko"
                            :class="`fa-lg text-color-${
                                forms_logic.tipos_files[unapregunta.tipo].color || 'deeppurple'
                            }`"
                            slot="media"
                        ></font-awesome-icon>
                    </f7-list-item>
                </f7-list>
                <f7-block><f7-button @click="abreFileTypes($event)" fill raised>Add new file</f7-button></f7-block>
                <f7-block>
                    <f7-block-title>Tags</f7-block-title>
                    Here you can create tags for uploaded files
                </f7-block>
                <f7-block>
                    <f7-chip
                        deleteable
                        v-for="tag in tagsSorted"
                        :key="tag.id"
                        class="filterBadge link"
                        @click="changeTagText(tag)"
                        @delete.stop="inactivateTag(tag)"
                    >
                        {{ tag.tx }}
                    </f7-chip>
                </f7-block>
                <f7-block>
                    <f7-button @click="addNewTag()" fill raised>Add new tag</f7-button>
                </f7-block>
            </f7-tab>
            <f7-tab v-if="toolbarActiveTab == 'instituaccess'" id="instituaccess" class="page-content">
                <f7-block>
                    Here you can create and manage the
                    {{ projectinfo.grouper || "institutions" }} that will be allowed to participate in the study
                </f7-block>
                <f7-list media-list>
                    <f7-list-item v-for="instix in instituionsSorted" :key="instix.id" header="Name">
                        <div :class="instix.inactive ? 'disabled' : ''" slot="title">
                            {{ instix.name }}
                        </div>
                        <div slot="after">
                            <f7-link @click="openTimezonePopup(instix.id)" class="margin-right">
                                {{ instix.tz || "America/Chicago" }} ({{
                                    getUTC(instix.tz || "America/Chicago") || "-06:00"
                                }})
                            </f7-link>
                            <f7-link :class="instix.inactive ? 'disabled' : ''" @click="addnewInstituion(instix)">
                                <font-awesome-icon :icon="['fad', 'pen']" class="margin-right"></font-awesome-icon>
                            </f7-link>
                            <f7-link
                                v-if="!instix.inactive"
                                @click="attempToInactivateInstitution({ instix, actOrInact: true })"
                                tooltip="Inactivate"
                            >
                                <font-awesome-icon :icon="['fad', 'power-off']"></font-awesome-icon>
                            </f7-link>
                            <f7-link v-else @click="activateOrInactivateIntitution({ instix })" tooltip="Activate">
                                <font-awesome-icon color="gray" :icon="['fad', 'power-off']"></font-awesome-icon>
                            </f7-link>
                        </div>
                        <div v-if="instix.inactive" slot="footer">Inactive</div>
                    </f7-list-item>
                </f7-list>
                <f7-block v-if="!projectinfo.institutions || !instituionsSorted.length">
                    No {{ projectinfo.grouper || "institutions" }} created. You need to create at least one in order to
                    authorize users.
                </f7-block>
                <f7-block><f7-button @click="addnewInstituion()" fill raised>Add New Institution</f7-button></f7-block>
            </f7-tab>
            <f7-tab
                v-if="toolbarActiveTab == 'accountspro' && (myAdminPrivsHere.accounts || suprivx.admin)"
                id="accountspro"
                class="page-content"
            >
                <f7-block class="stikySearchbar no-margin">
                    <f7-searchbar
                        v-if="!cargando"
                        search-container=".search-list"
                        search-in=".item-title, .item-header, .item-subtitle"
                        placeholder="Search Account"
                    ></f7-searchbar>
                </f7-block>

                <f7-block v-if="!cargando" class="no-margin-bottom no-margin-top">
                    <f7-row>
                        <f7-col width="70">
                            <f7-button @click="openCrearCuentaPopup()" tab-link-active>Create new account</f7-button>
                        </f7-col>
                        <f7-col width="30">
                            <f7-button
                                outline
                                @click="exportAccountsReport({ projectid: projectInFocus || adminprojectFocus })"
                            >
                                Export Report
                                <font-awesome-icon :icon="['fad', 'download']"></font-awesome-icon>
                            </f7-button>
                        </f7-col>
                    </f7-row>
                </f7-block>
                <f7-block v-if="cargando && !allusers.length" class="text-align-center">
                    <f7-preloader></f7-preloader>
                    <br />
                    Loading accounts...
                </f7-block>

                <f7-list class="searchbar-not-found no-margin-top">
                    <f7-list-item title="Nothing found"></f7-list-item>
                </f7-list>
                <f7-list v-if="!cargando" media-list class="searchbar-found search-list misuserisc no-margin-top">
                    <f7-list-item divider title="Pending access requests to this project"></f7-list-item>
                    <f7-list-item v-if="!allrequests.length" title="No pending access requests"></f7-list-item>
                    <f7-list-item
                        link="#"
                        v-for="user in allrequests"
                        :key="user.uid"
                        :title="user.displayName"
                        @click="gotoCuenta(user)"
                        :header="user.email"
                        :after="
                            user.lastSignInTime
                                ? `Last sign in ${$dayjs(user.lastSignInTime).format('MM/DD/YYYY')}`
                                : ''
                        "
                        :footer="`Requested at ${$dayjs.unix(user.request.wh).format('HH:mm [on] MM/DD/YY')}`"
                    >
                        <img :src="user.photoURL || '../static/heros/avatar.jpg'" slot="media" />
                        <f7-link tooltip="email verified" slot="header">
                            <font-awesome-icon
                                :icon="['fad', 'user-check']"
                                class="margin-left-half"
                                color="teal"
                                v-if="user.emailVerified"
                            ></font-awesome-icon>
                        </f7-link>
                    </f7-list-item>

                    <f7-list-item divider title="Active users with privileges on this project"></f7-list-item>
                    <f7-list-item
                        link="#"
                        v-for="user in allusers"
                        :key="user.uid"
                        :title="user.displayName"
                        @click="gotoCuenta(user)"
                        :subtitle="user.email"
                        :after="
                            user.lastSignInTime
                                ? `Last sign in ${$dayjs(user.lastSignInTime).format('MM/DD/YYYY')}`
                                : ''
                        "
                    >
                        <img :src="user.photoURL || '../static/heros/avatar.jpg'" slot="media" />
                        <f7-link tooltip="email verified" slot="subtitle">
                            <font-awesome-icon
                                :icon="['fad', 'user-check']"
                                class="margin-left-half"
                                color="teal"
                                v-if="user.emailVerified"
                            ></font-awesome-icon>
                        </f7-link>
                    </f7-list-item>
                </f7-list>
            </f7-tab>
            <f7-tab v-if="toolbarActiveTab == 'phidictionary'" id="phidictionary" class="page-content">
                <f7-block>
                    To be able to use PHI, you need to create a PHI Dictionary. This dictionary will be used to
                    de-identify PHI data from entities, depending on user's privileges.

                    <f7-button @click="uploadPHIDictionary" fill color="primary" class="margin-top-half">
                        Upload PHI Dictionary (CSV file)
                    </f7-button>
                    <input
                        id="phi-dictionary-input"
                        type="file"
                        @change="handlePHIDictionaryUpload($event)"
                        accept=".csv"
                        style="display: none"
                    />

                    <f7-button @click="createPHIDictionaryEntryPopupOpened = true" outline class="margin-top">
                        Create a new dictionary entry manually
                    </f7-button>
                </f7-block>
                <template v-if="loadingPHIDictionary">
                    <f7-block class="text-align-center">
                        <f7-preloader></f7-preloader>
                        <br />
                        Loading PHI Dictionary...
                    </f7-block>
                </template>
                <template v-else-if="!loadingPHIDictionary && phiDictionary.length">
                    <div class="display-flex justify-content-space-between align-items-center">
                        <f7-block-title class="no-margin-vertical">PHI Dictionary</f7-block-title>
                        <div class="display-flex">
                            <f7-button class="text-color-primary no-margin-vertical" @click="downloadPHIDictionary">
                                <font-awesome-icon :icon="['fad', 'download']"></font-awesome-icon>
                                Download as CSV
                            </f7-button>
                            <f7-button class="text-color-red no-margin-vertical" @click="deletePHIDictionary">
                                <font-awesome-icon :icon="['fad', 'trash']"></font-awesome-icon>
                                Delete all
                            </f7-button>
                        </div>
                    </div>
                    <PHIDictionaryList
                        :dictionary="phiDictionary"
                        @edited-entry="handlePHIDictionaryEntryEdited"
                        @deleted-entry="handlePHIDictionaryEntryDeleted"
                    />
                </template>
                <template v-else>
                    <f7-block-title>
                        PHI Dictionary is empty. Please
                        <f7-link @click="createPHIDictionaryEntryPopupOpened = true">create</f7-link>
                        a new entry or
                        <f7-link @click="uploadPHIDictionary">upload</f7-link>
                        a PHI dictionary as CSV file.
                    </f7-block-title>
                </template>
            </f7-tab>
        </f7-tabs>

        <f7-popover class="popover-tags-dictionary">
            <f7-list class="fixfamarg">
                <f7-list-item
                    v-for="tag in tagsSorted"
                    :key="tag.id"
                    @click="updatePHIDictionaryEntry(currentPHIDictionaryEntryId, 'tag', tag.id)"
                    link="#"
                    popover-close
                    :title="tag.tx"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popover class="popover-qutypes popupmasacncho">
            <f7-list class="fixfamarg">
                <f7-list-item
                    v-for="tipopreg in forms_logic.tipos_preg"
                    :key="tipopreg.id"
                    @click="crearPregunta(tipopreg.id)"
                    link="#"
                    popover-close
                    :title="tipopreg.nm"
                >
                    <font-awesome-icon
                        :icon="tipopreg.iko"
                        class="fa-lg text-color-deeppurple"
                        slot="media"
                    ></font-awesome-icon>
                </f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popover class="popover-fyltypes popupmasacncho">
            <f7-list class="fixfamarg">
                <f7-list-item
                    v-for="tipopreg in forms_logic.tipos_files"
                    :key="tipopreg.id"
                    @click="crearPregunta(tipopreg.id)"
                    link="#"
                    popover-close
                    :title="tipopreg.id == 'a' ? 'Any extension' : `.${tipopreg.id}`"
                >
                    <font-awesome-icon
                        :icon="tipopreg.iko"
                        :class="`fa-lg text-color-${tipopreg.color || 'deeppurple'}`"
                        slot="media"
                    ></font-awesome-icon>
                </f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popup :opened="termsPopupOpened" @popup:opened="fetchProjectTerms" @popup:closed="handleTermsClosed">
            <f7-page>
                <f7-navbar title="Terms and conditions" :subtitle="`For project: ${projectInFocusInfo.name}`">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-preloader v-if="loadingTerms" class="margin text-align-center"></f7-preloader>
                <div v-else>
                    <f7c-alert type="info" class="margin">
                        All project members will have to agree with these terms and conditions.
                        <br />
                        If you leave this empty, project members will not be able to accept the terms.
                    </f7c-alert>

                    <f7-text-editor
                        placeholder="Type here the terms and conditions..."
                        :value="projectTerms"
                        @texteditor:change="(value) => (projectTerms = value)"
                    ></f7-text-editor>
                    <f7-block>
                        <f7-link
                            class="link sortable-toggle"
                            data-sortable=".sortable"
                            :color="!turnOnSort ? 'gray' : 'primary'"
                            @click="turnOnSort = !turnOnSort"
                        >
                            Sort inputs
                        </f7-link>
                    </f7-block>
                    <div class="margin-horizontal">
                        <f7-list class="margin-vertical-half" v-if="!loadingTerms" media-list sortable ref="sortedList">
                            <f7-list-input
                                v-for="(field, index) in filteredTermsInputs"
                                :key="index"
                                :id="field.id"
                                :type="preexisting(field) ? 'select' : 'text'"
                                :label="preexisting(field) ? 'Pre-existing input' : 'Free text input'"
                                :outline="preexisting(field) ? true : null"
                                :placeholder="preexisting(field) ? '' : 'Type here your question'"
                                :value="preexisting(field) ? field.preexistingType : field.question"
                                @change="$set(termsInputs[field.id], 'preexistingType', $event.target.value)"
                                @input="field.question = $event.target.value"
                            >
                                <template v-if="preexisting(field)">
                                    <option value="">Select a pre-existing input</option>
                                    <option
                                        v-for="preExistingInput in preExistingInputs(field.preexistingType)"
                                        :key="preExistingInput.value"
                                        :value="preExistingInput.value"
                                    >
                                        {{ preExistingInput.text }}
                                    </option>
                                </template>
                                <div slot="media">
                                    <f7-link @click="attempToInactivateTermInput(field)">
                                        <font-awesome-icon
                                            color="grey"
                                            :icon="['fad', 'trash']"
                                            class="fa-lg"
                                        ></font-awesome-icon>
                                    </f7-link>
                                </div>
                            </f7-list-input>
                        </f7-list>
                    </div>

                    <f7-link class="margin-horizontal no-ripple" icon-material="add" popover-open=".select-input-field">
                        Add input field
                    </f7-link>
                    <f7-popover class="select-input-field">
                        <f7-list>
                            <f7-list-item
                                v-if="
                                    Object.values(termsInputs || {}).filter((input) => {
                                        return input.type == 'preexisting' && !input.inact;
                                    }).length < 4
                                "
                                title="Pre-existing input"
                                link="#"
                                @click="addInputField('preexisting')"
                                popover-close
                            ></f7-list-item>
                            <f7-list-item
                                title="Free text input"
                                link="#"
                                @click="addInputField('freetext')"
                                popover-close
                            ></f7-list-item>
                        </f7-list>
                    </f7-popover>

                    <f7-button @click="saveTermsAndConditions" fill class="margin">Save terms and conditions</f7-button>
                </div>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="openAlternativePopup" @popup:closed="openAlternativePopup = false">
            <f7-page class="no-padding">
                <f7-navbar title="All alternatives">
                    <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
                </f7-navbar>
                <f7-searchbar
                    search-container=".alts-vlist"
                    search-item="li"
                    search-in=".item-title"
                    placeholder="Search for alternatives"
                    found-el=".alts-found"
                    not-found-el=".alts-not-found"
                    class="no-margin-horizontal"
                    :backdrop="false"
                    @searchbar:disable="searchbarDisabled()"
                ></f7-searchbar>

                <f7-list class="alts-not-found searchbar-not-found">
                    <f7-list-item title="No alternatives found"></f7-list-item>
                </f7-list>

                <f7-button
                    v-if="arrayToDelete.length > 0"
                    class="margin"
                    fill
                    color="red"
                    @click="deleteSelected(projectinfo.entityData[viewingPreguntaId])"
                >
                    Delete selected alternatives ({{ arrayToDelete.length }})
                </f7-button>

                <f7-list class="no-margin">
                    <f7-list-item divider>
                        Total number of alternatives:
                        {{ viewingAlternatives.length }}.
                    </f7-list-item>
                    <f7-list-item
                        checkbox
                        :checked="arrayToDelete.length > 0 && arrayToDelete.length == viewingAlternatives.length"
                        title="Select all"
                        :disabled="vlAltData.items.length == 1"
                        @change="selectAllToDelete($event.target.checked)"
                    ></f7-list-item>
                </f7-list>

                <br />

                <f7-list
                    class="alts-vlist alts-found no-margin-top"
                    virtual-list
                    media-list
                    ref="alternativesVirtualList"
                    :virtual-list-params="{
                        items: viewingAlternatives,
                        searchAll,
                        renderExternal,
                        height: 52,
                    }"
                >
                    <ul>
                        <f7-list-item
                            v-for="(alt, index) in vlAltData.items"
                            :key="index"
                            :title="alt.tx"
                            :style="`top: ${vlAltData.topPosition}px`"
                        >
                            <div slot="media">
                                <f7-checkbox
                                    :checked="arrayToDelete.includes(alt.id)"
                                    :disabled="vlAltData.items.length == 1"
                                    @change="selectTodelete($event.target.checked, alt.id)"
                                />
                            </div>
                            <f7-preloader
                                class="margin-right-half"
                                v-if="guardandoeste == viewingPreguntaId + 'elimina_alternativa' + alt.id"
                                slot="after"
                            ></f7-preloader>
                            <f7-link
                                :class="{
                                    disabled: filtrameAlternativas(viewingAlternatives).length == 1,
                                }"
                                color="gray"
                                @click="eliminaAlternativa(viewingPreguntaId, 'entityData', alt.id)"
                                slot="after"
                            >
                                <font-awesome-icon :icon="['fad', 'trash']" class="fa-lg"></font-awesome-icon>
                            </f7-link>
                        </f7-list-item>
                    </ul>
                </f7-list>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="leaboutOpened" @popup:closed="leaboutOpened = false">
            <f7-page>
                <f7-navbar title="Project about section"></f7-navbar>
                <f7-block>
                    <f7-text-editor
                        placeholder="Enter about here. You can user rich text, parragraphs, lists, etc..."
                        :value="neoprojectinfoabout"
                        @texteditor:change="(v) => (neoprojectinfoabout = v)"
                        :buttons="[
                            ['bold', 'italic', 'underline', 'strikeThrough'],
                            ['orderedList', 'unorderedList'],
                        ]"
                    />

                    <f7-row>
                        <f7-col>
                            <f7-button @click="cancelaEditAbout()" class="cancelButton">Cancel</f7-button>
                        </f7-col>
                        <f7-col>
                            <f7-button @click="saveNeoAbout()" fill raised>Save changes</f7-button>
                        </f7-col>
                    </f7-row>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-popup
            class="crear-cuenta"
            :close-by-backdrop-click="false"
            :opened="popupCrearCuentaOpened"
            @popup:closed="popupCrearCuentaOpened = false"
        >
            <crea-cuenta
                v-if="popupCrearCuentaOpened"
                @abortCreacion="popupCrearCuentaOpened = false"
                @gotoCuenta="goToCuentaInner"
            ></crea-cuenta>
        </f7-popup>

        <f7-popup :opened="editaReglaPoppOpened != null" @popup:closed="cerrandoReglaGenerator()">
            <f7-page v-if="editaReglaPoppOpened">
                <f7-navbar title="Create new rule">
                    <f7-nav-right>
                        <f7-link popup-close class="cancelButton">Cancel</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-list class="margin-top no-margin-bottom">
                    <f7-list-item
                        class="fondogrisback"
                        header="Creating new visibility rule for the property:"
                        :title="pregFocoEdicion"
                    ></f7-list-item>
                </f7-list>
                <f7-block-header class="margin-top">
                    1.- Select the property that will define this property's visibility.
                </f7-block-header>
                <f7-list class="no-margin-bottom">
                    <f7-list-input
                        label="Origin question"
                        type="select"
                        defaultValue=""
                        placeholder="Select..."
                        outline
                        :value="nuevaregla.origenid"
                        @input="setPrefDefinitoria($event.target.value)"
                    >
                        <option value="">Select</option>
                        <option v-for="preg in preguntesAntesdePregFoto" :key="preg.id" :value="preg.id">
                            {{ preg.displ }}
                        </option>
                    </f7-list-input>
                </f7-list>
                <f7-block-header class="no-margin-top">
                    Only properties that preceed the property in focus are selectable.
                </f7-block-header>

                <template v-if="nuevaregla.origenid">
                    <f7-block-header>2.- Select the type of verification to apply.</f7-block-header>
                    <f7-list media-list>
                        <f7-list-item
                            radio
                            :value="restricid"
                            @change="nuevaregla.restricid = $event.target.value"
                            :checked="nuevaregla.restricid == restricid"
                            name="selec-restriccion"
                            v-for="(cumval, restricid) in forms_logic.tipos_preg[
                                projectinfo.entityData[nuevaregla.origenid].tipo
                            ].res"
                            :key="restricid"
                            :title="forms_logic.retrix_types[restricid].nm"
                            :text="forms_logic.retrix_types[restricid].ds"
                        ></f7-list-item>
                    </f7-list>

                    <template
                        v-if="
                            ['elige_al_menos_estas_ids', 'elige_exactamente_estas_ids'].includes(nuevaregla.restricid)
                        "
                    >
                        <f7-block-header>3.- Select the option or options to evaluate.</f7-block-header>
                        <f7-list media-list>
                            <f7-block
                                v-if="
                                    !filtrameAlternativas(projectinfo.entityData[nuevaregla.origenid].alternativas)
                                        .length
                                "
                                class="no-margin text-align-center text-color-red"
                            >
                                There are no options created for this property
                            </f7-block>
                            <f7-list-item
                                v-for="alternat in filtrameAlternativas(
                                    projectinfo.entityData[nuevaregla.origenid].alternativas,
                                )"
                                :key="alternat.id"
                                checkbox
                                :value="alternat.id"
                                @change="populaArrayx($event, nuevaregla.restricid)"
                                :checked="nuevaregla[nuevaregla.restricid].includes(alternat.id)"
                                :title="alternat.tx"
                            ></f7-list-item>
                        </f7-list>
                    </template>
                    <template v-if="nuevaregla.restricid == 'equal_to_optionid'">
                        <f7-block-header>3.- Select the option to evaluate.</f7-block-header>
                        <f7-list media-list>
                            <f7-block
                                v-if="
                                    !filtrameAlternativas(projectinfo.entityData[nuevaregla.origenid].alternativas)
                                        .length
                                "
                                class="no-margin text-align-center text-color-red"
                            >
                                There are no options created for this property
                            </f7-block>
                            <f7-list-item
                                v-for="alternat in filtrameAlternativas(
                                    projectinfo.entityData[nuevaregla.origenid].alternativas,
                                )"
                                :key="alternat.id"
                                radio
                                name="uniaoptaevaluar"
                                :value="alternat.id"
                                @change="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                :checked="nuevaregla[nuevaregla.restricid] == alternat.id"
                                :title="alternat.tx"
                            ></f7-list-item>
                        </f7-list>
                    </template>
                    <template v-if="nuevaregla.restricid && nuevaregla.restricid != 'any_value'">
                        <template v-if="projectinfo.entityData[nuevaregla.origenid].tipo == 'input_texto'">
                            <f7-block-header>3.- Indicate text to evaluate.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Texto to evaluate"
                                    type="text"
                                    clear-button
                                    outline
                                    :value="nuevaregla[nuevaregla.restricid]"
                                    @input="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                ></f7-list-input>
                            </f7-list>
                        </template>
                        <template v-if="projectinfo.entityData[nuevaregla.origenid].tipo == 'input_numero'">
                            <f7-block-header>3.- Indicate the value to evaluate.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Valute to evaluate"
                                    type="number"
                                    clear-button
                                    outline
                                    :value="nuevaregla[nuevaregla.restricid]"
                                    @input="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                ></f7-list-input>
                            </f7-list>
                        </template>
                        <template v-if="projectinfo.entityData[nuevaregla.origenid].tipo == 'input_fecha'">
                            <f7-block-header>3.- Select the date to evaluate.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Date to evaluate"
                                    type="datepicker"
                                    placeholder="Select date"
                                    readonly
                                    outline
                                    :calendar-params="{
                                        dateFormat: 'mm/dd/yyyy',
                                        value: [],
                                        locale: 'en',
                                        closeOnSelect: true,
                                    }"
                                    clear-button
                                    @change="
                                        nuevaregla[nuevaregla.restricid] = $dayjs(
                                            $event.target.value,
                                            'MM/DD/YYYY',
                                        ).format('YYYYMMDD')
                                    "
                                ></f7-list-input>
                            </f7-list>
                        </template>
                    </template>
                </template>
                <f7-block>
                    <f7-button
                        :disabled="!puedoCrearRegla || guardandoeste == pdataSorted[editaReglaPoppOpened].id + 'depde'"
                        fill
                        large
                        raised
                        color="teal"
                        @click="crearRegla()"
                    >
                        <f7-preloader
                            v-if="guardandoeste == pdataSorted[editaReglaPoppOpened].id + 'depde'"
                        ></f7-preloader>
                        <span v-else>Create rule</span>
                    </f7-button>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="openDelimiterPopup" @popup:closed="delimiterPopupClosed">
            <f7-page>
                <f7-navbar title="Delimiter parser">
                    <f7-nav-right>
                        <f7-link popup-close class="cancelButton">Cancel</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-block class="parser-parent">
                    <f7-list v-if="!parsedValue.length && !existingInactivatedOptions.length">
                        <f7-list-input
                            label="Delimiter character"
                            type="text"
                            outline
                            @change="delimiter = $event.target.value"
                            :value="delimiter"
                            placeholder="Type here the delimiter character"
                        ></f7-list-input>
                        <f7-list-input
                            label="Text to be parsed"
                            type="textarea"
                            outline
                            @change="inputToParse = $event.target.value"
                            :value="inputToParse"
                            placeholder="Type here the text to be parsed"
                        ></f7-list-input>
                        <f7-button @click="parse()" fill color="primary" class="margin-vertical">Parse</f7-button>
                        <f7-list-item
                            checkbox
                            :checked="parserCapitalize"
                            @change="parserCapitalize = $event.target.checked"
                            title="Capitalize all options"
                        ></f7-list-item>
                    </f7-list>

                    <f7-list class="no-margin-top no-margin-bottom" v-if="parsedValue.length">
                        <f7-link @click="(parsedValue = []), (existingInactivatedOptions = [])">
                            <font-awesome-icon
                                class="margin-right-half"
                                :icon="['fad', 'arrow-left']"
                            ></font-awesome-icon>
                            Edit parse input
                        </f7-link>
                        <f7-list-item divider title="Options to be added"></f7-list-item>
                    </f7-list>
                    <f7-list v-show="parsedValue.length || existingInactivatedOptions.length" class="no-margin-top">
                        <f7-list-item
                            v-for="strn in parsedValueForCurrentChunk"
                            :key="strn"
                            :title="strn"
                        ></f7-list-item>
                        <f7-list-item
                            v-for="option in existingInactivatedOptions"
                            :key="option.id"
                            :title="`${option.tx} (Inactive)`"
                        ></f7-list-item>
                        <!-- 
                        <f7-button
                            @click="currentChunk += 50"
                            color="primary"
                            class="margin-vertical"
                        >Load more...</f7-button> 
                        -->
                        <f7-button
                            @click="attemptToAddOptions"
                            :disabled="!parsedValue.length && !existingInactivatedOptions.length"
                            fill
                            color="primary"
                            class="margin-vertical"
                        >
                            Add Options
                        </f7-button>
                    </f7-list>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="timezonePopup">
            <f7-page>
                <f7-navbar>
                    <f7-nav-left></f7-nav-left>
                    <f7-nav-title>Select time zone for {{ getName || "this Institution" }}</f7-nav-title>
                    <f7-nav-right>
                        <f7-link popup-close @click="timezonePopup = false">Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-searchbar
                    class="searchbar-tz"
                    search-container=".timezone-search-list"
                    search-in=".item-title,.item-header"
                    found-el=".timezone-searchbar-found"
                    not-found-el=".timezone-searchbar-not-found"
                    placeholder="Search time zone"
                ></f7-searchbar>
                <f7-list class="timezone-search-list timezone-searchbar-found no-margin-top">
                    <f7-list-item
                        v-for="tz in timeZones"
                        :key="tz"
                        radio
                        name="timezones"
                        :title="tz"
                        :header="getUTC(tz)"
                        @change="selectTimezone(tz)"
                    ></f7-list-item>
                </f7-list>
            </f7-page>
        </f7-popup>
        <f7-popup :opened="inactivatingInstitutionPopup" @popup:closed="handleTZPopupClosed">
            <f7-page>
                <f7-navbar title="Inactivating institution">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block-title>Choose an inactivation setting for this institution</f7-block-title>
                <f7-list>
                    <f7-list-item
                        radio
                        name="inactivatingInstitution"
                        header="Type 1"
                        title="Restringe add new entities to the institution"
                        @change="inactivationSettings = 'inactive'"
                        :checked="inactivationSettings == 'inactive'"
                    ></f7-list-item>
                    <f7-list-item
                        radio
                        header="Type 2"
                        name="inactivatingInstitution"
                        title="Same as above + Restringe access to users of this institution"
                        @change="inactivationSettings = 'restrictAccess'"
                        :checked="inactivationSettings == 'restrictAccess'"
                    ></f7-list-item>
                    <f7-list-item
                        radio
                        header="Type 3"
                        name="inactivatingInstitution"
                        title="Same as above + entities won't be visible in dashboard and project data"
                        @change="inactivationSettings = 'noVisible'"
                        :checked="inactivationSettings == 'noVisible'"
                    ></f7-list-item>
                </f7-list>
                <f7-block>
                    <f7-button
                        :disabled="!inactivationSettings"
                        @click="activateOrInactivateIntitution({ instix: null })"
                        outline
                    >
                        Inactivate
                    </f7-button>
                </f7-block>
            </f7-page>
        </f7-popup>

        <PublicDashboardSettingsPopup
            :opened="isPublicDashboardConfigPopupOpened"
            @closed="handlePublicDashboardConfigPopupClosed"
        />

        <CreatePHIDictionaryEntryPopup
            :opened="createPHIDictionaryEntryPopupOpened"
            @closed="createPHIDictionaryEntryPopupOpened = false"
            @created="handlePHIDictionaryEntryCreated"
        />
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import * as EmailValidator from "email-validator";
    import creaCuenta from "../components/cuenta_crea_popup.vue";
    import PublicDashboardSettingsPopup from "../components/PublicDashboardSettingsPopup.vue";
    import CreatePHIDictionaryEntryPopup from "../components/CreatePHIDictionaryEntryPopup.vue";
    import PHIDictionaryList from "../components/PHIDictionaryList.vue";
    import ct from "countries-and-timezones";
    import { commonmixin } from "../mixins/common";
    export default {
        components: {
            creaCuenta,
            PublicDashboardSettingsPopup,
            CreatePHIDictionaryEntryPopup,
            PHIDictionaryList,
        },
        mixins: [commonmixin],
        data() {
            return {
                toolbarActiveTab: "metaproject",
                loading: false,
                selectedAlts: [],
                termsInputs: {},

                loadingTerms: true,
                termsPopupOpened: false,
                projectTerms: "",
                oldTerms: null,
                editaReglaPoppOpened: null,
                parserCapitalize: true,
                delimiter: "",
                inputToParse: "",
                parsedValue: [],
                delimeterPregId: null,
                openDelimiterPopup: false,
                openAlternativePopup: false,
                viewingAlternatives: [],
                viewingPreguntaId: null,
                currentChunk: 50,
                vlAltData: {
                    items: [],
                },
                vlParserData: {
                    items: [],
                },
                MAX_ALTERNATIVES_TO_SHOW: 25,
                nuevaregla: {
                    origenid: null,
                    restricid: null,
                    elige_al_menos_estas_ids: [],
                    elige_exactamente_estas_ids: [],
                    equal_to_optionid: null,
                    contiene: "",
                    equal_to: "",
                    any_value: true,
                    mayor_que: null,
                    menor_que: null,
                },
                cargando: false,
                popupCrearCuentaOpened: false,
                allusers: [],
                allrequests: [],
                updt: null,
                estoyenaccounts: false,
                newQuestiontype: null,
                guardandoeste: null,
                leaboutOpened: false,
                progressdialog: null,
                neoprojectinfoabout: "",
                infoaboutoriginal: "",
                task: null,
                lenghrestric: [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                    28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
                ],
                lenghlimits: {
                    min: [],
                    max: [],
                },
                arrayToDelete: [],
                timezonePopup: false,
                selectedInstitution: null,
                focusedApikey: {},
                apiKeyPopup: false,
                newApikey: {
                    id: null,
                    apiSecret: null,
                    name: null,
                    expDateNever: true,
                    expDate: null,
                    institutions: {},
                    dailyLimit: 200,
                },
                newApikeyPopup: false,
                existingInactivatedOptions: [],
                inactivatingInstitutionPopup: false,
                institutionToInactivate: null,
                inactivationSettings: null,
                isPublicDashboardConfigPopupOpened: false,
                publicDashboardURL: null,
                extenx: null,
                phiDictionary: [],
                loadingPHIDictionary: false,
                currentPHIDictionaryEntryId: null,
                createPHIDictionaryEntryPopupOpened: false,
                turnOnSort: false,
            };
        },
        computed: {
            ...mapState([
                "projectInFocus",
                "user",
                "adminprojectFocus",
                "forms_logic",
                "forceUsersReload",
                "currentProjectTerms",
                "activeTab",
                "projects",
            ]),

            ...mapGetters(["projectInFocusInfo"]),
            filteredTermsInputs() {
                let inputs = Object.values(this.termsInputs || {}).filter((term) => {
                    return !term.inact;
                });
                return inputs;
            },
            areTermsInputsComplete() {
                const fields = this.filteredTermsInputs;

                if (!fields.length) return true;

                let isComplete = true;

                fields.forEach((field) => {
                    if (field.type === "preexisting" && (!field.preexistingType || !field.preexistingType.length)) {
                        isComplete = false;
                    }

                    if (field.type === "freetext" && !field.question.length) {
                        isComplete = false;
                    }
                });

                return isComplete;
            },

            projectinfo() {
                return this.$store.state.projects[this.adminprojectFocus];
            },
            pdataSorted() {
                let regunts = this.projectinfo.entityData || {};
                return Object.values(regunts)
                    .filter((unapreg) => {
                        return !unapreg.disabled;
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            pfilesSorted() {
                let regunts = this.projectinfo.entityFiles || {};
                return Object.values(regunts)
                    .filter((unapreg) => {
                        return !unapreg.disabled;
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            tagsSorted() {
                let tags = this.projectinfo.fileTags || {};
                return Object.values(tags)
                    .filter((tag) => {
                        return !tag.inct;
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            instituionsSorted() {
                let regunts = this.projectinfo.institutions || {};
                return Object.values(regunts)
                    .filter((unapreg) => {
                        return !unapreg.disabled;
                    })
                    .sort((a, b) => {
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            activeInstitutions() {
                return this.instituionsSorted.filter((institution) => {
                    return !institution.inactive;
                });
            },
            warnings_by_preg() {
                let warnings = {};
                let preguntas = this.projectinfo.entityData || {};
                this.pdataSorted.forEach((unapreg) => {
                    warnings[unapreg.id] = "";
                    if (["elige_many", "elige_una"].includes(unapreg.tipo)) {
                        if (!unapreg.alternativas || !Object.keys(unapreg.alternativas).length) {
                            if (warnings[unapreg.id]) {
                                warnings[unapreg.id] += " | ";
                            }
                            warnings[unapreg.id] += "No options created for the field";
                        }
                    }
                    if (unapreg.depde) {
                        //ver que regla logica
                        if (!preguntas[unapreg.depde.pregid] || preguntas[unapreg.depde.pregid].disabled) {
                            if (warnings[unapreg.id]) {
                                warnings[unapreg.id] += " | ";
                            }
                            warnings[unapreg.id] += "Rule depends on property that no longer exists";
                        } else if (preguntas[unapreg.depde.pregid].orden > unapreg.orden) {
                            if (warnings[unapreg.id]) {
                                warnings[unapreg.id] += " | ";
                            }
                            warnings[unapreg.id] += "Rule depends on property that is ordered after this";
                        }
                        //ver si dependen de prg con alternativas
                        if (
                            ["elige_many", "elige_una"].includes(preguntas[unapreg.depde.pregid].tipo) &&
                            Array.isArray(unapreg.depde[unapreg.depde.tipo])
                        ) {
                            //ver que no hayan reglas que dependadn de alternativas que no existan o esten inactivas
                            let hayincosisnteica = false;
                            if (
                                !preguntas[unapreg.depde.pregid].alternativas ||
                                !Object.keys(preguntas[unapreg.depde.pregid].alternativas).length
                            ) {
                                hayincosisnteica = true;
                            } else if (preguntas[unapreg.depde.pregid].tipo === "elige_una") {
                                let unreqid = unapreg.depde[unapreg.depde.tipo];
                                hayincosisnteica =
                                    !preguntas[unapreg.depde.pregid].alternativas[unreqid] ||
                                    preguntas[unapreg.depde.pregid].alternativas[unreqid].inct;
                            } else if (preguntas[unapreg.depde.pregid].tipo === "elige_many") {
                                let requerimientoarray = unapreg.depde[unapreg.depde.tipo] || [];
                                hayincosisnteica = requerimientoarray.some((unreqid) => {
                                    return (
                                        !preguntas[unapreg.depde.pregid].alternativas[unreqid] ||
                                        preguntas[unapreg.depde.pregid].alternativas[unreqid].inct
                                    );
                                });
                            }
                            if (hayincosisnteica) {
                                if (warnings[unapreg.id]) {
                                    warnings[unapreg.id] += " | ";
                                }
                                warnings[unapreg.id] +=
                                    "Rule depends on alternative that no longer exists in origin property";
                            }
                        }
                    }
                });
                return warnings;
            },
            suprivx() {
                return this.user.privx || {};
            },
            myAdminPrivsHere() {
                let proprivs = this.$store.state.user.privsByProject || {};
                let proadmin = proprivs[this.adminprojectFocus] || {};
                return proadmin?.admin || {};
            },
            //computed for rule generator
            preguntesAntesdePregFoto() {
                if (this.editaReglaPoppOpened != null) {
                    return this.pdataSorted
                        .filter((unapreg, idx) => {
                            return idx < this.editaReglaPoppOpened;
                        })
                        .map((unapre) => {
                            return {
                                id: unapre.id,
                                displ: this.striphtml(unapre.encabezado),
                            };
                        });
                } else {
                    return [];
                }
            },
            puedoCrearRegla() {
                if (!this.nuevaregla.origenid || !this.nuevaregla.restricid) {
                    return false;
                }
                if (this.nuevaregla.restricid == "any_value") {
                    return true;
                } else if (this.nuevaregla.restricid == "elige_al_menos_estas_ids") {
                    return this.nuevaregla["elige_al_menos_estas_ids"].length;
                } else if (this.nuevaregla.restricid == "elige_exactamente_estas_ids") {
                    return this.nuevaregla["elige_exactamente_estas_ids"].length;
                } else if (
                    this.nuevaregla[this.nuevaregla.restricid] &&
                    this.nuevaregla[this.nuevaregla.restricid] !== "Invalid Date"
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            pregFocoEdicion() {
                if (this.editaReglaPoppOpened != null) {
                    return this.striphtml(this.pdataSorted[this.editaReglaPoppOpened].encabezado);
                } else {
                    return "";
                }
            },
            parsedValueForCurrentChunk() {
                if (!this.parsedValue || !this.parsedValue.length) return [];
                return this.parsedValue.slice(0, this.currentChunk);
            },
            timeZones() {
                const timezones = ct.getAllTimezones();
                return Object.keys(timezones);
            },
            getName() {
                return this.projectinfo?.institutions?.[this.selectedInstitution]?.name;
            },
            currentRoute() {
                return this.$f7.views.main.router.currentRoute.url;
            },
            sortedApiKeys() {
                let apiKeys = this.projectinfo?.apiKeys || {};
                let array = Object.values(apiKeys) || [];
                return array.sort((a, b) => {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            },
            isDurationFieldAlreadySet() {
                return this.pdataSorted.some((field) => field.isDuration);
            },
        },
        async created() {
            await this.getPublicDashboardSettings();

            this.lenghlimits.min = this.lenghrestric;
            this.lenghlimits.max = this.lenghrestric;

            this.$f7.dialog.close();
        },
        watch: {
            "projectinfo.terms": {
                handler: function (val) {
                    if (val === true) this.termsPopupOpened = true;
                },
            },
        },
        methods: {
            otherFieldIsFilterInDashboard(id) {
                return this.pdataSorted.some((field) => {
                    return field.isFilterInDashboard && field.id != id;
                });
            },
            editDurationName(entityDataId) {
                this.$f7.dialog.prompt(
                    "Enter the name for this duration",
                    "Edit duration name",
                    async (durationName) => {
                        await this.modificaneoPregunta({
                            preguntaid: entityDataId,
                            where: "durationName",
                            valor: durationName,
                            newQuestiontype: "entityData",
                            subAction: "editDurationName",
                        });
                    },
                );
            },
            preexisting(field) {
                return (
                    field.type === "preexisting" &&
                    (this.termsInputs[field.id].preexistingType || this.preExistingInputs().length)
                );
            },
            openCrearCuentaPopup() {
                if (this.activeInstitutions.length === 0) {
                    return this.$f7.dialog.alert(
                        "There must be at least one active institution in the project to create an account",
                    );
                }

                this.popupCrearCuentaOpened = true;
            },
            handleTermsClosed() {
                // if (Object.values(this.oldTerms?.inputFields || {}).length)
                //     this.termsInputs = Object.assign({}, this.oldTerms.inputFields);
                this.termsInputs = Object.assign({});
                this.turnOnSort = false;
                this.termsPopupOpened = false;
            },
            attempToInactivateTermInput(termInput) {
                this.$f7.dialog.confirm("Are you sure you want yo Delete this field?", () => {
                    this.termsInputs[termInput.id].inact = true;
                    this.termsInputs[termInput.id].orden = null;
                    this.termsInputs = Object.assign({}, this.termsInputs);
                });
            },
            handlePublicDashboardConfigPopupClosed() {
                this.getPublicDashboardSettings();
                this.isPublicDashboardConfigPopupOpened = false;
            },
            handleTZPopupClosed() {
                this.institutionToInactivate = null;
                this.inactivatingInstitutionPopup = false;
            },
            async downloadPHIDictionary() {
                try {
                    this.$f7.dialog.preloader("Downloading PHI Dictionary...");

                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus },
                        "v2_downloadPHIDictionary",
                    );
                    console.log("response", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    this.downloadBase64File(response.base64CSV, "phi_dictionary.csv", "text/csv");

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },
            downloadBase64File(base64Data, fileName, mimeType) {
                const blobData = this.base64ToBlob(base64Data, mimeType);
                const url = window.URL.createObjectURL(blobData);
                const link = document.createElement("a");

                document.body.appendChild(link);
                link.href = url;
                link.setAttribute("download", fileName);
                link.classList.add("external");
                link.click();
                window.URL.revokeObjectURL(url);
            },
            base64ToBlob(base64Data, mimeType) {
                const byteCharacters = atob(base64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);

                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, { type: mimeType });
            },
            deletePHIDictionary() {
                this.$f7.dialog.confirm(
                    "Are you sure you want to delete all PHI Dictionary entries?",
                    "Delete PHI Dictionary",
                    async () => {
                        try {
                            this.$f7.dialog.preloader("Deleting PHI Dictionary...");

                            const response = await this.commonExecute(
                                { projectId: this.projectInFocus },
                                "v2_deletePHIDictionary",
                            );
                            console.log("response", response);

                            if (response.error) {
                                throw new Error(response.error.message);
                            }

                            this.phiDictionary = [];
                            this.$f7.dialog.close();
                        } catch (error) {
                            console.error(error.message);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(error.message, "Error");
                        }
                    },
                );
            },
            handlePHIDictionaryEntryDeleted(entryId) {
                const index = this.phiDictionary.findIndex((entry) => entry.id === entryId);
                this.phiDictionary.splice(index, 1);
            },
            handlePHIDictionaryEntryEdited(payload) {
                const { editedEntry, originalEntryId } = payload;
                const index = this.phiDictionary.findIndex((entry) => entry.id === originalEntryId);

                this.phiDictionary.splice(index, 1);
                this.phiDictionary[index] = editedEntry;
            },
            handlePHIDictionaryEntryCreated(entry) {
                this.phiDictionary.push(entry);
                this.createPHIDictionaryEntryPopupOpened = false;
            },
            openPHIDictionaryTagsPopover(entryId) {
                this.currentPHIDictionaryEntryId = entryId;
                this.$f7.popover.open(".popover-tags-dictionary");
            },
            separateColumnNames(columnNames) {
                return columnNames.split("_$$$_");
            },
            async deletePHIDictionaryEntry(entryId) {
                this.$f7.dialog.confirm(
                    "Are you sure you want to delete this dictionary entry?",
                    "Delete PHI dictionary entry",
                    async () => {
                        try {
                            this.$f7.dialog.preloader("Deleting PHI dictionary entry...");

                            const response = await this.commonExecute(
                                { projectId: this.$store.state.projectInFocus, entryId },
                                "v2_deletePHIDictionaryEntry",
                            );

                            const index = this.phiDictionary.findIndex((entry) => entry.id === entryId);
                            this.phiDictionary.splice(index, 1);

                            console.log("Deleted PHI dictionary item", entryId);

                            this.$f7.dialog.close();
                        } catch (error) {
                            console.error(error);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("There was an error deleting the project PHI dictionary", "Error");
                        }
                    },
                );
            },
            async updatePHIDictionaryEntry(entryId, field, value) {
                console.log("Updating PHI dictionary item", { entryId, field, value });

                try {
                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus, entryId, field, value },
                        "v2_updatePHIDictionaryEntry",
                    );

                    const index = this.phiDictionary.findIndex((entry) => entry.id === entryId);
                    const entry = this.phiDictionary[index];

                    this.$set(this.phiDictionary, index, {
                        ...entry,
                        [field]: value,
                    });

                    console.log("Updated PHI dictionary item", this.phiDictionary[index]);
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.alert("There was an error uploading the project PHI dictionary", "Error");
                }
            },
            handlePHIDictionaryUpload(e) {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const text = e.target.result;

                    console.log("Uploading PHI dictionary", {
                        projectId: this.$store.state.projectInFocus,
                        phiDictionary: text,
                    });

                    try {
                        this.loadingPHIDictionary = true;
                        const response = await this.commonExecute(
                            { projectId: this.$store.state.projectInFocus, phiDictionary: text },
                            "v2_uploadPHIDictionary",
                        );

                        if (response.error) {
                            throw new Error(response.error.message);
                        }

                        this.phiDictionary = response.phiDictionary;

                        this.$f7.toast.show({
                            text: "PHI dictionary uploaded successfully",
                            closeTimeout: 2000,
                        });

                        // Clear input
                        this.$$("#phi-dictionary-input").val("");

                        this.loadingPHIDictionary = false;
                    } catch (error) {
                        console.error(error);
                        this.loadingPHIDictionary = false;
                        this.$f7.dialog.alert("There was an error uploading the project PHI dictionary", "Error");
                    }
                };
                reader.readAsText(file);
            },
            uploadPHIDictionary() {
                this.$$("#phi-dictionary-input").click();
            },
            async getProjectPHIDictionary() {
                try {
                    this.loadingPHIDictionary = true;
                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus },
                        "v2_getProjectPHIDictionary",
                    );
                    console.log("response", response);
                    this.phiDictionary = response.phiDictionary;
                    this.loadingPHIDictionary = false;
                } catch (error) {
                    this.loadingPHIDictionary = false;
                    this.$f7.dialog.alert("There was an error getting the project PHI dictionary", "Error");
                }
            },
            activateTab(tab) {
                this.loading = true;
                this.toolbarActiveTab = tab;
                if (tab == "accountspro") {
                    this.modTabCuentas(true);
                } else {
                    this.modTabCuentas(false);
                }
                if (tab == "phidictionary") {
                    this.getProjectPHIDictionary();
                }
                setTimeout(() => {
                    this.loading = false;
                }, 1000);
            },
            copyURLToClipboard() {
                navigator.clipboard.writeText(this.publicDashboardURL);
                this.$f7.toast.show({
                    text: "Copied!",
                    closeTimeout: 1000,
                    position: "center",
                });
            },

            async getPublicDashboardSettings() {
                try {
                    this.guardandoeste = "core_isPublicDashboardEnabled";

                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus || this.$store.state.adminprojectFocus },
                        "v2_getProjectPublicDashboardSettings",
                    );

                    console.log("Responsess", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    if (response.alias) {
                        if (this.$store.state.dev) {
                            this.publicDashboardURL = `https://dashboards-cielo.web.app/${response.alias}`;
                        } else {
                            this.publicDashboardURL = `https://dashboard.cielo.wustl.edu/${response.alias}`;
                        }
                    } else if (response.url) {
                        if (this.$store.state.dev) {
                            this.publicDashboardURL = `https://dashboards-cielo.web.app/?dashboard=${response.url}`;
                        } else {
                            this.publicDashboardURL = `https://dashboard.cielo.wustl.edu/?dashboard=${response.url}`;
                        }
                    }

                    this.guardandoeste = null;
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                    this.guardandoeste = null;
                }
            },

            async changePublicDashboardStatus(value) {
                try {
                    console.log("changePublicDashboardStatus", value);

                    this.$f7.dialog.preloader("Updating public dashboard status...");
                    this.guardandoeste = "core_isPublicDashboardEnabled";

                    const response = await this.commonExecute(
                        {
                            projectId: this.$store.state.projectInFocus || this.$store.state.adminprojectFocus,
                            isPublicDashboardEnabled: value,
                        },
                        "v2_updateProjectPublicDashboardStatus",
                    );

                    console.log("response", response);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    this.guardandoeste = null;
                    this.$set(this.projectinfo, "isPublicDashboardEnabled", value);

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },
            deleteSelectedAlts(pregId) {
                this.$f7.dialog.confirm(
                    `You will delete the (${this.selectedAlts.length}) selected alternatives for this field`,
                    "Are you sure?",
                    async () => {
                        try {
                            this.$f7.dialog.preloader("Inactivating alternatives...");

                            let toUpdate = this.setLastUpdate();
                            let arrayToDeleteButOne = this.selectedAlts;
                            if (arrayToDeleteButOne.length == this.filtrameAlternativas(preg.alternativas).length)
                                arrayToDeleteButOne.shift();

                            this.arrayToDeleteButOne.forEach((altId) => {
                                toUpdate[
                                    `projects/${this.adminprojectFocus}/entityData/${pregId}/alternativas/${altId}/inct`
                                ] = true;
                            });

                            await this.$firebase.database().ref().update(toUpdate);

                            this.$store.commit("inactivateMultiple", {
                                pregid: pregId,
                                altsToDelete: arrayToDeleteButOne,
                            });

                            this.selectedAlts = [];
                            this.$f7.toast.show({
                                text: "Have to keep one alternative",
                                closeTimeout: 1000,
                                position: "center",
                            });

                            // const selectedRefId = Object.keys(this.$refs || {}).find((refId) => refId.includes(pregId));
                            // const ref = this.$refs[selectedRefId][0];

                            // console.log("ref", ref);

                            // if (ref) {
                            //     ref.setValue([]);
                            // }

                            this.$f7.dialog.close();
                        } catch (error) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Error while inactivating alternatives.", error);
                            console.error(error);
                        }
                    },
                );
            },

            addInputField(type) {
                const key = this.$firebase.database().ref(`terms/${this.adminprojectFocus}/inputFields`).push().key;
                let orden = this.filteredTermsInputs.length;
                this.$set(this.termsInputs, key, {
                    id: key,
                    type,
                    orden,
                });

                if (type === "freetext") this.$set(this.termsInputs[key], "question", "");
                this.turnOnSort = false;
                this.termsInputs = Object.assign({}, this.termsInputs);
            },

            async fetchProjectTerms() {
                try {
                    this.loadingTerms = true;
                    const snapshot = await this.$firebase
                        .database()
                        .ref(`terms/${this.adminprojectFocus}`)
                        .once("value");
                    const terms = snapshot.val();
                    if (terms) {
                        this.oldTerms = JSON.parse(JSON.stringify(terms));
                        this.$store.commit("setCurrentProjectTerms", {
                            projectId: this.$store.state.projectInFocus || this.$store.state.adminprojectFocus,
                            terms,
                        });
                        this.projectTerms = terms.htmlText;
                        if (Object.keys(terms.inputFields || {}).length > 0) {
                            Object.values(terms.inputFields)
                                .filter((input) => {
                                    return !input.inact;
                                })
                                .forEach((input, i) => {
                                    // reinicar aca
                                    if (input.orden) {
                                        //     terms.inputFields[input.id].orden=null
                                    } else if (!input.orden) {
                                        // terms.inputFields[input.id].orden = Number(i)
                                    }
                                });

                            let array = Object.values(terms.inputFields || {}).sort((a, b) => {
                                if (a.orden > b.orden) {
                                    return 1;
                                } else if (a.orden < b.orden) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });
                            array.forEach((input) => {
                                this.termsInputs[input.id] = input;
                            });
                        }
                        this.termsInputs = Object.assign({}, this.termsInputs);
                        this.loadingTerms = false;
                    }
                } catch (error) {
                    this.loadingTerms = false;
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("There was an error while fetching terms and conditions", error);
                    console.error(error);
                }
                this.loadingTerms = false;
            },

            async saveTermsAndConditions() {
                // checkea si hay cambios en los inputs de los terms
                let changedTermsInputs = Object.values(this.termsInputs || {}).some((input) => {
                    return (
                        !this.oldTerms?.inputFields?.[input.id] ||
                        (this.oldTerms.inputFields[input.id].type == "preexisting" &&
                            this.oldTerms.inputFields[input.id].preexistingType != input.preexistingType) ||
                        (this.oldTerms.inputFields[input.id].type == "freetext" &&
                            this.oldTerms.inputFields[input.id].question != input.question) ||
                        this.oldTerms.inputFields[input.id].orden != input.orden
                    );
                });
                const changedHtmlText = this.oldTerms?.htmlText != this.projectTerms;

                try {
                    if (!this.projectTerms.length) {
                        this.$f7.dialog.alert("You have to type something to save terms and conditions.");
                        return;
                    }

                    if (!this.areTermsInputsComplete) {
                        this.$f7.dialog.alert("Some input fields are incomplete.");
                        return;
                    }
                    //  if (!changedTermsInputs && !changedHtmlText) return (this.termsPopupOpened = false);

                    this.$f7.dialog.preloader("Saving...");
                    const terms = {
                        htmlText: this.projectTerms,
                        by: this.user.displayName,
                        date: this.$dayjs().unix(),
                        inputFields: this.termsInputs,
                    };

                    if (this.$refs.sortedList.$children.length) {
                        this.$refs.sortedList.$el.childNodes[0].childNodes.forEach((child, i) => {
                            this.termsInputs[child.id].orden = i;
                        });
                    }
                    await this.commonfiresave({
                        condialog: true,
                        parameters: {
                            terms: true,
                            valor: terms,
                        },
                    });

                    if (changedHtmlText) {
                        const warnChangesOfTerms = this.$firebase.functions().httpsCallable("v2_warnChangesOfTerms");
                        const response = await warnChangesOfTerms({
                            projectid: this.adminprojectFocus,
                            terms,
                            dev: this.$store.state.dev,
                            projectName: this.projectinfo.name,
                        });
                        console.log(response);
                        if (response.data.error) throw new Error(response.data.error);
                    }

                    this.$store.commit("setCurrentProjectTerms", {
                        projectId: this.adminprojectFocus,
                        terms,
                    });

                    this.$f7.dialog.close();

                    this.$f7.toast.show({
                        text: "Terms and conditions saved!",
                        closeTimeout: 2000,
                        horizontalPosition: "center",
                    });

                    this.termsPopupOpened = false;
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("There was an error while saving terms and conditions", error);
                    console.error(error);
                }
            },
            resetea() {
                this.delimiter = "";
                this.inputToParse = "";
                this.parsedValue = [];
                this.existingInactivatedOptions = [];
            },
            capitalize(string) {
                if (!string[0]) {
                    return "";
                }
                return string[0].toUpperCase() + string.slice(1).toLowerCase();
            },
            CSVToArray(strData, strDelimiter) {
                strDelimiter = strDelimiter || ",";

                var objPattern = new RegExp(
                    "(\\" +
                        strDelimiter +
                        "|\\r?\\n|\\r|^)" +
                        '(?:"([^"]*(?:""[^"]*)*)"|' +
                        '([^"\\' +
                        strDelimiter +
                        "\\r\\n]*))",
                    "gi",
                );

                var arrData = [[]];
                var arrMatches = null;

                while ((arrMatches = objPattern.exec(strData))) {
                    var strMatchedDelimiter = arrMatches[1];

                    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                        arrData.push([]);
                    }

                    var strMatchedValue;

                    if (arrMatches[2]) {
                        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
                    } else {
                        strMatchedValue = arrMatches[3];
                    }

                    arrData[arrData.length - 1].push(strMatchedValue);
                }

                return arrData;
            },

            csvFileChanged(e, preguntaId) {
                const file = e.target.files[0];
                const reader = new FileReader();

                reader.onload = (e) => {
                    const content = e.target.result;

                    if (!content) {
                        this.$f7.dialog.alert("The CSV file that you uploaded is empty. Please upload a new one.");
                        return;
                    }

                    const parsedContent = this.CSVToArray(content, ",");

                    let finalArray = [];

                    parsedContent.forEach((array) => {
                        finalArray = finalArray.concat(array);
                    });

                    if (finalArray.length) {
                        this.delimiter = ",";
                        this.inputToParse = finalArray.join(",");

                        this.openDelimiterPopup = true;

                        document.getElementById("csv-input").value = "";
                    }
                };

                reader.readAsText(file);
            },
            importCSV(preguntaId) {
                this.delimeterPregId = preguntaId;
                this.$$("#csv-input").click();

                console.log("@importCSV", {
                    delimeterPregId: this.delimeterPregId,
                    preguntaId,
                });
            },
            parse() {
                if (!this.delimiter.length || !this.inputToParse.length) {
                    return this.$f7.dialog.alert("You must provide a delimiter and a text to parse", "Missing data");
                }
                const delimiter = this.delimiter.trim();
                const inputToParse = this.inputToParse.trim();
                let thealts = this.projectinfo.entityData[this.delimeterPregId] || {};
                let prealterantivas = Object.values(thealts.alternativas || {});
                let arrayalt = prealterantivas.map((unalt) => {
                    return unalt?.tx?.toLowerCase();
                });
                let uniques = [];
                let existingInactivated = [];
                inputToParse
                    .split(delimiter)
                    .map((value) => {
                        let formattedValue = value.trim();

                        if (this.parserCapitalize) {
                            formattedValue = this.capitalize(formattedValue);
                        }

                        return formattedValue;
                    })
                    .forEach((unaopt) => {
                        if (unaopt && !arrayalt.includes(unaopt?.toLowerCase())) return uniques.push(unaopt);
                        let existing = prealterantivas.find((preAlt) => {
                            return preAlt?.tx?.toLowerCase() == unaopt?.toLowerCase() && preAlt.inct;
                        });
                        if (unaopt && existing) return existingInactivated.push(existing);
                    });
                let uniqueOptions = [...new Set(uniques)];

                if (!uniqueOptions.length && !existingInactivated.length) {
                    this.$f7.dialog.alert(
                        "All the alternatives entered already existed in this question - no new alternatives to add.",
                    );
                    return;
                }

                this.parsedValue = uniqueOptions;
                if (existingInactivated.length) this.existingInactivatedOptions = existingInactivated;
            },
            openDelimiter(pregId) {
                console.log("@openDelimiter, pregId", pregId);

                this.resetea();
                this.openDelimiterPopup = true;
                this.delimeterPregId = pregId;
            },
            attemptToAddOptions() {
                if (this.existingInactivatedOptions.length) {
                    this.$f7.dialog
                        .create({
                            title: "Inactive options",
                            text: "There are options that already exist but they are  inactive, do you want to activate them?",
                            buttons: [{ text: "No, add only new ones" }, { text: "YES" }],
                            onClick: async (dialog, index) => {
                                if (index === 0) {
                                    this.existingInactivatedOptions = [];
                                    this.addOptions();
                                } else if (index === 1) {
                                    this.addOptions();
                                }
                            },
                        })
                        .open();
                } else {
                    this.addOptions();
                }
            },
            async addOptions() {
                console.log("parsed value", this.parsedValue);

                //preguntaid
                let toadders = {};
                let toStore = {};
                this.parsedValue.forEach((neopreg) => {
                    let route = `projects/${this.adminprojectFocus}/entityData/${this.delimeterPregId}/alternativas`;
                    let neoptionid = this.$firebase.database().ref(route).push().key;

                    toadders[`${route}/${neoptionid}`] = {
                        id: neoptionid,
                        tx: neopreg.trim(),
                    };
                    toStore[neoptionid] = {
                        id: neoptionid,
                        tx: neopreg.trim(),
                    };
                });
                if (this.existingInactivatedOptions.length) {
                    this.existingInactivatedOptions.forEach((option) => {
                        let route = `projects/${this.adminprojectFocus}/entityData/${this.delimeterPregId}/alternativas/${option.id}/inct`;
                        toadders[`${route}`] = null;
                        toStore[option.id] = {
                            id: option.id,
                            tx: option.tx,
                            inct: null,
                        };
                    });
                }
                let toUpdate = this.setLastUpdate();
                toUpdate = Object.assign(toUpdate, toadders);
                console.log("toUpdate", toUpdate);
                //  console.log('guardando',optiontocreate)
                try {
                    this.$f7.dialog.preloader("Saving...");
                    await this.$firebase.database().ref().update(toUpdate);
                    //agregar a mi
                    this.$f7.dialog.close();
                    this.$store.commit("addItemNiveFive", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: "entityData",
                        d4: this.delimeterPregId,
                        e5: "alternativas",
                        f6: toStore,
                        merge: true,
                    });
                    this.resetea();
                    this.openDelimiterPopup = false;
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log("erró", error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            async crearRegla() {
                try {
                    this.guardandoeste = this.pdataSorted[this.editaReglaPoppOpened].id + "depde";
                    let nuevaregla = {
                        pregid: this.nuevaregla.origenid,
                        tipo: this.nuevaregla.restricid,
                    };
                    nuevaregla[this.nuevaregla.restricid] = this.nuevaregla[this.nuevaregla.restricid];
                    let datous = await this.commonExecute(
                        {
                            projectid: this.adminprojectFocus,
                            propertyid: this.pdataSorted[this.editaReglaPoppOpened].id,
                            rule: nuevaregla,
                        },
                        "v2_setRuleForEntityData",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let finalrul = datous.payload;
                    console.log("finalrul", finalrul);
                    //CHELONG
                    this.$store.commit("addItemNiveFive", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: "entityData",
                        d4: this.pdataSorted[this.editaReglaPoppOpened].id,
                        e5: "depde",
                        f6: finalrul,
                    });
                    this.cerrandoReglaGenerator();
                    this.guardandoeste = null;
                } catch (error) {
                    this.guardandoeste = null;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            populaArrayx(event, restriccionid) {
                const value = event.target.value;
                if (event.target.checked) {
                    if (!this.nuevaregla[restriccionid].includes(value)) {
                        this.nuevaregla[restriccionid].push(value);
                    }
                } else {
                    if (this.nuevaregla[restriccionid].includes(value)) {
                        this.nuevaregla[restriccionid].splice(this.nuevaregla[restriccionid].indexOf(value), 1);
                    }
                }
            },
            setPrefDefinitoria(pregdefinitoriaid) {
                this.resetReglaGenerator();
                this.nuevaregla.origenid = pregdefinitoriaid;
            },
            cerrandoReglaGenerator() {
                this.resetReglaGenerator();
                this.editaReglaPoppOpened = null;
            },
            resetReglaGenerator() {
                this.nuevaregla.restricid = null;
                this.nuevaregla.origenid = null;
                this.nuevaregla.elige_al_menos_estas_ids = [];
                this.nuevaregla.elige_exactamente_estas_ids = [];
                this.nuevaregla.contiene = "";
                this.nuevaregla.equal_to = "";
                this.nuevaregla.equal_to_optionid = null;
                this.nuevaregla.mayor_que = null;
                this.nuevaregla.any_value = true;
                this.nuevaregla.menor_que = null;
            },
            getAmostrar(unvalorxs, pregid) {
                if (Array.isArray(unvalorxs)) {
                    return unvalorxs
                        .map((altid) => {
                            return this.projectinfo.entityData[pregid].alternativas[altid].tx;
                        })
                        .join(" | ");
                } else if (unvalorxs === true) {
                    return null;
                } else if (this.projectinfo.entityData[pregid].tipo === "elige_una") {
                    if (!this.projectinfo.entityData[pregid].alternativas[unvalorxs]) {
                        return "ALTERNATIVA YA NO EXISTE EN PREGUNTA DE ORIGEN. ELIMINE ESTA REGLA.";
                    } else {
                        return this.projectinfo.entityData[pregid].alternativas[unvalorxs].tx;
                    }
                } else if (this.projectinfo.entityData[pregid].tipo === "input_fecha") {
                    return this.$dayjs(unvalorxs).format("MM/DD/YYYY");
                } else {
                    return unvalorxs;
                }
            },
            setLimiteNum(preguntaid, tipolimite) {
                return this.$f7.dialog.prompt(`Set the ${tipolimite} boundary`, "Set limit", (neolit) => {
                    if (neolit === "") {
                        return this.$f7.dialog.alert(`Boundary can't be empty`, "Error");
                    }
                    neolit = Number(neolit);
                    this.$f7.dialog.preloader("Saving..."); //lowerLimit
                    this.modificaneoPregunta({
                        preguntaid,
                        where: `${tipolimite}Limit`,
                        valor: neolit,
                        newQuestiontype: "entityData",
                        subAction: "set limit number",
                    }).then(() => {
                        this.$f7.dialog.close();
                    });
                });
            },
            modTabCuentas(que) {
                if (que) {
                    this.loadaccounts(false);
                }
                this.estoyenaccounts = que;
            },
            async loadaccounts(force) {
                try {
                    if (!this.allusers.length || force || this.forceUsersReload) {
                        this.cargando = true;
                        let projecto = this.adminprojectFocus;
                        let datous = await this.commonExecute({ projectid: projecto }, "v2_getmyusers", false);
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        this.allusers = datous.payload;
                        //console.log('allusers',this.allusers);
                        this.allrequests = datous.requests || [];
                        // console.log('requests', this.allrequests);
                        this.$store.commit("setWhatTo", {
                            what: "forceUsersReload",
                            to: false,
                        });
                    }
                    this.cargando = false;
                } catch (error) {
                    this.cargando = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            goToCuentaInner(cuenta) {
                this.popupCrearCuentaOpened = false;
                this.gotoCuenta(cuenta);
            },
            gotoCuenta(cuenta) {
                let cualtoget = ["profiles"];
                return this.getMeThese(cualtoget)
                    .then(() => {
                        // console.log(cuenta);
                        this.$f7.views.main.router.navigate(`/cuenta/project`, {
                            props: {
                                userInfo: cuenta,
                            },
                        });
                    })
                    .catch((error) => {
                        this.$f7.dialog.close();
                        console.error(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            },
            cancelaEditAbout() {
                this.neoprojectinfoabout = this.infoaboutoriginal;
                this.leaboutOpened = false;
            },
            async saveNeoAbout() {
                this.$f7.dialog.preloader("Saving...");
                let toUpdate = this.setLastUpdate();
                toUpdate[`about/${this.adminprojectFocus}`] = this.neoprojectinfoabout;
                await this.$firebase.database().ref().update(toUpdate);
                this.$f7.dialog.close();
                this.infoaboutoriginal = this.neoprojectinfoabout;
                this.leaboutOpened = false;
            },
            async traeAbout() {
                if (!this.infoaboutoriginal) {
                    let proabout = await this.$firebase.database().ref(`about/${this.adminprojectFocus}`).once("value");
                    this.neoprojectinfoabout = proabout.val() || "Not set";
                    this.infoaboutoriginal = this.neoprojectinfoabout;
                }
                if (this.estoyenaccounts) {
                    this.loadaccounts(false);
                }
            },
            async changeLimitLength({ where, valor }) {
                //lenghlimits min max
                //primaryidMinLen
                //primaryidMaxLen
                valor = valor ? parseInt(valor) : null;
                if (where == "primaryidMinLen") {
                    //ver que sea menor o igual que el max
                    if (this.projectinfo.primaryidMaxLen && valor && valor > this.projectinfo.primaryidMaxLen) {
                        return this.$f7.dialog.alert("Logic error, value out of range");
                    }
                    //cambiar el MAXlengthArray
                    this.lenghlimits.max = this.lenghrestric.filter((unval) => {
                        return valor ? unval >= valor : true;
                    });
                } else if (where == "primaryidMaxLen") {
                    //ver que sea menor o igual que el max
                    if (this.projectinfo.primaryidMinLen && valor && valor < this.projectinfo.primaryidMinLen) {
                        return this.$f7.dialog.alert("Logic error, value out of range");
                    }
                    //cambiar el MINlengthArray
                    this.lenghlimits.min = this.lenghrestric.filter((unval) => {
                        return valor ? unval <= valor : true;
                    });
                }
                // console.log('vamos a modificar este',action,valor)
                await this.modificaneoCore({ where, valor, subAction: "update primary ID limit length" });
            },
            async saveIdDataType(nuevodatatype) {
                //  console.log('guarando nuevo datatype',nuevodatatype);
                //primaryidType
                await this.modificaneoCore({
                    where: "primaryidType",
                    valor: nuevodatatype,
                    subAction: "update primary ID type",
                });
            },
            alsnapshot(snapshot) {
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                let nentero = Math.round(percentage);
                if (!isNaN(nentero) && this.progressdialog && !this.progressdialog.destroyed) {
                    //console.log('elsnaosho',this.progressdialog);
                    this.progressdialog.setProgress(nentero);
                    this.progressdialog.setText(nentero + "%");
                }
            },
            alerror(err) {
                // console.log(err.code);
                this.progressdialog.close();
                this.$f7.dialog.alert(err.code, "upload error");
            },
            async altermino() {
                try {
                    this.progressdialog.close();
                    // console.log(this.task,'se subio la foto!');
                    this.$f7.dialog.preloader("Processing...");
                    let datous = await this.commonExecute(
                        { projectid: this.adminprojectFocus, extenx: this.extenx },
                        "v2_procesaProjectBanner",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let neourl = `${datous.payload}&tm=${Date.now()}`;
                    this.$store.commit("modifyProjectBanner", {
                        projectId: this.adminprojectFocus,
                        bannerURL: neourl,
                    });
                    console.log("New banner URL", this.projects[this.adminprojectFocus].banner);
                    this.$f7.dialog.close();
                } catch (error) {
                    console.log("Error aqui", error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            subirfoto(filereceive) {
                let filename = filereceive.name;
                let extenx = filename.split(".").pop().toLowerCase();

                this.neofilename = `banner.${extenx}`;
                this.extenx = extenx;
                this.progressdialog = this.$f7.dialog.progress("Uploading project banner...", 0);
                this.progressdialog.setText("0%");
                //  console.log('SUBIENDO A',`core/${this.$firebase.auth().currentUser.uid}/${this.adminprojectFocus}/avatar/${this.neofilename}`)
                this.task = this.$firebase
                    .storage()
                    .ref(
                        `/neocore/${this.$firebase.auth().currentUser.uid}/${this.adminprojectFocus}/avatar/${
                            this.neofilename
                        }`,
                    )
                    .put(filereceive);
                this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino);
            },
            filesChange(e) {
                let file = e.target.files[0];
                if (file) {
                    //console.log(file)
                    this.subirfoto(file);
                }
            },
            async preuploadImage() {
                try {
                    this.$f7.dialog.preloader("Requesting auth...");
                    let datous = await this.commonExecute(
                        { projectid: this.adminprojectFocus, targetid: "avatar" },
                        "v2_dameTokenPara",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    await this.$firebase.auth().currentUser.reload();
                    let user = this.$firebase.auth().currentUser;
                    let idToken = await user.getIdToken(true);
                    // console.log('EL TOKEN ES ',idToken);
                    this.$f7.dialog.close();
                    //  console.log('token forged ok',datous);
                    this.$$("#projectavatar-input").click();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            addnewInstituion(inst = {}) {
                return this.$f7.dialog.prompt(
                    "Indicate the name of the institution",
                    "Institution name",
                    async (instName) => {
                        if (!instName) {
                            return this.$f7.dialog.alert("Name can not be empty", "Error");
                        }
                        let newInstitution = null;
                        if (Object.values(inst).length == 0) {
                            newInstitution = true;
                            let neoisntitutionid = this.$firebase
                                .database()
                                .ref(`projects/${this.adminprojectFocus}/institutions`)
                                .push().key;
                            inst.id = neoisntitutionid;
                        }
                        inst.name = instName.trim();
                        //  console.log('guardando',inst)
                        await this.setInstitution({ inst, newInstitution });
                    },
                    () => {},
                    inst ? inst.name : "",
                );
            },
            async commonfiresave({ condialog, parameters }) {
                try {
                    if (condialog) {
                        this.$f7.dialog.preloader("Saving...");
                    }
                    let lastUpdate = this.setLastUpdate();
                    parameters.lastUpdate = lastUpdate;
                    parameters.dev = this.$store.state.dev;
                    parameters.projectid = this.adminprojectFocus;
                    let call = this.$firebase.functions().httpsCallable("v2_updateProjectCoreInfo");
                    let response = await call(parameters);
                    if (response.data.error) throw new Error(response.data.error);
                    if (condialog) {
                        this.$f7.dialog.close();
                    }
                    return true;
                } catch (error) {
                    if (condialog) {
                        this.$f7.dialog.close();
                    }
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                    return null;
                }
            },
            async setInstitution({ inst, timeZone, newInstitution }) {
                try {
                    await this.commonfiresave({
                        condialog: true,
                        parameters: {
                            institutions: true,
                            subAction: timeZone
                                ? "Update institution time zone"
                                : newInstitution
                                ? "Create institution"
                                : "Update institution name",
                            valor: inst,
                            preguntaid: inst.id,
                            newQuestiontype: "institutions",
                        },
                    });
                    this.$store.commit("addItemNivefour", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: "institutions",
                        d4: inst.id,
                        e5: inst,
                    });
                } catch (error) {
                    console.log("erró");
                }
            },
            attempToInactivateInstitution({ instix }) {
                this.institutionToInactivate = Object.assign({}, instix);
                this.inactivatingInstitutionPopup = true;
            },
            async activateOrInactivateIntitution({ instix }) {
                if (instix) {
                    instix.inactive = null;
                    instix.restrictAccess = null;
                    instix.noVisible = null;
                } else {
                    instix = Object.assign({}, this.institutionToInactivate);
                    instix.inactive = true;
                    if (this.inactivationSettings == "restrictAccess") {
                        instix.restrictAccess = true;
                    } else if (this.inactivationSettings == "noVisible") {
                        instix.restrictAccess = true;
                        instix.noVisible = true;
                    }
                }

                await this.commonfiresave({
                    condialog: true,
                    parameters: {
                        institutions: true,
                        subAction: !instix.inactive ? "Activate institution" : "Inactivate institution",
                        valor: instix,
                        preguntaid: instix.id,
                        newQuestiontype: "institutions",
                    },
                });

                this.$store.commit("addItemNivefour", {
                    a1: "projects",
                    b2: this.adminprojectFocus,
                    c3: "institutions",
                    d4: instix.id,
                    e5: instix,
                });
                this.institutionToInactivate = null;
                this.inactivationSettings = null;
                this.inactivatingInstitutionPopup = false;
            },
            eliminaAlternativa(preguntaid, newQuestiontype, alternativaid) {
                return this.$f7.dialog.confirm(
                    "Confirm you want to inactivate this option",
                    "Confirm inactivation",
                    async () => {
                        try {
                            await this.commonfiresave({
                                condialog: true,
                                parameters: {
                                    inactivateAlternative: true,
                                    subAction: "Inactivate alternative",
                                    valor: true,
                                    newQuestiontype: newQuestiontype,
                                    preguntaid: preguntaid,
                                    alternativaid,
                                },
                            });
                            //agregar a mi
                            this.$store.commit("addItemNiveSeven", {
                                a1: "projects",
                                b2: this.adminprojectFocus,
                                c3: newQuestiontype,
                                d4: preguntaid,
                                e5: "alternativas",
                                f6: alternativaid,
                                g7: "inct",
                                h8: true,
                            });

                            if (this.viewingPreguntaId && this.viewingAlternatives.length) {
                                const foundAltIndex = this.viewingAlternatives.findIndex((a) => a.id == alternativaid);

                                if (foundAltIndex === -1) return;

                                this.viewingAlternatives[foundAltIndex].inct = true;

                                this.$refs?.alternativesVirtualList?.f7VirtualList?.deleteItem(foundAltIndex);
                            }
                        } catch (error) {
                            console.log("erró", error);
                        }
                    },
                );
            },
            creaAlternativa(preguntaid, newQuestiontype) {
                return this.$f7.dialog.prompt("Indicate the option", "New option", async (neopreg) => {
                    if (!neopreg) {
                        return this.$f7.dialog.alert("Option can not be empty", "Error");
                    }
                    let lowerizada = neopreg.toLowerCase().trim();
                    let optiontocreate, neoptionid;
                    //   console.log('data previoa es',this.projectinfo[newQuestiontype][preguntaid]);
                    let existe = Object.values(this.projectinfo[newQuestiontype][preguntaid].alternativas || {}).find(
                        (unaalt) => {
                            return unaalt.tx.toLowerCase() == lowerizada;
                        },
                    );
                    if (existe) {
                        console.log("existe", existe);
                        // si esta desactivada, activarla denuevo , SYNTAX
                        if (existe.inct) {
                            this.$f7.dialog
                                .create({
                                    title: "Option already exists",
                                    text: "This option already exists but it is inactive, do you want to activate it or create a new one with the same name?",
                                    buttons: [{ text: "CANCEL" }, { text: "Activate" }, { text: "Create new" }],
                                    onClick: async (dialog, index) => {
                                        if (index === 1) {
                                            neoptionid = existe.id;
                                            optiontocreate = Object.assign({}, existe, {
                                                inct: null,
                                                tx: neopreg.trim(),
                                            });
                                            this.saveOption({
                                                newQuestiontype,
                                                preguntaid,
                                                neoptionid,
                                                optiontocreate,
                                            });
                                        }
                                        if (index === 2) {
                                            neoptionid = this.$firebase
                                                .database()
                                                .ref(
                                                    `projects/${this.adminprojectFocus}/${newQuestiontype}/${preguntaid}/alternativas`,
                                                )
                                                .push().key;
                                            optiontocreate = {
                                                id: neoptionid,
                                                tx: neopreg.trim(),
                                            };
                                            this.saveOption({
                                                newQuestiontype,
                                                preguntaid,
                                                neoptionid,
                                                optiontocreate,
                                            });
                                        }
                                    },
                                })
                                .open();
                        } else {
                            return this.$f7.dialog.alert("Option already exists", "Error");
                        }
                    } else {
                        neoptionid = this.$firebase
                            .database()
                            .ref(`projects/${this.adminprojectFocus}/${newQuestiontype}/${preguntaid}/alternativas`)
                            .push().key;
                        optiontocreate = {
                            id: neoptionid,
                            tx: neopreg.trim(),
                        };

                        this.saveOption({ newQuestiontype, preguntaid, neoptionid, optiontocreate });
                    }

                    //  console.log('guardando',optiontocreate)
                });
            },
            async saveOption({ newQuestiontype, preguntaid, neoptionid, optiontocreate }) {
                try {
                    await this.commonfiresave({
                        condialog: true,
                        parameters: {
                            newAlternative: true,
                            subAction: "Create new alternative",
                            valor: optiontocreate,
                            newQuestiontype: newQuestiontype,
                            preguntaid: preguntaid,
                            alternativaid: neoptionid,
                        },
                    });
                    //agregar a mi
                    this.$store.commit("addItemNiveSix", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: newQuestiontype,
                        d4: preguntaid,
                        e5: "alternativas",
                        f6: neoptionid,
                        g7: optiontocreate,
                    });
                } catch (error) {
                    console.log("erró", error);
                }
            },
            filtrameAlternativas(altes) {
                if (altes) {
                    return Object.values(altes).filter((unalt) => {
                        return !unalt.inct;
                    });
                } else {
                    return [];
                }
            },
            eliminaPregunta(preguntaid, newQuestiontype) {
                this.$f7.dialog.confirm("Please confirm you want to delete this property", "Confirm", () => {
                    this.$f7.dialog.preloader("Deleting...");
                    this.modificaneoPregunta({
                        preguntaid,
                        where: "disabled",
                        valor: true,
                        newQuestiontype,
                        subAction: "delete field",
                    }).then(() => {
                        this.$f7.dialog.close();
                    });
                });
            },
            abreEditorEnunciado(preguntaid, newQuestiontype) {
                let prompt = this.$f7.dialog.create({
                    title: "Edit property header",
                    text: "Indicate the header of the field",
                    content: `<div class="dialog-input-field item-input"><div class="item-input-wrap"><textarea  id="editTextarea" class="dialog-input promptTextarea" required >${this.projectinfo[newQuestiontype][preguntaid].encabezado}</textarea></div></div>`,
                    buttons: [{ text: "CANCEL" }, { text: "OK" }],
                    onClick: async (dialog, index) => {
                        let neopreg = dialog.$el.find(".dialog-input").val();
                        if (index === 1) {
                            if (!neopreg) {
                                return this.$f7.dialog.alert("Header can not be empty", "Error");
                            }
                            await this.modificaneoPregunta({
                                preguntaid,
                                where: "encabezado",
                                valor: neopreg,
                                newQuestiontype,
                                subAction: "update header",
                            });
                        }
                    },
                });

                prompt.open();
                document.getElementById("editTextarea").focus();
            },
            abreEditorLinfo(preguntaid, newQuestiontype) {
                let prompt = this.$f7.dialog.create({
                    title: "Edit property information",
                    text: "Indicate the information of the field",
                    content: `<div class="dialog-input-field item-input"><div class="item-input-wrap"><textarea  id="editTextarea" class="dialog-input promptTextarea" required >${
                        this.projectinfo[newQuestiontype][preguntaid].linfo || ""
                    }</textarea></div></div>`,
                    buttons: [{ text: "CANCEL" }, { text: "OK" }],
                    onClick: async (dialog, index) => {
                        let neopreg = dialog.$el.find(".dialog-input").val();
                        if (index === 1) {
                            await this.modificaneoPregunta({
                                preguntaid,
                                where: "linfo",
                                valor: neopreg,
                                newQuestiontype,
                                subAction: "update information box",
                            });
                        }
                    },
                });

                prompt.open();
                document.getElementById("editTextarea").focus();
            },
            eliminarLinfo(preguntaid, newQuestiontype) {
                this.$f7.dialog.confirm(
                    "Are you sure you want to delete de Infomartion box",
                    "Delete Information",
                    async () => {
                        await this.modificaneoPregunta({
                            preguntaid,
                            where: "linfo",
                            valor: null,
                            newQuestiontype,
                            subAction: "delete information box",
                        });
                    },
                );
            },
            async modificaneoPregunta({ preguntaid, where, valor, newQuestiontype, subAction }) {
                try {
                    if (this.projectinfo?.[newQuestiontype]?.[preguntaid]?.[where] == valor) return;

                    this.guardandoeste = `${preguntaid}${where}`;

                    let lastUpdate = this.setLastUpdate();
                    let call = this.$firebase.functions().httpsCallable("v2_updateProjectCoreInfo");
                    let response = await call({
                        valor,
                        subAction,
                        lastUpdate,
                        dev: this.$store.state.dev,
                        projectid: this.adminprojectFocus,
                        preguntaid,
                        newQuestiontype,
                        itemid: where,
                    });
                    if (response.data.error) throw new Error(response.data.error);

                    this.guardandoeste = null;
                    if (!this.projectinfo[newQuestiontype]) {
                        this.$set(this.projectinfo, newQuestiontype, {});
                    }
                    if (!this.projectinfo[newQuestiontype][preguntaid]) {
                        this.$set(this.projectinfo[newQuestiontype], preguntaid, {});
                    }
                    this.$set(this.projectinfo[newQuestiontype][preguntaid], where, valor);
                    this.avisaOk("Changes saved OK");
                } catch (error) {
                    this.guardandoeste = null;
                    console.log(error);
                    return this.$f7.dialog.alert(error.message, error.code);
                }
            },
            openditer({ where, subAction }) {
                return this.$f7.dialog.prompt(
                    "What is the new value",
                    "Editing value",
                    async (neoval) => {
                        let rimedado = neoval.trim();
                        if (!rimedado) {
                            return this.$f7.dialog.alert("New value can not be empty", "Error");
                        }
                        if (where == "cemail" && !EmailValidator.validate(rimedado)) {
                            return this.$f7.dialog.alert("e-mail does not seem correctly formatted", "Error");
                        }
                        await this.modificaneoCore({ where, valor: rimedado, subAction });
                    },
                    () => {},
                    this.projectinfo[where],
                );
            },
            async modificaneoCore({ where, valor, subAction }) {
                try {
                    if (this.projectinfo[where] == valor) return;
                    this.guardandoeste = `core_${where}`;
                    let lastUpdate = this.setLastUpdate();
                    let call = this.$firebase.functions().httpsCallable("v2_updateProjectCoreInfo");
                    let response = await call({
                        metadata: true,
                        preguntaid: where,
                        subAction,
                        valor,
                        lastUpdate,
                        dev: this.$store.state.dev,
                        projectid: this.adminprojectFocus,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.guardandoeste = null;
                    this.$set(this.projectinfo, where, valor);
                    this.avisaOk("Changes saved OK");
                } catch (error) {
                    this.guardandoeste = null;
                    console.log("error", error);
                    return this.$f7.dialog.alert(error.message, error.code);
                }
            },
            abrePopTypes(event) {
                this.newQuestiontype = "entityData";
                this.$f7.popover.open(".popover-qutypes", event.target);
            },
            abreFileTypes(event) {
                this.newQuestiontype = "entityFiles";
                this.$f7.popover.open(".popover-fyltypes", event.target);
            },
            async crearPregunta(tipopregid) {
                let prompt = this.$f7.dialog.create({
                    title: "New field",
                    text: "Indicate the header of the field",
                    content:
                        '<div class="dialog-input-field item-input"><div class="item-input-wrap"><textarea id="newTextarea" class="dialog-input promptTextarea" required ></textarea></div></div>',
                    buttons: [{ text: "CANCEL" }, { text: "OK" }],
                    onClick: async (dialog, index) => {
                        let neopreg = dialog.$el.find(".dialog-input").val();
                        if (index === 1) {
                            if (!neopreg) {
                                return this.$f7.dialog.alert("Header can not be empty", "Error");
                            }

                            this.$f7.dialog.preloader("Validating...");

                            // Validate if the question is already in the project
                            const preguntasSnapshot = await this.$firebase
                                .database()
                                .ref(`projects/${this.adminprojectFocus}/${this.newQuestiontype}`)
                                .once("value");
                            let preguntasInProject = preguntasSnapshot.val();

                            const isPreguntaAlreadyInProject = Object.values(preguntasInProject || {}).find(
                                (p) => this.fxndclean(p.encabezado) === this.fxndclean(neopreg),
                            );

                            if (isPreguntaAlreadyInProject) {
                                this.$f7.dialog.close();
                                if (!isPreguntaAlreadyInProject.disabled)
                                    return this.$f7.dialog.alert(
                                        "There is a field with the same header in this project",
                                        "Validation Error",
                                    );
                                else
                                    return this.$f7.dialog
                                        .create({
                                            title: "There is a field with the same header in this project, but it is disabled",
                                            text: "Do you want to enable it or create a new one?",
                                            buttons: [
                                                {
                                                    text: "Cancel",
                                                },
                                                {
                                                    text: "Enable",
                                                    onClick: async () => {
                                                        await this.modificaneoPregunta({
                                                            preguntaid: isPreguntaAlreadyInProject.id,
                                                            where: "disabled",
                                                            valor: null,
                                                            newQuestiontype: this.newQuestiontype,
                                                            subAction: "enable field",
                                                        });
                                                    },
                                                },
                                                {
                                                    text: "Create new",
                                                    onClick: async () => {
                                                        let oldEncabezado = isPreguntaAlreadyInProject.encabezado;
                                                        let newLegacyName;
                                                        let legacyArray = Object.values(preguntasInProject).filter(
                                                            (p) => {
                                                                return p.encabezado.includes(
                                                                    `${oldEncabezado}_legacy_`,
                                                                );
                                                            },
                                                        );
                                                        if (legacyArray.length > 0) {
                                                            let sortedLegacy = legacyArray.sort((a, b) => {
                                                                let aArray = a.encabezado.split("_legacy_");
                                                                let aNumber = aArray[1];
                                                                let bArray = b.encabezado.split("_legacy_");
                                                                let bNumber = bArray[1];
                                                                if (aNumber > bNumber) {
                                                                    return -1;
                                                                } else if (aNumber < bNumber) {
                                                                    return 1;
                                                                } else {
                                                                    return 0;
                                                                }
                                                            });
                                                            let lastLegacyArray =
                                                                sortedLegacy[0].encabezado.split("_legacy_");
                                                            let legacyNumber = lastLegacyArray[1];
                                                            let newNumber = String(Number(legacyNumber) + 1);
                                                            newLegacyName = `${lastLegacyArray[0]}_legacy_${newNumber}`;
                                                        } else {
                                                            newLegacyName = `${oldEncabezado}_legacy_1`;
                                                        }
                                                        await this.modificaneoPregunta({
                                                            preguntaid: isPreguntaAlreadyInProject.id,
                                                            where: "encabezado",
                                                            valor: newLegacyName,
                                                            newQuestiontype: this.newQuestiontype,
                                                            subAction: "update header",
                                                        });
                                                        this.saveNewQuestion(tipopregid, neopreg);
                                                    },
                                                },
                                            ],
                                        })
                                        .open();
                            }
                            this.saveNewQuestion(tipopregid, neopreg);
                        }
                    },
                });
                prompt.open();
                document.getElementById("newTextarea").focus();
            },
            saveNewQuestion(tipopregid, neopreg) {
                this.$f7.dialog.close();

                let neopreguntaid = this.$firebase
                    .database()
                    .ref(`projects/${this.adminprojectFocus}/${this.newQuestiontype}`)
                    .push().key;
                let preguntaAcrear = {
                    id: neopreguntaid,
                    orden: this.projectinfo[this.newQuestiontype]
                        ? Object.keys(this.projectinfo[this.newQuestiontype]).length
                        : 0,
                    encabezado: neopreg.trim(),
                    tipo: tipopregid,
                };
                // console.log('guardando',preguntaAcrear)
                if (this.newQuestiontype == "entityFiles") {
                    this.$f7.dialog
                        .create({
                            title: "How many files can be uploaded?",
                            text: "this option cannot be changed later",
                            buttons: [
                                {
                                    text: "Cancel",
                                },
                                {
                                    text: "Only one",
                                    onClick: () => {
                                        this.uploadNewQuestion(neopreguntaid, preguntaAcrear);
                                    },
                                },
                                {
                                    text: "Multiple",
                                    onClick: () => {
                                        preguntaAcrear.many = true;
                                        this.uploadNewQuestion(neopreguntaid, preguntaAcrear);
                                    },
                                },
                            ],
                        })
                        .open();
                } else {
                    this.uploadNewQuestion(neopreguntaid, preguntaAcrear);
                }
            },
            async uploadNewQuestion(neopreguntaid, preguntaAcrear) {
                try {
                    await this.commonfiresave({
                        condialog: true,
                        parameters: {
                            createField: true,
                            subAction: "Create new field",
                            valor: preguntaAcrear,
                            newQuestiontype: this.newQuestiontype,
                            preguntaid: neopreguntaid,
                        },
                    });
                    //agregar a mi
                    this.$store.commit("addItemNivefour", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: this.newQuestiontype,
                        d4: neopreguntaid,
                        e5: preguntaAcrear,
                    });
                } catch (error) {
                    console.log("erró");
                }
            },
            onSort(data) {
                // Sort data
                //  console.log(data);
                let clonado = this.pdataSorted.slice(0);
                clonado.splice(data.to, 0, clonado.splice(data.from, 1)[0]);
                //  console.log(this.pdataSorted);
                //   console.log(clonado);
                let newPreguntas = {};
                let toUpdate = this.setLastUpdate();

                clonado.forEach((unapreg, idx) => {
                    unapreg.orden = idx;
                    newPreguntas[unapreg.id] = unapreg;
                    toUpdate["projects/" + this.adminprojectFocus + "/entityData/" + unapreg.id + "/orden"] = idx;
                });
                this.updt = "sort_pat_data";
                return this.$firebase
                    .database()
                    .ref()
                    .update(toUpdate)
                    .then(() => {
                        //     console.log('cambio ok');
                        //newPreguntas
                        this.$store.commit("addItemNivethree", {
                            a1: "projects",
                            b2: this.adminprojectFocus,
                            c3: "entityData",
                            d4: newPreguntas,
                        });
                        this.$f7.sortable.disable(".headpreg");
                        this.updt = null;
                        this.avisaOk();
                    })
                    .catch((error) => {
                        this.updt = null;
                        console.error(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            },
            onSortFiles(data) {
                // Sort data
                //  console.log(data);
                let clonado = this.pfilesSorted.slice(0);
                clonado.splice(data.to, 0, clonado.splice(data.from, 1)[0]);
                //   console.log(this.pfilesSorted);
                // console.log(clonado);
                let newPreguntas = {};
                let toUpdate = this.setLastUpdate();
                clonado.forEach((unapreg, idx) => {
                    unapreg.orden = idx;
                    newPreguntas[unapreg.id] = unapreg;
                    toUpdate["projects/" + this.adminprojectFocus + "/entityFiles/" + unapreg.id + "/orden"] = idx;
                });
                this.updt = "sort_pat_files";
                return this.$firebase
                    .database()
                    .ref()
                    .update(toUpdate)
                    .then(() => {
                        //  console.log('cambio ok');
                        //newPreguntas
                        this.$store.commit("addItemNivethree", {
                            a1: "projects",
                            b2: this.adminprojectFocus,
                            c3: "entityFiles",
                            d4: newPreguntas,
                        });
                        this.$f7.sortable.disable(".headpregdos");
                        this.updt = null;
                        this.avisaOk();
                    })
                    .catch((error) => {
                        this.updt = null;
                        console.error(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            },
            searchAll(query, items) {
                const found = [];
                let clnquery = query.toUpperCase();
                for (let i = 0; i < items.length; i += 1) {
                    if (items[i].tx.toUpperCase().indexOf(clnquery) >= 0 || query.trim() === "") found.push(i);
                }
                return found;
            },
            searchAllDictionary(query, items) {
                const found = [];
                for (let i = 0; i < items.length; i++) {
                    const entry = items[i];
                    if (entry.columnName.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === "")
                        found.push(i);
                    if (
                        entry.deidentificationType.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
                        query.trim() === ""
                    )
                        found.push(i);
                }
                return found;
            },
            renderExternalDictionary(vl, vlData) {
                console.log("renderExternalDictionary", vlData);
                this.vlData = vlData;
            },
            renderExternal(vl, vlAltData) {
                //  console.log('renderExternal', vlAltData);
                this.vlAltData = vlAltData;
            },
            seeAlternatives(alternatives, preguntaId) {
                this.$f7.dialog.preloader("Loading alternatives to show...");

                const alternativeItems = this.filtrameAlternativas(Object.values(alternatives));

                this.viewingAlternatives = alternativeItems;
                this.viewingPreguntaId = preguntaId;
                if (this.viewingPreguntaId != preguntaId) this.this.arrayToDelete = [];
                this.$refs?.alternativesVirtualList?.f7VirtualList?.replaceAllItems(alternativeItems);
                this.openAlternativePopup = true;

                this.$f7.dialog.close();
            },
            delimiterPopupClosed() {
                this.parsedValue = [];
                this.existingInactivatedOptions = [];
                this.delimeterPregId = null;
                this.openDelimiterPopup = false;
            },
            selectTodelete(checked, id) {
                if (checked) this.arrayToDelete.push(id);
                else this.arrayToDelete.splice(this.arrayToDelete.indexOf(id), 1);
            },
            selectAllToDelete(checked, alternativas) {
                if (!checked) return (this.arrayToDelete = []);
                let alts = alternativas ? alternativas : this.viewingAlternatives;
                alts.forEach((alt) => {
                    if (!this.arrayToDelete.includes(alt.id)) this.arrayToDelete.push(alt.id);
                });
            },
            deleteSelected(preg) {
                let preguntaId = preg.id;
                return this.$f7.dialog.confirm(
                    `Confirm you want to inactivate ${
                        this.arrayToDelete.length == 1 ? "this option" : "these options"
                    }`,
                    "Confirm inactivation",
                    async () => {
                        try {
                            let pregid = preguntaId ? preguntaId : this.viewingPreguntaId;
                            let arrayToDeleteButOne = this.arrayToDelete;
                            if (arrayToDeleteButOne.length == this.filtrameAlternativas(preg.alternativas).length)
                                arrayToDeleteButOne.shift();
                            await this.commonfiresave({
                                condialog: true,
                                parameters: {
                                    inactivateAlternative: true,
                                    multi: true,
                                    subAction: "Inactivate alternatives",
                                    valor: true,
                                    newQuestiontype: "entityData",
                                    preguntaid: pregid,
                                    alternativaids: arrayToDeleteButOne,
                                },
                            });
                            //agregar a mi
                            this.$store.commit("inactivateMultiple", {
                                pregid,
                                altsToDelete: arrayToDeleteButOne,
                            });
                            arrayToDeleteButOne.forEach((alternativaid) => {
                                if (this.viewingPreguntaId && this.viewingAlternatives.length) {
                                    const foundAltIndex = this.viewingAlternatives.findIndex(
                                        (a) => a.id == alternativaid,
                                    );

                                    if (foundAltIndex === -1) return;

                                    this.viewingAlternatives[foundAltIndex].inct = true;

                                    this.$refs?.alternativesVirtualList?.f7VirtualList?.deleteItem(foundAltIndex);
                                }
                            });
                            this.arrayToDelete = [];
                            this.$f7.toast.show({
                                text: "Have to keep one alternative",
                                closeTimeout: 1000,
                                position: "center",
                            });
                        } catch (error) {
                            console.log("erró", error);
                        }
                    },
                );
            },
            fxndclean(rawIdentity) {
                let a = String(rawIdentity).trim().toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
                let b = a.split(".").join(""); //sacar puntos
                let c = b.split("-").join(""); //sacar dashes
                let d = c.split(" ").join(""); //sacar espacios
                let e = d.split(",").join(""); //sacar comas
                let f = e.split("/").join(""); //sacar slashes
                return f;
            },
            searchbarDisabled() {
                this.$refs?.alternativesVirtualList?.f7VirtualList?.resetFilter();
            },
            openTimezonePopup(instid) {
                this.selectedInstitution = instid;
                this.timezonePopup = true;
            },
            selectTimezone(timezone) {
                let inst = this.projectinfo.institutions[this.selectedInstitution];
                inst.tz = timezone;
                this.setInstitution({ inst, timeZone: true });
                this.timezonePopup = false;
            },
            getUTC(name) {
                let tz = ct.getTimezone(name);
                return tz.utcOffsetStr;
            },
            checkNameExists(value) {
                let allTags = Object.values(this.projectinfo?.fileTags || {});
                return allTags.find((tag) => {
                    return tag.tx.trim().toLowerCase() == value.trim().toLowerCase();
                });
            },
            setTagInStore(tag) {
                return this.$store.commit("addItemNivefour", {
                    a1: "projects",
                    b2: this.adminprojectFocus,
                    c3: "fileTags",
                    d4: tag.id,
                    e5: tag,
                });
            },
            async handleExistingName({ exists, value, newName }) {
                if (exists.inct) {
                    this.$f7.dialog.confirm(
                        "There is a tag with the same name, but it is inactive, Do you want to activate it?",
                        async () => {
                            try {
                                let tag = { ...exists, tx: value, inct: null };
                                await this.commonfiresave({
                                    condialog: true,
                                    parameters: {
                                        fileTags: true,
                                        subAction: newName ? "Update tag name" : "Activate tag",
                                        valor: tag,
                                        newQuestiontype: "fileTags",
                                        preguntaid: tag.id,
                                    },
                                });
                                return this.setTagInStore(tag);
                            } catch (error) {
                                throw new Error(error);
                            }
                        },
                    );
                } else {
                    return this.$f7.dialog.alert("There is already a tag with this name");
                }
            },
            async addNewTag() {
                try {
                    this.$f7.dialog.prompt(
                        "Create a new tag",
                        async (value) => {
                            if (!value.trim()) return this.$f7.dialog.alert("Name can not be empty");
                            try {
                                let exists = this.checkNameExists(value);
                                console.log("exists", exists);
                                if (exists) {
                                    return this.handleExistingName({ exists, value });
                                } else {
                                    let id = this.$firebase
                                        .database()
                                        .ref(`projects/${this.adminprojectFocus}/fileTags`)
                                        .push().key;
                                    let tag = { id, tx: value };

                                    await this.commonfiresave({
                                        condialog: true,
                                        parameters: {
                                            fileTags: true,
                                            subAction: "Create new tag",
                                            valor: tag,
                                            newQuestiontype: "fileTags",
                                            preguntaid: id,
                                        },
                                    });

                                    this.setTagInStore(tag);
                                }
                            } catch (error) {
                                throw new Error(error);
                            }
                        },
                        () => {},
                    );
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            changeTagText(tag) {
                this.$f7.dialog.prompt(
                    "Change the tag text",
                    async (value) => {
                        if (!value.trim()) return this.$f7.dialog.alert("Name can not be empty");
                        let exists = this.checkNameExists(value);
                        if (exists) {
                            return this.handleExistingName({ exists, value, newName: true });
                        } else {
                            try {
                                tag.tx = value;
                                await this.commonfiresave({
                                    condialog: true,
                                    parameters: {
                                        fileTags: true,
                                        subAction: "Update tag name",
                                        valor: tag,
                                        newQuestiontype: "fileTags",
                                        preguntaid: tag.id,
                                    },
                                });

                                this.setTagInStore(tag);
                            } catch (error) {
                                console.log(error);
                                this.$f7.dialog.alert(error.message);
                            }
                        }
                    },
                    () => {},
                    tag.tx,
                );
            },
            inactivateTag(tag) {
                this.$f7.dialog.confirm(
                    "Are you sure you want to inactivate this tag?",
                    async () => {
                        try {
                            tag.inct = true;
                            await this.commonfiresave({
                                condialog: true,
                                parameters: {
                                    fileTags: true,
                                    subAction: "Inactivate tag",
                                    valor: tag,
                                    newQuestiontype: "fileTags",
                                    preguntaid: tag.id,
                                },
                            });
                            this.setTagInStore(tag);
                        } catch (error) {
                            console.log(error);
                            this.$f7.dialog.alert(error.message);
                        }
                    },
                    () => {},
                );
            },
            removeAdminPage() {
                // let pages = this.$f7.view.main.history;
                //  pages.splice(pages.indexOf('/project_admin/'), 1);
            },
            openApiKeyPopup(apikey) {
                this.focusedApikey = Object.assign({}, apikey);
                this.apiKeyPopup = true;
            },
            copyToClipboard(text) {
                navigator.clipboard.writeText(text);
                this.$f7.toast.show({
                    text: "Copied!",
                    closeTimeout: 1000,
                    position: "center",
                });
            },
            async saveApikey() {
                if (!this.newApikey.name) return this.$f7.dialog.alert("A name is needed");
                if (this.apikeyNameExists())
                    return this.$f7.dialog.alert("This name is already used by another Api Key");
                if (!this.newApikey.expDateNever && !this.newApikey.expDate)
                    return this.$f7.dialog.alert("A expiration date is needed");
                if (!Object.keys(this.newApikey.institutions).length)
                    return this.$f7.dialog.alert("At least one institution is needed");
                try {
                    this.$f7.dialog.preloader("Saving...");
                    let toUpdate = this.setLastUpdate();
                    let call = this.$firebase.functions().httpsCallable("v2_saveApiKey");
                    let response = await call({
                        name: this.newApikey.name,
                        expDateNever: this.newApikey.expDateNever,
                        expDate: this.newApikey.expDate,
                        institutions: this.newApikey.institutions,
                        dailyLimit: this.newApikey.dailyLimit ? this.newApikey.dailyLimit : 200,
                        projectid: this.adminprojectFocus,
                        dev: this.$store.state.dev,
                    });
                    console.log(response);

                    if (response.data.error) throw new Error(response.data.error);
                    await this.$firebase.database().ref().update(toUpdate);
                    this.$store.commit("addItemNivefour", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: "apiKeys",
                        d4: response.data.id,
                        e5: response.data,
                    });
                    this.newApikeyPopup = false;
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            closeNewApikeyPopup() {
                this.newApikeyPopup = false;
                this.newApikey = Object.assign(
                    {},
                    {
                        id: null,
                        apiSecret: null,
                        name: null,
                        expDateNever: true,
                        expDate: null,
                        institutions: {},
                        dailyLimit: 200,
                    },
                );
            },
            async selectInstitution({ institid, preguntaid, action, valor, newQuestiontype }) {
                this.guardandoeste == institid;
                try {
                    let lastUpdate = this.setLastUpdate();
                    let call = this.$firebase.functions().httpsCallable("v2_updateProjectCoreInfo");
                    let response = await call({
                        apikey: true,
                        action,
                        valor: valor ? true : null,
                        lastUpdate,
                        dev: this.$store.state.dev,
                        projectid: this.adminprojectFocus,
                        institid,
                        preguntaid,
                        newQuestiontype,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.$store.commit("addItemNiveFive", {
                        a1: "projects",
                        b2: this.adminprojectFocus,
                        c3: "apiKeys",
                        d4: this.focusedApikey.id,
                        e5: institid,
                        f6: valor ? true : null,
                    });
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            selectInstitutionForNewApikey() {
                let array = this.$refs["institutionsSelectionForNewApikey"].f7SmartSelect.getValue() || [];
                array.forEach((selected) => {
                    this.newApikey.institutions[selected] = true;
                });
                if (this.newApikey.institutions) {
                    Object.keys(this.newApikey.institutions).forEach((inst) => {
                        if (!array.includes(inst)) {
                            delete this.newApikey.institutions[inst];
                        }
                    });
                }
            },
            getApikeyStatus(apikey) {
                let expired =
                    !apikey.expDateNever &&
                    apikey.expDate &&
                    this.$dayjs(apikey.expDate, "MM/DD/YYYY").unix() < this.$dayjs().unix();
                return expired ? "EXPIRED" : apikey.inactive ? "INACTIVE" : "ACTIVE";
            },
            preExistingInputs(preexistingType) {
                let preExistingInputs = [
                    { value: "name", text: "Name" },
                    { value: "email", text: "Email" },
                    { value: "phone", text: "Phone" },
                    {
                        value: "institution",
                        text: this.projectinfo?.grouper || "Institution",
                    },
                ];
                return preExistingInputs.filter((input) => {
                    return (
                        preexistingType == input.value ||
                        !this.filteredTermsInputs.some((term) => {
                            return term.preexistingType == input.value;
                        })
                    );
                });
            },
            apikeyNameExists() {
                let apikeys = this.projectinfo.apiKeys || {};
                return Object.values(apikeys).some((api) => {
                    return api.name.toLowerCase() == this.newApikey?.name.toLowerCase();
                });
            },
            async exportProjectConfigSetNewLog() {
                let call = this.$firebase.functions().httpsCallable("v2_updateProjectCoreInfo");
                try {
                    let response = await call({
                        exportProjectConfig: true,
                        action: "Export project configuration",
                        dev: this.$store.state.dev,
                        projectid: this.adminprojectFocus,
                        valor: true,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
        },
    };
</script>
<style>
    .popupmasacncho {
        width: 300px;
    }

    .altoalternativa {
        height: 20px !important;
        max-height: 20px !important;
        line-height: 20px !important;
    }

    .headpreg .accordion-item > a.item-link,
    .headpregdos .accordion-item > a.item-link {
        background: #e8e8e8;
        border-top: 1px solid #ffffff;
    }

    .headpreg .accordion-item > .item-link .item-inner:before,
    .headpreg.sortable .sortable-handler:after,
    .headpregdos .accordion-item > .item-link .item-inner:before,
    .headpregdos.sortable .sortable-handler:after {
        color: #929292 !important;
    }

    .headpreg .accordion-item-content,
    .headpregdos .accordion-item-content {
        background: #d6d6d6;
    }

    .bloqueamultilinea {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 23px;
        overflow: hidden;
    }

    .setwithseg {
        width: 220px;
    }

    #projectavatar-input {
        display: none;
    }

    .linfoabount {
        height: 200px;
        overflow-y: auto;
        background: #fff;
        padding: 10px;
    }

    .promptTextarea {
        min-height: 65px;
        width: 100%;
    }

    .fondogrisback {
        background: #f3f3f3 !important;
    }

    .fozaltox {
        overflow-y: auto;
        max-height: 300px;
        background: #fff;
    }

    .filterBadge {
        color: white;
        background-color: #a51417;
        min-width: 20%;
        margin: 10px;
    }

    .filterBadge .chip-label {
        width: 100%;
    }

    .align-self-auto .item-media {
        align-self: auto !important;
    }

    .expDateDisabled .item-inner {
        display: contents !important;
    }

    .stikySearchbar {
        position: sticky;
        top: 0px;
        z-index: 99;
        padding: 5px;
        background-color: #f3f3f3;
    }

    .checkedColor {
        color: #138a98;
    }
</style>
