<template>
    <Linevuechart :chartOptions="chartOptions" :chartData="chartData" :height="height" />
</template>

<script>
    import { Line as Linevuechart } from "vue-chartjs";
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        TimeScale,
        Legend,
        LineElement,
        PointElement,
        CategoryScale,
        LinearScale,
    } from "chart.js";
    import "chartjs-adapter-dayjs-3";
    ChartJS.register(Title, Tooltip, TimeScale, Legend, LineElement, PointElement, CategoryScale, LinearScale);
    export default {
        name: "LineChart",
        components: { Linevuechart },
        props: {
            chartData: {
                type: Object,
                required: true,
            },
            chartOptions: {
                type: Object,
                default: () => {
                    return {
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                display: false,
                            },
                        },
                        scales: {
                            x: {
                                type: "time",
                                distribution: "linear",
                                time: {
                                    // Luxon format string
                                    tooltipFormat: "DD MMM",
                                    unit: "day",
                                    displayFormats: {
                                        millisecond: "DD MMM",
                                        second: "DD MMM",
                                        minute: "DD MMM",
                                        hour: "DD MMM",
                                        day: "DD MMM YY",
                                    },
                                },
                            },
                            y: {
                                beginAtZero: true,
                                stepped: true,
                                title: {
                                    display: true,
                                    text: "Cumulative count",
                                },
                                ticks: {
                                    beginAtZero: true,
                                    userCallback: (label) => {
                                        if (Math.floor(label) === label) {
                                            return label;
                                        }
                                    },
                                },
                            },
                        },
                    };
                },
            },
            height: {
                type: Number,
                default: 100,
            },
        },
    };
</script>
