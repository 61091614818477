<template>
    <div v-if="iconOnly">
        <f7-link class="no-ripple" :tooltip="contentProtectedText">
            <font-awesome-icon :icon="['fad', 'lock']" class="fa-sm" color="gray"></font-awesome-icon>
        </f7-link>
    </div>
    <small v-else-if="oneline" class="text-color-gray">
        <font-awesome-icon :icon="['fad', 'lock']" class="fa-sm" color="gray"></font-awesome-icon>
        This content is protected.
    </small>
    <div v-else class="display-flex flex-direction-column text-align-center">
        <font-awesome-icon :icon="['fad', 'lock']" class="fa-2x lock-icon" color="gray"></font-awesome-icon>
        <h3 class="text-color-gray margin-top-half no-margin-bottom">
            {{ contentProtectedText }}
        </h3>
    </div>
</template>

<script>
export default {
    props: {
        oneline: {
            type: Boolean,
            default: false,
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            contentProtectedText: "This content is protected. You don't have privileges to see this.",
        };
    },
};
</script>

<style scoped>
.lock-icon {
    width: 100%;
}
</style>
