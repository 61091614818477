<template>
    <f7-page class="fondoimagenmenu no-padding-bottom">
        <f7-block class="text-align-center">
            <img class="cielologo" src="../static/icons/512x512.png" />
            <p class="no-margin login-bccly libre-baskerville">
                <strong>CIELO</strong>
            </p>
            <p class="no-margin ahicame">{{ appVersion }}</p>
            <f7-badge v-if="dev" color="red">Dev</f7-badge>
            <p class="libre-baskerville margin">
                Collaborative Informatics Environment for Learning on Health Outcomes
            </p>
            <!--  <f7-button fill @click="probar()">probar limit access emails</f7-button> -->
        </f7-block>
        <!--  <f7-block class="elfooter padding no-margin-bottom text-align-left">
            &copy;{{ $dayjs().format('YYYY') }} Washington University in St.
            Louis</f7-block
        > -->
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    export default {
        data() {
            return {};
        },
        computed: {
            ...mapState(["user", "appVersion", "activeTab", "dev", "projectInFocus", "forceProjectSelect"]),
            ...mapGetters(["muestromenuProjecto"]),
        },
        watch: {
            //aqui watcher a si navega o no
            muestromenuProjecto(newvalue) {
                if (newvalue) {
                    //console.log('hay proyecto, muestra menu');
                    this.$f7.views.left.router.navigate("/projectMenu/");
                } else {
                    //console.log('cerrando menu');
                    this.$f7.views.left.router.back();
                }
            },
        },
        methods: {
            probar() {
                this.$firebase.functions().httpsCallable("accessLimitNotice")();
            },
        },
    };
</script>
<style>
    .mevacnpng {
        width: 80%;
        max-width: 80%;
    }
    .fondoimagenmenu {
        background-image: url("../static/heros/loginback_1.jpg");
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center;
    }
    .list ul:before,
    .list ul:after {
        content: none !important;
    }
    .libre-baskerville {
        font-family: "Libre Baskerville";
    }
</style>
