<template>
    <f7-page name="cielo-configurations" @page:afterin="getAvailableEmailRecipients">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="CIELO configurations"></f7-nav-title>
        </f7-navbar>

        <f7-block-title>General</f7-block-title>
        <f7-list media-list>
            <f7-list-item
                title="Maintenance mode"
                footer="Only super admins will be able to access the app. If you turn this on, regular users will be automatically logged out and will not be able to log in unless you turn it off."
            >
                <f7-toggle
                    slot="after"
                    ref="isMaintenanceModeEnabled"
                    @toggle:change="handleMaintenanceModeChanged"
                    :checked="cieloConfigurations.isMaintenanceModeEnabled"
                ></f7-toggle>
            </f7-list-item>
        </f7-list>

        <f7-block-title>Email CIELO users</f7-block-title>
        <f7-list>
            <f7-list-input
                label="Subject"
                type="text"
                placeholder="Subject"
                :value="email.subject"
                @input="email.subject = $event.target.value"
            ></f7-list-input>
            <f7-list-item
                media-item
                title="Users recipients"
                footer="Click here to select the users to send the email to (leave empty this and projects recipients to send to all users)"
                :disabled="isLoadingRecipients"
                smart-select
                :smart-select-params="{
                    pageTitle: 'Select the users recipients',
                    virtualList: true,
                    searchbar: true,
                    searchbarPlaceholder: 'Search user for email or name',
                    setValueText: true,
                    valueEl: '.after-users-smart',
                    formatValueText: formatAfterTextUsers,
                }"
            >
                <select v-model="email.recipients" multiple name="recipients">
                    <option v-for="user in availableRecipients" :key="user.uid" :value="user.email">
                        {{ user.email }} {{ user.displayName ? `(${user.displayName})` : "" }}
                    </option>
                </select>
                <span class="after-users-smart" slot="after"></span>
            </f7-list-item>
            <f7-list-item
                media-item
                title="Projects recipients"
                footer="If you select a project as recipient, every member of that project will be added to the recipients list."
                smart-select
                :smart-select-params="{
                    pageTitle: 'Select the projects (all selected project members will be added as recipients)',
                    virtualList: true,
                    searchbar: true,
                    searchbarPlaceholder: 'Search for project name',
                    setValueText: true,
                    valueEl: '.after-projects-smart',
                    formatValueText: formatAfterTextProjects,
                }"
            >
                <select v-model="email.projectsRecipients" multiple name="projects-recipients">
                    <option v-for="project in $store.getters.projects" :key="project.id" :value="project.id">
                        {{ project.name }}
                    </option>
                </select>
                <span class="after-projects-smart" slot="after"></span>
            </f7-list-item>
            <f7-list-input
                label="Body"
                type="texteditor"
                placeholder="Body"
                :value="email.body"
                @texteditor:change="(value) => (email.body = value)"
            ></f7-list-input>
        </f7-list>

        <f7-button @click="sendEmail" fill class="margin">Send email</f7-button>
    </f7-page>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name: "cielo-configurations",

        data() {
            return {
                email: {
                    subject: "CIELO: Type your subject here",
                    body: "Dear Cielo user: Type your message here",
                    recipients: [],
                    projectsRecipients: [],
                },

                availableRecipients: [],
                isLoadingRecipients: false,
            };
        },

        computed: {
            ...mapState(["cieloConfigurations"]),
        },

        methods: {
            async handleMaintenanceModeChanged(value) {
                if (value == this.cieloConfigurations.isMaintenanceModeEnabled) return;
                try {
                    this.$f7.dialog.confirm(
                        "Are you sure you want to change the maintenance mode?",
                        async () => {
                            await this.$store.dispatch("setMaintenanceMode", value);

                            this.$f7.toast.show({
                                text: "Maintenance mode was successfully updated",
                                closeTimeout: 2000,
                                verticalPosition: "bottom",
                                horizontalPosition: "center",
                            });
                        },
                        () => {
                            this.$refs.isMaintenanceModeEnabled.f7Toggle.checked = !value;
                        },
                    );
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.alert(error.message, "Error while updating maintenance mode.");
                }
            },

            async sendEmail() {
                try {
                    if (!this.email.subject.trim() || !this.email.body.trim()) {
                        return this.$f7.dialog.alert("Please fill in the subject and the body of the email.");
                    }

                    this.$f7.dialog.confirm(
                        "Are you sure you want to send this email to CIELO users?",
                        async () => {
                            this.$f7.dialog.preloader("Sending email to CIELO users...");

                            const response = await this.$store.dispatch("sendEmailToUsers", this.email);

                            this.$f7.dialog.close();

                            if (response.data.error) {
                                return this.$f7.dialog.alert(response.data.error.message, "Error while sending email.");
                            }

                            this.$f7.toast.show({
                                text: `A total of ${response.data.totalEmailsSent} emails were sent successfully.`,
                                closeTimeout: 5000,
                                verticalPosition: "bottom",
                                horizontalPosition: "center",
                            });
                        },
                        () => {},
                    );
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error while sending email.");
                }
            },

            async getAvailableEmailRecipients() {
                try {
                    if (this.availableRecipients.length > 0) {
                        return;
                    }

                    this.isLoadingRecipients = true;

                    const response = await this.$store.dispatch("getAvailableEmailRecipients");

                    if (response.data.error) {
                        this.$f7.dialog.close();
                        return this.$f7.dialog.alert(
                            response.data.error.message,
                            "Error while loading available email recipients.",
                        );
                    }

                    this.availableRecipients = response.data.availableRecipients;

                    this.isLoadingRecipients = false;
                } catch (error) {
                    this.isLoadingRecipients = false;
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error while loading available email recipients.");
                }
            },

            formatAfterTextUsers(values) {
                if (values.length === 0) {
                    return "All CIELO users";
                } else if (values.length === 1) {
                    return "1 user selected";
                } else {
                    return `${values.length} users selected`;
                }
            },
            formatAfterTextProjects(values) {
                if (values.length === 0) {
                    return "";
                } else if (values.length === 1) {
                    return "1 project selected";
                } else {
                    return `${values.length} projects selected`;
                }
            },
        },
    };
</script>
