<template>
    <f7-page @page:beforein="loadaccounts(false)" name="core_cuentas">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title
                :subtitle="`Manage all accounts in ${cual == 'project' ? projectInFocusInfo.name : 'CIELO'}`"
                title="Accounts"
            ></f7-nav-title>
            <f7-nav-right>
                <f7-block>
                    <f7-link tooltip="Refresh accounts" @click="loadaccounts(true)">
                        <font-awesome-icon :icon="['fad', 'rotate']"></font-awesome-icon>
                    </f7-link>
                </f7-block>
            </f7-nav-right>
            <f7-subnavbar>
                <f7-searchbar
                    class="searchbar-project-accounts"
                    search-container=".search-list"
                    search-in=".item-title, .item-header, .item-subtitle"
                    placeholder="Search Account"
                ></f7-searchbar>
            </f7-subnavbar>
        </f7-navbar>
        <f7-block v-if="!cargando" class="no-margin-bottom">
            <f7-row>
                <f7-col width="70">
                    <f7-button @click="popupCrearCuentaOpened = true" tab-link-active>Create new account</f7-button>
                </f7-col>
                <f7-col width="30">
                    <f7-button
                        outline
                        @click="exportAccountsReport({ projectid: cual == 'project' ? projectInFocus : null })"
                    >
                        Export Report
                        <font-awesome-icon :icon="['fad', 'download']"></font-awesome-icon>
                    </f7-button>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block v-if="cargando" class="text-align-center">
            <f7-preloader></f7-preloader>
            <br />
            Loading accounts...
        </f7-block>

        <f7-list class="searchbar-not-found no-margin-top">
            <f7-list-item title="Nothing found"></f7-list-item>
        </f7-list>

        <f7-list
            v-show="!cargando && (allusers.length || allrequests.length)"
            media-list
            class="search-list searchbar-found misuserisc no-margin-top"
        >
            <template v-if="cual == 'project'">
                <f7-list-item divider title="Pending access requests to this project"></f7-list-item>
                <f7-list-item v-if="!allrequests.length" title="No pending access requests"></f7-list-item>
                <f7-list-item
                    link="#"
                    v-for="user in allrequests"
                    :key="user.uid"
                    :title="user.displayName"
                    @click="gotoCuenta(user)"
                    :header="user.email"
                    :footer="`Requested at ${$dayjs.unix(user.request.wh).format('HH:mm [on] MM/DD/YY')}`"
                >
                    <img :src="user.photoURL || '../static/heros/avatar.jpg'" slot="media" />
                    <f7-link tooltip="email verified" slot="header">
                        <font-awesome-icon
                            :icon="['fad', 'user-check']"
                            class="margin-left-half"
                            color="teal"
                            v-if="user.emailVerified"
                        ></font-awesome-icon>
                    </f7-link>
                </f7-list-item>
            </template>
            <f7-list-item divider title="Active users with privileges on this project"></f7-list-item>
            <f7-list-item
                link="#"
                v-for="user in allusers"
                :key="user.uid"
                :title="user.displayName"
                @click="gotoCuenta(user)"
                :subtitle="user.email"
                :after="user.lastSignInTime ? `Last sign in ${$dayjs(user.lastSignInTime).format('MM/DD/YYYY')}` : ''"
            >
                <img :src="user.photoURL || '../static/heros/avatar.jpg'" slot="media" />
                <f7-link tooltip="email verified" slot="subtitle">
                    <font-awesome-icon
                        :icon="['fad', 'user-check']"
                        class="margin-left-half"
                        color="teal"
                        v-if="user.emailVerified"
                    ></font-awesome-icon>
                </f7-link>
            </f7-list-item>
        </f7-list>

        <f7-popup
            class="crear-cuenta"
            :close-by-backdrop-click="false"
            :opened="popupCrearCuentaOpened"
            @popup:closed="popupCrearCuentaOpened = false"
        >
            <crea-cuenta
                v-if="popupCrearCuentaOpened"
                @abortCreacion="popupCrearCuentaOpened = false"
                @gotoCuenta="goToCuentaInner"
            ></crea-cuenta>
        </f7-popup>
    </f7-page>
</template>
<script>
    import { commonmixin } from "../mixins/common";
    import creaCuenta from "../components/cuenta_crea_popup.vue";
    import { mapState, mapGetters } from "vuex";

    export default {
        mixins: [commonmixin],
        components: {
            creaCuenta,
        },
        props: ["cual"],
        data() {
            return {
                cargando: true,
                popupCrearCuentaOpened: false,
                allusers: [],
                allrequests: [],
            };
        },
        computed: {
            ...mapState(["projectInFocus", "forceUsersReload"]),
            ...mapGetters(["projectInFocusInfo"]),
        },
        // watch:{
        //     projectInFocus(neoprojectid){
        //       //  console.log('ATENTO, CAMBIO DE CENTRO',neoprojectid);
        //        this.loadaccounts(true);
        //     }
        // },
        methods: {
            async loadaccounts(force) {
                try {
                    // console.log('Fuente',this.cual)
                    if (!this.allusers.length || force || this.forceUsersReload) {
                        this.cargando = true;
                        let projecto = null;
                        if (this.cual == "project") {
                            projecto = this.projectInFocus;
                        }
                        let datous = await this.commonExecute({ projectid: projecto }, "v2_getmyusers", false);
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        this.allusers = datous.payload;
                        // console.log('allusers',this.allusers);
                        this.allrequests = datous.requests || [];
                        // console.log('requests', this.allrequests);
                        this.$store.commit("setWhatTo", {
                            what: "forceUsersReload",
                            to: false,
                        });
                    }
                    this.cargando = false;
                } catch (error) {
                    this.cargando = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },

            goToCuentaInner(cuenta) {
                this.popupCrearCuentaOpened = false;
                this.gotoCuenta(cuenta);
            },
            gotoCuenta(cuenta) {
                let cualtoget = ["profiles"];
                if (this.cual !== "project") {
                    cualtoget.push("projects");
                }
                return this.getMeThese(cualtoget)
                    .then(() => {
                        // console.log(cuenta);
                        this.$f7.views.main.router.navigate(`/cuenta/${this.cual}`, {
                            props: {
                                userInfo: cuenta,
                            },
                        });
                    })
                    .catch((error) => {
                        this.$f7.dialog.close();
                        console.error(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            },
        },
    };
</script>
<style>
    .misuserisc img {
        width: 45px;
        border-radius: 50%;
    }
</style>
