<template>
    <f7-page>
        <f7-navbar :back-link="Object.values(savedQueries).length ? 'Back' : null">
            <f7-nav-left v-if="!Object.values(savedQueries).length">
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>
                {{
                    savedQueries[selectedQueryId] && selectedQueryId ? savedQueries[selectedQueryId].name : "New query"
                }}
            </f7-nav-title>
            <f7-nav-right>
                <f7-button v-if="!selectedQueryId && isQueryValid" @click="saveQuery" outline class="margin-right-half">
                    <font-awesome-icon :icon="['fad', 'floppy-disk']"></font-awesome-icon>
                    Save query
                </f7-button>
                <template v-if="selectedQueryId && isQueryValid">
                    <f7-button
                        :disabled="isSameQuery"
                        popover-open=".saveQueryPopover"
                        outline
                        class="margin-right-half"
                    >
                        <font-awesome-icon :icon="['fad', 'floppy-disk']"></font-awesome-icon>
                        Save query
                    </f7-button>
                    <f7-block>
                        <f7-link tooltip="Delete Query" color="red" @click="deleteQuery()">
                            <font-awesome-icon :icon="['fad', 'trash-can']"></font-awesome-icon>
                        </f7-link>
                    </f7-block>
                </template>
            </f7-nav-right>
        </f7-navbar>
        <f7-popover class="saveQueryPopover">
            <f7-button popover-close=".saveQueryPopover" @click="updateQuery" outline class="margin">
                <font-awesome-icon :icon="['fad', 'circle-arrow-up']"></font-awesome-icon>
                Update this Query
            </f7-button>
            <f7-button popover-close=".saveQueryPopover" @click="saveQuery" outline class="margin">
                <font-awesome-icon :icon="['fad', 'floppy-disk']"></font-awesome-icon>
                Save as new
            </f7-button>
        </f7-popover>
        <f7-block>
            <queryx :queryniv="leQuery" :rutar="String(leQuery.n)" @clearQueryId="clearQueryId()"></queryx>
        </f7-block>

        <f7-popup class="demo-popup" @popup:opened="imripim()" :opened="cond_abierto" @popup:closed="cerrarex()">
            <f7-view>
                <f7-page>
                    <f7-navbar title="Add Condition">
                        <f7-nav-right>
                            <f7-link popup-close>Close</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-block>
                        <f7-segmented v-if="cond_mkgroup" class="relationButtons" raised tag="p">
                            <f7-button
                                :class="{ bg_AND: cond_t == 'AND' }"
                                large
                                @click="setTipo('AND')"
                                :active="cond_t == 'AND'"
                            >
                                AND
                            </f7-button>
                            <f7-button
                                :class="{ bg_OR: cond_t == 'OR' }"
                                large
                                @click="setTipo('OR')"
                                :active="cond_t == 'OR'"
                            >
                                OR
                            </f7-button>
                        </f7-segmented>
                        <p :class="`bg_${cond_t} padding-horizontal-half`" v-else-if="cond_t">
                            {{ cond_t }}
                        </p>

                        <f7-list v-if="cond_abierto">
                            <f7-list-input
                                label="Field"
                                type="select"
                                outline
                                :value="cond_iddatatipe"
                                placeholder="Please choose..."
                                @change="changeField($event.target.value)"
                            >
                                <option value="">Please select</option>
                                <option
                                    v-for="field in fields"
                                    :key="field.id"
                                    :value="field.id"
                                    :disabled="field.divider"
                                    :class="{ optionsDivider: field.divider }"
                                >
                                    {{ field.encabezado }}
                                </option>
                            </f7-list-input>
                            <f7-list-input
                                :disabled="!cond_iddatatipe"
                                label="Condition"
                                type="select"
                                outline
                                placeholder="Please choose..."
                                :value="cond_tipoequalidad"
                                @change="setCondition($event.target.value)"
                            >
                                <option :value="null">Select...</option>
                                <option
                                    v-for="condition in conditions(cond_iddatatipe)"
                                    :key="condition.id"
                                    :value="condition.id"
                                >
                                    {{ condition.tx }}
                                </option>
                            </f7-list-input>
                            <template v-if="cond_iddatatipe && cond_tipoequalidad">
                                <f7-list-input
                                    v-if="getTipo(cond_iddatatipe) == 'input_texto'"
                                    outline
                                    :disabled="!cond_tipoequalidad"
                                    type="text"
                                    label="Criteria"
                                    :value="cond_valor_a_evaluar[0]"
                                    @input="setCriteria($event.target.value)"
                                />
                                <template
                                    v-else-if="
                                        cond_tipoequalidad == 'between' && getTipo(cond_iddatatipe) == 'input_numero'
                                    "
                                >
                                    <f7-list-input
                                        class="doubleNumber"
                                        outline
                                        :disabled="!cond_tipoequalidad"
                                        type="number"
                                        label="Bottom"
                                        :value="cond_valor_a_evaluar[0]"
                                        @input="setCriteria($event.target.value, 'bottom')"
                                    />
                                    <f7-list-input
                                        class="doubleNumber"
                                        outline
                                        :disabled="!cond_tipoequalidad"
                                        type="number"
                                        label="Top"
                                        :value="cond_valor_a_evaluar[1]"
                                        @input="setCriteria($event.target.value, 'top')"
                                    />
                                </template>
                                <f7-list-input
                                    v-else-if="getTipo(cond_iddatatipe) == 'input_numero'"
                                    :disabled="!cond_tipoequalidad"
                                    type="number"
                                    outline
                                    label="Criteria"
                                    :value="cond_valor_a_evaluar[0]"
                                    @input="setCriteria($event.target.value)"
                                />
                                <f7-list-input
                                    v-else-if="getTipo(cond_iddatatipe) == 'input_fecha'"
                                    outline
                                    :disabled="!cond_tipoequalidad"
                                    label="Criteria"
                                    type="datepicker"
                                    :calendar-params="{
                                        dateFormat: 'mm/dd/yyyy',
                                        locale: 'en-US',
                                        closeOnSelect: true,
                                        rangePicker: cond_tipoequalidad == 'between',
                                    }"
                                    @calendar:change="setCriteria($event, 'date')"
                                />

                                <f7-list-item
                                    v-else-if="cond_tipoequalidad == 'equal' && getTipo(cond_iddatatipe)"
                                    :disabled="!cond_tipoequalidad"
                                    class="display-inline-flex width100"
                                    smart-select
                                    :smart-select-params="{
                                        openIn: 'popup',
                                        virtualList: true,
                                        searchbar: true,
                                        closeOnSelect: true,
                                        popupCloseLinkText: 'Close',
                                    }"
                                    title="Criteria Select"
                                    :ref="'equal'"
                                >
                                    <select @change="setSmartCriteria('equal')" :value="''">
                                        <option v-show="false" :value="null">Select an option</option>
                                        <option
                                            v-for="alt in alternativas(cond_iddatatipe)"
                                            :key="alt.id"
                                            :selected="cond_valor_a_evaluar.includes(alt.id)"
                                            :value="alt.id"
                                        >
                                            {{ alt.tx || alt.name }}
                                        </option>
                                    </select>
                                </f7-list-item>
                                <f7-list-item
                                    v-else-if="
                                        (cond_tipoequalidad == 'includes' ||
                                            cond_tipoequalidad == 'excludes' ||
                                            cond_tipoequalidad == 'exactly') &&
                                        getTipo(cond_iddatatipe)
                                    "
                                    :disabled="!cond_tipoequalidad"
                                    class="display-inline-flex width100"
                                    smart-select
                                    :smart-select-params="{
                                        virtualList: true,
                                        searchbar: true,
                                        openIn: 'popup',
                                        popupCloseLinkText: 'Done',
                                        cssClass: 'doneButton',
                                    }"
                                    title="Criteria Select"
                                    :ref="'includes'"
                                >
                                    <select multiple @change="setSmartCriteria('includes')">
                                        <option
                                            v-for="alt in alternativas(cond_iddatatipe)"
                                            :key="alt.id"
                                            :selected="cond_valor_a_evaluar.includes(alt.id)"
                                            :value="alt.id"
                                        >
                                            {{ alt.tx || alt.name || alt.encabezado }}
                                        </option>
                                    </select>
                                </f7-list-item>
                                <f7-list-input
                                    v-else-if="cond_iddatatipe == 'ids'"
                                    label="Criteria"
                                    type="textarea"
                                    outline
                                    info="Write the IDs separated by comma(,)"
                                    @input="cond_valor_a_evaluar = $event.target.value"
                                ></f7-list-input>
                            </template>
                        </f7-list>
                        <f7-button :disabled="cond_mkgroup && !cond_t" raised fill large @click="generateCondition()">
                            Add
                        </f7-button>
                    </f7-block>
                </f7-page>
            </f7-view>
        </f7-popup>
        <f7-block>
            <f7-row>
                <f7-col width="20" class="align-self-center">
                    <f7-button
                        :disabled="!leQuery.c.length && !leQuery.r"
                        class="margin-auto width-fit-content"
                        @click="clearSearch"
                    >
                        <font-awesome-icon :icon="['fad', 'eraser']" />
                        Clear search
                    </f7-button>
                </f7-col>
                <f7-col width="80">
                    <f7-button
                        :disabled="!leQuery.c.length && !leQuery.r"
                        @click="fetchResults"
                        class="margin-right"
                        fill
                        large
                    >
                        <font-awesome-icon :icon="['fad', 'magnifying-glass']" />
                        Fetch results
                    </f7-button>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import queryx from "../components/queryx.vue";

    export default {
        components: {
            queryx,
        },
        data() {
            return {
                cond_iddatatipe: "", // field
                cond_tipoequalidad: "", //condition
                cond_valor_a_evaluar: [],
                dateConditions: [
                    { id: "equal", tx: "Exact date" },
                    { id: "before", tx: "Before" },
                    { id: "after", tx: "After" },
                    { id: "between", tx: "Between" },
                ],
                stringConditions: [
                    { id: "equal", tx: "Equal to" },
                    { id: "includes", tx: "Includes" },
                ],
                alternativesConditions: [
                    { id: "equal", tx: "Equal to" },
                    { id: "includes", tx: "Includes any" },
                    { id: "exactly", tx: "Includes exactly" },
                ],
                institutionConditions: [
                    { id: "equal", tx: "Equal to" },
                    { id: "includes", tx: "Includes any" },
                ],
                numberConditions: [
                    { id: "equal", tx: "Equal to" },
                    { id: "lower", tx: "Lower than" },
                    { id: "higher", tx: "Higher than" },
                    { id: "between", tx: "Between" },
                ],
                filesConditions: [
                    { id: "includes", tx: "Includes" },
                    { id: "excludes", tx: "Excludes" },
                ],
                tagsConditions: [
                    { id: "includes", tx: "Includes any" },
                    { id: "exactly", tx: "Includes exactly" },
                    { id: "excludes", tx: "Excludes" },
                ],
                idsConditions: [
                    { id: "includes", tx: "Includes" },
                    { id: "excludes", tx: "Excludes" },
                ],
            };
        },
        computed: {
            ...mapState([
                "cond_abierto",
                "cond_rutar",
                "cond_t",
                "cond_mkgroup",
                "cond_borrar",
                "leQuery",
                "user",
                "savedQueries",
                "selectedQueryId",
                "searchableEntityData",
                "searchableEntityFiles",
                "canFilterByInstitution",
            ]),
            ...mapGetters(["projectInFocusInfo"]),
            tags() {
                let allTags = this.projectInFocusInfo?.fileTags || {};
                return Object.values(allTags).filter((tag) => {
                    return !tag.inct;
                });
            },
            fields() {
                let entityData = {};
                let array = this.searchableEntityData.sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1;
                    } else if (a.orden < b.orden) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                array.forEach((entity) => {
                    if (
                        (entity.depde && this.projectInFocusInfo.entityData[entity.depde.pregid].disabled) ||
                        entity.disabled
                    ) {
                        return;
                    } else entityData[entity.id] = entity;
                });
                let toReturn = {
                    firstGroupDividerTitle: {
                        divider: true,
                        id: "ID/Created at/Institution",
                        encabezado: "ID/Created at/Institution",
                    },
                    id: { id: "ids", encabezado: "IDs" },
                    createdAt: { id: "createdAt", encabezado: "Created At" },
                };
                if (this.canFilterByInstitution) {
                    toReturn.institutions = { id: "institutions", encabezado: "Institutions" };
                }
                if (this.searchableEntityFiles?.length) {
                    (toReturn.secondGroupDividerTitle = {
                        divider: true,
                        id: "Project files/ File tags",
                        encabezado: "Project files/ File tags",
                    }),
                        (toReturn.files = { id: "files", encabezado: "Project Files" });
                    if (this.tags.length) toReturn.tags = { id: "tags", encabezado: "File Tags" };
                }
                if (Object.keys(entityData).length > 0) {
                    (toReturn.thirdGroup = {
                        divider: true,
                        id: "All Data entity fields",
                        encabezado: "All Data entity fields",
                    }),
                        (toReturn = Object.assign({}, toReturn, entityData));
                }
                return toReturn;
            },

            isQueryValid() {
                if (this.leQuery.n !== 0) return true;
                if (this.leQuery.c.length > 0) return true;
                if (this.leQuery.t.length > 0) return true;
                if (this.leQuery.r) return true;
                return false;
            },
            isSameQuery() {
                let cleanQuery = Object.values(this.leQuery || {}).filter((item) => {
                    if ((item && !Array.isArray(item)) || (Array.isArray(item) && item.length)) return item;
                });
                let cleanSelectedQuery = Object.values(this.savedQueries?.[this.selectedQueryId]?.query || {}).filter(
                    (item) => {
                        if ((item && !Array.isArray(item)) || (Array.isArray(item) && item.length)) return item;
                    },
                );
                return JSON.stringify(cleanQuery) == JSON.stringify(cleanSelectedQuery);
            },
            InstitutionsICanCreateFrom() {
                if (this.myPrivsHere.admin && this.myPrivsHere.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere?.prv) {
                    let cuales = Object.values(this.myPrivsHere.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.create || potinsti.det.update) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            InstitutionsICanEditFrom() {
                if (this.myPrivsHere.admin && this.myPrivsHere.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere?.prv) {
                    let cuales = Object.values(this.myPrivsHere.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.update || potinsti.det.create) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            myPrivsHere() {
                let allprivs = this.user.privsByProject || {};
                return allprivs[this.$store.state.projectInFocus] || {};
            },
        },
        watch: {
            cond_borrar(neovalor) {
                if (neovalor) {
                    this.borraEste(neovalor);
                }
            },
        },
        methods: {
            clearQueryId() {
                this.$store.commit("setWhatTo", {
                    what: "selectedQueryId",
                    to: null,
                });
            },
            deleteQuery() {
                this.$f7.dialog.confirm("Are you sure you want to delete this query?", "Delete query", async () => {
                    try {
                        this.$f7.dialog.preloader("Deleting query...");

                        const deleteQuery = this.$firebase.functions().httpsCallable("v2_deleteQuery");
                        await deleteQuery({
                            projectId: this.projectInFocusInfo.id,
                            queryId: this.selectedQueryId,
                            dev: this.$store.state.dev,
                        });

                        let local = Object.assign({}, this.savedQueries);
                        delete local[this.selectedQueryId];
                        this.$store.commit("setObjTo", {
                            what: "savedQueries",
                            to: local,
                        });
                        this.clearSearch();
                        this.$f7.views.main.router.back();
                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message);
                    }
                });
            },
            saveQuery() {
                this.$f7.dialog.prompt("Set a name for this query", "Query name", async (name) => {
                    try {
                        const trimmedName = name.trim();

                        if (!trimmedName) {
                            this.$f7.dialog.alert("Please enter a valid name");
                            return;
                        }

                        this.$f7.dialog.preloader("Saving query...");

                        const saveQuery = this.$firebase.functions().httpsCallable("v2_saveQuery");
                        const response = await saveQuery({
                            projectId: this.projectInFocusInfo.id,
                            queryName: trimmedName,
                            queryData: this.leQuery,
                            dev: this.$store.state.dev,
                        });

                        if (response.data.error) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(response.data.error.message);
                            return;
                        }

                        const savedQuery = response.data.data;
                        this.$set(this.savedQueries, savedQuery.id, savedQuery);
                        this.$store.commit("setWhatTo", {
                            what: "selectedQueryId",
                            to: savedQuery.id,
                        });

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message);
                    }
                });
            },
            async updateQuery() {
                if (!this.selectedQueryId) return this.$f7.dialog.alert("There is no query selected");
                try {
                    this.$f7.dialog.preloader("Updating query...");
                    const saveQuery = this.$firebase.functions().httpsCallable("v2_saveQuery");
                    const response = await saveQuery({
                        projectId: this.projectInFocusInfo.id,
                        queryName: this.savedQueries[this.selectedQueryId].name,
                        queryData: this.leQuery,
                        dev: this.$store.state.dev,
                        queryId: this.selectedQueryId,
                    });

                    if (response.data.error) {
                        throw new Error(response.data.error);
                    }

                    const savedQuery = response.data.data;
                    this.$set(this.savedQueries, savedQuery.id, savedQuery);
                    this.$store.commit("setWhatTo", {
                        what: "selectedQueryId",
                        to: savedQuery.id,
                    });

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },

            clearSearch() {
                let newQuery = {
                    n: 0,
                    c: [],
                    t: "",
                    r: null,
                };
                this.$store.commit("setObjTo", { what: "leQuery", to: newQuery });
                this.$store.commit("setWhatTo", {
                    what: "selectedQueryId",
                    to: null,
                });
            },
            borraEste(rutadel) {
                let clonquery = JSON.parse(JSON.stringify(this.leQuery));
                let arrayed = rutadel.split("_");
                let largop = arrayed.length;
                let ultimoseed = arrayed[largop - 1];
                let ultimoseedArray = ultimoseed.split("-");
                let cualidx = ultimoseedArray[1] || 0;
                if (largop == 1) {
                    clonquery.r = null;
                } else {
                    let objetivo;
                    let puntero = 2;
                    if (largop >= 2) {
                        objetivo = clonquery;
                    }
                    while (largop > puntero) {
                        //largop-(puntero-1)

                        let rutaero = arrayed[puntero - 1].split("-")[1];
                        let cual = rutaero || 0;
                        objetivo = objetivo.c[cual];
                        puntero++;
                    }
                    if (objetivo.c.length > 1) {
                        objetivo.c.splice(cualidx, 1);
                    }
                    if (objetivo.c.length == 1) {
                        if (objetivo.c[0].r) {
                            objetivo = Object.assign(objetivo, {
                                t: "",
                                r: objetivo.c[0].r,
                                c: [],
                            });
                        } else if (objetivo.c[0].c && objetivo.c[0].c.length) {
                            objetivo = Object.assign(objetivo, {
                                t: objetivo.c[0].t,
                                r: null,
                                n: objetivo.c[0].n,
                                c: objetivo.c[0].c,
                            });
                        }
                    }
                }
                this.$store.commit("setObjTo", { what: "leQuery", to: clonquery });
                this.$store.commit("setWhatTo", { what: "cond_borrar", to: null });
                this.$f7.views.main.router.navigate(this.$f7.views.main.router.currentRoute.url, {
                    ignoreCache: true,
                    reloadCurrent: true,
                });
            },
            imripim() {
                console.log("cond_rutar", this.cond_rutar);
            },
            setTipo(cond_t) {
                this.$store.commit("setWhatTo", { what: "cond_t", to: cond_t });
            },
            cerrarex() {
                this.cond_iddatatipe = "";
                this.cond_tipoequalidad = "";
                this.cond_valor_a_evaluar = [];
                this.$store.commit("setWhatTo", {
                    what: "cond_abierto",
                    to: false,
                });
            },
            generateCondition() {
                if (!this.cond_valor_a_evaluar.length) return this.$f7.dialog.alert("Criteria missing");
                if (this.cond_iddatatipe == "ids") {
                    let array = this.cond_valor_a_evaluar.toLowerCase().split(",");
                    this.cond_valor_a_evaluar = array.map((id) => {
                        return id.trim();
                    });
                }
                let clonquery = JSON.parse(JSON.stringify(this.leQuery));
                let arrayed = this.cond_rutar.split("_");
                let largop = arrayed.length;
                let currentRAtThisLevel;
                let ultimoseed = this.cond_rutar.split("_")[largop - 1];
                let ultimoseedArray = ultimoseed.split("-");
                let cualidx = ultimoseedArray[1] || 0;
                let cualN = parseInt(ultimoseedArray[0]);
                let laregalx = {
                    iddatatipe: this.cond_iddatatipe, // field
                    tipoequalidad: this.cond_tipoequalidad, // field
                    valor_a_evaluar: this.cond_valor_a_evaluar, // field
                };

                let nuevoN = cualN + 1;
                let potnuevogrx = {
                    n: cualN,
                    c: [
                        {
                            n: nuevoN,
                            c: [],
                            t: "",
                            r: null,
                        },
                        {
                            n: nuevoN,
                            c: [],
                            t: "",
                            r: laregalx,
                        },
                    ],
                    t: this.cond_t,
                    r: null,
                };
                if (largop == 1) {
                    currentRAtThisLevel = clonquery.r;
                    if (this.cond_mkgroup) {
                        potnuevogrx.c[0].r = currentRAtThisLevel;
                        clonquery = Object.assign({}, potnuevogrx);
                    } else if (this.cond_t) {
                        clonquery.c.push({
                            n: nuevoN,
                            c: [],
                            t: "",
                            r: laregalx,
                        });
                    } else if (!currentRAtThisLevel) {
                        clonquery.r = Object.assign({}, laregalx);
                    }
                }
                if (largop == 2) {
                    currentRAtThisLevel = clonquery.c[cualidx].r;
                    if (this.cond_mkgroup) {
                        potnuevogrx.c[0].r = currentRAtThisLevel;
                        clonquery.c[cualidx] = Object.assign({}, potnuevogrx);
                    } else if (this.cond_t) {
                        clonquery.c[cualidx].c.push({
                            n: nuevoN,
                            c: [],
                            t: "",
                            r: laregalx,
                        });
                    } else if (!currentRAtThisLevel) {
                        clonquery.c[cualidx].r = Object.assign({}, laregalx);
                    }
                } else if (largop == 3) {
                    let penultimoseed = this.cond_rutar.split("_")[largop - 2];
                    let pencualidx = penultimoseed.split("-")[1] || 0;

                    currentRAtThisLevel = clonquery.c[pencualidx].c[cualidx].r;
                    if (this.cond_mkgroup) {
                        potnuevogrx.c[0].r = currentRAtThisLevel;
                        clonquery.c[pencualidx].c[cualidx] = Object.assign({}, potnuevogrx);
                    } else if (this.cond_t) {
                        clonquery.c[pencualidx].c[cualidx].c.push({
                            n: nuevoN,
                            c: [],
                            t: "",
                            r: laregalx,
                        });
                    } else if (!currentRAtThisLevel) {
                        clonquery.c[pencualidx].c[cualidx].r = Object.assign({}, laregalx);
                    }
                } else if (largop == 4) {
                    let penultimoseed = this.cond_rutar.split("_")[largop - 2];
                    let pencualidx = penultimoseed.split("-")[1] || 0;
                    let antepenultimoseed = this.cond_rutar.split("_")[largop - 3];
                    let antepenpencualidx = antepenultimoseed.split("-")[1] || 0;

                    currentRAtThisLevel = clonquery.c[antepenpencualidx].c[pencualidx].c[cualidx].r;
                    if (this.cond_mkgroup) {
                        potnuevogrx.c[0].r = currentRAtThisLevel;
                        clonquery.c[antepenpencualidx].c[pencualidx].c[cualidx] = Object.assign({}, potnuevogrx);
                    } else if (this.cond_t) {
                        clonquery.c[antepenpencualidx].c[pencualidx].c[cualidx].c.push({
                            n: nuevoN,
                            c: [],
                            t: "",
                            r: laregalx,
                        });
                    } else if (!currentRAtThisLevel) {
                        clonquery.c[antepenpencualidx].c[pencualidx].c[cualidx].r = Object.assign({}, laregalx);
                    }
                }

                this.$store.commit("setObjTo", { what: "leQuery", to: clonquery });
                this.cerrarex();
            },
            alternativas(field) {
                if (field == "institutions") {
                    const hasPrivsInAllInstitutions =
                        this.myPrivsHere.admin &&
                        (this.myPrivsHere.admin["project-admin"] || this.myPrivsHere.admin.read);

                    const institutionsWhereIHavePrivileges = [];

                    if (hasPrivsInAllInstitutions) {
                        institutionsWhereIHavePrivileges.push(...Object.values(this.projectInFocusInfo.institutions));
                    } else {
                        const myInstitutionId = Object.keys(this.myPrivsHere?.prv || {})[0];
                        if (myInstitutionId) {
                            institutionsWhereIHavePrivileges.push(
                                this.projectInFocusInfo.institutions[myInstitutionId],
                            );
                        }
                    }

                    return institutionsWhereIHavePrivileges;
                }

                if (field == "files") {
                    if (this.searchableEntityFiles) {
                        return this.searchableEntityFiles;
                    }
                    return Object.values(this.projectInFocusInfo?.entityFiles).filter((file) => {
                        return !file.disabled;
                    });
                }
                if (field == "tags") {
                    return this.tags;
                }
                let alternativas = this.projectInFocusInfo?.entityData?.[field]?.alternativas;
                return Object.values(alternativas).filter((alt) => {
                    return !alt.inct;
                });
            },
            conditions(field) {
                let tipo = this.projectInFocusInfo?.entityData?.[field]?.tipo;
                if (field == "createdAt" || tipo == "input_fecha") return this.dateConditions;
                if (tipo == "input_texto") return this.stringConditions;
                if (tipo == "elige_una" || tipo == "elige_many") return this.alternativesConditions;
                if (field == "institutions") return this.institutionConditions;
                if (field == "files") return this.filesConditions;
                if (field == "tags") return this.tagsConditions;
                if (tipo == "input_numero") return this.numberConditions;
                if (field == "ids") return this.idsConditions;
            },
            getTipo(field) {
                if (this.projectInFocusInfo?.entityData?.[field]?.tipo)
                    return this.projectInFocusInfo.entityData[field].tipo;
                if (field == "createdAt") return "input_fecha";
                if (field == "institutions") return "elige_una";
                if (field == "files") return "elige_many";
                if (field == "tags") return "elige_many";
            },
            changeField(value) {
                this.cond_tipoequalidad = null;
                this.cond_valor_a_evaluar = [];
                this.cond_iddatatipe = value;
            },
            setCondition(value) {
                this.cond_valor_a_evaluar = [];
                if (
                    this.cond_iddatatipe == "institutions" ||
                    this.cond_iddatatipe == "tags" ||
                    this.projectInFocusInfo?.entityData?.[this.cond_iddatatipe]?.tipo == "elige_many" ||
                    this.projectInFocusInfo?.entityData?.[this.cond_iddatatipe]?.tipo == "elige_una"
                ) {
                    this.cond_tipoequalidad = null;
                    setTimeout(() => {
                        this.cond_tipoequalidad = value;
                    }, 5);
                } else this.cond_tipoequalidad = value;
            },
            setSmartCriteria(index) {
                let value = this.$refs[index].f7SmartSelect.getValue() || [];
                if (value.length && this.cond_tipoequalidad == "equal") return (this.cond_valor_a_evaluar[0] = value);
                else return (this.cond_valor_a_evaluar = value);
            },
            setCriteria(value, wich) {
                if (!this.cond_valor_a_evaluar) this.cond_valor_a_evaluar = [];
                if (wich == "bottom") return (this.cond_valor_a_evaluar[0] = value);
                if (wich == "top") return (this.cond_valor_a_evaluar[1] = value);
                if (wich == "date") {
                    if (this.cond_tipoequalidad == "between") {
                        this.cond_valor_a_evaluar[0] = this.$dayjs(value[0]).unix();
                        return (this.cond_valor_a_evaluar[1] = this.$dayjs(value[1]).unix());
                    } else return (this.cond_valor_a_evaluar[0] = this.$dayjs(value).unix());
                }
                this.cond_valor_a_evaluar[0] = value;
            },
            async fetchResults() {
                try {
                    this.$f7.dialog.preloader("Searching...");
                    let call = this.$firebase.functions().httpsCallable("v2_filterPatients");
                    let results = await call({
                        query: this.leQuery,
                        projectid: this.projectInFocusInfo.id,
                        dev: this.$store.state.dev,
                    });
                    if (results.data.error) throw new Error(results.data.error);
                    this.$store.commit("setWhatTo", {
                        what: "fetchedPatients",
                        to: results.data.selectedPatients,
                    });
                    this.$store.commit("setWhatTo", {
                        what: "allFetchedPatientsIds",
                        to: results.data.allSelectedPatientsIds,
                    });
                    this.$f7.views.main.router.navigate("/searchResults/");
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
        },
    };
</script>
<style>
    .buskqdx .timeline-item-content.nobordx {
        border: 0px !important;
    }
    .absolutamente {
        position: absolute;
        top: 40px;
        background: #cecece;
        border-radius: 5px;
        padding: 2px 3px;
        font-size: 12px;
        z-index: 9999999;
        color: #fff;
        font-weight: bolder;
    }
    .timeline.nomrg {
        margin: -5px !important;
    }
    .buskqdx .timeline-item-date {
        width: 13px !important;
    }
    .buskqdx .timeline-item-divider {
        margin: 3px !important;
    }
    .buskqdx .div_or.timeline-item-divider,
    .div_or.absolutamente {
        background: #2e7ff9 !important;
    }
    .bg_OR {
        background: #2e7ff9 !important;
        color: white;
        padding: 4px auto;
        width: 30px;
        border-radius: 8%;
    }
    .bg_AND {
        background: #029a00 !important;
        color: white;
        padding: 4px auto;
        width: 30px;
        border-radius: 8%;
    }

    .div_or.absolutamente {
        padding: 2px 6px !important;
    }

    .buskqdx .div_and.timeline-item-divider,
    .div_and.absolutamente {
        background: #029a00 !important;
    }

    .font_or {
        color: #2e7ff9 !important;
    }
    .font_and {
        color: #029a00 !important;
    }

    .list.nelist .item-inner {
        padding: 2px 5px 2px 0px !important;
        min-width: 180px;
    }
    .relationButtons {
        width: 100px;
        margin: auto;
    }
    .virtual-list {
        margin-top: 0 !important;
    }
    .width-fit-content {
        width: fit-content;
    }
    .selected-query {
        border-left: 5px solid #2e7ff9;
    }
    .width100,
    .width100 .item-content,
    .width100 .smart-select {
        width: 100% !important;
    }
    .optionsDivider {
        background-color: #f3f3f3;
    }
</style>
