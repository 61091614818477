<template>
    <f7-page @page:beforein="getTermsConds()">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Terms and conditions" :subtitle="`For: ${projectInFocusInfo.name}`"></f7-nav-title>
        </f7-navbar>

        <f7-block class="margin-top">
            <f7-block-title>My answers for terms and conditions for this project</f7-block-title>

            <div v-html="viewingProjectTerms" class="scrollable-text margin-bottom"></div>

            <f7-list v-if="viewingProjectTermsInputs" media-list>
                <f7-list-item
                    v-for="input in viewingProjectTermsInputs"
                    :key="input.id"
                    :header="input.preexistingType || input.question"
                    :title="answer(input)"
                ></f7-list-item>
            </f7-list>
        </f7-block>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        data() {
            return {
                infoabout: "Loading...",
                viewingProjectTerms: null,
                viewingProjectTermsInputs: null,
                answers: null,
            };
        },
        computed: {
            ...mapState(["user", "dev", "projectInFocus"]),
            ...mapGetters(["privxInfo", "projectInFocusInfo"]),
        },
        methods: {
            answer(input) {
                console.log(input, this.answers);
                if (input.preexistingType == "institution") {
                    return this.projectInFocusInfo?.institutions[this.answers?.[input.id]?.value]?.name;
                } else return this.answers?.[input.id]?.value;
            },
            async getTermsConds() {
                this.$f7.dialog.preloader("Loading terms and conditions for this project...");
                try {
                    let array = [
                        this.$firebase.database().ref(`terms/${this.projectInFocus}`).once("value"),
                        this.$firebase
                            .database()
                            .ref(`termsFieldsResponses/${this.projectInFocus}/${this.user.uid}`)
                            .once("value"),
                    ];
                    let multiSnap = await Promise.all(array);
                    const terms = multiSnap[0].val() || "Not set";
                    this.answers = multiSnap[1].val() || null;

                    this.viewingProjectTerms = terms.htmlText;
                    this.viewingProjectTermsInputs =
                        Object.values(terms.inputFields || {}).filter((term) => {
                            return !term.inact;
                        }) || null;
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(
                        "There was an error while loading terms and conditions for this project.",
                        error,
                    );
                    console.error(error);
                }
            },
        },
    };
</script>
<style></style>
