<template>
  <f7-page @page:beforein="getprojectAbout()" name="aboutproject">
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title subtitle="Project info" title="About"></f7-nav-title>
    </f7-navbar>


<f7-block v-html="infoabout"></f7-block>





  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
      return {
        infoabout:'Loading...'
      }
    },
    computed:{
     ...mapState(['user','dev','projectInFocus']),
     ...mapGetters(['privxInfo'])
    },
    methods:{
      async getprojectAbout(){
         let proabout = await this.$firebase.database().ref(`about/${this.projectInFocus}`).once('value');
         this.infoabout = proabout.val() || 'Not set';
      }

    }
}
</script>
<style>


</style>