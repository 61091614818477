<template>
    <Bar :chartOptions="chartOptions" :chartData="chartData" :height="height" />
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);
export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        chartOptions: {
            type: Object,
            default: () => {
                return {
                    responsive: true,
                    indexAxis: 'y',
                    elements: {
                        bar: {
                            borderWidth: 2,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            display: true,
                            color: 'white',
                            align: 'end',
                            offset: -7,
                            font: {
                                weight: 'bold',
                            },
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                precision: 0,
                            },
                        },
                        y: {
                            stacked: true,
                        },
                    },
                };
            },
        },
        height: {
            type: Number,
            default: 300,
        },
    },
};
</script>
