<template>
    <f7-popup :opened="opened" @popup:opened="getPublicDashboardSettings" @popup:closed="$emit('closed')">
        <f7-page>
            <f7-navbar title="Public dashboard settings">
                <f7-nav-right>
                    <f7-link popup-close>Close</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-row v-if="loading" class="margin text-align-center">
                <f7-col>
                    <f7-preloader></f7-preloader>
                </f7-col>
            </f7-row>
            <template v-else>
                <f7-block-title>
                    <b>General settings</b>
                </f7-block-title>
                <f7-list media-list>
                    <f7-list-item
                        link="#"
                        @click="editPublicDashboardAlias"
                        title="Public dashboard alias (optional)"
                        footer="You can create an alias in order to access public dashboard from a user-friendly URL"
                    >
                        <div slot="after" class="display-flex">
                            <span class="margin-right">{{ settings.alias || "Edit alias" }}</span>

                            <f7-link
                                v-if="fullURL !== 'Not set'"
                                @click.stop="copyURLToClipboard"
                                tooltip="Copy link to clipboard"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'clipboard']"
                                    class="fa-md margin-right-half"
                                ></font-awesome-icon>
                            </f7-link>
                        </div>
                    </f7-list-item>
                </f7-list>

                <!-- <p class="text-align-center text-color-gray no-margin-vertical" style="font-size: 30px;">&bull;</p> -->

                <f7-card outline class="margin-horizontal">
                    <f7-card-header>Custom cards</f7-card-header>
                    <f7-card-content>
                        <div v-for="(chart, index) in settings.customCharts" :key="index">
                            <f7-block-title class="display-flex justify-content-space-between margin-bottom-half">
                                Card #{{ index + 1 }}
                                <f7-link @click="deleteCustomChart(index)" class="text-color-red">
                                    <font-awesome-icon
                                        :icon="['fad', 'trash']"
                                        class="fa-md margin-right-half"
                                    ></font-awesome-icon>
                                    Delete
                                </f7-link>
                            </f7-block-title>
                            <f7-list media-list>
                                <f7-list-input
                                    type="text"
                                    label="Title"
                                    placeholder="Type here the card title"
                                    :value="chart.title"
                                    @input="(e) => (chart.title = e.target.value)"
                                ></f7-list-input>
                                <f7-list-input
                                    type="textarea"
                                    label="Text"
                                    placeholder="Type here the card text"
                                    :value="chart.text"
                                    @input="(e) => (chart.text = e.target.value)"
                                ></f7-list-input>
                                <f7-list-item
                                    checkbox
                                    title="Pinned at the top of the dashboard"
                                    :checked="chart.pinnedAtTop"
                                    @change="chart.pinnedAtTop = $event.target.checked"
                                ></f7-list-item>
                            </f7-list>
                        </div>
                        <span class="margin-horizontal text-color-gray" v-if="!settings.customCharts.length">
                            No custom cards added yet
                        </span>

                        <f7-button outline @click="addCustomChart" class="margin">
                            <font-awesome-icon :icon="['fad', 'plus']"></font-awesome-icon>
                            Add custom card
                        </f7-button>

                        <f7-button fill @click="save" class="margin">
                            <font-awesome-icon :icon="['fas', 'save']"></font-awesome-icon>
                            Save changes
                        </f7-button>
                    </f7-card-content>
                </f7-card>
            </template>
        </f7-page>
    </f7-popup>
</template>

<script>
    import { commonmixin } from "../mixins/common.js";
    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
        },

        mixins: [commonmixin],

        data() {
            return {
                settings: {
                    url: "Not set",
                    alias: "",
                    customCharts: [],
                },
                loading: true,
            };
        },

        computed: {
            fullURL() {
                if (this.settings.alias) {
                    if (this.$store.state.dev) {
                        return `https://dashboards-cielo.web.app/${this.settings.alias}`;
                    } else {
                        return `https://dashboard.cielo.wustl.edu/${this.settings.alias}`;
                    }
                }
                if (this.settings.url !== "Not set") {
                    if (this.$store.state.dev) {
                        return `https://dashboards-cielo.web.app/?dashboard=${this.settings.url}`;
                    } else {
                        return `https://dashboard.cielo.wustl.edu/?dashboard=${this.settings.url}`;
                    }
                }

                return "Not set";
            },
        },

        methods: {
            async save(onlyCustomCharts = false) {
                try {
                    this.$f7.dialog.preloader("Saving...");

                    const response = await this.commonExecute(
                        {
                            projectId: this.$store.state.projectInFocus || this.$store.state.adminprojectFocus,
                            settings: this.settings,
                        },
                        "v2_savePublicDashboardSettings",
                    );

                    console.log("Response", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    this.$set(this.settings, "customCharts", response.customCharts);

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                }
            },
            async getPublicDashboardSettings() {
                try {
                    this.loading = true;

                    const response = await this.commonExecute(
                        { projectId: this.$store.state.projectInFocus || this.$store.state.adminprojectFocus },
                        "v2_getProjectPublicDashboardSettings",
                    );

                    console.log("Response", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    if (response.url) {
                        this.$set(this.settings, "url", response.url);
                    }

                    if (response.alias) {
                        this.$set(this.settings, "alias", response.alias);
                    }

                    if (response.customCharts.length) {
                        this.$set(this.settings, "customCharts", response.customCharts);
                    }

                    this.loading = false;
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error");
                    this.loading = false;
                }
            },
            addCustomChart() {
                this.settings.customCharts.push({
                    title: "",
                    text: "",
                    pinnedAtTop: null,
                });
            },
            deleteCustomChart(index) {
                this.settings.customCharts.splice(index, 1);
            },
            copyURLToClipboard() {
                navigator.clipboard.writeText(this.fullURL);
                this.$f7.toast.show({
                    text: "Copied!",
                    closeTimeout: 1000,
                    position: "center",
                });
            },
            editPublicDashboardAlias() {
                this.$f7.dialog.prompt(
                    "Type here the alias for the Public Dashboard",
                    "Edit Public Dashboard alias",
                    (alias) => {
                        this.$set(this.settings, "alias", alias);
                        this.save();
                    },
                    () => {},
                    this.settings.alias,
                );
            },
        },
    };
</script>
