<template>
    <f7-popup :opened="opened" @popup:closed="$emit('closed')">
        <f7-navbar title="Maintenance login">
            <f7-nav-right>
                <f7-link icon-f7="xmark" @click="$emit('closed')"></f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-list media-list class="logininputs no-margin-top margin-bottom-half">
            <f7-list-input
                label="Email"
                :value="email"
                outline
                class="border-color-red"
                type="email"
                @input="email = $event.target.value">
                <font-awesome-icon :icon="['fad', 'at']" class="fa-2x topLeft5" slot="media" />
            </f7-list-input>
            <f7-list-input
                label="Password"
                :value="password"
                outline
                @keyup.native.enter="$emit('login', { email, password })"
                type="password"
                @input="password = $event.target.value">
                <font-awesome-icon :icon="['fad', 'key']" class="fa-2x" slot="media" />
            </f7-list-input>
        </f7-list>

        <f7-button @click="$emit('login', { email, password })" fill class="margin">Log in</f7-button>
    </f7-popup>
</template>

<script>
export default {
    props: {
        opened: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            email: '',
            password: '',
        };
    },
};
</script>
