<template>
    <div class="timeline nomrg">
        <div v-if="queryniv.r" class="timeline-item">
            <div class="timeline-item-content nobordx">
                <f7-list class="no-margin nelist" media-list>
                    <f7-list-item :subtitle="getConditionName" :title="getFieldName" :text="getCriteriaNames">
                        <f7-link @click="borraeste({ rutar })" class="margin-right-half" slot="after">
                            <font-awesome-icon color="red" :icon="['fad', 'circle-xmark']"></font-awesome-icon>
                        </f7-link>
                        <f7-button
                            v-if="queryniv.c.length || queryniv.n"
                            @click="
                                creaone({
                                    cond_rutar: rutar,
                                    cond_t: '',
                                    cond_mkgroup: true,
                                })
                            "
                            slot="media"
                        >
                            + group
                        </f7-button>
                    </f7-list-item>
                </f7-list>
                <f7-button
                    v-if="!queryniv.c.length && !queryniv.n"
                    @click="
                        creaone({
                            cond_rutar: rutar,
                            cond_t: '',
                            cond_mkgroup: true,
                        })
                    "
                    :class="`margin-top font_${queryniv.t.toLowerCase()}`"
                    raised
                >
                    Add condition
                </f7-button>
            </div>
        </div>
        <template v-else-if="queryniv.c.length">
            <div :class="`absolutamente absolutamente_${rutar} div_${queryniv.t.toLowerCase()}`">
                {{ queryniv.t }}
            </div>
            <div v-for="(unc, idx) in queryniv.c" :key="idx" class="timeline-item buskqdx">
                <div :class="{ ultimx: idx + 1 == queryniv.c.length }" class="timeline-item-date"></div>
                <div :class="`div_${queryniv.t.toLowerCase()} timeline-item-divider`"></div>
                <div class="timeline-item-content">
                    <queryx :queryniv="unc" :rutar="`${rutar}_${unc.n}-${idx}`"></queryx>
                    <f7-button
                        @click="
                            creaone({
                                cond_rutar: rutar,
                                cond_t: queryniv.t,
                                cond_mkgroup: false,
                            })
                        "
                        :class="`margin-top font_${queryniv.t.toLowerCase()}`"
                        raised
                        v-if="idx + 1 == queryniv.c.length"
                    >
                        Add condition
                    </f7-button>
                </div>
            </div>
        </template>
        <f7-button
            v-else
            :class="`margin-top`"
            @click="creaone({ cond_rutar: rutar, cond_t: '', cond_mkgroup: false })"
            large
            raised
        >
            Add first condition
        </f7-button>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import queryx from "../components/queryx.vue";

    export default {
        name: "queryx",
        components: {
            queryx,
        },
        props: {
            queryniv: Object,
            rutar: String,
        },
        data() {
            return {
                dateConditionsNames: {
                    equal: "Exact date",
                    before: "Before",
                    after: "after",
                    between: "Between",
                },
                stringConditionsNames: { equal: "Equal to", includes: "Includes" },
                alternativesConditionsNames: {
                    equal: "Equal to",
                    includes: "Includes any",
                    exactly: "Includes exactly",
                },
                institutionConditionsNames: {
                    equal: "Equal to",
                    includes: "Includes any",
                },
                filesConditionsNames: {
                    includes: "Includes",
                    excludes: "Excludes",
                },
                tagsConditionsNames: {
                    includes: "Includes any",
                    exactly: "Includes exactly",
                    excludes: "Excludes",
                },
                numberConditionsNames: {
                    equal: "Equal to",
                    lower: "Lower than",
                    higher: "Higher than",
                    between: "Between",
                },
                idsConditionsNames: {
                    includes: "Includes",
                    excludes: "Excludes",
                },
            };
        },
        mounted() {
            if (!this.queryniv.r) {
                this.calculaAlto();
            }
        },
        computed: {
            ...mapGetters(["projectInFocusInfo"]),
            getFieldName() {
                if (this.queryniv.r) {
                    if (this.queryniv.r.iddatatipe == "institutions") return "Institutions";
                    if (this.queryniv.r.iddatatipe == "createdAt") return "Created at";
                    if (this.queryniv.r.iddatatipe == "files") return "Files";
                    if (this.queryniv.r.iddatatipe == "tags") return "Tags";
                    if (this.queryniv.r.iddatatipe == "ids") return "IDs";
                    else return this.projectInFocusInfo?.entityData?.[this.queryniv?.r?.iddatatipe]?.encabezado;
                }
            },
            getConditionName() {
                let field = this.queryniv.r.iddatatipe;
                let condition = this.queryniv.r.tipoequalidad;
                let tipo = this.projectInFocusInfo?.entityData?.[field]?.tipo;
                if (field == "createdAt" || tipo == "input_fecha") return this.dateConditionsNames[condition];
                if (tipo == "input_texto") return this.stringConditionsNames[condition];
                if (tipo == "elige_una" || tipo == "elige_many") return this.alternativesConditionsNames[condition];
                if (field == "institutions") return this.institutionConditionsNames[condition];
                if (field == "files") return this.filesConditionsNames[condition];
                if (field == "tags") return this.tagsConditionsNames[condition];
                if (tipo == "input_numero") return this.numberConditionsNames[condition];
                if (field == "ids") return this.idsConditionsNames[condition];
            },
            getCriteriaNames() {
                if (this.queryniv.r) {
                    let array = [];
                    if (this.queryniv.r.iddatatipe == "institutions") {
                        array = this.queryniv.r.valor_a_evaluar.map((criteria) => {
                            return this.projectInFocusInfo?.institutions?.[criteria]?.name;
                        });
                    } else if (
                        this.queryniv.r.iddatatipe == "createdAt" ||
                        this.projectInFocusInfo.entityData[this.queryniv.r.iddatatipe]?.tipo == "input_fecha"
                    ) {
                        array = this.queryniv.r.valor_a_evaluar.map((criteria) => {
                            return this.$dayjs(criteria * 1000).format("MM/DD/YYYY");
                        });
                    } else if (this.queryniv.r.iddatatipe == "files") {
                        array = this.queryniv.r.valor_a_evaluar.map((criteria) => {
                            return this.projectInFocusInfo?.entityFiles?.[criteria]?.encabezado;
                        });
                    } else if (this.queryniv.r.iddatatipe == "tags") {
                        array = this.queryniv.r.valor_a_evaluar.map((criteria) => {
                            return this.projectInFocusInfo?.fileTags?.[criteria]?.tx;
                        });
                    } else if (this.projectInFocusInfo.entityData[this.queryniv.r.iddatatipe]?.alternativas) {
                        array = this.queryniv.r.valor_a_evaluar.map((criteria) => {
                            return this.projectInFocusInfo?.entityData?.[this.queryniv.r.iddatatipe].alternativas?.[
                                criteria
                            ]?.tx;
                        });
                    } else {
                        array = this.queryniv.r.valor_a_evaluar;
                    }
                    return array.join(", ");
                }
            },
        },
        methods: {
            borraeste({ rutar }) {
                this.$store.commit("setWhatTo", { what: "cond_borrar", to: rutar });
            },
            creaone({ cond_rutar, cond_t, cond_mkgroup }) {
                this.$store.commit("setWhatTo", {
                    what: "cond_rutar",
                    to: cond_rutar,
                });
                this.$store.commit("setWhatTo", { what: "cond_t", to: cond_t });
                this.$store.commit("setWhatTo", {
                    what: "cond_mkgroup",
                    to: cond_mkgroup,
                });
                this.$store.commit("setWhatTo", { what: "cond_abierto", to: true });
            },
            calculaAlto() {
                let altox = this.$$(`.absolutamente_${this.rutar}`).closest(".timeline").height();

                //altoultima
                //timeline-item
                let ultimop = this.$$(`.ultimx`).closest(".timeline-item").height();

                this.$$(`.absolutamente_${this.rutar}`).css("top", `${(altox - (ultimop + 24)) / 2}px`);
                //
            },
        },
    };
</script>
<style></style>
