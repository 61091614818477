<template>
    <div v-if="!dismissed" class="alert" :class="alertTypeClass">
        <div class="wrapper" :class="wrapperClass">
            <div class="display-flex flex-direction-row justify-content-space-between">
                <div class="display-flex flex-direction-row justify-content-flex-start align-items-center">
                    <f7-icon v-if="showIcon" :material="materialIcon" class="margin-right"></f7-icon>
                    <div>
                        <slot></slot>
                    </div>
                </div>
                <f7-button v-if="dismissible" @click="dismissed = true" small>
                    <f7-icon material="close" size="18"></f7-icon>
                </f7-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'alert',
    data: function () {
        return {
            dismissed: false,
        };
    },
    props: {
        type: {
            type: String,
            required: false,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'info', 'success', 'warning', 'danger'].indexOf(value) !== -1;
            },
        },
        icon: {
            type: String,
            required: false,
        },
        noIcon: {
            type: Boolean,
            required: false,
        },
        dismissible: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        alertTypeClass: function () {
            return `alert-${this.type}`;
        },
        wrapperClass: function () {
            return `wrapper-${this.type}`;
        },
        showIcon: function () {
            if (this.noIcon) {
                return false;
            }
            if (this.icon) {
                return true;
            }
            if (this.type !== 'primary') {
                return true;
            }
            return false;
        },
        materialIcon: function () {
            if (this.icon) return this.icon;
            switch (this.type) {
                case 'success':
                    return 'done';
                case 'warning':
                    return 'warning';
                case 'danger':
                    return 'error';
                case 'info':
                default:
                    return 'info';
            }
        },
    },
};
</script>

<style scoped>
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 6px;
}
.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffde7b;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert-title {
    font-size: 23rem;
    font-weight: bold;
}
.wrapper a {
    font-weight: 700;
}
.wrapper-primary a {
    color: #004085;
}
.wrapper-info a {
    color: #0c5460;
}
.wrapper-success a {
    color: #155724;
}
.wrapper-warning a {
    color: #856404;
}
.wrapper-danger a {
    color: #721c24;
}
</style>
