<template>
    <f7-page>
        <f7-navbar back-link="Back">
            <f7-nav-title>Results</f7-nav-title>
        </f7-navbar>
        <f7-block-title class="margin-half">{{ allFetchedPatientsIds.length }} entities returned</f7-block-title>
        <f7-block
            class="no-margin"
            v-if="
                allFetchedPatientsIds.length &&
                allFetchedPatientsIds.length < 101 &&
                (userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject)
            "
        >
            <f7-checkbox
                :disabled="!thereIsAtLeastOneDownloadableEntityBundle"
                :checked="selectedEntities.length == allFetchedPatientsIds.length"
                @change="selectAll($event.target.checked)"
                color="purple"
            />
            <span class="margin-left">Select all</span>
        </f7-block>
        <f7-block
            v-if="
                allFetchedPatientsIds.length &&
                (userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject)
            "
        >
            <f7-row>
                <f7-col
                    v-if="thereIsAtLeastOneDownloadableEntityBundle"
                    :width="allFetchedPatientsIds.length > 100 ? 50 : 100"
                >
                    <f7-button outline @click="decargameselected()">
                        <font-awesome-icon :icon="['fad', 'cloud-arrow-down']"></font-awesome-icon>
                        Download selected entities
                    </f7-button>
                </f7-col>
                <f7-col v-if="allFetchedPatientsIds.length > 100" width="50">
                    <f7-button @click="downloadAllResults()" fill>
                        Download all
                        {{ this.allFetchedPatientsIds.length }}
                        results
                    </f7-button>
                </f7-col>
            </f7-row>
        </f7-block>
        <div class="list media-list no-margin-top" v-if="fetchedPatients && allFetchedPatientsIds.length">
            <ul>
                <li class="media-item" v-for="one in fetchedPatients" :key="one.id">
                    <div class="item-content">
                        <div
                            v-if="userHasDownloadPrivilegeInInstitution || userHasDownloadPrivilegeInProject"
                            class="item-media align-self-center"
                        >
                            <f7-checkbox
                                :disabled="!userCanDownloadEntityBundle(one)"
                                :checked="selectedEntities.includes(one.id)"
                                @change="selectEntity($event.target.checked, one.id)"
                            />
                        </div>
                        <div @click="goToEntity(one)" class="item-inner item-link">
                            <div class="item-title-row">
                                <div class="item-title" :style="one.inactive ? 'color:red' : ''">
                                    <div class="item-header">
                                        {{ getEntityInstitutionName(one) }}
                                    </div>
                                    {{ one.id }}
                                    <i v-if="one.inactive" style="color: grey">(inactive)</i>
                                    <div class="item-footer">
                                        {{ createdAtTimezone(projectInFocusInfo, one) }}
                                        <span class="font11">{{ createdAtUTC(projectInFocusInfo, one) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <f7-list v-else-if="fetchedPatients"><f7-list-item title="Nothing found"></f7-list-item></f7-list>
        <f7-block-title v-if="allFetchedPatientsIds.length > 100" class="bold text-align-center">
            There are {{ allFetchedPatientsIds.length - 100 }} more results...
        </f7-block-title>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { commonmixin } from "../mixins/common";
    export default {
        mixins: [commonmixin],
        data() {
            return {
                selectedEntities: [],
                justDownload: false,
            };
        },
        computed: {
            ...mapState(["fetchedPatients", "allFetchedPatientsIds", "user"]),
            ...mapGetters([
                "projectInFocusInfo",
                "hasEntitiesFromAnotherInstitution",
                "userHasPrivileges",
                "userPrivsSelectedProject",
            ]),

            myPrivsHere() {
                let allprivs = this.user.privsByProject || {};
                return allprivs[this.$store.state.projectInFocus] || {};
            },

            userHasPHIPrivilege() {
                return (
                    this.myPrivsHere.admin && (this.myPrivsHere.admin.phi || this.myPrivsHere.admin["project-admin"])
                );
            },

            userHasDownloadPrivilegeInInstitution() {
                return this.userHasPrivileges({
                    requiredInstitutionPrivs: ["download"],
                });
            },
            userHasDownloadPrivilegeInProject() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["download"],
                });
            },
            userCanDownloadDeidentifiedBundles() {
                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    return false;
                }

                return true;
            },
            thereIsAtLeastOneDownloadableEntityBundle() {
                return this.fetchedPatients.some((entity) => {
                    return this.userCanDownloadEntityBundle(entity);
                });
            },
        },
        mounted() {
            this.getMeThese(["forms_logic"]);
        },
        methods: {
            getEntityInstitutionName(entity) {
                if (entity.isDeidentified) {
                    const institution = this.projectInFocusInfo?.institutions?.[entity?.instid] || {};

                    if (institution) {
                        return institution?.name || entity?.instid;
                    } else {
                        return entity?.instid;
                    }
                }

                const institution = this.projectInFocusInfo?.institutions?.[entity?.instid] || {};
                return institution?.name || entity?.instid;
            },
            userCanDownloadNonDeidentifiedBundles(entities) {
                if (this.projectInFocusInfo.isDeidentificationEnabled) {
                    const myInstitutionId = Object.keys(this.userPrivsSelectedProject?.prv || {})[0] || null;
                    const areAllEntitiesFromMyInstitution = entities.every((entityId) => {
                        const entity = this.fetchedPatients.find((e) => e.id === entityId);

                        console.log("entitiy", entity);

                        if (!entity) {
                            return false;
                        }

                        return entity.instid === myInstitutionId;
                    });

                    console.log("Are all entities from my institution?", areAllEntitiesFromMyInstitution);

                    if (areAllEntitiesFromMyInstitution) {
                        return this.userHasPrivileges({
                            requiredInstitutionPrivs: ["phi"],
                        });
                    }

                    const isUserAllowed = this.userHasPrivileges({
                        requiredAdminPrivs: ["phi"],
                    });

                    return isUserAllowed;
                }

                return true;
            },
            userCanDownloadEntityBundle(entity) {
                if (this.userHasDownloadPrivilegeInProject) {
                    return true;
                } else if (this.userHasDownloadPrivilegeInInstitution) {
                    return this.myPrivsHere?.prv?.[entity.instid]?.det?.download;
                }

                return false;
            },
            selectEntity(checked, id) {
                if (checked) this.selectedEntities.push(id);
                else this.selectedEntities.splice(this.selectedEntities.indexOf(id), 1);
            },
            selectAll(checked) {
                if (checked) {
                    Object.values(this.fetchedPatients).forEach((pat) => {
                        if (this.userCanDownloadEntityBundle(pat) && !this.selectedEntities.includes(pat.id)) {
                            this.selectedEntities.push(pat.id);
                        }
                    });
                } else this.selectedEntities = [];
            },
            async decargameselected(entities) {
                if (!entities && (!this.allFetchedPatientsIds.length || !this.selectedEntities.length)) {
                    return false;
                }

                const allEntities = entities || this.selectedEntities;

                const areOnlyEntitiesIds = allEntities.every((entity) => typeof entity === "string");

                if (!areOnlyEntitiesIds) {
                    const hasEntitiesFromAnotherInstitution = this.hasEntitiesFromAnotherInstitution({
                        projectId: this.$store.state.projectInFocus,
                        entities: allEntities.map((entityId) => this.fetchedPatients.find((e) => e.id === entityId)),
                    });

                    if (hasEntitiesFromAnotherInstitution && !this.userHasPHIPrivilege) {
                        this.downloadMultipleEntitiesBundle({
                            entities: allEntities,
                            downloadPHIInfo: false,
                        });
                        return;
                    }
                }

                this.downloadMultipleEntitiesBundle({
                    entities: allEntities,
                    downloadPHIInfo: true,
                });

                // PHI Download has been temporarily disabled
                /* this.$f7.dialog
                .create({
                    text: 'Do you want to include Personal Health Information in the bundle(s)?',
                    title: 'Personal Health Information (PHI)',
                    buttons: [
                        {
                            text: 'Cancel',
                            bold: false,
                        },
                        {
                            text: 'Exclude',
                            bold: false,
                            onClick: () => {
                                this.downloadMultipleEntitiesBundle({
                                    entities: allEntities,
                                    downloadPHIInfo: false,
                                });
                            },
                        },
                        {
                            text: 'Include',
                            bold: true,
                            onClick: () => {
                                this.downloadMultipleEntitiesBundle({
                                    entities: allEntities,
                                    downloadPHIInfo: true,
                                });
                            },
                        },
                    ],
                })
                .open(); */
            },
            async downloadMultipleEntitiesBundle({ entities, downloadPHIInfo, deidentify = false }) {
                if (this.projectInFocusInfo.isDeidentificationEnabled) {
                    if (!this.userCanDownloadNonDeidentifiedBundles(entities)) {
                        this.makeDownloadCall({
                            entities,
                            downloadPHIInfo: true,
                            deidentify: true,
                        });
                    } else if (this.userCanDownloadDeidentifiedBundles) {
                        this.$f7.dialog
                            .create({
                                text: "Do you want to download the<br>de-identified version of the bundle(s)?",
                                title: "De-identification",
                                buttons: [
                                    {
                                        text: "Cancel",
                                        bold: false,
                                    },
                                    {
                                        text: "Yes",
                                        bold: false,
                                        onClick: () => {
                                            this.makeDownloadCall({
                                                entities,
                                                downloadPHIInfo: true,
                                                deidentify: true,
                                            });
                                        },
                                    },
                                    {
                                        text: "No",
                                        bold: true,
                                        onClick: () => {
                                            this.makeDownloadCall({
                                                entities,
                                                downloadPHIInfo: true,
                                                deidentify: false,
                                            });
                                        },
                                    },
                                ],
                            })
                            .open();
                    }
                } else {
                    this.makeDownloadCall({ entities, downloadPHIInfo: true, deidentify: false });
                }
            },
            async makeDownloadCall({ entities, downloadPHIInfo, deidentify = false }) {
                try {
                    this.$f7.dialog.preloader(
                        "Generating ZIP file. This action may take a few minutes depending on the size of the bundle...",
                    );
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entities: entities ? entities : this.selectedEntities,
                            downloadPHIInfo,
                            deidentify,
                        },
                        "v2_generaetMultipleZip",
                        false,
                    );
                    console.log(datous);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }

                    if (datous.asyncProcessWasTriggered) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(
                            "The ZIP file is being generated. You will receive an email with the ZIP file when it is ready.",
                        );
                        return;
                    }

                    console.log(datous.payload);
                    this.openDownloadedFile({
                        downloadname: datous.name,
                        url: datous.payload,
                    });

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            openDownloadedFile({ downloadname, url }) {
                let fileLink = document.createElement("a");
                fileLink.href = url;
                fileLink.classList.add("external");
                fileLink.setAttribute("download", downloadname);
                // fileLink.download=downloadname;
                fileLink.setAttribute("target", "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$f7.dialog.close();
            },
            goToEntity(entity) {
                this.$f7.views.main.router.navigate(`/entity/`, {
                    props: {
                        entity: entity,
                    },
                });
            },
            downloadAllResults() {
                this.decargameselected(this.allFetchedPatientsIds);
            },
        },
    };
</script>
