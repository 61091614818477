<template>
    <div>
        <f7-row v-if="loading" class="text-align-center margin">
            <f7-col>
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-row>
        <template v-else-if="chartsData">
            <div class="text-align-center margin-top">
                <img style="max-width: 50vw" src="../static/logos/wustl-logo-black.png" />
            </div>

            <!-- Pinned custom charts -->
            <template v-if="topCustomCharts.length">
                <div
                    v-if="topCustomCharts.length === 1"
                    class="display-flex justify-content-center"
                    style="max-width: 650px; margin: auto"
                >
                    <f7-card>
                        <f7-card-header>
                            <span class="bold">{{ topCustomCharts[0].title }}</span>
                        </f7-card-header>
                        <f7-card-content class="text-align-center">
                            {{ topCustomCharts[0].text }}
                        </f7-card-content>
                    </f7-card>
                </div>
                <f7-row v-else>
                    <f7-col v-for="chart in topCustomCharts" :key="chart.id" width="100" medium="50">
                        <f7-card>
                            <f7-card-header>
                                <span class="bold">{{ chart.title }}</span>
                            </f7-card-header>
                            <f7-card-content class="text-align-center">
                                {{ chart.text }}
                            </f7-card-content>
                        </f7-card>
                    </f7-col>
                </f7-row>
            </template>

            <!-- Entities count -->
            <f7-block-title>Project general details</f7-block-title>
            <f7-list media-list>
                <f7-list-item>
                    <div class="display-flex align-items-center justify-content-space-between">
                        <span slot="title">Entities count</span>
                        <h2 slot="after" class="no-margin">{{ chartsData.entitiesCount }}</h2>
                    </div>
                </f7-list-item>
            </f7-list>

            <!-- Entity files completion -->
            <f7-block-title>Status of category files</f7-block-title>
            <f7-list media-list>
                <f7-list-item v-for="entityFile in chartsData.entityFilesCounts" :key="entityFile.id">
                    <div class="display-flex align-items-center justify-content-space-between">
                        <span slot="title">{{ entityFile.encabezado }}</span>
                        <h2 slot="after" class="no-margin">
                            {{
                                chartsData.entitiesCount == 0
                                    ? 0
                                    : Math.round((entityFile.count / chartsData.entitiesCount) * 100)
                            }}%
                        </h2>
                    </div>
                </f7-list-item>
            </f7-list>

            <!-- Cumulative count over time (entities) -->
            <f7-block-title>Cumulative count over time</f7-block-title>
            <f7-card class="no-margin-horizontal no-margin-bottom">
                <f7-card-content class="text-align-center alto160">
                    <line-chart
                        v-if="
                            chartsData.institutionsContributionsCumulativeChartData.datasets[0].data.length &&
                            chartsData.institutionsContributionsCumulativeChartData.datasets[0].data[0].x
                        "
                        :chart-data="chartsData.institutionsContributionsCumulativeChartData"
                        :height="160"
                    ></line-chart>
                    <f7-block v-else>No data</f7-block>
                </f7-card-content>
            </f7-card>

            <!-- Contributions by institutions -->
            <f7-block-title>Contributions by institutions</f7-block-title>
            <f7-card class="no-margin-horizontal no-margin-bottom">
                <!-- Chart -->
                <f7-card-content class="text-align-center alto160">
                    <bar-chart
                        :chart-data="chartsData.institutionsContributionsChartData"
                        :height="160"
                        :options="noLegendOptionChart"
                    ></bar-chart>
                </f7-card-content>

                <!-- Contribution details -->
                <f7-card-content>
                    <f7-list class="no-margin-top" accordion-list>
                        <f7-list-item accordion-item>
                            <span slot="title">
                                <span class="bold">Detail of Contributions by institutions</span>
                                (click to expand)
                            </span>
                            <f7-accordion-content>
                                <f7-list class="listadoinsticiones badgebold">
                                    <f7-list-item
                                        v-for="institution in chartsData.institutionsWithContributions"
                                        :key="institution.id"
                                        :title="institution.name"
                                        :badge-color="institution.inactive ? null : 'blue'"
                                        :badge="institution.contributions || '0'"
                                    >
                                        <f7-link
                                            :tooltip="institution.inactive ? 'Inactive' : 'Active'"
                                            class="unsetcurosr"
                                            slot="media"
                                        >
                                            <font-awesome-icon
                                                :icon="['fad', 'circle']"
                                                :color="institution.inactive ? 'red' : 'teal'"
                                            ></font-awesome-icon>
                                        </f7-link>
                                    </f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </f7-card-content>
            </f7-card>

            <!-- Entity data answers -->
            <f7-block-title>Entity data answers</f7-block-title>
            <f7-block v-if="!Object.values(chartsData.entityDataCharts).length">
                No dynamic charts configured for this project
            </f7-block>
            <f7-row>
                <f7-col v-for="chart in chartsData.entityDataCharts" :key="chart.id" width="100" medium="50">
                    <f7-card>
                        <f7-card-header>
                            <span
                                class="bold"
                                :class="{
                                    'margin-auto': Object.keys(chart.answersCount).length > 20,
                                }"
                            >
                                {{ chart.encabezado }}
                            </span>
                            <span v-if="Object.keys(chart.answersCount).length > 20" class="margin-auto bold">
                                Frequency
                            </span>
                        </f7-card-header>
                        <f7-card-content
                            v-if="chart.tipo == 'elige_una'"
                            class="text-align-center unos250"
                            :class="{
                                'no-padding': Object.keys(chart.answersCount).length > 20,
                            }"
                        >
                            <div v-if="Object.keys(chart.answersCount).length > 20" class="data-table no-margin max343">
                                <table>
                                    <tbody>
                                        <tr v-for="(alt, id) in chart.answersCount" :key="id" class="text-align-center">
                                            <td>
                                                {{ chart.alternativas[id].tx }}
                                            </td>
                                            <td>{{ alt }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <bar-chart v-else :chart-data="chart.chart" :options="noLegendOptionChart"></bar-chart>
                        </f7-card-content>
                        <f7-card-content v-else>
                            <f7-list>
                                <f7-list-item divider title="Descriptive Statistics"></f7-list-item>
                                <f7-list-item title="Average" :after="chart.numericValues.mean || '0'"></f7-list-item>
                                <f7-list-item title="Count" :after="chart.numericValues.size || '0'"></f7-list-item>
                                <f7-list-item title="Max" :after="chart.numericValues.max || '0'"></f7-list-item>
                                <f7-list-item title="Q3" :after="chart.numericValues.q3 || '0'"></f7-list-item>
                                <f7-list-item title="Median" :after="chart.numericValues.median || '0'"></f7-list-item>
                                <f7-list-item title="Q1" :after="chart.numericValues.q1 || '0'"></f7-list-item>
                                <f7-list-item title="Min" :after="chart.numericValues.min || '0'"></f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>
                </f7-col>
            </f7-row>

            <template v-if="bottomCustomCharts.length">
                <f7-block-title>Other data</f7-block-title>
                <f7-row>
                    <f7-col v-for="chart in bottomCustomCharts" :key="chart.id" width="100" medium="50">
                        <f7-card>
                            <f7-card-header>
                                <span class="bold">{{ chart.title }}</span>
                            </f7-card-header>
                            <f7-card-content class="text-align-center">
                                {{ chart.text }}
                            </f7-card-content>
                        </f7-card>
                    </f7-col>
                </f7-row>
            </template>
        </template>
        <span v-else>No data to show</span>
    </div>
</template>

<script>
    import { commonmixin } from "../mixins/common";
    export default {
        props: {
            url: {
                type: String,
                required: false,
            },
        },

        mixins: [commonmixin],

        /* components: {
        BarChart,
        PieChart,
        LineChart,
    },
*/
        data() {
            return {
                chartsData: null,
                loading: false,

                // Chart options
                noLegendOptionChart: {
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    precision: 0,
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                },
            };
        },

        watch: {
            url: {
                handler: function (newVal, oldVal) {
                    //console.log("url watch", { newVal, oldVal });
                    if (newVal !== oldVal) {
                        console.log("URL changed, will fetch public dashboard");
                        this.getPublicDashboard();
                    }
                },
            },
        },

        computed: {
            topCustomCharts() {
                return this.chartsData.customCharts.filter((chart) => chart.pinnedAtTop);
            },
            bottomCustomCharts() {
                return this.chartsData.customCharts.filter((chart) => !chart.pinnedAtTop);
            },
        },

        methods: {
            async getPublicDashboard() {
                if (!this.url) {
                    console.log("No URL provided, will not fetch public dashboard");
                    return;
                }

                try {
                    this.loading = true;

                    const response = await this.commonExecute(
                        {
                            publicDashboardURL: this.url,
                        },
                        "v2_getPublicDashboard",
                    );

                    console.log("response", response);

                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    this.chartsData = response;
                    this.loading = false;

                    this.$emit("loaded", this.chartsData.projectName);
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "Error fetching public dashboard");
                    this.loading = false;
                }
            },
        },
    };
</script>
