<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Support Files"></f7-nav-title>
            <f7-subnavbar v-if="hasPrivsToAddOrDeletefiles">
                <f7-segmented raised>
                    <f7-button class="margin-right" @click="setUploadFocus()" tab-link-active>
                        <font-awesome-icon :icon="['fad', 'upload']" />
                        Add new file
                    </f7-button>
                    <f7-button @click="addNewLink()" tab-link-active>
                        <font-awesome-icon :icon="['fad', 'link']" />
                        Add new link
                    </f7-button>
                </f7-segmented>
            </f7-subnavbar>
        </f7-navbar>
        <f7-block-title>Here you can access useful documents that will help you to work on this project</f7-block-title>
        <f7-row>
            <f7-col width="50">
                <f7-block-title v-if="sortedSupportsFiles.length == 0">No support Files</f7-block-title>
                <f7-card v-for="file in sortedSupportsFiles" :key="file.id">
                    <f7-card-header class="bold">
                        <div>{{ file.name }}</div>
                        <div v-if="hasPrivsToAddOrDeletefiles">
                            <f7-link @click="deleteFile(file)" tooltip="Delete file">
                                <font-awesome-icon color="red" :icon="['fad', 'trash-can']" />
                            </f7-link>
                        </div>
                    </f7-card-header>
                    <f7-card-content>
                        <f7-progressbar v-if="loadingDescription == file.id" infinite></f7-progressbar>
                        <f7-row v-else>
                            <f7-col width="90" class="text-align-center">
                                <span v-if="file.description && openedTextarea != file.id">{{ file.description }}</span>
                                <template v-else>
                                    <f7-link v-if="openedTextarea != file.id" @click="openTextarea(file)">
                                        Add description
                                    </f7-link>
                                    <f7-input
                                        v-else
                                        class="padding-bottom"
                                        type="textarea"
                                        resizable
                                        outline
                                        :input-id="`textarea${file.id}`"
                                        :value="descriptionToSave"
                                        @input="descriptionToSave = $event.target.value"
                                    />
                                </template>
                            </f7-col>
                            <f7-col width="10">
                                <div>
                                    <f7-link
                                        v-if="
                                            hasPrivsToAddOrDeletefiles && file.description && openedTextarea != file.id
                                        "
                                        @click="openTextarea(file)"
                                        tooltip="Edit description"
                                    >
                                        <font-awesome-icon :icon="['fad', 'pen']" />
                                    </f7-link>
                                    <f7-link
                                        v-else-if="hasPrivsToAddOrDeletefiles && openedTextarea == file.id"
                                        @click="saveDescription(file)"
                                        tooltip="save description"
                                    >
                                        <font-awesome-icon class="fa-lg" :icon="['fad', 'floppy-disk']" />
                                    </f7-link>
                                </div>
                            </f7-col>
                        </f7-row>
                    </f7-card-content>
                    <f7-card-footer>
                        <f7-button class="width100" @click="supportFileDownload(file)">
                            Download
                            <font-awesome-icon :icon="['fad', 'cloud-arrow-down']" class="fa-lg" />
                        </f7-button>
                    </f7-card-footer>
                </f7-card>
            </f7-col>
            <f7-col width="50">
                <f7-block-title v-if="sortedSupportsLinks.length == 0">No support Links</f7-block-title>
                <f7-card v-for="link in sortedSupportsLinks" :key="link.id">
                    <f7-card-header class="bold">
                        <f7-link external target="_blank" :href="link.url">
                            {{ link.name }}
                            <font-awesome-icon class="margin-left" :icon="['fad', 'link']" />
                        </f7-link>
                        <div v-if="hasPrivsToAddOrDeletefiles">
                            <f7-link class="margin-right" @click="editLink(link)" tooltip="edit link">
                                <font-awesome-icon :icon="['fad', 'pen']" />
                            </f7-link>
                            <f7-link @click="atemptToDeleteLink(link)" tooltip="Delete link">
                                <font-awesome-icon color="red" :icon="['fad', 'trash-can']" />
                            </f7-link>
                        </div>
                    </f7-card-header>
                    <f7-card-content>
                        <span>{{ link.description || "No description" }}</span>
                    </f7-card-content>
                </f7-card>
            </f7-col>
        </f7-row>
        <f7-popup class="links-popup" :opened="linksPopupOpened" @popup:closed="cancelEditLink()" close>
            <f7-page>
                <f7-navbar title="Support link">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block>
                    <f7-list>
                        <f7-list-input
                            label="Link name"
                            type="text"
                            outline
                            :value="newLinkName"
                            @input="newLinkName = $event.target.value"
                            clear-button
                        ></f7-list-input>
                        <f7-list-input
                            label="Link description (optional)"
                            type="text"
                            outline
                            :value="newLinkDescription"
                            @input="newLinkDescription = $event.target.value"
                            clear-button
                        ></f7-list-input>
                        <f7-list-input
                            label="Link URL"
                            type="text"
                            outline
                            :value="newLinkURL"
                            @input="newLinkURL = $event.target.value"
                            clear-button
                        ></f7-list-input>
                    </f7-list>

                    <f7-row>
                        <f7-col>
                            <f7-button @click="cancelEditLink()" large class="cancelButton">Cancel</f7-button>
                        </f7-col>
                        <f7-col><f7-button @click="saveNewLink()" fill raised large>Save</f7-button></f7-col>
                    </f7-row>
                </f7-block>
            </f7-page>
        </f7-popup>
        <input type="file" id="filetoupload" style="display: none" @change="filesChange($event)" />
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    export default {
        data() {
            return {
                uploadFocus: { type: ".a", file: {}, itemid: null },
                uploadMeta: {},
                progress: 0,
                openedTextarea: "",
                descriptionToSave: "",
                loadingDescription: "",
                linksPopupOpened: false,
                linkId: "",
                newLinkName: "",
                newLinkDescription: "",
                newLinkURL: "",
            };
        },
        computed: {
            ...mapState(["dev", "user", "projects"]),
            ...mapGetters(["projectInFocusInfo", "userHasPrivileges"]),
            sortedSupportsFiles() {
                let supportFiles = this.projectInFocusInfo?.supportFiles || {};
                return Object.values(supportFiles).sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            },
            sortedSupportsLinks() {
                let supportLinks = this.projectInFocusInfo?.supportLinks || {};
                return Object.values(supportLinks).sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            },
            hasPrivsToAddOrDeletefiles() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["project-admin"],
                });
            },
        },
        methods: {
            async setUploadFocus() {
                try {
                    //console.log('tratndo de subir',preg);
                    // this.uploadFocus.type = `.${preg.tipo}`;
                    this.$f7.dialog.preloader("Requesting auth...");
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            targetid: "supportFiles",
                        },
                        "v2_dameTokenPara",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    // console.log(datous.payload);
                    this.uploadMeta = Object.assign({}, datous.payload);
                    await this.$firebase.auth().currentUser.reload();
                    let user = this.$firebase.auth().currentUser;
                    await user.getIdToken(true);
                    this.$f7.dialog.close();
                    this.$$("#filetoupload").click();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            filesChange(e) {
                let file = e.target.files[0];
                console.log("filesChange", file);
                if (file) {
                    // console.log(file)
                    this.subirArchivox(file);
                }
            },
            alsnapshot(snapshot) {
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                let nentero = Math.round(percentage);
                if (!isNaN(nentero)) {
                    this.progress = nentero;
                }
                // console.log('snapshot',this.uploadFocus.file);
            },
            alerror(err) {
                this.$f7.dialog.close();
                this.progress = 0;
                this.uploadMeta = Object.assign({});
                this.uploadFocus.itemid = null;
                this.$f7.dialog.alert(err.code, "upload error");
            },
            async altermino() {
                try {
                    this.progress = 100;
                    let call = this.$firebase.functions().httpsCallable("v2_setSupportFiles");
                    let results = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.dev,
                        neofile: this.uploadFocus.file.neofile,
                        itemid: this.uploadMeta.itemid,
                        flnm: this.uploadFocus.file.name,
                    });
                    console.log("results", results);
                    if (results.data.error) throw new Error(results.data.error.message);
                    let newFile = results.data.payload;
                    if (!this.projectInFocusInfo.supportFiles) this.projectInFocusInfo.supportFiles = {};
                    this.projectInFocusInfo.supportFiles[newFile.id] = newFile;
                    this.$store.commit("setObjTo", {
                        what: "projects",
                        to: this.projects,
                    });

                    this.$f7.dialog.close();
                    this.uploadMeta = Object.assign({});
                    this.uploadFocus.itemid = null;
                } catch (error) {
                    this.$f7.dialog.close();
                    this.uploadMeta = Object.assign({});
                    this.uploadFocus.itemid = null;
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            subirArchivox(filereceive) {
                this.$f7.dialog.preloader("Uploading file...");
                let date = this.$dayjs().format("MM-DD-YYYY_HH'mm'ss");
                let array = filereceive.name.split(".");
                let name = array[0];
                let ext = array[1];
                let neofile = `${name}_(${date}).${ext}`;
                this.uploadFocus.file = Object.assign(
                    {},
                    {
                        name: filereceive.name,
                        neofile,
                        size: filereceive.size,
                    },
                );
                this.uploadFocus.itemid = this.uploadMeta.itemid;
                this.progress = 0;
                //  console.log('SUBIENDO A',`neocore/${this.$firebase.auth().currentUser.uid}/${this.uploadMeta.pid}/${this.uploadMeta.tid}/${this.uploadMeta.imn}`)
                this.task = this.$firebase
                    .storage()
                    .ref(
                        `/neocore/${this.$firebase.auth().currentUser.uid}/${
                            this.projectInFocusInfo.id
                        }/supportFiles/${neofile}`,
                    )
                    .put(filereceive);
                //   console.log('seteandofile',this.uploadFocus.file,filereceive);

                this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino);
            },
            async supportFileDownload(file) {
                try {
                    this.$f7.dialog.preloader("downloading file...");
                    let call = this.$firebase.functions().httpsCallable("v2_supportFileDownload");
                    let datous = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.dev,
                        file,
                    });
                    if (datous.data.error) throw new Error(response.data.error);
                    let fileinfo = datous.data.payload;
                    this.$f7.dialog.close();
                    // this.$set(this.genURLs,enlatntig.id,fileinfo.ulr);
                    this.openDownloadedFile({
                        downloadname: file.name,
                        url: fileinfo.ulr,
                    });
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            openDownloadedFile({ downloadname, url }) {
                let fileLink = document.createElement("a");
                fileLink.href = url;
                fileLink.classList.add("external");
                fileLink.setAttribute("download", downloadname);
                // fileLink.download=downloadname;
                fileLink.setAttribute("target", "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$f7.dialog.close();
            },
            async saveDescription(file) {
                if (!this.descriptionToSave) return this.$f7.dialog.alert("Description cannot be empty");
                try {
                    this.loadingDescription = file.id;
                    await this.$firebase
                        .database()
                        .ref(`projects/${this.projectInFocusInfo.id}/supportFiles/${file.id}/description`)
                        .set(this.descriptionToSave);
                    file.description = this.descriptionToSave;
                    this.$store.commit("setObjTo", {
                        what: "projects",
                        to: this.projects,
                    });
                    this.descriptionToSave = "";
                    this.openedTextarea = "";
                    this.loadingDescription = "";
                } catch (error) {
                    this.loadingDescription = "";
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            openTextarea(file) {
                this.openedTextarea = file.id;
                setTimeout(() => {
                    let el = document.getElementById(`textarea${file.id}`).focus();
                    this.descriptionToSave = file.description || "";
                }, 30);
            },
            async deleteFile(file) {
                let fileid = file.id;
                this.$f7.dialog.confirm(`Please confirm you want to delete ${file.name}`, "Are you sure?", async () => {
                    this.$f7.dialog.preloader("Deleting file...");
                    try {
                        let datous = await this.commonExecute(
                            {
                                projectid: this.projectInFocusInfo.id,
                                fileid,
                                fileName: file.name,
                            },
                            "v2_removeSupportFile",
                            false,
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        delete this.projectInFocusInfo.supportFiles[file.id];
                        this.$store.commit("setObjTo", {
                            what: "projects",
                            to: this.projects,
                        });
                        this.avisaOk("File deleted successfully");
                        this.$f7.dialog.close();
                    } catch (error) {
                        this.$f7.dialog.close();
                        console.error(error.code, error.message);
                        this.$f7.dialog.alert(error.message, error.code || "Error");
                    }
                });
            },
            addNewLink() {
                this.linksPopupOpened = true;
            },
            atemptToDeleteLink(link) {
                let confirm = this.$f7.dialog.create({
                    title: "Are you sure you want to delete this link?",
                    buttons: [{ text: "No" }, { text: "Yes" }],
                    onClick: async (dialog, index) => {
                        if (index === 1) {
                            this.deleteLink(link);
                        }
                    },
                });
                confirm.open();
            },
            async deleteLink(link) {
                let call = this.$firebase.functions().httpsCallable("v2_deleteSupportLink");
                try {
                    this.$f7.dialog.preloader("Deleting...");
                    let results = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.dev,
                        id: link.id,
                        name: link.name,
                    });
                    console.log(results);
                    if (results.data.error) throw new Error(results.data.error);

                    delete this.projectInFocusInfo.supportLinks[link.id];
                    this.$store.commit("setObjTo", {
                        what: "projects",
                        to: this.projects,
                    });
                    this.linksPopupOpened = false;
                    this.$f7.dialog.close();
                } catch (error) {
                    this.linksPopupOpened = false;
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            editLink(link) {
                this.linkId = link.id;
                this.newLinkName = link.name;
                this.newLinkDescription = link.description || "";
                this.newLinkURL = link.url;
                this.linksPopupOpened = true;
            },
            cancelEditLink() {
                this.linkId = "";
                this.newLinkName = "";
                this.newLinkDescription = "";
                this.newLinkURL = "";
                this.linksPopupOpened = false;
            },
            async saveNewLink() {
                if (!this.newLinkName || !this.newLinkURL) return this.$f7.dialog.alert("Some information is missing");

                let id = !this.linkId
                    ? this.$firebase.database().ref(`projects/${this.projectInFocusInfo.id}/supportLinks`).push().key
                    : this.linkId;
                let call = this.$firebase.functions().httpsCallable("v2_setSupportLink");
                try {
                    this.$f7.dialog.preloader("Saving...");
                    let results = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.dev,
                        id,
                        name: this.newLinkName,
                        description: this.newLinkDescription,
                        url: this.newLinkURL,
                    });
                    console.log(results);
                    if (results.data.error) throw new Error(results.data.error);

                    if (!this.projectInFocusInfo.supportLinks) this.projectInFocusInfo.supportLinks = {};
                    let linkToSave = {
                        id,
                        name: this.newLinkName,
                        description: this.newLinkDescription,
                        url: this.newLinkURL,
                    };
                    this.projectInFocusInfo.supportLinks[id] = linkToSave;
                    this.$store.commit("setObjTo", {
                        what: "projects",
                        to: this.projects,
                    });
                    this.linksPopupOpened = false;
                    this.$f7.dialog.close();
                } catch (error) {
                    this.linksPopupOpened = false;
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
        },
        watch: {
            openedTextarea() {
                this.descriptionToSave = "";
            },
        },
    };
</script>
<style></style>
