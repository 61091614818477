<template>
    <f7-page @page:afterin="fetchBundlesHistory({})">
        <f7-navbar back-link>
            <f7-nav-title>Entities bundles history</f7-nav-title>
            <f7-nav-right>
                <f7-link tooltip="Refresh list" @click="fetchBundlesHistory({ refresh: true })">
                    <font-awesome-icon :icon="['fad', 'rotate']"></font-awesome-icon>
                </f7-link>
                <f7-badge v-if="bundlesNotDownloaded > 0" class="margin-right" color="yellow">
                    <p class="newBundle">{{ bundlesNotDownloaded }} New bundles</p>
                </f7-badge>
            </f7-nav-right>
        </f7-navbar>

        <f7-block>
            <f7-block v-if="loading" class="text-align-center">
                <f7-preloader></f7-preloader>
            </f7-block>
            <template v-else-if="sortedBundleHistory.length">
                <f7-row>
                    <f7-col></f7-col>
                    <f7-col class="text-align-right">
                        <f7-link
                            :class="{ disabled: bundlesNotDownloaded == 0 }"
                            @click="attemptToClearBadges()"
                            class="self-align-right"
                        >
                            Clear notification badges
                            <font-awesome-icon :icon="['fad', 'trash']" class="margin-left"></font-awesome-icon>
                        </f7-link>
                    </f7-col>
                </f7-row>
                <f7-list media-list class="no-margin-top">
                    <f7-list-item v-for="(bundle, index) in sortedBundleHistory" :key="index">
                        <template slot="header">
                            <f7-row>
                                <f7-col>
                                    <f7-badge color="orange" v-if="bundle.status === 0">Processing</f7-badge>
                                    <f7-badge color="green" v-else-if="bundle.status === 1 || bundle.status === 4">
                                        Processed
                                    </f7-badge>
                                    <f7-badge color="red" v-else-if="bundle.status === 2">Error</f7-badge>
                                    <f7-badge color="red" v-else-if="bundle.status === 3">Cancelled</f7-badge>
                                </f7-col>
                                <f7-col v-if="bundle.status === 1" class="text-align-right">
                                    <f7-badge color="yellow"><p class="newBundle bold">NEW</p></f7-badge>
                                </f7-col>
                            </f7-row>
                            <br />
                        </template>
                        <template slot="text">
                            <b>Requested at</b>
                            :
                            {{ $dayjs.unix(bundle.downloadTriggeredAt / 1000).format("MM/DD/YYYY HH:mm:ss") }}
                            ({{ $dayjs.unix(bundle.downloadTriggeredAt / 1000).fromNow() }})
                            <br />

                            <b>Bundle was generated at</b>
                            :
                            {{ $dayjs.unix(bundle.generatedAt / 1000).format("MM/DD/YYYY HH:mm:ss") }}
                            <span v-if="bundle.downloadTriggeredAt !== bundle.generatedAt">
                                ({{ diffInMinutesAndSeconds(bundle.downloadTriggeredAt, bundle.generatedAt) }})
                            </span>
                            <br />

                            <b>Number of entities bundled</b>
                            :
                            {{ bundle.numberOfEntities }}
                            <br />

                            <span v-if="$store.getters.projectInFocusInfo.isDeidentificationEnabled">
                                <b>De-identified</b>
                                :
                                {{ bundle.deidentify === true ? "Yes" : "No" }}
                            </span>

                            <!-- <f7-link @click="seeEntitiesInBundle(bundle.entities)">
                            <span class="small-text">(See entities)</span>
                        </f7-link> -->
                            <br />
                            <small v-if="bundle.errorReason">
                                <f7-list accordion-list inset class="no-margin">
                                    <f7-list-item accordion-item>
                                        <small slot="title">
                                            <b class="text-color-red">There were errors while generating the bundle</b>
                                            - Click here to expand errors
                                        </small>
                                        <f7-accordion-content>
                                            <f7-list-item
                                                v-for="(reason, index) in Object.values(bundle.errorReason)"
                                                :key="index"
                                            >
                                                <small slot="title">
                                                    {{ reason }}
                                                </small>
                                            </f7-list-item>
                                        </f7-accordion-content>
                                    </f7-list-item>
                                </f7-list>
                            </small>

                            <template v-if="bundle.status === 1 || bundle.status === 4">
                                <f7-button
                                    v-if="bundle.url"
                                    @click="downloadBundle(bundle.url, bundle.jobId)"
                                    class="margin-top"
                                    fill
                                    small
                                >
                                    Download bundle
                                </f7-button>

                                <template v-else-if="bundle.urls">
                                    <f7-button
                                        v-if="Object.keys(bundle.urls).length === 1"
                                        @click="downloadBundle(Object.values(bundle.urls)[0], bundle.jobId)"
                                        class="margin-top"
                                        fill
                                        small
                                    >
                                        Download bundle
                                    </f7-button>

                                    <template v-else>
                                        <f7-button @click="downloadAllZipParts(bundle)" small fill class="margin-top">
                                            Download bundle
                                        </f7-button>

                                        <f7-list accordion-list inset class="no-margin bg-color-gray">
                                            <f7-list-item accordion-item>
                                                <small class="text-color-gray" slot="title">
                                                    <b>
                                                        This bundle was divided in
                                                        {{ Object.keys(bundle.urls).length }}
                                                        parts. Click here to expand.
                                                    </b>
                                                </small>
                                                <f7-accordion-content>
                                                    <div v-for="(url, id, index) in bundle.urls" :key="id">
                                                        <f7-link
                                                            @click="downloadBundle(url, bundle.jobId)"
                                                            class="no-ripple"
                                                        >
                                                            <font-awesome-icon
                                                                class="fa-sm margin-horizontal-half"
                                                                :icon="['fad', 'download']"
                                                            ></font-awesome-icon>
                                                            Download part no.
                                                            {{ index + 1 }}
                                                        </f7-link>
                                                        <br />
                                                    </div>
                                                </f7-accordion-content>
                                            </f7-list-item>
                                        </f7-list>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </f7-list-item>
                </f7-list>
            </template>
            <f7-list v-else>
                <f7-list-item>
                    <template slot="title">
                        You have not generated any bundles yet. Once you generate a bundle, it will be shown here.
                    </template>
                </f7-list-item>
            </f7-list>
        </f7-block>
    </f7-page>
</template>

<script>
    import { mapState } from "vuex";
    import { commonmixin } from "../mixins/common";
    export default {
        mixins: [commonmixin],

        data() {
            return {
                loading: true,
            };
        },

        computed: {
            ...mapState(["projectInFocus", "bundlesHistory"]),

            sortedBundleHistory() {
                return Object.values(this.bundlesHistory || {}).sort((a, b) => {
                    return b.downloadTriggeredAt - a.downloadTriggeredAt;
                });
            },
            bundlesNotDownloaded() {
                let count = 0;
                this.sortedBundleHistory.forEach((bundle) => {
                    if (bundle.status === 1) count++;
                });
                return count;
            },
        },

        methods: {
            async downloadAllZipParts(bundle) {
                console.log("bundle", bundle);

                try {
                    this.$f7.dialog.preloader("Downloading bundle...");

                    const response = await this.commonExecute(
                        {
                            projectId: bundle.projectId,
                            jobId: bundle.jobId,
                        },
                        "v2_downloadAllZipParts",
                        false,
                    );

                    console.log("response", response);

                    if (response.error) {
                        throw Error(response.error);
                    }

                    window.location.href = response.bundleUrl;
                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
            attemptToClearBadges() {
                this.$f7.dialog.confirm("Are you sure you want to clear all notification badges?", () => {
                    this.clearNotificationBadges();
                });
            },
            async clearNotificationBadges() {
                try {
                    this.$f7.dialog.preloader("Clearing notifications badges...");
                    let call = this.$firebase.functions().httpsCallable("v2_clearNotificationBadges");
                    let response = await call({
                        projectId: this.projectInFocus,
                        dev: this.$store.state.dev,
                    });
                    console.log("response.bundlesHistory ", response);
                    if (response.data.error) throw new Error(response.data.error);
                    this.$store.commit("setObjTo", { what: "bundlesHistory", to: response.data.bundlesHistory });
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            async fetchBundlesHistory({ refresh }) {
                if (
                    !refresh &&
                    this.bundlesHistory &&
                    Object.values(this.bundlesHistory)?.[0]?.projectId == this.projectInFocus
                )
                    return (this.loading = false);
                this.loading = true;

                try {
                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus },
                        "v2_getUserBundlesHistory",
                    );
                    console.log("Fetch bundles history response", response);

                    if (response.error) {
                        throw Error(response.error);
                    }
                    this.$store.commit("setObjTo", { what: "bundlesHistory", to: response.bundlesHistory });
                    this.loading = false;
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.alert(error.message, "There was an error while fetching your bundles history");
                    this.loading = false;
                }
            },

            async downloadBundle(url, jobId) {
                this.$f7.dialog.preloader("Downloading bundle...");
                try {
                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus, bundleStoragePath: url, jobId, dev: this.$store.state.dev },
                        "v2_downloadBundle",
                    );

                    if (response.error) {
                        throw Error(response.error);
                    }

                    window.location.href = response.bundleUrl;
                    this.bundlesHistory[jobId].status = 4;
                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "There was an error while fetching your bundles history");
                }
            },

            seeEntitiesInBundle(entities) {
                console.log("See entities in bundle", entities);
                this.$f7.dialog.alert("Not implemented yet");
            },

            diffInMinutesAndSeconds(unixDate1, unixDate2) {
                // Convert Unix dates to Day.js objects
                const date1 = this.$dayjs.unix(unixDate1 / 1000);
                const date2 = this.$dayjs.unix(unixDate2 / 1000);

                // Calculate the difference in milliseconds
                const diffInMilliseconds = date2.diff(date1);

                // Convert the difference to a Day.js duration
                const duration = this.$dayjs.duration(diffInMilliseconds);

                // Extract minutes and seconds from the duration
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                // Return the result as a string
                return `${minutes} minutes and ${seconds} seconds`;
            },
        },
    };
</script>

<style scoped>
    .small-text {
        font-size: 0.7rem;
    }
    .list {
        --f7-list-item-padding-horizontal: 0;
        --f7-list-item-padding-vertical: 2px;
        --f7-list-item-min-height: 0;
    }
</style>
