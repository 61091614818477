<template>
    <f7-page>
        <f7-navbar :subtitle="lesubtitle" :title="letitle">
            <f7-nav-right>
                <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block v-if="cargando" class="text-align-center">
            <f7-preloader></f7-preloader>
            <br />
            Loading...
        </f7-block>
        <div class="timeline">
            <div v-for="(alog, idx) in log" :key="idx" class="timeline-item">
                <div class="timeline-item-date">
                    {{ $dayjs.unix(alog.x).format("DD") }}
                    <small>{{ $dayjs.unix(alog.x).format("MMM") }}</small>
                </div>
                <div class="timeline-item-divider"></div>
                <div class="timeline-item-content">
                    <div class="timeline-item-inner">
                        <div class="timeline-item-time">
                            {{ $dayjs.unix(alog.x).format("HH:mm:ss") }} - {{ alog.w }}
                        </div>
                        <div class="timeline-item-title">{{ alog.i }}</div>
                        <div v-if="false" class="timeline-item-subtitle">{{ alog.w }}</div>
                        <div v-if="alog.fn" class="timeline-item-text">{{ alog.fn }}</div>
                        <div v-if="alog.i == 'updates_entityData'" class="timeline-item-text">
                            {{ projectInFocusInfo.entityData[alog.f].encabezado }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </f7-page>
</template>
<script>
    import { mapGetters } from "vuex";
    import { commonmixin } from "../mixins/common";

    export default {
        mixins: [commonmixin],
        props: ["indice", "projectid", "valor", "entity", "userinfo"],
        data() {
            return {
                cargando: true,
                log: [],
            };
        },
        computed: {
            ...mapGetters(["projectInFocusInfo"]),
            lesubtitle() {
                if (this.userinfo) {
                    return this.userinfo.displayName;
                } else {
                    return `Project: ${this.projectInFocusInfo.name}`;
                }
            },
            letitle() {
                if (this.userinfo) {
                    return "User events for";
                } else if (this.indice == "a") {
                    //es el paciente
                    return `${this.projectInFocusInfo.entity || "entity"}: ${this.entity.displayid}`;
                } else if (this.indice == "g") {
                    //es la institucion
                    return this.projectInFocusInfo.institutions[this.valor].name;
                } else if (this.indice == "p") {
                    //es el proyecto
                    return `Project log`;
                }
            },
        },
        mounted() {
            // console.log('props son',this.indice,this.projectid,this.valor);
            this.gettholog();
        },
        methods: {
            async gettholog() {
                try {
                    this.cargando = true;
                    let datous = await this.commonExecute({ key: this.indice, value: this.valor }, "v2_getlog", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let linfo = datous.payload;
                    // console.log('linfo',linfo);
                    linfo.sort((a, b) => {
                        let alow = a.x;
                        let blow = b.x;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    this.log = linfo;
                    this.cargando = false;
                } catch (error) {
                    this.cargando = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
        },
    };
</script>
<style></style>
