<template>
    <f7-page class="backgoris no-padding-bottom" @page:afterin="setObservables">
        <f7-block class="margin-vertical bold projectnamemenu">{{ projectInFocusInfo.name }}</f7-block>

        <f7-list class="no-margin-top latmenu">
            <f7-list-item
                v-for="menux in filteredMenuProject"
                :key="menux.urlx"
                link="#"
                :class="{ active: menux.urlx == activeTab }"
                @click="preGoToMenu(menux.urlx)"
                panel-close
                :title="menux.title"
                :badge="menux.badge ? '4' : null"
                badge-color="red"
                :footer="menux.footer"
            >
                <i slot="media" :class="`fas fa-lg fa-${menux.ico}`"></i>
                <div v-if="menux.urlx == '/projectdata/' && bundlesNotDownloaded > 0" slot="after" class="iconToTop">
                    <font-awesome-icon color="black" :icon="['fad', 'clock-rotate-left']"></font-awesome-icon>
                </div>
            </f7-list-item>
        </f7-list>
        <f7-block>
            <f7-button color="white" panel-close @click="cerrarProjecto()" outline>Close Project</f7-button>
        </f7-block>

        <f7-block class="text-align-center">
            <img class="cielologo" src="../static/icons/512x512.png" />
            <p class="no-margin login-bccly libre-baskerville">
                <strong>CIELO</strong>
            </p>
            <p class="no-margin ahicame">{{ appVersion }}</p>
            <f7-badge v-if="dev" color="red">Dev</f7-badge>
        </f7-block>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    export default {
        data() {
            return {
                projectmenu: [
                    {
                        urlx: "/dashboard/",
                        title: "Dashboard",
                        footer: "Project Overview",
                        ico: "tachometer-alt",
                        unoporinsti: true,
                    },
                    {
                        urlx: "/projectdata/",
                        title: "Data",
                        footer: "Manage & download",
                        ico: "list",
                        unoporinsti: true,
                    },
                    {
                        urlx: "/queries/",
                        title: "Search",
                        footer: "Across entities",
                        ico: "search",
                        unoporinsti: true,
                        requiresDeidentificationEnabled: true,
                    },
                    {
                        urlx: "/search-on-csv/",
                        title: "Search on CSV",
                        footer: "Across entities",
                        ico: "search",
                        unoporinsti: true,
                    },
                    {
                        urlx: "/prolog/",
                        title: "Activity Feed",
                        footer: "Project log",
                        ico: "history",
                        unoporinsti: true,
                        requiredAdminPrivs: ["activity-feed"],
                    },
                    {
                        urlx: "/project_admin/",
                        title: "Admin",
                        footer: "Project Settings",
                        ico: "cogs",
                        prvNeeded: ["project-admin"],
                        cieloAdmin: true,
                    },
                    {
                        urlx: "/dataimport/",
                        title: "Data import",
                        footer: "Import from file and Redcap",
                        ico: "file-import",
                        prvNeeded: ["full", "read"],
                        requiredAdminPrivs: ["full"],
                        requiredInstitutionPrivs: ["create"],
                        unoporinsti: true,
                    },
                    {
                        urlx: "/cuentas/project/",
                        title: "Accounts",
                        footer: "Create and manage",
                        ico: "users",
                        prvNeeded: ["accounts"],
                    },
                    {
                        urlx: "/support/",
                        title: "Support Files",
                        footer: "Dictionaries, snippets...",
                        ico: "book",
                    },
                    {
                        urlx: "/termsConds/project/",
                        title: "Terms & Conds",
                        footer: "Terms & Conditions of the project",
                        ico: "scroll",
                    },
                    {
                        urlx: "/about/project/",
                        title: "About",
                        footer: "Project info",
                        ico: "info-circle",
                    },
                ],

                timeoutId: null,
                loading: false,
            };
        },
        computed: {
            ...mapState([
                "user",
                "appVersion",
                "activeTab",
                "dev",
                "projectInFocus",
                "forceProjectSelect",
                "forms_logic",
                "bulkUploadStatusesRef",
                "lastUpdateRef",
                "userPrivilegesRef",
                "bundlesHistory",
                "bundleHistoryRef",
                "remainingSecondsUntilRestrictedAccess",
            ]),
            ...mapGetters(["projectInFocusInfo", "privxInfo", "userHasPrivileges"]),
            suprivx() {
                return this.user?.privx || {};
            },
            privsByProject() {
                return this.user?.privsByProject || {};
            },
            tieneAlMenosunPrivEnIsnti() {
                if (!this.projectInFocus) {
                    return false;
                }
                return (
                    this.privsByProject[this.projectInFocus] &&
                    this.privsByProject[this.projectInFocus].prv &&
                    Object.values(this.privsByProject[this.projectInFocus].prv).some(
                        (uninst) => uninst.det && (uninst.det.create || uninst.det.update),
                    )
                );
            },
            filteredMenuProject() {
                let menutoshow = [];

                if (!this.projectInFocus) {
                    return menutoshow;
                }

                const isDeidentificationEnabled = this.projectInFocusInfo.isDeidentificationEnabled === true;

                const hasRequiredPrivileges = (unmenu) => {
                    if (isDeidentificationEnabled && (unmenu.requiredInstitutionPrivs || unmenu.requiredAdminPrivs)) {
                        return (
                            this.userHasPrivileges({ requiredInstitutionPrivs: unmenu.requiredInstitutionPrivs }) ||
                            this.userHasPrivileges({ requiredAdminPrivs: unmenu.requiredAdminPrivs })
                        );
                    }

                    return (
                        !unmenu.prvNeeded ||
                        unmenu.prvNeeded.some((unpriv) => {
                            return (
                                this.privsByProject?.[this.projectInFocus] &&
                                this.privsByProject?.[this.projectInFocus]?.admin &&
                                this.privsByProject?.[this.projectInFocus]?.admin?.[unpriv]
                            );
                        }) ||
                        (unmenu.unoporinsti && this.tieneAlMenosunPrivEnIsnti) ||
                        (unmenu.cieloAdmin && this.privxInfo?.admin)
                    );
                };

                menutoshow = this.projectmenu.filter((unmenu) => hasRequiredPrivileges(unmenu));

                return menutoshow;
            },
            bundlesNotDownloaded() {
                let bundles = this.bundlesHistory || {};
                let count = 0;
                Object.values(bundles || {}).forEach((bundle) => {
                    if (bundle.status === 1) count++;
                });
                return count;
            },
            // esadminhere(){
            //   if(this.projectInFocus){
            //     let privsByProject = this.user.privsByProject || {};
            //     let privsahir=privsByProject[this.projectInFocus] || {};
            //     let adminsahi = privsahir.admin || {}
            //     return adminsahi['project-admin']
            //   }
            //   else{
            //     return false;
            //   }
            // }
        },
        watch: {
            projectInFocus(newvalue, previousvaelu) {
                console.log(`changin project from ${previousvaelu} to ${newvalue}`, this.activeTab);
                let esUnproyecto = this.projectmenu.find((unmenu) => {
                    return unmenu.urlx === this.activeTab;
                });
                if (!esUnproyecto) {
                    this.gotoMenux("/dashboard/");
                }
                this.$store.commit("setWhatTo", {
                    what: "forceProjectSelect",
                    to: false,
                });
            },
            remainingSecondsUntilRestrictedAccess(newVal) {
                this.setAccessLimitTimeout(newVal);
            },
        },
        mounted() {
            if (!this.timeoutId && this.remainingSecondsUntilRestrictedAccess) {
                this.setAccessLimitTimeout(this.remainingSecondsUntilRestrictedAccess);
            }
        },
        methods: {
            setAccessLimitTimeout(seconds) {
                if (Number.isInteger(seconds) && seconds > 0) {
                    // Set timeout to close project when time is up
                    this.timeoutId = setTimeout(() => {
                        if (
                            Number.isInteger(this.$store.state.remainingSecondsUntilRestrictedAccess) &&
                            this.$store.state.remainingSecondsUntilRestrictedAccess < 1728000
                        ) {
                            // 1728000 20 dias, sdettiemout maximo 25 dias
                            this.cerrarProjecto();
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(
                                "Your access to this project has expired. Please contact an administrator.",
                            );
                        }
                    }, seconds * 1000);
                } else if (this.timeoutId) {
                    console.log("Cancelling timeout to close project");
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            },
            async preGoToMenu(urlx) {
                if (this.$f7.views.main.router.url == urlx) return;
                if (urlx == "/dataimport/") {
                    await this.getMeThese(["forms_logic"]);
                    this.$store.commit("setWhatTo", {
                        what: "adminprojectFocus",
                        to: this.projectInFocus,
                    });
                    this.gotoMenux(urlx);
                } else if (urlx == "/project_admin/") {
                    if (!this.loading) {
                        console.log("not loading");
                        this.loading = true;
                        this.$f7.dialog.preloader("Loading...");
                    }
                    setTimeout(async () => {
                        await this.getMeThese(["forms_logic"]);
                        this.$store.commit("setWhatTo", {
                            what: "adminprojectFocus",
                            to: this.projectInFocus,
                        });
                        this.gotoMenux(urlx);
                        this.loading = false;
                        console.log(" this.loading", this.loading);
                    }, 100);
                } else if (urlx == "/projectdata/") {
                    this.$store.commit("setWhatTo", { what: "goToProyectDataFromMenu", to: true });
                    this.gotoMenux(urlx);
                } else this.gotoMenux(urlx);
            },
            cerrarProjecto() {
                this.$store.commit("turnOffObservables", { fromLogout: false });

                this.$store.commit("setWhatTo", {
                    what: "shouldForceRefreshEntities",
                    to: false,
                });

                this.gotoMenux("/allprojects/");
                this.$store.commit("setWhatTo", {
                    what: "forceProjectSelect",
                    to: true,
                });
                this.$store.commit("setWhatTo", {
                    what: "remainingSecondsUntilRestrictedAccess",
                    to: null,
                });
                this.$store.commit("setWhatTo", {
                    what: "bundlesHistory",
                    to: null,
                });

                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            },
            setObservables() {
                let ref = this.$firebase.database().ref(`bulkUploadStatuses/${this.projectInFocus}`);
                this.$store.commit("setWhatTo", { what: "bulkUploadStatusesRef", to: ref });
                this.bulkUploadStatusesRef.on(
                    "value",
                    (snapshot) => {
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: [],
                        });
                        this.$store.commit("setWhatTo", {
                            what: "lastEntityId",
                            to: null,
                        });
                        if (this.activeTab === "/projectdata/") {
                            this.$store.commit("setWhatTo", {
                                what: "shouldForceRefreshEntities",
                                to: true,
                            });
                        } else if (this.$store.state.allentities.length > 0) {
                            this.$f7.toast.show({
                                text: "A bulk upload was just processed. Entities in the Data tab will be refreshed.",
                                horizontalPosition: "center",
                                closeTimeout: 5000,
                                closeButton: true,
                            });
                        }
                    },
                    (error) => {
                        console.error(error);
                    },
                );
                let updateRef = this.$firebase.database().ref(`projects/${this.projectInFocus}/lastUpdate`);
                this.$store.commit("setWhatTo", { what: "lastUpdateRef", to: updateRef });
                this.lastUpdateRef.on(
                    "value",
                    async (snapshot) => {
                        let snap = snapshot.val();
                        if (snap != this.projectInFocusInfo.lastUpdate) {
                            let res = await this.$firebase
                                .database()
                                .ref(`projects/${this.projectInFocus}`)
                                .once("value");
                            let neoProject = res.val();
                            this.$store.commit("meteraprop", {
                                cual: "projects",
                                id: neoProject.id,
                                val: neoProject,
                            });
                        }
                    },

                    (error) => {
                        console.error(error);
                    },
                );
                let userRef = this.$firebase
                    .database()
                    .ref("privsByProject")
                    .orderByChild("uid")
                    .equalTo(this.user.uid);
                this.$store.commit("setWhatTo", { what: "userPrivilegesRef", to: userRef });
                this.userPrivilegesRef.on("child_changed", (childSnapshot) => {
                    let newPriv = childSnapshot.val();
                    let user = Object.assign({}, this.user);
                    console.log("child_changed (projectMenu.vue)", newPriv);

                    if (newPriv.accessLimit && this.$store.state.projectInFocus === newPriv.pid) {
                        const remainingSecondsUntilRestrictedAccess = this.$dayjs(
                            `${newPriv.accessLimit.date} ${newPriv.accessLimit.time}`,
                        ).diff(this.$dayjs(), "seconds");
                        console.log("Remaining seconds until restricted access", remainingSecondsUntilRestrictedAccess);

                        this.$store.commit("setWhatTo", {
                            what: "remainingSecondsUntilRestrictedAccess",
                            to: remainingSecondsUntilRestrictedAccess,
                        });

                        this.setAccessLimitTimeout(remainingSecondsUntilRestrictedAccess);
                    }

                    user.privsByProject[newPriv.pid] = newPriv;
                    this.$store.commit("setObjTo", {
                        what: "user",
                        to: user,
                    });
                });

                const userHasDownloadInstitutionPriv = this.userHasPrivileges({
                    requiredInstitutionPrivs: ["download"],
                });
                const userHasDownloadAdminPriv = this.userHasPrivileges({
                    requiredAdminPrivs: ["download"],
                });

                if (userHasDownloadInstitutionPriv || userHasDownloadAdminPriv) {
                    let bundleHistoryRef = this.$firebase
                        .database()
                        .ref(`bulkDownloads/${this.projectInFocus}/${this.user.uid}`);
                    this.$store.commit("setWhatTo", { what: "bundleHistoryRef", to: bundleHistoryRef });
                    this.bundleHistoryRef.on(
                        "value",
                        async (snapshot) => {
                            try {
                                const response = await this.commonExecute(
                                    { projectId: this.projectInFocus },
                                    "v2_getUserBundlesHistory",
                                );
                                console.log("Fetch bundles history response", response);

                                if (response.error) {
                                    throw Error(response.error);
                                }
                                this.$store.commit("setObjTo", { what: "bundlesHistory", to: response.bundlesHistory });
                                this.loading = false;
                            } catch (error) {
                                console.error(error.message);
                                this.$f7.dialog.alert(
                                    error.message,
                                    "There was an error while fetching your bundles history",
                                );
                                this.loading = false;
                            }
                        },
                        (error) => {
                            console.error(error);
                        },
                    );
                }
            },
        },
    };
</script>
<style>
    .latmenu .item-media svg {
        margin: 0 auto;
    }
    .latmenu li {
        background: #062d3a;
        color: #fff;
    }
    .latmenu li .item-footer {
        color: #b9b9b9;
    }
    .latmenu li .item-media {
        color: #cecece;
    }
    .latmenu li.active {
        background: #ffffff;
        color: #a51417;
    }
    .latmenu li.active .item-title {
        font-weight: bold;
    }
    .latmenu li.active .item-media {
        color: #a51417;
    }
    .latmenu li.active .item-footer {
        color: #062d3a;
    }

    .mevacnpng {
        width: 80%;
        max-width: 80%;
    }
    .projectnamemenu {
        font-size: 16px !important;
    }
    .iconToTop {
        top: -5px;
        position: relative;
        background-color: #ffff79;
        border-radius: 100%;
        width: 19px;
        height: 19px;
        text-align: center;
    }
</style>
