<template>
    <f7-page @page:beforein="loadproect()" name="core_projects">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="in CIELO" title="Projects"></f7-nav-title>
            <f7-nav-right>
                <f7-block>
                    <f7-button
                        class="abreclonador padding-left padding-right"
                        fill
                        raised
                        popover-open=".popover-projectos"
                    >
                        <font-awesome-icon class="fa-lg" icon="plus"></font-awesome-icon>
                    </f7-button>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>

        <f7-block v-if="loading" class="text-align-center">
            <f7-preloader></f7-preloader>
            <br />
            Loading projects...
        </f7-block>
        <f7-block v-else-if="!projects.length">There are no projects created in CIELO</f7-block>

        <f7-list v-else class="cor_proyectxs no-margin-vertical" media-list>
            <f7-list-item
                v-for="(project, idx) in projects"
                :key="idx"
                :title="project.name"
                :text="project.descr || 'No description set'"
                @click="gotoproject(project.id)"
                link="#"
                :footer="`ID: ${project.id}`"
            >
                <div slot="header">
                    <f7-link v-if="project.cemail" external :href="`mailto:${project.cemail}`">
                        <u>{{ project.cemail }}</u>
                    </f7-link>
                    <span v-else>No contact set</span>
                </div>
                <img slot="media" :src="project.banner || '../static/heros/loginback_1.jpg'" />
                <span slot="after">
                    <span>
                        <f7-link tooltip="Active" class="text-color-primary" v-if="project.published">
                            <font-awesome-icon :icon="['fad', 'shop']"></font-awesome-icon>
                        </f7-link>
                        <f7-link tooltip="Inactive" class="text-color-red" v-else>
                            <font-awesome-icon :icon="['fad', 'shop-slash']"></font-awesome-icon>
                        </f7-link>
                    </span>
                    <span class="margin-left-half">
                        <f7-link tooltip="Visible" class="text-color-primary" v-if="project.visible">
                            <font-awesome-icon :icon="['fad', 'eye']"></font-awesome-icon>
                        </f7-link>
                        <f7-link tooltip="Hidden" class="text-color-red" v-else>
                            <font-awesome-icon :icon="['fad', 'eye-slash']"></font-awesome-icon>
                        </f7-link>
                    </span>
                    <span class="margin-left-half">
                        <f7-link tooltip="Joinable" class="text-color-primary" v-if="project.allowAccess">
                            <font-awesome-icon :icon="['fad', 'user-clock']"></font-awesome-icon>
                        </f7-link>
                        <f7-link tooltip="Closed" class="text-color-red" v-else>
                            <font-awesome-icon :icon="['fad', 'user-lock']"></font-awesome-icon>
                        </f7-link>
                    </span>
                </span>
            </f7-list-item>
        </f7-list>

        <f7-popover class="popover-projectos">
            <f7-list>
                <f7-list-item link="#" @click="neoproject()" popover-close title="Create new project"></f7-list-item>
                <f7-list-item
                    link="#"
                    @click="abreclonador()"
                    popover-close
                    title="Clone existing project"
                ></f7-list-item>
                <f7-list-item
                    link="#"
                    @click="selectConfigjs()"
                    popover-close
                    title="Load project from file"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popover class="projectos-clone">
            <f7-list>
                <f7-list-item v-for="project in projects" :key="project.id" :footer="project.id" :title="project.name">
                    <f7-button
                        @click="creaProjecto({ neoname: null, seedid: project.id })"
                        popover-close
                        raised
                        slot="after"
                    >
                        Clone
                    </f7-button>
                </f7-list-item>
            </f7-list>
        </f7-popover>

        <input type="file" id="configjs-input" @change="filesChange($event)" accept=".json" />
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState(["user", "dev"]),
            ...mapGetters(["projects"]),
        },
        methods: {
            selectConfigjs() {
                this.$$("#configjs-input").click();
            },
            filesChange(e) {
                let file = e.target.files[0];
                if (file) {
                    console.log(file);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        let obj = JSON.parse(e.target.result);
                        console.log("JSONEADO", obj);
                        this.requestconfigparse({ laconfig: obj });
                    };
                    reader.readAsText(file);
                }
            },
            async requestconfigparse({ laconfig }) {
                try {
                    this.$f7.dialog.preloader("Validating configuration file...");
                    let datous = await this.commonExecute({ laconfig }, "v2_cloneProjectFromFile", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let projecinfo = datous.payload;
                    this.$store.commit("meteraprop", {
                        cual: "projects",
                        id: projecinfo.id,
                        val: projecinfo,
                    });
                    this.$f7.dialog.close();
                    this.gotoproject(projecinfo.id);
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            abreclonador() {
                this.$f7.popover.open(".projectos-clone", ".abreclonador", true);
            },
            async gotoproject(projectid) {
                this.$store.commit("setWhatTo", {
                    what: "projectInFocus",
                    to: null,
                });
                await this.getMeThese(["forms_logic"]);
                this.$store.commit("setWhatTo", {
                    what: "adminprojectFocus",
                    to: projectid,
                });
                this.$f7.views.main.router.navigate("/project_admin/");
            },
            async loadproect() {
                let snap = await this.commonfireread({
                    refa: "projects",
                    condialog: false,
                });
                this.loading = false;
                if (snap.exists()) {
                    this.$store.commit("setObjTo", {
                        what: "projects",
                        to: snap.val(),
                    });
                }
            },
            async neoproject() {
                // console.log('neo project');
                return this.$f7.dialog.prompt("Indicate the new project name", "New Project", async (nombre) => {
                    let rimeed = nombre.trim();
                    if (!rimeed) {
                        return this.$f7.dialog.alert("You must provide a name for the project");
                    }
                    if (
                        this.projects.some((project) => {
                            return project.name.toLowerCase() == rimeed.toLowerCase();
                        })
                    ) {
                        return this.$f7.dialog.alert(
                            "There is already a project with the same name, please try another name",
                        );
                    }
                    this.creaProjecto({ neoname: rimeed, seedid: null });
                });
            },
            async creaProjecto({ neoname, seedid }) {
                try {
                    this.$f7.dialog.preloader("Creating...");
                    let datous = await this.commonExecute({ neoname, seedid }, "v2_creaProject", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let neoProject = datous.payload;
                    this.$store.commit("meteraprop", {
                        cual: "projects",
                        id: neoProject.id,
                        val: neoProject,
                    });
                    this.$f7.dialog.close();
                    this.gotoproject(neoProject.id);
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
        },
    };
</script>
<style>
    .scrldescrp {
        height: 100px;
        max-height: 100px;
        overflow-y: auto;
        border: 1px solid #cecece;
        background: #f7f7f7;
        border-radius: 10px;
    }
    .pronamex {
        background: #00000061;
        width: 100%;
    }
    .projectos-clone {
        max-height: 45vh;
        overflow-y: auto;
    }

    .cor_proyectxs img {
        width: 80px;
    }
    #configjs-input {
        display: none;
    }
</style>
