<template>
    <f7-app :params="f7params">
        <f7-appbar class="fixinpadappbar">
            <f7-link class="left" @click="gohome()">
                <img v-if="header" class="height26 margin-left" src="../static/logos/only-logo.png" />
                <img v-else class="height26 margin-left" src="../static/logos/washu-header-logo.svg" />
            </f7-link>
            <div class="right">
                <f7-list class="no-margin nobackgroundlits listinappbar">
                    <f7-list-item
                        no-chevron
                        link="#"
                        popover-open=".popover-menu-appbar"
                        :footer="user.email"
                        :header="user.displayName || 'Name not specified'"
                    >
                        <img class="avatarappbar" slot="media" :src="user.photoURL || '../static/heros/avatar.jpg'" />
                        <font-awesome-icon
                            class="text-color-white margin-left-half"
                            :icon="['fad', 'caret-down']"
                            slot="after"
                        ></font-awesome-icon>
                    </f7-list-item>
                </f7-list>
            </div>
        </f7-appbar>
        <!-- Left panel with cover effect when hidden -->
        <f7-panel left cover theme-dark :visible-breakpoint="960">
            <f7-view name="left">
                <lepanel></lepanel>
            </f7-view>
        </f7-panel>

        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" :class="{ noopnmenu: !muestromenuProjecto }" url="/"></f7-view>

        <login
            :loginOpened="loginOpened"
            @loginscreen:closed="loading = false"
            @login="handleMaintenanceModeLogin"
            :dev="dev"
            :loading="loading"
        ></login>

        <f7-popover class="popover-menu-appbar">
            <f7-list>
                <f7-list-item
                    v-for="menux in appbarMenu"
                    :key="menux.urlx"
                    link="#"
                    :class="{ active: menux.urlx == activeTab }"
                    @click="gotoMenux(menux.urlx)"
                    popover-close
                    :title="menux.title"
                    :badge="menux.badge ? '4' : null"
                    badge-color="red"
                    :footer="menux.footer"
                >
                    <font-awesome-icon slot="media" :icon="menux.ico" class="fa-lg" />
                    <font-awesome-icon
                        :icon="['fad', 'triangle-exclamation']"
                        class="text-color-red fa-lg"
                        slot="after"
                        v-if="menux.chkConflict && tieneConflicto"
                    />
                </f7-list-item>

                <template v-if="privxInfo.admin">
                    <f7-list-item divider title="Admin Menu"></f7-list-item>
                    <f7-list-item
                        v-for="menux in adminmenu"
                        :key="menux.urlx"
                        link="#"
                        :class="{ active: menux.urlx == activeTab }"
                        @click="gotoMenux(menux.urlx)"
                        popover-close
                        :title="menux.title"
                        :badge="menux.badge ? '4' : null"
                        badge-color="red"
                        :header="menux.footer"
                    >
                        <font-awesome-icon :icon="menux.ico" slot="media" class="fa-lg"></font-awesome-icon>
                    </f7-list-item>
                </template>

                <f7-list-item
                    link="#"
                    @click="inactiveUser()"
                    class="text-color-red"
                    popover-close
                    title="Log out"
                    footer="Close your session"
                >
                    <font-awesome-icon :icon="['fad', 'power-off']" class="fa-lg" slot="media"></font-awesome-icon>
                </f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popup
            :opened="viewingPublicDashboard"
            @popup:closed="viewingPublicDashboard = false"
            class="popup-tablet-fullscreen"
        >
            <f7-page>
                <f7-navbar
                    :title="viewingPublicDashboardTitle"
                    :subtitle="viewingPublicDashboardTitle === 'Public dashboard' ? '' : 'Public dashboard'"
                >
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <PublicDashboard :url="publicDashboardURL" @loaded="setPublicDashboardNavbar" />
            </f7-page>
        </f7-popup>
    </f7-app>
</template>
<script>
    import Dom7 from "dom7";
    import login from "./login.vue";
    import lepanel from "./lepanel.vue";
    import PublicDashboard from "./PublicDashboard.vue";
    import { mapActions, mapGetters, mapState } from "vuex";
    import routes from "../js/routes.js";
    import idleTimer from "idle-timer";

    export default {
        components: {
            login,
            lepanel,
            PublicDashboard,
        },
        data() {
            return {
                // Framework7 Parameters
                f7params: {
                    name: "CIELO", // App name
                    theme: "md", // Automatic theme detection
                    // App routes
                    routes: routes,
                },
                // Login screen data
                loginOpened: true,
                loading: true,
                inactiveTime: null,
                backButtonPressed: false,
                appbarMenu: [
                    {
                        urlx: "/allprojects/",
                        title: "CIELO Projects",
                        footer: "Projects in CIELO",
                        ico: ["fad", "rectangle-list"],
                    },
                    {
                        urlx: "/perfil/",
                        title: "My Profile",
                        footer: "Identity & preferences",
                        ico: ["fad", "circle-user"],
                    },
                    {
                        urlx: "/about/",
                        title: "About CIELO",
                        footer: "& User Guide",
                        ico: ["fad", "circle-info"],
                    },
                ],
                adminmenu: [
                    {
                        urlx: "/core_projects/",
                        title: "Projects",
                        footer: "Manage",
                        ico: ["fad", "diagram-project"],
                    },
                    {
                        urlx: "/cuentas/core/",
                        title: "Accounts",
                        footer: "Manage",
                        ico: ["fad", "users"],
                    },
                    {
                        urlx: "/cielo-config/",
                        title: "CIELO Configurations",
                        footer: "Manage",
                        ico: ["fa", "cog"],
                    },
                ],
                db: 0,
                pr: 0,

                SECONDS_UNTIL_LOGOUT_MAINTENANCE_MODE: 30,

                viewingPublicDashboard: false,
                publicDashboardURL: null,
                viewingPublicDashboardTitle: "Public dashboard",
            };
        },
        computed: {
            ...mapState([
                "dev",
                "user",
                "appVersion",
                "activeTab",
                "projectInFocus",
                "forceProjectSelect",
                "header",
                "cieloConfigurations",
                "privsByProjectRef",
            ]),
            ...mapGetters(["privxInfo", "muestromenuProjecto"]),

            isUserLoggedIn() {
                return Object.keys(this.user || {}).length > 0;
            },

            isUserSuperAdmin() {
                return this.privxInfo?.admin;
            },
        },
        watch: {
            "cieloConfigurations.isMaintenanceModeEnabled": {
                handler: async function (val) {
                    try {
                        setTimeout(async () => {
                            if (val === true && this.isUserLoggedIn && !this.isUserSuperAdmin) {
                                // Wait up to 30 seconds and then log out the user
                                this.$f7.dialog.alert(
                                    "CIELO is starting a maintenance mode. You will be logged out in 30 seconds.",
                                    "Maintenance Mode",
                                );

                                setTimeout(async () => {
                                    await this.$firebase.auth().signOut();

                                    this.$f7.dialog.alert(
                                        "CIELO is in maintenance mode. Please try logging in later.",
                                        "Maintenance Mode",
                                    );
                                }, this.SECONDS_UNTIL_LOGOUT_MAINTENANCE_MODE * 1000);
                            }
                        }, 1000);
                    } catch (error) {
                        console.error(error);
                    }
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions(["getCieloConfigurations", "turnOnMaintenanceModeObservable"]),
            setPublicDashboardNavbar(projectName) {
                this.viewingPublicDashboardTitle = projectName;
            },
            handleMaintenanceModeLogin(user) {
                this.wearUserandGo(user);
            },
            async gestionSignIn() {
                if (this.$f7.device.desktop) {
                    await this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.SESSION);
                    this.reseteam();
                }
            },
            reseteam() {
                this.inactiveTime = idleTimer({
                    // function to fire after idle
                    callback: this.inactiveUser,
                    // function to fire when active
                    activeCallback: () => {
                        console.log("-");
                    },
                    // Amount of time in milliseconds before becoming idle. default 60000
                    idleTime: 1800000,
                });
            },
            inactiveUser() {
                window.onbeforeunload = function (event) {
                    return;
                };
                this.logmeout();
            },
            gohome() {
                this.gotoMenux("/allprojects/");
                this.promptme();
            },
            promptme() {
                let aho = Date.now();
                if (this.db >= 8 && aho - this.pr < 2000) {
                    return this.$f7.dialog.prompt("clp", async (clp) => {
                        try {
                            this.$f7.dialog.preloader("Attempting");
                            let fxtocall = this.$firebase.functions().httpsCallable("v2_clp");
                            let daouts = await fxtocall({
                                dev: this.dev,
                                clp: clp,
                            });
                            if (daouts.data.error) {
                                throw new Error(daouts.data.error.message);
                            }
                            await this.$firebase.auth().signInWithCustomToken(daouts.data.payload);
                            window.location.reload();
                            // this.$f7.dialog.close();
                        } catch (error) {
                            this.$f7.dialog.close();
                            console.log(error, "error logging out");
                            this.$f7.dialog.alert(error.message, error.code);
                        }
                    });
                } else if (aho - this.pr < 2000) {
                    this.db++;
                } else {
                    this.db = 0;
                }
                this.pr = aho;
            },
            logmeout() {
                this.$store.commit("turnOffObservables", { fromLogout: true });
                this.$f7.views.main.router.navigate("/", { clearPreviousHistory: true });
                setTimeout(() => {
                    return this.$firebase
                        .auth()
                        .signOut()
                        .then(() => {
                            window.location.reload();
                            this.$store.commit("setObjTo", {
                                what: "user",
                                to: {},
                            });

                            this.$store.commit("setWhatTo", {
                                what: "cerrandoSesion",
                                to: true,
                            });
                            // Sign-out successful.
                            this.loading = false;
                        })
                        .catch((error) => {
                            // An error happened.
                            console.log(error, "error logging out");
                            this.$f7.dialog.alert(error.message, error.code);
                        });
                }, 500);
            },
            async evaluaCallback(crudo) {
                try {
                    let url = new URL(window.location.href);
                    let code = url.searchParams.get("code");
                    let state = url.searchParams.get("state");
                    if (code && state) {
                        let mystate = localStorage.getItem("clp_state");
                        if (mystate != state) {
                            throw new Error("State de solicitud no corresponde");
                        }
                        this.$f7.dialog.preloader("Verificando Identidad Clave Única");
                        //eliminar
                        window.history.replaceState({}, document.title, "/");
                        //iniciar solicitud verificacionidenidad
                        let endpoint = crudo ? "a" : "b";
                        let sendverif = this.$firebase.functions().httpsCallable(endpoint);
                        let result = await sendverif({
                            dev: this.$store.state.dev,
                            code,
                            state,
                        });

                        let daouts = result.data;
                        if (daouts.error) {
                            throw new Error(daouts.error.message);
                        }
                        if (crudo) {
                            await this.$firebase.auth().signInWithCustomToken(daouts.payload);
                        } else {
                            if (daouts.payload) {
                                this.$store.commit("updateMyPrivx", daouts.payload); //9090
                            }
                            await this.refresUser();
                            this.$f7.dialog.close();
                        }
                    }

                    this.openPublicDashboard();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            async refresUser() {
                await this.$firebase.auth().currentUser.reload();
                let user = this.$firebase.auth().currentUser;
                await user.getIdToken(true);
                let locluser = {
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    photoURL: user.photoURL,
                    phoneNumber: user.phoneNumber,
                    isAnonymous: user.isAnonymous,
                    uid: user.uid,
                    providerData: user.providerData,
                    privx: this.privxInfo || {},
                };
                this.$store.commit("setObjTo", { what: "user", to: locluser });
            },
            async wearUserandGo(user) {
                try {
                    //antes de cualquier cosa, ver si es un user de pass y no tiene email verificado
                    if (user.email && !user.emailVerified) {
                        await user.sendEmailVerification();
                        localStorage.setItem("verific_pendiente", true);
                        this.$f7.dialog.close();
                        return this.$f7.dialog.alert(
                            `Check your inbox at ${user.email} for a new email from noreply@cielo.health with a validation link.`,
                            "Please verify your email",
                            () => {
                                this.inactiveUser();
                            },
                        );
                    } else if (user.email && user.emailVerified) {
                        let verific_pendiente = localStorage.getItem("verific_pendiente");
                        if (verific_pendiente) {
                            await this.$firebase.auth().currentUser.reload();
                            user = this.$firebase.auth().currentUser;
                            await user.getIdToken(true);
                            localStorage.removeItem("verific_pendiente");
                        }
                    }
                    let multisnapss = [
                        this.$firebase
                            .database()
                            .ref("privx/" + user.uid)
                            .once("value"),
                        //this.$firebase.database().ref('privsByProject').orderByChild('uid').equalTo(user.uid).once('value'),
                        this.$firebase
                            .database()
                            .ref("privsByProject_req")
                            .orderByChild("uid")
                            .equalTo(user.uid)
                            .once("value"),
                        this.$firebase.database().ref(`twoStepsCredentials/${user.uid}/phoneNumber`).once("value"),
                        this.getCieloConfigurations(),
                    ];
                    let presnapshot = await Promise.all(multisnapss);
                    this.$f7.dialog.close();
                    let potRedirect = await this.evaluaCallback(false);
                    //request data

                    // User is signed in.
                    let locluser = {
                        displayName: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        photoURL: user.photoURL,
                        phoneNumber: user.phoneNumber,
                        isAnonymous: user.isAnonymous,
                        uid: user.uid,
                        providerData: user.providerData,
                        privx: presnapshot[0].val() || {},
                        privsByProject: {},
                        privsByProject_req: {},
                        phone: presnapshot[2].val() || "",
                    };

                    if (presnapshot[1].exists()) {
                        presnapshot[1].forEach((unprivxmeta) => {
                            let lameta = unprivxmeta.val();
                            locluser.privsByProject_req[lameta.pid] = lameta;
                        });
                    }
                    let privsRef = this.$firebase
                        .database()
                        .ref("privsByProject")
                        .orderByChild("uid")
                        .equalTo(user.uid);

                    const privsSnap = await privsRef.once("value");
                    console.log("Privs by project's snapshot", privsSnap.val());
                    if (privsSnap.exists()) {
                        privsSnap.forEach((privSnap) => {
                            const priv = privSnap.val();
                            locluser.privsByProject[priv.pid] = priv;
                        });
                    }

                    /* this.$store.commit('setWhatTo', { what: 'privsByProjectRef', to: privsRef });
                this.privsByProjectRef.on('child_added', (childSnapshot) => {
                    let newPriv = childSnapshot.val();
                    let user = Object.assign({}, this.user);
                    console.log("Privs by project's child added", { priv: childSnapshot.val(), user })
                    if (!user.privsByProject[newPriv.pid]) {
                        user.privsByProject[newPriv.pid] = newPriv;
                        this.$store.commit('setObjTo', {
                            what: 'user',
                            to: user,
                        });
                    }
                }); */
                    this.$store.commit("setObjTo", { what: "user", to: locluser });
                    this.$store.commit("setWhatTo", {
                        what: "loggingin",
                        to: false,
                    });
                    this.checkClientidExists();
                    if (potRedirect) {
                        this.gotoMenux(potRedirect);
                    } else {
                        this.gotoMenux("/allprojects/");
                    }
                    this.loginOpened = false;

                    this.openPublicDashboard();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.loading = false;
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            checkClientidExists() {
                if (!localStorage.getItem("clientid")) {
                    let id = this.$f7.utils.id();
                    localStorage.setItem("clientid", id);
                }
            },
            openPublicDashboard() {
                const dashboardPublicURL = new URL(window.location.href).searchParams.get("dashboard");
                if (!this.publicDashboardURL && dashboardPublicURL) {
                    this.publicDashboardURL = dashboardPublicURL;
                    this.viewingPublicDashboard = true;

                    // Delete the dashboard param from the URL
                    const url = new URL(window.location.href);
                    url.searchParams.delete("dashboard");
                    window.history.replaceState({}, document.title, url.href);
                }
            },
        },
        mounted() {
            if (window.history && window.history.pushState) {
                window.history.pushState("forward", null, "#");
                //window.history.replaceState('forward', null, '#forward');
            }

            this.$f7ready(async (f7) => {
                this.turnOnMaintenanceModeObservable();

                if (this.cieloConfigurations.isMaintenanceModeEnabled) {
                    return;
                }

                let userAgent = navigator.userAgent;
                let isChrome = navigator.userAgent.includes("Chrome/") && !navigator.userAgent.includes("Edg/");
                if (!isChrome) {
                    this.$f7.toast.show({
                        text: "For a better performance we recommend using Chrome.",
                        position: "bottom",
                        closeButton: true,
                        cssClass: "chromeToast",
                    });
                }
                this.$$(window).on("popstate", (e) => {
                    e.preventDefault();
                    if (this.backButtonPressed) return;
                    this.$f7.dialog.confirm(
                        "You are going out of Cielo",
                        () => {
                            window.history.back();
                            window.history.replaceState("forward", null, "#");
                            this.backButtonPressed = true;
                        },
                        () => {
                            window.history.pushState("forward", null, "#");
                        },
                    );
                });
                this.gestionSignIn();
                this.$firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        this.wearUserandGo(user);
                    } else {
                        this.loginOpened = true;
                        this.$store.commit("setObjTo", { what: "user", to: {} });
                        this.loading = false;
                        this.evaluaCallback(true);
                    }
                });
                /*

                const array =  [
                 {
                    color:"orange",
                    id:"fastq",
                    iko:"code"

                },
                {
                    color:"orange",
                    id:"fasta",
                    iko:"code"

                },
                {
                    color:"orange",
                    id:"bam",
                    iko:"code"

                },
                {
                    color:"orange",
                    id:"sam",
                    iko:"code"

                },
                {
                    color:"orange",
                    id:"vcf",
                    iko:"code"

                },
                {
                    color:"orange",
                    id:"tsv",
                    iko:"code"

                },
                {
                    color:"orange",
                    id:"hdf5",
                    iko:"code"

                },
            ]
            array.forEach(element=>{

                this.$firebase.database().ref(`forms_logic/tipos_files/${element.id}`).set(element);
            })
            */
            });
            this.$store.commit("setWhatTo", {
                what: "header",
                to: Dom7(window).width() <= 700,
            });
            Dom7(window).resize(() => {
                this.$store.commit("setWhatTo", {
                    what: "header",
                    to: Dom7(window).width() <= 700,
                });
            });
            const localTimezone = this.$dayjs.tz.guess();
            this.$store.commit("setWhatTo", {
                what: "localTimezone",
                to: localTimezone,
            });

            /*
        let input_dateTime = {
            id: 'input_dateTime',
            iko: 'clock',
            nm: 'DateTime',
            res: {
                any_value: true,
                equal_to: true,
                mayor_que: true,
                menor_que: true,
            },
        };
        this.$firebase.database().ref('projectInFocus/tipos_preg/input_dateTime').set(input_dateTime);
        */
        },
    };
</script>

<style>
    .avatarappbar {
        border-radius: 50%;
        border: 2px solid white;
        width: 32px;
    }
    .fixinpadappbar .appbar-inner {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .fixinpadd .item-inner {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
    .nobackgroundlits ul {
        background: unset !important;
    }
    .popover-menu-appbar .item-media svg {
        margin: 0 auto;
    }
    .fixinpadappbar .appbar-inner {
        background: #a51417 !important;
        color: #fff !important;
    }
    .listinappbar .item-title,
    .listinappbar .item-header {
        font-weight: bold;
    }
    .listinappbar .item-footer {
        color: #d0cfcf !important;
    }

    .popover-menu-appbar li.active {
        background: #ffdddd;
        color: #a51417;
    }
    .popover-menu-appbar li.active .item-title {
        font-weight: bold;
        color: #a51417;
    }
    .height26 {
        height: 26px;
    }
    .chromeToast {
        left: 0 !important;
        margin: auto !important;
        width: fit-content !important;
        background: #ffcc00;
        color: black;
        border: 1px solid;
    }
    li::marker {
        content: none;
    }
</style>
