import { render, staticRenderFns } from "./queryx.vue?vue&type=template&id=28ca1527"
import script from "./queryx.vue?vue&type=script&lang=js"
export * from "./queryx.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports