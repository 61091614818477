<template>
    <f7-page name="entity" v-if="entity">
        <f7-navbar
            :title="`${projectInFocusInfo.entity || 'entity'}: ${entity.displayid}`"
            :subtitle="projectInFocusInfo.name"
            back-link="Back"
        >
            <f7-nav-right>
                <f7-block>
                    <f7-link @click="popupOpened = true">
                        <font-awesome-icon :icon="['fad', 'clock-rotate-left']"></font-awesome-icon>
                    </f7-link>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>
        <f7-block class="no-margin-top">
            <f7-link v-if="$f7.device.macos" popover-open=".popover-issues-macbook" class="margin-vertical">
                Having trouble uploading files?
            </f7-link>
            <f7-popover class="popover-issues-macbook">
                <f7-block>
                    For Macbook M1 users using Google Chrome, if file upload is not working try the following:
                    <ol>
                        <li>Go to System preferences > Security and privacy > privacy tab</li>
                        <li>Click on the lock to make changes</li>
                        <li>Search for Full disk access</li>
                        <li>Click on the + button</li>
                        <li>Search for Google Chrome and click open</li>
                        <li>Restart Google Chrome</li>
                    </ol>
                </f7-block>
            </f7-popover>

            <f7c-alert v-if="entity.isDeidentified" type="info">
                This entity was
                <b>de-identified</b>
                . You can only view non-sensitive data and only CSV files are shown (including files history).
            </f7c-alert>
            <f7c-alert v-if="entity.inactive" type="warning">
                This {{ projectInFocusInfo.entity || "entity" }} is inactive. Read-only access enabled.
            </f7c-alert>
            <f7c-alert v-else-if="!tengoupdateprivileges" type="warning">
                You do not have
                <b>write</b>
                privileges to edit this {{ projectInFocusInfo.entity || "entity" }}
            </f7c-alert>

            <f7-row>
                <f7-col width="100" medium="50">
                    <f7-block-title>Data</f7-block-title>

                    <f7-list>
                        <f7-list-item
                            :header="projectInFocusInfo.entity || 'entity'"
                            :title="entity.displayid"
                        ></f7-list-item>
                        <f7-list-item header="Origin" :title="getEntityInstitutionName(entity)"></f7-list-item>
                        <f7-list-item
                            v-for="entityData in activeEntityData"
                            v-show="!entityData.depde || canBeShown(entity.entityData, entityData.depde)"
                            :key="entityData.id"
                            :header="entityData.encabezado"
                        >
                            <template>
                                <f7-preloader
                                    class="margin-right-half"
                                    v-if="entityToModify && entityToModify.id == entityData.id"
                                    slot="after"
                                ></f7-preloader>
                                <div v-else slot="after">
                                    <f7-link
                                        class="text-color-red"
                                        v-if="requiredNotAnswered(entityData)"
                                        @click="abreeditor(entityData)"
                                        :tooltip="requiredNotAnswered(entityData)"
                                    >
                                        <font-awesome-icon
                                            :icon="['fad', 'triangle-exclamation']"
                                            class="fa-lg"
                                        ></font-awesome-icon>
                                    </f7-link>
                                    <f7-button v-else-if="canedit" @click="abreeditor(entityData)">
                                        <font-awesome-icon color="gray" :icon="['fad', 'pen']"></font-awesome-icon>
                                    </f7-button>
                                </div>
                                <div slot="title">
                                    <f7-link
                                        v-if="entityData.tipo === 'input_link'"
                                        :href="visualizeAnswer(entityData.id)"
                                        external
                                        target="_blank"
                                        class="no-ripple"
                                    >
                                        {{ visualizeAnswer(entityData.id) }}
                                    </f7-link>
                                    <span v-else>
                                        {{ visualizeAnswer(entityData.id) }}
                                    </span>
                                </div>
                            </template>
                        </f7-list-item>
                    </f7-list>
                    <div v-if="userCanDownloadEntityBundle(entity)">
                        <template v-if="!bundlelink">
                            <f7-block-title>
                                Download
                                {{ projectInFocusInfo.entity || "entity" }}
                                Bundle
                                <font-awesome-icon
                                    class="fa-lg margin-left"
                                    :icon="['fad', 'cloud-arrow-down']"
                                ></font-awesome-icon>
                            </f7-block-title>
                            <f7-block>
                                <f7-button
                                    v-if="userCanDownloadNonDeidentifiedBundles"
                                    :disabled="downloadBundleDisabledButton"
                                    class="margin-bottom"
                                    @click="genbundlelink()"
                                    medium
                                    fill
                                >
                                    <font-awesome-icon :icon="['fad', 'file-zipper']"></font-awesome-icon>
                                    Download bundle
                                </f7-button>
                                <f7-button
                                    v-if="userCanDownloadDeidentifiedBundles"
                                    :disabled="downloadBundleDisabledButton"
                                    class="margin-bottom"
                                    @click="genbundlelink(true)"
                                    medium
                                    fill
                                >
                                    <font-awesome-icon :icon="['fad', 'file-zipper']"></font-awesome-icon>
                                    Download de-identified bundle
                                </f7-button>
                                <f7-list v-if="false && userHasPHIPrivilege(entity.instid)" class="no-margin">
                                    <f7-list-item
                                        class="phi-checkbox"
                                        checkbox
                                        :checked="downloadPHIInfo"
                                        @change="downloadPHIInfo = $event.target.checked"
                                        title="Include PHI (Personal Health Information)"
                                    ></f7-list-item>
                                </f7-list>
                            </f7-block>
                        </template>
                        <f7-block v-else>
                            <f7-link external target="_blank" :href="bundlelink">
                                <font-awesome-icon
                                    class="fa-lg margin-right"
                                    :icon="['fad', 'cloud-arrow-down']"
                                ></font-awesome-icon>
                                Download bundle.zip
                            </f7-link>
                        </f7-block>
                    </div>

                    <f7-block v-if="tengoupdateprivileges">
                        <f7-button v-if="!entity.inactive" @click="preinactiva('pre')" color="red" outline>
                            <font-awesome-icon class="fa-lg" :icon="['fad', 'eye-slash']"></font-awesome-icon>
                            Inactivate
                            {{ projectInFocusInfo.entity || "entity" }}
                        </f7-button>
                        <f7-button v-else @click="preinactiva(null)" color="purple" outline>
                            <font-awesome-icon class="fa-lg" :icon="['fad', 'eye']"></font-awesome-icon>
                            Reactivate
                            {{ projectInFocusInfo.entity || "entity" }}
                        </f7-button>
                        <f7-block v-if="entity.inactive == 'pre'">
                            <f7-button @click="preinactiva('full')" color="red">
                                <font-awesome-icon class="fa-lg" :icon="['fad', 'trash-can']"></font-awesome-icon>
                                Delete
                                {{ projectInFocusInfo.entity || "entity" }}
                            </f7-button>
                        </f7-block>
                    </f7-block>
                </f7-col>
                <f7-col width="100" medium="50">
                    <f7-block-title>Files</f7-block-title>
                    <f7-block v-if="!activeFiles.length">No files</f7-block>
                    <div v-for="preg in activeFiles" :key="preg.id">
                        <f7-card v-if="!preg.depde || canBeShown(projectInFocusInfo.entityFiles, preg.depde)">
                            <f7-row class="padding-vertical-half backblulight">
                                <f7-col width="70">
                                    <f7-block>
                                        <f7-link
                                            popover-open=".popover-fileHistory"
                                            @click="fileHistoryInFocus = preg.id"
                                            v-if="projectInFocusInfo.history && userCanDownloadEntityBundle(entity)"
                                        >
                                            <font-awesome-icon :icon="['fad', 'clock-rotate-left']"></font-awesome-icon>
                                        </f7-link>
                                        <span v-html="`${preg.encabezado}`"></span>
                                        <small>
                                            [
                                            <span
                                                :class="{
                                                    'text-color-red': preg.req,
                                                }"
                                            >
                                                {{ preg.req ? "Required" : "Optional" }}
                                            </span>
                                            ,
                                            {{ preg.many ? "Multiple files allowed" : "Only one file allowed" }}
                                            ]
                                        </small>
                                    </f7-block>
                                </f7-col>
                                <f7-col width="30" class="text-align-right">
                                    <f7-block v-if="canedit">
                                        <f7-preloader
                                            size="20"
                                            class="margin-right-half"
                                            v-if="fileToHandle && fileToHandle.uniqueId == preg.id"
                                        ></f7-preloader>
                                        <template
                                            v-else-if="!entity.entityFiles || !entity.entityFiles[preg.id] || preg.many"
                                        >
                                            <input
                                                v-if="refreshFiletoadd"
                                                type="file"
                                                @mouseover="openTooltip({ add: true, id: preg.id })"
                                                :class="`file-input icon-tooltip${preg.id}`"
                                                @change="setUploadFocus(preg, $event.target.files[0])"
                                                :accept="preg.tipo == 'a' ? null : `.${preg.tipo}`"
                                            />
                                            <f7-link
                                                tooltip="Add new file"
                                                :class="{
                                                    disabled: uploadFocus.itemid,
                                                }"
                                                color="cielo"
                                                class="position-absolute"
                                            >
                                                <font-awesome-icon
                                                    class="fa-lg"
                                                    :icon="['fad', 'folder-plus']"
                                                ></font-awesome-icon>
                                            </f7-link>
                                        </template>
                                        <template v-else-if="entity.entityFiles[preg.id] && !preg.many">
                                            <input
                                                v-if="refreshFiletoadd"
                                                type="file"
                                                @mouseover="openTooltip({ add: false, id: preg.id })"
                                                :class="`file-input icon-tooltip${preg.id}`"
                                                @change="setUploadFocus(preg, $event.target.files[0])"
                                                :accept="preg.tipo == 'a' ? null : `.${preg.tipo}`"
                                            />
                                            <f7-link
                                                tooltip="Replace file"
                                                :class="{
                                                    disabled: uploadFocus.itemid,
                                                }"
                                                color="cielo"
                                                class="position-absolute"
                                            >
                                                <font-awesome-icon
                                                    class="fa-lg"
                                                    :icon="['fad', 'file-import']"
                                                ></font-awesome-icon>
                                            </f7-link>
                                        </template>
                                    </f7-block>
                                </f7-col>
                            </f7-row>
                            <f7-card-content>
                                <LockedContentPHI v-if="false && !userHasPHIPrivilege(entity.instid) && !preg.notPhi" />
                                <f7-list v-else class="fixfamarg" media-list>
                                    <f7-list-item v-if="uploadFocus.itemid == preg.id" :title="uploadFocus.file.name">
                                        <f7-progressbar
                                            v-if="!processing"
                                            slot="subtitle"
                                            :progress="progress"
                                        ></f7-progressbar>
                                        <f7-progressbar v-else slot="subtitle" infinite></f7-progressbar>
                                        <f7-preloader slot="media"></f7-preloader>
                                        <f7-chip slot="after" outline :text="`${progress}%`"></f7-chip>
                                    </f7-list-item>
                                    <f7-list-item
                                        class="text-color-gray"
                                        v-else-if="
                                            uploadFocus.itemid != preg.id &&
                                            (!entity.entityFiles || !entity.entityFiles[preg.id])
                                        "
                                    >
                                        No
                                        {{ preg.tipo == "a" ? "file" : preg.tipo }}
                                        uploaded
                                    </f7-list-item>
                                    <f7-list-item
                                        v-else-if="!preg.many"
                                        :style="
                                            makeSureIsOneFile(preg.id)
                                                ? ''
                                                : entity.entityFiles[preg.id].hiddenDueToPHI
                                                ? ''
                                                : 'color:red'
                                        "
                                        :title="
                                            makeSureIsOneFile(preg.id)
                                                ? entity.entityFiles[preg.id].name
                                                : entity.entityFiles[preg.id].hiddenDueToPHI
                                                ? 'No access permission to view file'
                                                : 'File error, please upload again'
                                        "
                                    >
                                        <f7-preloader
                                            size="20"
                                            class="margin-right-half"
                                            v-if="
                                                fileToHandle &&
                                                fileToHandle.uniqueId == `${preg.id}${entity.entityFiles[preg.id].id}`
                                            "
                                            slot="after"
                                        ></f7-preloader>
                                        <span v-else-if="entity.entityFiles[preg.id]" slot="after">
                                            <f7-link
                                                v-if="userCanWriteEntity(entity) && !preg.req"
                                                color="gray"
                                                tooltip="delete file"
                                                @click="deletefile(preg, entity.entityFiles[preg.id])"
                                                class="margin-right"
                                            >
                                                <font-awesome-icon
                                                    class="fa-lg"
                                                    :icon="['fad', 'trash-can']"
                                                ></font-awesome-icon>
                                            </f7-link>
                                            <f7-link
                                                v-if="
                                                    !genURLs[entity.entityFiles[preg.id].id] &&
                                                    makeSureIsOneFile(preg.id) &&
                                                    userCanDownloadSingleFiles
                                                "
                                                tooltip="download"
                                                color="teal"
                                                @click="getDownloadUrl(preg, null, entity.entityFiles[preg.id].name)"
                                            >
                                                <font-awesome-icon
                                                    class="fa-lg"
                                                    :icon="['fad', 'cloud-arrow-down']"
                                                ></font-awesome-icon>
                                            </f7-link>
                                        </span>

                                        <div
                                            v-if="
                                                entity.entityFiles[preg.id] &&
                                                !entity.entityFiles[preg.id].hiddenDueToPHI
                                            "
                                            class="text-align-center fullwidth"
                                            slot="media"
                                        >
                                            <font-awesome-icon
                                                :icon="forms_logic.tipos_files[preg.tipo].iko"
                                                :class="`fa-lg text-color-${
                                                    forms_logic.tipos_files[preg.tipo].color || 'deeppurple'
                                                }`"
                                                slot="media"
                                            ></font-awesome-icon>
                                            <br />
                                            {{ preg.tipo == "a" ? "any" : preg.tipo }}
                                        </div>
                                        <f7-link
                                            external
                                            target="_blank"
                                            v-if="
                                                entity.entityFiles[preg.id] && genURLs[entity.entityFiles[preg.id].id]
                                            "
                                            :href="genURLs[entity.entityFiles[preg.id].id]"
                                            slot="text"
                                        >
                                            DOWNLOAD LINK
                                        </f7-link>
                                        <div
                                            v-if="
                                                entity.entityFiles[preg.id] &&
                                                !entity.entityFiles[preg.id].hiddenDueToPHI
                                            "
                                            slot="footer"
                                        >
                                            <div>
                                                {{
                                                    `${entity.entityFiles[preg.id].bulkName || ""} | ${
                                                        entity.entityFiles[preg.id].size >= 1024
                                                            ? (entity.entityFiles[preg.id].size / 1024).toFixed(2) +
                                                              " KB"
                                                            : entity.entityFiles[preg.id].size + " bytes"
                                                    } | Uploaded at ${$dayjs(entity.entityFiles[preg.id].at).format(
                                                        "HH:mm:ss [on] MM/DD/YYYY",
                                                    )}`
                                                }}
                                            </div>
                                            <div class="margin-top">
                                                <f7-progressbar infinite v-if="loadingTags == preg.id"></f7-progressbar>
                                                <span v-else class="bold">{{ getEntityTags(preg.id) }}</span>
                                                <f7-link
                                                    :class="!tags.length ? 'disabled' : ''"
                                                    v-if="userCanWriteEntity(entity) && makeSureIsOneFile(preg.id)"
                                                    popover-open=".tagsPopover"
                                                    @click="setFileidForAddingTags(preg.id)"
                                                    class="float-right"
                                                >
                                                    {{ tags.length ? "Tags" : "No Tags created or active" }}
                                                    &nbsp;
                                                    <font-awesome-icon color="orange" :icon="['fad', 'tags']" />
                                                </f7-link>
                                            </div>
                                            <f7-link
                                                v-if="
                                                    userCanWriteEntity(entity) && isFileCSV(entity.entityFiles[preg.id])
                                                "
                                                @click="
                                                    changeCSVFileMultipleDataStartRow(
                                                        preg.id,
                                                        entity.entityFiles[preg.id],
                                                    )
                                                "
                                                class="margin-top-half"
                                            >
                                                <span
                                                    v-if="
                                                        entity.entityFiles[preg.id].startingDataRow &&
                                                        entity.entityFiles[preg.id].startingDataRow !==
                                                            DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW
                                                    "
                                                >
                                                    Change starting data row
                                                </span>
                                                <span v-else>Mark as multiple header CSV</span>
                                                <b
                                                    v-if="
                                                        entity.entityFiles[preg.id].startingDataRow &&
                                                        entity.entityFiles[preg.id].startingDataRow !==
                                                            DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW
                                                    "
                                                    class="margin-horizontal-half"
                                                >
                                                    (current: {{ entity.entityFiles[preg.id].startingDataRow }})
                                                </b>
                                            </f7-link>
                                        </div>
                                    </f7-list-item>
                                    <template v-else-if="preg.many">
                                        <f7-list-item
                                            v-for="unyp in sortedFiles(entity.entityFiles[preg.id])"
                                            :key="unyp.id"
                                            :title="
                                                unyp.hiddenDueToPHI ? 'No access permission to view file' : unyp.name
                                            "
                                        >
                                            <div slot="footer" v-if="!unyp.hiddenDueToPHI">
                                                <div>
                                                    {{
                                                        `${unyp.bulkName || ""} | ${
                                                            unyp.size >= 1024
                                                                ? (unyp.size / 1024).toFixed(2) + " KB"
                                                                : unyp.size + " bytes"
                                                        } | Uploaded at ${$dayjs(unyp.at).format(
                                                            "HH:mm:ss [on] MM/DD/YYYY",
                                                        )}`
                                                    }}
                                                </div>
                                                <div class="margin-top">
                                                    <f7-progressbar
                                                        infinite
                                                        v-if="loadingTags == unyp.id"
                                                    ></f7-progressbar>
                                                    <span v-else class="bold">
                                                        {{ getEntityTags(preg.id, unyp.id) }}
                                                    </span>
                                                    <f7-link
                                                        v-if="userCanWriteEntity(entity)"
                                                        tooltip="Select tags"
                                                        :class="!tags.length ? 'disabled' : ''"
                                                        popover-open=".tagsPopover"
                                                        @click="setFileidForAddingTags(preg.id, unyp.id)"
                                                        class="float-right"
                                                    >
                                                        {{ tags.length ? "Tags" : "No Tags created or active" }}
                                                        &nbsp;
                                                        <font-awesome-icon color="orange" :icon="['fad', 'tags']" />
                                                    </f7-link>
                                                </div>
                                                <f7-link
                                                    v-if="userCanWriteEntity(entity) && isFileCSV(unyp)"
                                                    @click="changeCSVFileMultipleDataStartRow(preg.id, unyp)"
                                                    class="margin-top-half"
                                                >
                                                    <span
                                                        v-if="
                                                            unyp.startingDataRow &&
                                                            unyp.startingDataRow !==
                                                                DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW
                                                        "
                                                    >
                                                        Change starting data row
                                                    </span>
                                                    <span v-else>Mark as multiple header CSV</span>
                                                    <b
                                                        v-if="
                                                            unyp.startingDataRow &&
                                                            unyp.startingDataRow !==
                                                                DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW
                                                        "
                                                        class="margin-horizontal-half"
                                                    >
                                                        (current: {{ unyp.startingDataRow }})
                                                    </b>
                                                </f7-link>
                                            </div>
                                            <f7-preloader
                                                size="20"
                                                class="margin-right-half"
                                                v-if="fileToHandle && fileToHandle.uniqueId == `${preg.id}${unyp.id}`"
                                                slot="after"
                                            ></f7-preloader>
                                            <span slot="after" v-else-if="!unyp.hiddenDueToPHI">
                                                <f7-link
                                                    v-if="
                                                        canedit &&
                                                        (!preg.req ||
                                                            Object.values(entity.entityFiles[preg.id]).length > 1)
                                                    "
                                                    color="gray"
                                                    tooltip="delete file"
                                                    @click="deletefile(preg, unyp)"
                                                    class="margin-right"
                                                >
                                                    <font-awesome-icon
                                                        class="fa-lg"
                                                        :icon="['fad', 'trash-can']"
                                                    ></font-awesome-icon>
                                                </f7-link>
                                                <f7-link
                                                    v-if="!genURLs[unyp.id] && userCanDownloadSingleFiles"
                                                    color="teal"
                                                    tooltip="download"
                                                    @click="getDownloadUrl(preg, unyp.id, unyp.name)"
                                                >
                                                    <font-awesome-icon
                                                        class="fa-lg"
                                                        :icon="['fad', 'cloud-arrow-down']"
                                                    ></font-awesome-icon>
                                                </f7-link>
                                            </span>
                                            <div
                                                class="text-align-center fullwidth"
                                                v-if="!unyp.hiddenDueToPHI"
                                                slot="media"
                                            >
                                                <font-awesome-icon
                                                    :icon="forms_logic.tipos_files[preg.tipo].iko"
                                                    :class="`fa-lg text-color-${
                                                        forms_logic.tipos_files[preg.tipo].color || 'deeppurple'
                                                    }`"
                                                    slot="media"
                                                ></font-awesome-icon>
                                                <br />
                                                {{ preg.tipo == "a" ? "any" : preg.tipo }}
                                            </div>
                                            <f7-link
                                                external
                                                target="_blank"
                                                v-if="genURLs[unyp.id]"
                                                :href="genURLs[unyp.id]"
                                                slot="text"
                                            >
                                                DOWNLOAD LINK
                                            </f7-link>
                                        </f7-list-item>
                                    </template>
                                </f7-list>
                            </f7-card-content>
                        </f7-card>
                    </div>
                    <f7-popover
                        class="tagsPopover"
                        @popover:opened="selectedTagsPopover = true"
                        @popover:closed="closeSearchbar()"
                    >
                        <template v-if="!tags.length">
                            <f7-block>
                                <span>There are no tags created or actives</span>

                                <f7-link class="topLeft5" popover-close=".tagsPopover">
                                    <font-awesome-icon :icon="['fad', 'circle-xmark']" class="fa-lg" />
                                </f7-link>
                            </f7-block>
                        </template>
                        <template v-else>
                            <f7-navbar title="Tags">
                                <f7-nav-right><f7-link popover-close=".tagsPopover">Close</f7-link></f7-nav-right>
                            </f7-navbar>
                            <template>
                                <f7-searchbar
                                    ref="tagSearchbar"
                                    search-container=".searchbar-tags"
                                    search-in=".item-title"
                                    placeholder="Search"
                                ></f7-searchbar>
                            </template>
                            <f7-list class="searchbar-not-found margin-top">
                                <f7-list-item title="Nothing found"></f7-list-item>
                            </f7-list>
                            <f7-list class="searchbar-tags tagsList margin-top">
                                <f7-list-item
                                    v-for="tag in tags"
                                    :key="tag.id"
                                    checkbox
                                    :disabled="loadingCheckboxes.includes(tag.id)"
                                    :title="tag.tx"
                                    :value="tag.id"
                                    :checked="selectedTags[tag.id]"
                                    @change="selectTag($event.target.checked, tag)"
                                ></f7-list-item>
                            </f7-list>
                        </template>
                    </f7-popover>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
            <log-popup
                v-if="popupOpened"
                indice="a"
                :projectid="projectInFocusInfo.id"
                :entity="entity"
                :valor="`${projectInFocusInfo.id}/${entity.id}`"
            ></log-popup>
        </f7-popup>

        <f7-popup class="modify-entity-popup" :opened="!!entityToModify" @popup:closed="entityToModify = null">
            <modify-entity
                v-if="entityToModify"
                @procesaCambio="procesCambioEntity"
                :projectID="projectInFocusInfo.id"
                :preg="entityToModify"
                :entity="entity"
            ></modify-entity>
        </f7-popup>

        <f7-popover
            class="popover-fileHistory"
            @popover:open="requestFileHistory()"
            @popover:closed="fileHistoryInFocus = null"
        >
            <f7-list>
                <f7-list-item divider title="Previous Versions"></f7-list-item>
                <f7-list-item v-if="pidiendoHistoria" title="Loading history...">
                    <f7-preloader slot="after"></f7-preloader>
                </f7-list-item>
                <f7-list-item
                    v-for="(file, index) in currenFileHistory"
                    :key="index"
                    link="#"
                    @click="downloadFileHistory(file.auditId)"
                    :title="file.name"
                    :footer="`${file.replacedAt ? 'Replaced' : 'Deleted'} on ${$dayjs
                        .unix(file.replacedAt || file.deletedAt)
                        .format('DD MMMM YYYY [at] HH:mm')}`"
                ></f7-list-item>
                <f7-list-item
                    v-if="!pidiendoHistoria && !currenFileHistory.length"
                    title="There are no previous versions"
                ></f7-list-item>
            </f7-list>
        </f7-popover>
    </f7-page>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import logPopup from "../components/logPopup.vue";
    import LockedContentPHI from "../components/LockedContentPHI.vue";
    import modifyEntity from "../components/modify_entity.vue";
    import { commonmixin } from "../mixins/common";
    export default {
        mixins: [commonmixin],
        props: ["index", "entity"],
        components: { logPopup, modifyEntity, LockedContentPHI },
        data() {
            return {
                fileToHandle: null,
                guardandoeste: null,
                fileHistoryInFocus: null,
                currenFileHistory: [],
                pidiendoHistoria: false,
                popupOpened: false,
                entityToModify: null,
                uploadMeta: {},
                progress: 0,
                processing: false,
                task: null,
                uploadFocus: { type: ".pdf", file: {}, itemid: null },
                genURLs: {},
                bundlelink: null,
                selectedTags: {},
                fileidforAddingTags: null,
                loadingTags: false,
                loadingCheckboxes: [],
                selectedTagsPopover: false,
                bulkName: "",
                refreshFiletoadd: true,
                unix: null,
                downloadPHIInfo: true,
                downloadBundleDisabledButton: false,
                DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW: 2,
                filesTooltip: null,
            };
        },
        computed: {
            ...mapState(["user", "dev", "forms_logic", "projectInFocus", "projects", "allentities"]),
            ...mapGetters(["projectInFocusInfo", "userPrivsSelectedProject", "userHasPrivileges"]),
            activeEntityData() {
                return this.projectInFocusInfo?.entityData ? this.onlyActive(this.projectInFocusInfo?.entityData) : [];
            },
            activeFiles() {
                return this.projectInFocusInfo?.entityFiles
                    ? this.onlyActive(this.projectInFocusInfo?.entityFiles)
                    : [];
            },
            myPrivsHere() {
                let allprivs = this.user?.privsByProject || {};
                return allprivs?.[this.$store.state.projectInFocus] || {};
            },
            isUserProjectAdminOrSuperadmin() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["project-admin"],
                });
            },
            isEntityFromMyInstitution() {
                const myInstitutionId = Object.keys(this.userPrivsSelectedProject?.prv || {})[0] || null;

                if (!myInstitutionId) {
                    return false;
                }

                return this.entity?.instid === myInstitutionId;
            },

            tengoupdateprivileges() {
                const privilegesOptions = {};

                if (this.isEntityFromMyInstitution) {
                    privilegesOptions.requiredInstitutionPrivs = ["create"];
                } else {
                    privilegesOptions.requiredAdminPrivs = ["full"];
                }

                return this.userHasPrivileges(privilegesOptions);
            },
            canedit() {
                return this.tengoupdateprivileges && !this.entity?.inactive;
            },
            tags() {
                let tags = Object.values(this.projectInFocusInfo?.fileTags || {}) || [];
                return tags
                    .filter((tag) => {
                        return !tag.inct;
                    })
                    .sort((a, b) => {
                        let alow = a.tx || a.tx;
                        let blow = b.tx || b.tx;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            userHasDownloadPrivilegeInInstitution() {
                return this.userHasPrivileges({
                    requiredInstitutionPrivs: ["download"],
                });
            },
            userHasDownloadPrivilegeInProject() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["download"],
                });
            },
            userCanDownloadDeidentifiedBundles() {
                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    return false;
                }

                return true;
            },
            userCanDownloadNonDeidentifiedBundles() {
                if (this.projectInFocusInfo.isDeidentificationEnabled) {
                    let isUserAllowedToSeePHIInEntity = false;

                    if (this.isEntityFromMyInstitution) {
                        isUserAllowedToSeePHIInEntity = this.userHasPrivileges({ requiredInstitutionPrivs: ["phi"] });
                    } else {
                        isUserAllowedToSeePHIInEntity = this.userHasPrivileges({ requiredAdminPrivs: ["phi"] });
                    }

                    return isUserAllowedToSeePHIInEntity;
                }

                return true;
            },
            userCanDownloadSingleFiles() {
                if (!this.userCanDownloadEntityBundle(this.entity)) {
                    console.log("User cannot download entity bundle");
                    return false;
                }

                return true;
            },
        },
        mounted() {
            if (this.entity) this.openedEntitySetNewLog();
            if (this.entity && !this.userHasPHIPrivilege(this.entity.instid)) {
                this.downloadPHIInfo = false;
            }
        },
        methods: {
            openTooltip({ add, id }) {
                const targetEl = `.icon-tooltip${id}`;
                this.filesTooltip = this.$f7.tooltip
                    .create({
                        targetEl,
                        text: add ? "Add new file" : "Replace file",
                    })
                    .show();
            },
            closeTooltip() {
                if (this.filesTooltip) {
                    this.filesTooltip.destroy();
                    this.filesTooltip = null;
                }
            },
            checkIfNoPHIPrivsToSeeFile(pregId) {},
            async updateEntityFileStartingDataRow(entityId, fileFieldId, fileId, startingDataRowString) {
                const startingDataRow = parseInt(startingDataRowString);

                try {
                    this.$f7.dialog.preloader("Updating CSV file starting data row...");
                    const res = await this.commonExecute(
                        {
                            projectId: this.projectInFocus,
                            entityId,
                            fileFieldId,
                            fileId,
                            startingDataRow,
                        },
                        "v2_updateEntityFileStartingDataRow",
                    );

                    if (res.error) {
                        console.error(res.error);
                        throw new Error(res.error.message);
                    }

                    // todo: Update entity locally
                    const entityFile = this.entity.entityFiles[fileFieldId].id
                        ? this.entity.entityFiles[fileFieldId]
                        : this.entity.entityFiles[fileFieldId][fileId];
                    this.$set(entityFile, "startingDataRow", startingDataRow);

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message, "There was an error updating the CSV file starting data row");
                }
            },
            changeCSVFileMultipleDataStartRow(fileFieldId, ef) {
                this.$f7.dialog.prompt(
                    `Enter the row number where the actual data start (after headers) (default: ${this.DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW})`,
                    (startingDataRow) => {
                        this.updateEntityFileStartingDataRow(this.entity.id, fileFieldId, ef.id, startingDataRow);
                    },
                    () => {},
                    ef?.startingDataRow || this.DEFAULT_CSV_FILE_MULTIPLE_HEADERS_DATA_START_ROW,
                );
            },
            isFileCSV(file) {
                const fileNameIncludesCSV = (file?.name || file?.bulkName)?.toLowerCase().split(".").pop() === "csv";

                if (fileNameIncludesCSV) {
                    return true;
                }

                return file.url.toLowerCase().split(".").pop() === "csv";
            },
            getEntityInstitutionName(entity) {
                if (entity.isDeidentified) {
                    const institution = this.projectInFocusInfo?.institutions?.[entity?.instid] || {};

                    if (institution) {
                        return institution?.name || entity?.instid;
                    } else {
                        return entity?.instid;
                    }
                }

                const institution = this.projectInFocusInfo?.institutions?.[entity?.instid] || {};
                return institution?.name || entity?.instid;
            },
            async openedEntitySetNewLog() {
                let call = this.$firebase.functions().httpsCallable("v2_openEntitySetNewLog");
                try {
                    call({ projectid: this.projectInFocus, dev: this.$store.state.dev, entityId: this.entity.id });
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            userCanDownloadEntityBundle(entity) {
                if (this.isEntityFromMyInstitution) {
                    return this.userHasDownloadPrivilegeInInstitution;
                } else {
                    return this.userHasDownloadPrivilegeInProject;
                }
            },
            userCanWriteEntity(entity) {
                if (this.userHasPrivileges({ requiredAdminPrivs: ["full"] })) {
                    return true;
                }

                const myInstitutionId = Object.keys(this.userPrivsSelectedProject?.prv || {})[0] || null;

                if (!myInstitutionId) {
                    return false;
                } else if (myInstitutionId === entity?.instid) {
                    return this.userHasPrivileges({ requiredInstitutionPrivs: ["create"] });
                }

                return false;
            },
            userHasPHIPrivilege(institutionId) {
                if (this.myPrivsHere.admin && (this.myPrivsHere.admin.phi || this.myPrivsHere.admin["project-admin"])) {
                    return true;
                }

                const institutionPrivileges = Object.keys(this.myPrivsHere?.prv || {});

                if (institutionPrivileges.includes(institutionId)) {
                    return true;
                }

                return false;
            },
            async downloadFileHistory(auditId) {
                try {
                    this.$f7.dialog.preloader("Generating download...");
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entityid: this.entity.id,
                            institutionid: this.entity.instid,
                            itemid: this.fileHistoryInFocus,
                            auditId,
                        },
                        "v2_getPrevVersions",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let fileinfo = datous.payload;
                    this.openDownloadedFile({
                        downloadname: fileinfo.name,
                        url: fileinfo.ulr,
                    });
                    this.$f7.popover.close(".popover-fileHistory", true);
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            async requestFileHistory() {
                try {
                    this.pidiendoHistoria = true;
                    this.currenFileHistory = [];
                    await this.timeouter(200);
                    //getPrevVersions
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entityid: this.entity.id,
                            institutionid: this.entity.instid,
                            itemid: this.fileHistoryInFocus,
                            fileid: null,
                        },
                        "v2_getPrevVersions",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let modificacions = Object.values(datous.payload.history || {});
                    modificacions.sort((a, b) => {
                        let alow = a.replacedAt || a.deletedAt;
                        let blow = b.replacedAt || b.deletedAt;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    this.currenFileHistory = modificacions;
                    this.pidiendoHistoria = false;
                } catch (error) {
                    this.pidiendoHistoria = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            preinactiva(aque) {
                if (!aque) {
                    this.inactivaActiva(aque);
                } else if (aque == "pre") {
                    return this.$f7.dialog.confirm(
                        "Inactive entities do not appear in the project data page and are not considered for statistics. Data is still preserved and can be reactivated.",
                        "Please confirm",
                        () => {
                            this.inactivaActiva(aque);
                        },
                    );
                } else if (aque == "full") {
                    return this.$f7.dialog.confirm(
                        "Deleted entities are not recoverable. The entity ID will be released and could be used by any researcher.",
                        "Please confirm",
                        () => {
                            this.inactivaActiva(aque);
                        },
                    );
                }
            },
            async inactivaActiva(aque) {
                try {
                    this.$f7.dialog.preloader(
                        !aque ? "Reactivating..." : aque == "pre" ? "Inactivating..." : "Deleting...",
                    );
                    let supayload = {
                        projectid: this.projectInFocusInfo.id,
                        entityid: this.entity.id,
                        institutionid: this.entity.instid,
                        neovalue: aque,
                    };
                    let datous = await this.commonExecute(supayload, "v2_editActiveStatusEntity", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    this.$f7.dialog.close();
                    this.avisaOk();
                    this.$set(this.entity, "inactive", supayload.neovalue);
                    if (this.index > -1) {
                        let local = this.allentities;
                        aque === "full" ? local.splice(this.index, 1) : (local[this.index] = this.entity);
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: [],
                        });
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: local,
                        });
                    }
                    if (aque === "full") {
                        this.$f7.views.main.router.back();
                    }
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            async genbundlelink(deidentify = false) {
                this.downloadBundleDisabledButton = true;
                this.$f7.dialog.preloader("Generating ZIP file...");
                try {
                    // PHI Download has been temporarily disabled
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entities: [this.entity.id],
                            institutionid: this.entity.instid,
                            downloadPHIInfo: true,
                            deidentify,
                        },
                        "v2_generaetMultipleZip",
                        false,
                    );
                    console.log("Generated bundle link", datous);
                    if (datous.error) {
                        throw new Error(datous.error);
                    }

                    this.$f7.dialog.close();

                    if (datous.payload) {
                        window.location.href = datous.payload;
                    } else {
                        this.$f7.dialog.alert(
                            "The ZIP file is being generated. You will be notified via email when it is ready.",
                        );
                    }

                    this.downloadBundleDisabledButton = false;
                } catch (error) {
                    this.downloadBundleDisabledButton = false;
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            async deletefile(preg, file) {
                let fileid = file.id;
                this.$f7.dialog.confirm(`Please confirm you want to delete ${file.name}`, "Are you sure?", async () => {
                    // this.$f7.dialog.preloader('Deleting file...');
                    this.fileToHandle = Object.assign({}, file);
                    this.fileToHandle.uniqueId = `${preg.id}${fileid}`;
                    try {
                        let datous = await this.commonExecute(
                            {
                                projectid: this.projectInFocusInfo.id,
                                entityid: this.entity.id,
                                institutionid: this.entity.instid,
                                fileid,
                                itemid: preg.id,
                            },
                            "v2_removefile",
                            false,
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        if (this.entity.entityFiles[preg.id][fileid]) {
                            this.$delete(this.entity.entityFiles[preg.id], fileid);
                            if (Object.values(this.entity.entityFiles[preg.id]).length == 0) {
                                this.$delete(this.entity.entityFiles, preg.id);
                            }
                        } else {
                            this.$delete(this.entity.entityFiles, preg.id);
                        }
                        if (this.index > -1) {
                            let local = this.allentities;
                            local[this.index] = this.entity;
                            this.$store.commit("setWhatTo", {
                                what: "allentities",
                                to: [],
                            });
                            this.$store.commit("setWhatTo", {
                                what: "allentities",
                                to: local,
                            });
                        }
                        this.avisaOk("File deleted successfully");
                        this.$f7.dialog.close();
                    } catch (error) {
                        this.$f7.dialog.close();
                        console.error(error.code, error.message);
                        this.$f7.dialog.alert(error.message, error.code || "Error");
                    }
                });
            },
            procesCambioEntity(payload) {
                if (!this.entity.entityData) this.$set(this.entity, "entityData", {});
                this.$set(this.entity.entityData, payload.pregid, payload.value);
                if (this.index > -1) {
                    let local = this.allentities;
                    local[this.index] = this.entity;
                    this.$store.commit("setWhatTo", {
                        what: "allentities",
                        to: [],
                    });
                    this.$store.commit("setWhatTo", {
                        what: "allentities",
                        to: local,
                    });
                }
                this.entityToModify = null;
            },
            abreeditor(pred) {
                this.entityToModify = pred;
            },
            filesChange(file) {
                if (file) {
                    this.subirArchivox(file);
                }
            },
            alsnapshot(snapshot) {
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                let nentero = Math.round(percentage);
                if (!isNaN(nentero)) {
                    this.progress = nentero;
                }
            },
            alerror(err) {
                console.log(err.code);
                this.progress = 0;
                this.processing = false;
                this.refreshFiletoadd = true;
                this.uploadMeta = Object.assign({});
                this.uploadFocus.itemid = null;
                this.$f7.dialog.alert(err.code, "upload error");
            },
            async altermino() {
                try {
                    this.progress = 100;
                    this.processing = true;
                    let fileid = this.uploadFocus.file.neofile.split(".").shift();
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entityid: this.entity.id,
                            institutionid: this.entity.instid,
                            neofile: this.uploadFocus.file.neofile,
                            itemid: this.uploadMeta.itemid,
                            flnm: this.uploadFocus.file.name,
                            size: this.uploadFocus.file.size,
                            bulkName: this.bulkName,
                            unix: this.unix,
                        },
                        "v2_addneofile",
                        false,
                    );
                    if (datous.error) {
                        console.log("datous.error");
                        throw new Error(datous.error.message);
                    }
                    let tosave = datous.payload;
                    if (!this.entity.entityFiles) this.entity.entityFiles = {};
                    if (this.projectInFocusInfo.entityFiles[this.uploadMeta.itemid].many) {
                        if (!this.entity.entityFiles[this.uploadMeta.itemid]) {
                            this.$set(this.entity.entityFiles, this.uploadMeta.itemid, {});
                        }
                        this.$set(this.entity.entityFiles[this.uploadMeta.itemid], tosave.id, tosave);
                    } else {
                        this.$set(this.entity.entityFiles, this.uploadMeta.itemid, tosave);
                    }
                    if (this.index > -1) {
                        let local = this.allentities;
                        local[this.index] = this.entity;
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: [],
                        });
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: local,
                        });
                    }
                    this.processing = false;
                    this.refreshFiletoadd = true;
                    this.uploadMeta = Object.assign({});
                    this.uploadFocus.itemid = null;
                    this.unix = null;
                } catch (error) {
                    this.processing = false;
                    this.refreshFiletoadd = true;
                    this.uploadMeta = Object.assign({});
                    this.uploadFocus.itemid = null;
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            checkINameExists(filereceive) {
                let filename = filereceive.name;
                let nameExists = Object.values(this.entity.entityFiles || {}).some((file) => {
                    file.name == filename;
                });
                if (nameExists) {
                    return this.$f7.dialog.confirm(
                        "There is already a file with this name, do you want to upload it anyways?",
                        () => {
                            this.subirArchivox(filereceive);
                        },
                    );
                } else {
                    this.subirArchivox(filereceive);
                }
            },
            subirArchivox(filereceive) {
                this.refreshFiletoadd = false;
                let filename = filereceive.name;
                this.bulkName = filename;
                let unix = this.$dayjs().unix();
                let array = filereceive.name.split(".");
                let name = array[0];
                let ext = array[1];
                let neofile = `${filename}.${ext}`;
                this.uploadFocus.file = Object.assign(
                    {},
                    {
                        name: filereceive.name,
                        neofile: filename,
                        size: filereceive.size,
                    },
                );
                let extenx = filename.split(".").pop().toLowerCase();
                let filexpetedSplitted = this.uploadMeta.imn.split(".");
                let extenpermit = filexpetedSplitted.pop();
                if (extenpermit !== "a" && extenx !== extenpermit) {
                    this.alerror({ code: `File extension missmatch (required ${extenpermit})` });
                } else {
                    this.uploadFocus.itemid = this.uploadMeta.itemid;
                    this.progress = 0;
                    if (extenpermit == "a") {
                        filexpetedSplitted.push(extenx);
                        this.uploadMeta.imn = filexpetedSplitted.join(".");
                    }
                    this.task = this.$firebase
                        .storage()
                        .ref(
                            `/neocore/${this.$firebase.auth().currentUser.uid}/${this.projectInFocusInfo.id}/${
                                this.uploadMeta.tid
                            }/${unix}/${filename}`,
                        )
                        .put(filereceive);

                    this.unix = unix;
                    this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino);
                }
            },
            getAnswerValue(value, entityDataId) {
                const entityData = this.projectInFocusInfo?.entityData?.[entityDataId];
                const typeOfAnswer = entityData.tipo;

                switch (typeOfAnswer) {
                    case "elige_many":
                        const alternatives = entityData.alternativas;
                        return Object.keys(value || {})
                            .map((alternativeId) => {
                                const answer = alternatives[alternativeId];
                                if (!answer) return value[alternativeId];
                                if (answer.inct) return `${answer.tx} (Inactivated)`;
                                return alternatives[alternativeId].tx;
                            })
                            .join(", ");

                    case "elige_una":
                        const answer = entityData.alternativas[value];
                        if (!answer) return value;
                        if (answer.inct) return `${answer.tx} (Inactivated)`;
                        return answer.tx;

                    case "input_fecha":
                        if (!value) return value;
                        return this.$dayjs(value).format("MM-DD-YYYY");

                    case "input_dateTime":
                        if (!value) return value;
                        return this.$dayjs(value).format("MM-DD-YYYY HH:mm");

                    default:
                        return value;
                }
            },
            visualizeAnswer(entityDataId) {
                if (this.entity.isDeidentified) {
                    // Obtener la respuesta que viene en un objeto
                    const answer = this.entity?.entityData?.[entityDataId] || {};

                    if (answer?.deidentificationType === "Kept") {
                        return this.getAnswerValue(answer.value, entityDataId);
                    }

                    return answer.value;
                }

                const answerValue = this.entity?.entityData?.[entityDataId] || null;
                return this.getAnswerValue(answerValue, entityDataId);
            },
            onlyActive(esteObj) {
                return Object.values(esteObj || {})
                    .filter((unet) => {
                        return !unet.disabled && !this.noMandatorySinAlternativas(unet);
                    })
                    .sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        } else if (a.orden < b.orden) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            noMandatorySinAlternativas(uset) {
                return (
                    (uset.tipo == "elige_una" || uset.tipo == "elige_many") &&
                    !uset.req &&
                    (!uset.alternativas || !Object.keys(uset.alternativas).length)
                );
            },
            async setUploadFocus(preg, file) {
                try {
                    this.closeTooltip();
                    this.uploadFocus.type = `.${preg.tipo}`;
                    // this.$f7.dialog.preloader('Requesting auth...');
                    this.fileToHandle = Object.assign({}, preg);
                    this.fileToHandle.uniqueId = preg.id;
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            targetid: this.entity.id,
                            itemid: preg.id, // File field id
                            pregEncabezado: preg.encabezado,
                        },
                        "v2_dameTokenPara",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    this.uploadMeta = Object.assign({}, datous.payload);
                    await this.$firebase.auth().currentUser.reload();
                    let user = this.$firebase.auth().currentUser;
                    await user.getIdToken(true);
                    this.$f7.dialog.close();

                    this.filesChange(file);
                    // let button = document.getElementById("filetoadd");
                    // button.click();

                    // this.$$("#filetoadd").click();
                    this.fileToHandle = null;
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            openDownloadedFile({ downloadname, url }) {
                let fileLink = document.createElement("a");
                fileLink.href = url;
                fileLink.classList.add("external");
                fileLink.setAttribute("download", downloadname);
                // fileLink.download=downloadname;
                fileLink.setAttribute("target", "_blank");
                document.body.appendChild(fileLink);
                fileLink.click();
                this.$f7.dialog.close();
            },
            async getDownloadUrl(preg, subfile = null, downloadname) {
                try {
                    let enlatntig = subfile
                        ? this.entity.entityFiles[preg.id][subfile]
                        : this.entity.entityFiles[preg.id];
                    // this.$f7.dialog.preloader('Generating download...');
                    this.fileToHandle = Object.assign({}, enlatntig);
                    let datous = await this.commonExecute(
                        {
                            projectid: this.projectInFocusInfo.id,
                            entityid: this.entity.id,
                            institutionid: this.entity.instid,
                            itemid: preg.id,
                            fileid: enlatntig.id,
                        },
                        "v2_reqdownload",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let fileinfo = datous.payload;
                    this.openDownloadedFile({ downloadname, url: fileinfo.ulr });
                    this.fileToHandle = null;
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error.code, error.message);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            },
            requiredNotAnswered(preg) {
                if (!preg.req) return false;
                if (!this.entity?.entityData?.[preg.id]) return "An answer is required";
            },
            selectTag(checked, tag) {
                if (!this.selectedTagsPopover) return;
                this.loadingCheckboxes.push(tag.id);
                let local = Object.assign({}, this.selectedTags);
                if (checked) {
                    local[tag.id] = true;
                } else {
                    local[tag.id] = null;
                }
                this.selectedTags = Object.assign({}, local);
                this.uploadTagsToFile(tag.id);
            },
            getEntityTags(id, fileid) {
                let tags = [];
                if (fileid) {
                    tags = Object.keys(this.entity?.entityFiles[id]?.[fileid]?.tags || {}) || [];
                } else {
                    tags = Object.keys(this.entity?.entityFiles?.[id]?.tags || {}) || [];
                }
                let fileTags = this.projectInFocusInfo?.fileTags || {};
                let tagNames = tags.map((tag) => {
                    if (
                        !fileTags?.[tag]?.inct &&
                        ((!fileid && this.entity?.entityFiles?.[id].tags[tag]) ||
                            (fileid && this.entity?.entityFiles?.[id]?.[fileid].tags[tag]))
                    )
                        return fileTags[tag]?.tx;
                });
                if (tagNames.length) {
                    return tagNames
                        .filter((tag) => {
                            return tag;
                        })
                        .join(" - ");
                }
                return "";
            },
            async uploadTagsToFile(tagid) {
                try {
                    let call = this.$firebase.functions().httpsCallable("v2_updateFileTags");
                    let many = this.fileidforAddingTags ? true : null;
                    this.loadingTags = this.fileidforAddingTags ? this.fileidforAddingTags : this.pregidforAddingTags;
                    let res = await call({
                        projectid: this.projectInFocus,
                        entityid: this.entity.id,
                        pregid: this.pregidforAddingTags,
                        fileid: this.fileidforAddingTags,
                        institutionid: this.entity.instid,
                        dev: this.dev,
                        many,
                        tags: this.selectedTags,
                    });
                    if (res.data.error) throw new Error(res.data.error);
                    if (many) {
                        this.$set(
                            this.entity?.entityFiles[this.pregidforAddingTags][this.fileidforAddingTags],
                            "tags",
                            this.selectedTags,
                        );
                    } else {
                        this.$set(this.entity?.entityFiles[this.pregidforAddingTags], "tags", this.selectedTags);
                    }
                    this.loadingTags = false;
                    this.loadingCheckboxes.splice(this.loadingCheckboxes.indexOf(tagid), 1);
                    if (this.index > -1) {
                        let local = this.allentities;
                        local[this.index] = this.entity;
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: [],
                        });
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: local,
                        });
                    }
                } catch (error) {
                    this.loadingTags = false;
                    this.loadingCheckboxes.splice(this.loadingCheckboxes.indexOf(tagid), 1);
                    this.$f7.dialog.alert(error.message);
                }
            },
            setFileidForAddingTags(pregid, fileid) {
                this.selectedTags = null;
                let fileTags = {};
                this.pregidforAddingTags = pregid;
                if (fileid) {
                    this.fileidforAddingTags = fileid;
                    fileTags = Object.assign({}, this.entity?.entityFiles?.[pregid]?.[fileid]?.tags || {});
                } else {
                    this.fileidforAddingTags = null;
                    fileTags = Object.assign({}, this.entity?.entityFiles?.[pregid].tags || {});
                }
                this.selectedTags = Object.assign({}, fileTags);
            },
            sortedFiles(pregFiles) {
                return Object.values(pregFiles).sort((a, b) => {
                    if (a.at < b.at) {
                        return 1;
                    } else if (a.at > b.at) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            },
            closeSearchbar() {
                this.$refs.tagSearchbar.disable();
            },
            makeSureIsOneFile(pregid) {
                let file = this.entity?.entityFiles?.[pregid] || {};
                if (file.id) return true;
            },
            closeSearchbar() {
                this.selectedTagsPopover = false;
                this.$refs.tagSearchbar.disable();
            },
        },
    };
</script>
<style>
    .backblulight {
        background: #d3f3f5;
    }

    .backbluwhite {
        background: white;
    }

    .topLeft5 {
        top: 5px !important;
        left: 5px !important;
    }

    .tagsList {
        overflow: auto;
        max-height: 59vh;
    }

    .tagsPopover {
        width: 600px;
        height: 80vh;
    }

    .tagsPopover .popover-inner {
        overflow: hidden;
    }

    .popover-fileHistory {
        max-height: 35vh;
        overflow-y: auto;
    }

    .phi-checkbox .item-content {
        padding: 0;
    }
    .file-input {
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        width: 20px;
        z-index: 9999999;
        height: 20px;
        position: relative;
        float: right;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
    }
</style>
