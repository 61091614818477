<template>
    <f7-page @page:beforein="init">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Create or select a query</f7-nav-title>
        </f7-navbar>
        <f7-block>
            <template v-if="projectInFocusInfo.isDeidentificationEnabled && userIsMissingAnyPHIPrivileges">
                <f7c-alert type="info">
                    You do not have the required privileges to view PHI data in entities from other institutions. You
                    can only search on entities from your institution.
                </f7c-alert>
            </template>
            <f7-button :class="`margin-top`" @click="newQuery" fill large raised>New Query</f7-button>
        </f7-block>
        <f7-block>
            <f7-block-title class="display-flex align-items-center" v-if="Object.keys(savedQueries).length">
                Saved queries
                <f7-link
                    disabled
                    class="no-ripple margin-left-half"
                    style="cursor: default"
                    tooltip="These queries are personal. Only you can see the queries you've created."
                >
                    <font-awesome-icon :icon="['fad', 'circle-info']"></font-awesome-icon>
                </f7-link>
            </f7-block-title>
            <f7-list media-list>
                <f7-list-item
                    link="#"
                    v-for="query in savedQueries"
                    :key="query.id"
                    :title="query.name"
                    :footer="`Created at ${$dayjs.unix(query.wh).format('YYYY-MM-DD HH:mm')}`"
                    @click="selectQuery(query)"
                ></f7-list-item>
            </f7-list>
        </f7-block>
    </f7-page>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { commonmixin } from "../mixins/common";
    export default {
        mixins: [commonmixin],
        computed: {
            ...mapState(["user", "savedQueries", "projectInFocus"]),
            ...mapGetters(["projectInFocusInfo", "userHasPrivileges"]),

            userHasInstitutionPHIPrivileges() {
                return this.userHasPrivileges({
                    requiredInstitutionPrivs: ["phi"],
                });
            },
            userHasProjectPHIPrivileges() {
                return this.userHasPrivileges({
                    requiredAdminPrivs: ["phi"],
                });
            },
            userIsMissingAnyPHIPrivileges() {
                return !this.userHasInstitutionPHIPrivileges || !this.userHasProjectPHIPrivileges;
            },
        },
        methods: {
            addMissingCFields(query) {
                if (!query.c) {
                    query.c = [];
                    return query;
                }

                query.c.forEach((q) => {
                    this.addMissingCFields(q);
                });

                return query;
            },
            async init() {
                await Promise.all([this.fetchSavedQueries(), this.getSearchableEntityData()]);
            },
            async getSearchableEntityData() {
                try {
                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus },
                        "v2_getSearchableEntityData",
                        true,
                    );

                    console.log("Response getSearchableEntityData", response);

                    if (response.error) throw new Error(response.error.message);

                    this.$store.commit("setSearchableEntityData", response.entityData);
                    this.$store.commit("setSearchableEntityFiles", response.entityFiles);
                    this.$store.commit("setUserCanFilterByInstitution", response.canFilterByInstitution);
                } catch (error) {
                    console.log(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
            async fetchSavedQueries() {
                try {
                    if (Object.values(this.savedQueries).length) return;
                    this.$f7.dialog.preloader("Loading queries...");
                    const snapshot = await this.$firebase
                        .database()
                        .ref(`savedQueries/${this.projectInFocusInfo.id}/${this.user.uid}`)
                        .once("value");
                    let savedQueries = snapshot.val() || {};
                    this.$store.commit("setObjTo", {
                        what: "savedQueries",
                        to: savedQueries,
                    });
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            newQuery() {
                this.$store.commit("setWhatTo", {
                    what: "selectedQueryId",
                    to: null,
                });
                let newQuery = {
                    n: 0,
                    c: [],
                    t: "",
                    r: null,
                };
                this.$store.commit("setObjTo", { what: "leQuery", to: newQuery });
                this.navigateToQuery();
            },
            navigateToQuery() {
                console.log("yyyy");
                this.$f7.views.main.router.navigate("/filterData_nueo/");
            },
            selectQuery(queryMetadata) {
                let queryCopy = JSON.parse(JSON.stringify(queryMetadata.query));

                queryCopy = this.addMissingCFields(queryCopy);

                this.$store.commit("setWhatTo", {
                    what: "leQuery",
                    to: queryCopy,
                });
                this.$store.commit("setWhatTo", {
                    what: "selectedQueryId",
                    to: queryMetadata.id,
                });
                this.navigateToQuery();
            },
        },
    };
</script>

<style></style>
