<template>
    <f7-page>
        <f7-navbar title="Create new account"></f7-navbar>

        <f7-list class="fixfamarg no-margin-vertical">
            <f7-list-item divider title="1.- User email"></f7-list-item>
            <f7-list-input
                label="email"
                type="email"
                outline
                placeholder="user@domain.com"
                input-id="emailinput"
                :value="userInfo.email"
                @keyup.native.enter="siguiente()"
                error-message="e-mail invalid"
                :error-message-force="!userInfo.emailvalido"
                @input="escribeEmail($event.target.value)"
                @change="evaluaEmail()"
                :disabled="userInfo.emailok"
            >
                <font-awesome-icon
                    class="fa-2x text-color-primary"
                    :icon="['fad', 'at']"
                    slot="media"
                ></font-awesome-icon>
            </f7-list-input>
            <template v-if="userInfo.emailok">
                <f7-list-item divider title="2.- User metadata"></f7-list-item>
                <f7-list-input
                    label="Full name"
                    type="text"
                    outline
                    input-id="name1input"
                    :value="userInfo.nombres"
                    @keyup.native.enter="siguiente()"
                    @input="userInfo.nombres = $event.target.value"
                    placeholder="First and Last name to display"
                    clear-button
                ></f7-list-input>
            </template>
        </f7-list>
        <f7-block>
            <f7-row>
                <f7-col><f7-button @click="abortCreacion()" large class="cancelButton">Cancel</f7-button></f7-col>
                <f7-col><f7-button @click="siguiente()" fill raised large>Next</f7-button></f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { commonmixin } from "../mixins/common";
    import * as EmailValidator from "email-validator";

    export default {
        mixins: [commonmixin],
        props: ["elprojectid"],
        data() {
            return {
                popupCrearCuentaOpened: false,
                resultados: {},
                creando: false, //no borrar, sirve como freno para creacion multiple
                userInfo: {
                    nombres: "",
                    email: "",
                    emailok: false,
                    emailvalido: true,
                },
            };
        },
        computed: {
            ...mapState(["user", "projectInFocus"]),
            privsByProject() {
                return this.user.privsByProject || {};
            },
        },
        methods: {
            escribeEmail(e) {
                this.userInfo.emailvalido = true;
                this.userInfo.email = e;
            },
            evaluaEmail() {
                this.userInfo.emailvalido = EmailValidator.validate(this.userInfo.email);
            },
            abortCreacion() {
                this.$emit("abortCreacion");
            },
            async siguiente() {
                try {
                    //console.log('userInfo',this.userInfo);
                    //ahora verificar email
                    if (!this.userInfo.emailok) {
                        if (!this.userInfo.email || !EmailValidator.validate(this.userInfo.email)) {
                            return this.$f7.dialog.alert("e-mail wrongly formatted", "e-mail invalid");
                        }
                        this.$f7.dialog.preloader("Verifying email...");
                        let abuscar = this.userInfo.email.toLowerCase();
                        // console.log('buscando',abuscar);

                        //projectInFocus
                        let fromProject = null;
                        if (
                            this.privsByProject[this.projectInFocus] &&
                            this.privsByProject[this.projectInFocus].admin &&
                            this.privsByProject[this.projectInFocus].admin.accounts
                        ) {
                            fromProject = this.projectInFocus;
                        }
                        let datous = await this.commonExecute(
                            { email: abuscar, fromProject },
                            "v2_checkEmailExists",
                            false,
                        );
                        if (datous.error) {
                            console.log(datous.error.code);
                            if (datous.error.code == "auth/user-not-found") {
                                this.$f7.dialog.close();
                                this.userInfo.emailok = true;
                                setTimeout(() => {
                                    document.getElementById("name1input").focus();
                                }, 300);
                                return false;
                            } else {
                                throw new Error(datous.error.message);
                            }
                        }
                        let userRecord = datous.payload;
                        this.$f7.dialog.close();
                        this.gotoCuenta(userRecord);
                        // console.log('elusreinfo',userRecord);
                        // this.$f7.dialog.close();
                        //  return this.$f7.dialog.create({
                        //     title: 'Email already exists',
                        //     text: `The email ${this.userInfo.email} already exists in CIELO.`,
                        //     buttons: [
                        //       {
                        //         text: 'Cancel',
                        //         color: 'red'
                        //       },
                        //       {
                        //         text: 'Go to account',
                        //         onClick: ()=>{
                        //           this.abortCreacion();
                        //           this.gotoCuenta(userRecord);
                        //         }
                        //       }
                        //     ]
                        //   }).open();
                    } else {
                        if (this.creando) {
                            return false;
                        }
                        //estan ambos OK, tratar de crear
                        //llamar FX del back
                        if (!this.userInfo.nombres) {
                            return this.$f7.dialog.alert("You must provide a display name");
                        }
                        this.creando = true;
                        this.$f7.dialog.preloader("Creating account...");
                        // console.log('enviando',this.userInfo);
                        let tosend = {
                            em: this.userInfo.email,
                            nms: this.userInfo.nombres,
                            fromProject: null,
                        };
                        if (
                            this.privsByProject[this.projectInFocus] &&
                            this.privsByProject[this.projectInFocus].admin &&
                            this.privsByProject[this.projectInFocus].admin.accounts
                        ) {
                            tosend.fromProject = this.projectInFocus;
                        }
                        let datous = await this.commonExecute(tosend, "v2_createAccount", true);
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        // console.log('creacion ok',datous);
                        this.gotoCuenta(datous.payload);
                        this.$f7.dialog.close();
                        this.creando = false;
                    }
                } catch (error) {
                    this.creando = false;
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            gotoCuenta(cuenta) {
                this.$emit("gotoCuenta", cuenta);
            },
        },
    };
</script>
<style>
    .cuentasresults img {
        width: 80px;
    }
</style>
