<template>
    <f7-login-screen :opened="loginOpened" id="my-login-screen">
        <f7-view>
            <f7-page :class="`mylogin back${bg}`" login-screen>
                <f7-navbar class="topnavback">
                    <f7-nav-left>
                        <img v-if="header" class="height26 margin-left" src="../static/logos/only-logo.png" />
                        <img v-else class="height26 margin-left" src="../static/logos/washu-header-logo.svg" />
                    </f7-nav-left>
                </f7-navbar>

                <f7-row class="altox" v-if="!didLoadMaintenanceModeForFirstTime">
                    <f7-col>
                        <f7-button class="padtop" large>
                            <f7-preloader color="white"></f7-preloader>
                        </f7-button>
                    </f7-col>
                </f7-row>
                <template v-else-if="!cieloConfigurations.isMaintenanceModeEnabled">
                    <f7-block class="login-bccly text-color-white no-margin-vertical no-padding-top text-align-center">
                        <span v-if="loading">Loading...</span>
                        <span v-else-if="cerrandoSesion">Logging out...</span>
                    </f7-block>
                    <f7-row class="altox" v-if="loading">
                        <f7-col>
                            <f7-button class="padtop" large><f7-preloader color="white"></f7-preloader></f7-button>
                        </f7-col>
                    </f7-row>
                    <f7-row class="altox" v-else-if="cerrandoSesion">
                        <f7-col>
                            <f7-button class="padtop" large><f7-preloader color="white"></f7-preloader></f7-button>
                        </f7-col>
                    </f7-row>
                    <template v-else>
                        <f7-block
                            v-if="!accountCreatroOpened"
                            class="no-margin-vertical bg-color-white padding borradiux"
                        >
                            <f7-list class="no-margin-vertical" media-list>
                                <f7-list-item
                                    class="cielolist"
                                    title="CIELO"
                                    subtitle="Collaborative Informatics Environment"
                                    text="for Learning on Health Outcomes"
                                >
                                    <div class="text-align-center" slot="media">
                                        <img class="cielologo" src="../static/icons/512x512.png" />
                                        <span class="versionex">{{ $store.state.appVersion }}</span>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                            <f7-list media-list class="logininputs no-margin-top margin-bottom-half">
                                <f7-list-input
                                    label="email"
                                    :value="email"
                                    outline
                                    class="border-color-red"
                                    type="email"
                                    @input="email = $event.target.value"
                                    :clear-button="!loggingin"
                                    :readonly="loggingin"
                                >
                                    <font-awesome-icon :icon="['fad', 'at']" class="fa-2x topLeft5" slot="media" />
                                </f7-list-input>
                                <f7-list-input
                                    label="Password"
                                    :value="password"
                                    outline
                                    @keyup.native.enter="ingresarConemailpass()"
                                    type="password"
                                    @input="password = $event.target.value"
                                    :clear-button="!loggingin"
                                    :readonly="loggingin"
                                >
                                    <font-awesome-icon :icon="['fad', 'key']" class="fa-2x" slot="media" />
                                </f7-list-input>
                            </f7-list>

                            <f7-button @click="ingresarConemailpass()" large fill class="gradientrojo">
                                <f7-preloader color="white" v-if="loggingin"></f7-preloader>
                                <span v-else>Sign In</span>
                            </f7-button>
                            <f7-block class="margin-vertical-half text-align-center">
                                <f7-link
                                    class="fullwidth text-color-red"
                                    @click="forgotMyPass()"
                                    :class="{ disabled: loggingin }"
                                >
                                    Forgot your password?
                                </f7-link>
                            </f7-block>
                            <f7-button @click="azureLogin()" fill>Login with your WUSTL account</f7-button>
                            <p class="text-align-center gray no-margin-vertical">
                                <small>You don't have a CIELO account?</small>
                            </p>
                            <f7-button
                                :class="{ disabled: loggingin }"
                                @click="accountCreatroOpened = true"
                                color="red"
                                outline
                            >
                                Create new account
                            </f7-button>
                        </f7-block>
                        <f7-block v-else class="no-margin-vertical bg-color-white padding borradiux">
                            <f7-button @click="accountCreatroOpened = false" color="red" class="text-align-left">
                                <span>
                                    <font-awesome-icon
                                        class="margin-right fa-lg"
                                        :icon="['fad', 'circle-arrow-left']"
                                    />
                                    Back to sign in
                                </span>
                            </f7-button>
                            <f7-list class="no-margin-vertical" media-list>
                                <f7-list-item class="centeredTitle" title="Create New Account">
                                    <div slot="subtitle" class="white-space-normal">
                                        <f7-block class="text-align-justify no-margin-bottom">
                                            To access
                                            <b>{{ appUrl }}</b>
                                            you need a verified e-mail account. After creating the account, you will
                                            need to verify your address by clicking on a link in your inbox.
                                        </f7-block>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                            <f7-list no-hairlines-md class="no-margin-top">
                                <f7-list-input
                                    label="e-mail"
                                    type="email"
                                    outline
                                    :value="email"
                                    placeholder="email@domain.com"
                                    @input="email = $event.target.value"
                                    :clear-button="!creating"
                                    :readonly="creating"
                                >
                                    <font-awesome-icon :icon="['fad', 'at']" class="fa-2x" slot="media" />
                                </f7-list-input>

                                <f7-list-input
                                    label="New password"
                                    :type="verpass ? 'text' : 'password'"
                                    outline
                                    :placeholder="`Password for ${appUrl}`"
                                    :value="password"
                                    @keyup.native.enter="creteAccount()"
                                    @input="password = $event.target.value"
                                    :clear-button="!creating"
                                    :readonly="creating"
                                >
                                    <font-awesome-icon :icon="['fad', 'key']" class="fa-2x" slot="media" />

                                    <f7-link
                                        slot="content"
                                        @click="verpass = !verpass"
                                        class="padding margin-top-half text-color-gray"
                                    >
                                        <font-awesome-icon
                                            :icon="verpass ? ['fad', 'eye'] : ['fad', 'eye-slash']"
                                            class="fa-lg"
                                        />
                                    </f7-link>
                                </f7-list-input>
                            </f7-list>
                            <f7-block>
                                <f7-button @click="creteAccount()" fill large>
                                    <f7-preloader color="white" v-if="creating"></f7-preloader>
                                    <span v-else>Create account</span>
                                </f7-button>
                            </f7-block>
                        </f7-block>
                    </template>
                </template>
                <MaintenanceModeCard v-else />

                <div class="letoolbar">
                    <f7-row class="margin">
                        <f7-col>
                            <p>
                                <strong>
                                    CIELO: Collaborative Informatics Environment for Learning on Health Outcomes
                                </strong>
                            </p>
                            <p class="no-margin-bottom">
                                <f7-link external target="_blank" href="https://informatics.wustl.edu/">
                                    <u>Institute for Informatics</u>
                                </f7-link>
                            </p>
                            <p class="no-margin-top">
                                <f7-link external target="_blank" href="https://medicine.wustl.edu/">
                                    <u>Washington University School of Medicine</u>
                                </f7-link>
                            </p>
                        </f7-col>
                        <f7-col class="float-right">
                            <p class="no-margin-bottom">4444 Forest Park Ave, Suite 6318</p>
                            <p class="no-margin">Campus Box 8102</p>
                            <p class="no-margin-top">St. Louis, MO 63108-2212</p>
                        </f7-col>
                    </f7-row>
                    <f7-row class="margin-left">
                        <f7-col class="margin-bottom">
                            <img @click="openMaintenanceModePopup" src="../static/logos/wusm-logo-footer.svg" />
                            <div class="footer__copyright margin-top">
                                <f7-link external target="_blank" href="http://www.wustl.edu/policies/copyright.html">
                                    &copy; {{ $dayjs().format("YYYY") }} Washington University in St. Louis
                                </f7-link>
                            </div>
                        </f7-col>
                        <f7-col>
                            <f7-row class="soloDesktop elrowlinks">
                                <f7-col>
                                    <f7-link external target="_blank" href="https://emergency.wustl.edu/">
                                        Emergency
                                    </f7-link>
                                </f7-col>
                                <f7-col>
                                    <f7-link external target="_blank" href="https://medicine.wustl.edu/policies/">
                                        Policies
                                    </f7-link>
                                </f7-col>
                                <f7-col>
                                    <f7-link external target="_blank" href="https://medicine.wustl.edu/news/">
                                        News
                                    </f7-link>
                                </f7-col>
                            </f7-row>
                        </f7-col>
                    </f7-row>
                </div>

                <f7-list class="no-margin-vertical">
                    <img src="../static/heros/loginback_2.jpg" class="hidemax" />
                    <img src="../static/heros/loginback_3.jpg" class="hidemax" />
                </f7-list>

                <MaintenanceModeLoginPopup
                    :opened="isMaintenanceModeLoginPopupOpened"
                    @closed="isMaintenanceModeLoginPopupOpened = false"
                    @login="handleMaintenanceModeLogin"
                />
            </f7-page>
        </f7-view>
    </f7-login-screen>
</template>
<script>
    import { mapState } from "vuex";
    import MaintenanceModeCard from "@/components/MaintenanceModeCard.vue";
    import MaintenanceModeLoginPopup from "@/components/MaintenanceModeLoginPopup.vue";
    export default {
        props: ["loginOpened", "loading", "dev"],
        components: { MaintenanceModeCard, MaintenanceModeLoginPopup },
        mixins: ["commonmixin"],
        data() {
            return {
                bg: 1,
                accountCreatroOpened: false,
                email: "",
                password: "",
                verpass: false,
                veryfying: false,
                creating: false,

                isMaintenanceModeLoginPopupOpened: false,
                maintenanceLoginClickCounts: 0,
            };
        },
        computed: {
            ...mapState([
                "loggingin",
                "cerrandoSesion",
                "appUrl",
                "header",
                "cieloConfigurations",
                "user",
                "didLoadMaintenanceModeForFirstTime",
            ]),
        },
        watch: {
            loginOpened() {
                this.accountCreatroOpened = false;
                this.$store.commit("setWhatTo", { what: "loggingin", to: false });
                this.creating = false;
            },
        },
        mounted() {
            setTimeout(() => this.animaback(), 5000);
            this.azureGetResults();
        },
        methods: {
            async handleMaintenanceModeLogin({ email, password }) {
                try {
                    if (!email || !password) {
                        return this.$f7.dialog.alert("You must provide email and password");
                    }

                    this.$f7.dialog.preloader("Logging in...");

                    this.email = email;
                    this.password = password;

                    this.$store.commit("setWhatTo", { what: "loggingin", to: true });

                    const response = await this.commonExecute({ email }, "v2_isUserAllowedToLoginInMaintenanceMode");

                    if (!response.isUserAllowed) {
                        this.$f7.dialog.close();
                        return this.$f7.dialog.alert("You are not allowed to login in maintenance mode");
                    }

                    this.isMaintenanceModeLoginPopupOpened = false;

                    this.ingresarConemailpass().then((res) => {
                        this.$emit("login", res.user);

                        this.$f7.dialog.close();
                    });
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
            openMaintenanceModePopup() {
                if (this.cieloConfigurations.isMaintenanceModeEnabled) {
                    if (this.maintenanceLoginClickCounts < 8) {
                        return this.maintenanceLoginClickCounts++;
                    } else {
                        this.maintenanceLoginClickCounts = 0;
                        this.isMaintenanceModeLoginPopupOpened = true;
                    }
                }
            },
            async azureGetResults() {
                try {
                    let res = await this.$firebase.auth().getRedirectResult();
                    console.log("res", res);
                } catch (error) {
                    console.log("error and code", error, error.code); // Handle error.
                    if (error.code === "auth/account-exists-with-different-credential") {
                        let pendingCred = error.credential;
                        // The provider account's email address.
                        let email = error.email;
                        // Get sign-in methods for this email.
                        let methods = await this.$firebase.auth().fetchSignInMethodsForEmail(email);
                        if (methods[0] === "password") {
                            this.$f7.dialog.password(
                                "If you want to access with those credential please enter the password",
                                "This account exists with the email and password method",
                                async (password) => {
                                    let results = await this.$firebase
                                        .auth()
                                        .signInWithEmailAndPassword(email, password);
                                    console.log("results", results);
                                    return results.user.linkWithCredential(pendingCred);
                                },
                            );
                        }
                    }
                }
            },
            printError(error) {
                this.$f7.dialog.alert(error.message, error.code);
            },
            forgotMyPass() {
                this.$f7.dialog.prompt(
                    "Please indicate the email you used to create your account",
                    "Email",
                    (email) => {
                        if (!email) {
                            return this.$f7.dialog.alert("You must provide email");
                        }
                        this.$f7.dialog.preloader("Sending...");
                        this.$firebase
                            .auth()
                            .sendPasswordResetEmail(email)
                            .then(() => {
                                this.$f7.dialog.close();
                                this.$f7.dialog.alert("Please check your inbox (and spam folder) at " + email);
                            })
                            .catch((error) => {
                                this.$f7.dialog.close();
                                this.printError(error);
                                console.log(error);
                            });
                    },
                );
            },
            animaback() {
                if (this.loginOpened) {
                    this.bg = this.bg == 3 ? 1 : this.bg + 1;
                    setTimeout(() => this.animaback(), 4000);
                }
            },
            creteAccount() {
                if (!this.email || !this.password) {
                    return this.$f7.dialog.alert("You must provide email and password");
                }
                this.creating = true;
                this.$f7.dialog.preloader("Creating account...");
                this.$firebase
                    .auth()
                    .createUserWithEmailAndPassword(this.email, this.password)
                    .then(() => {
                        this.accountCreatroOpened = false;
                    })
                    .catch((error) => {
                        this.$f7.dialog.close();
                        this.creating = false;
                        this.printError(error);
                        console.log(error);
                    });
            },
            ingresarConemailpass() {
                if (!this.email || !this.password) {
                    return this.$f7.dialog.alert("You must provide email and password");
                }
                this.$store.commit("setWhatTo", { what: "loggingin", to: true });
                return this.$firebase
                    .auth()
                    .signInWithEmailAndPassword(this.email, this.password)
                    .catch((error) => {
                        this.$store.commit("setWhatTo", {
                            what: "loggingin",
                            to: false,
                        });
                        this.printError(error);
                        console.log(error);
                    });
            },
            async azureLogin() {
                let provider = new this.$firebase.auth.OAuthProvider("microsoft.com");
                provider.setCustomParameters({
                    tenant: "4ccca3b5-71cd-4e6d-974b-4d9beb96c6d6",
                    client_id: "f42df04b-b9be-48a4-a034-3982c1ad8bef",
                    prompt: "consent",
                });
                try {
                    this.$firebase.auth().signInWithRedirect(provider);
                    let result = await this.$firebase.auth().getRedirectResult();
                    console.log("result", result);
                    // User is signed in.
                    // IdP data available in result.additionalUserInfo.profile.

                    // Get the OAuth access token and ID Token
                    //    const credential = this.$firebase.auth.OAuthProvider.credentialFromResult(result);
                    //   const accessToken = credential.accessToken;
                    //   const idToken = credential.idToken;
                } catch (error) {
                    console.log(error); // Handle error.
                    this.$f7.dialog.alert(error.message);
                }
            },
        },
    };
</script>
<style>
    .stlouislogo img {
        width: 200px;
        max-width: 100%;
    }
    .masklogo {
        width: 140px;
        border-radius: 30px;
    }
    .mylogin.page {
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-position: center;
        /* transition: background 0.5s linear; */
        transition: background-image 0.5s linear;
        /* height: calc(100% - 115px);*/
    }
    .mylogin.back1.page {
        background-image: url("../static/heros/loginback_1.jpg");
    }

    .mylogin.back2.page {
        background-image: url("../static/heros/loginback_2.jpg");
    }

    .mylogin.back3.page {
        background-image: url("../static/heros/loginback_3.jpg");
    }
    .login-screen-content {
        background: unset;
        margin-bottom: 0 !important;
    }
    .login-bccly {
        font-size: 16px;
    }

    .hidemax {
        display: none !important;
    }
    .mylogin .padtop svg {
        margin-top: 9px;
    }
    .mylogin .gray {
        color: #696969;
    }
    .altox {
        height: 50vh;
    }
    .cielologo {
        width: 70px;
    }
    .logininputs input {
        padding-left: 8px !important;
        border-radius: 9px;
    }

    .logininputs .item-input-wrap input {
        background: #fff;
    }

    .logininputs .item-media svg {
        margin: 0 auto !important;
        color: #4e4e4e;
    }

    .fixmedia .item-media svg {
        margin: 0 auto !important;
    }

    .inputpadlef input {
        padding-left: 5px !important;
    }

    .borradiux {
        border-radius: 10px;
    }
    .cielolist {
        font-family: "Libre Baskerville";
        font-size: 35px;
        width: 100%;
    }
    .topnavback .navbar-bg {
        background: #680101 !important;
    }
    .topnavback {
        height: 41px !important;
    }

    .lesubnvavar {
        height: 83px !important;
    }
    .lesubnvavar,
    .gradientrojo {
        background-color: #900;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#900), color-stop(72%, #680101));
        background-image: linear-gradient(180deg, #900 0, #680101 72%);
    }

    .wulink {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-decoration: none;
        color: #cbb8b8 !important;
    }
    .letoolbar {
        /* display: unset;
 padding-bottom: 23px;
  height: 115px!important;*/
        background-color: #333;
        color: #dedede;
        bottom: 0px;
        margin-top: 50px;
    }
    .letoolbar img {
        width: 250px;
    }
    .footer__copyright {
        font-size: 12px;
        padding-left: 0px !important;
    }
    .footer__copyright a {
        display: inline-block !important;
        color: #767676;
        text-decoration: none;
        padding-left: 0px !important;
    }
    .elrowlinks {
        max-width: 300px;
        float: right;
        margin-top: 10px;
    }
    .elrowlinks a {
        font-size: 11px;
        padding: 9px 12px;
        text-transform: uppercase;
        color: #dedede;
    }
    .lesubnvavar img {
        max-width: 100%;
    }
    .soloDesktop {
        display: none;
    }
    .device-desktop .soloDesktop {
        display: flex !important;
    }
    .versionex {
        font-size: 11px !important;
        color: #840001;
    }
    .mylogin .toolbar-bottom {
        bottom: -115px !important;
    }
    .white-space-normal {
        white-space: normal;
    }
    .centeredTitle .item-content .item-inner .item-title-row {
        text-align: center;
        display: unset !important;
    }
</style>
