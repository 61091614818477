import ct from "countries-and-timezones";
import { mapState } from "vuex";
export const commonmixin = {
    data() {
        return {
            avisaCambiosOk: null,
        };
    },
    computed: {
        ...mapState(["adminprojectFocus", "projectInFocus", "user"]),
        projectinfo() {
            return this.$store.state.projects[this.adminprojectFocus];
        },
    },
    methods: {
        gotoMenux(urlx) {
            let forceHideProjectLeftMenu = [
                "/allprojects/",
                "/perfil/",
                "/about/",
                "/core_projects/",
                "/cuentas/core/",
                "/cielo-config/",
            ];
            this.$store.commit("setWhatTo", { what: "activeTab", to: urlx });
            if (forceHideProjectLeftMenu.includes(urlx)) {
                this.$store.commit("setWhatTo", {
                    what: "forceProjectSelect",
                    to: true,
                });
            }
            this.$f7.views.main.router.navigate(urlx);
        },
        getymyageobj(fechanac) {
            let y = this.$dayjs(fechanac);
            let edadanos = this.$dayjs().diff(y, "year");
            let nueva = this.$dayjs(fechanac).add(edadanos, "y");
            let edadmeses = this.$dayjs().diff(nueva, "month");
            let almes = this.$dayjs(fechanac).add(edadanos, "y").add(edadmeses, "M");
            let edaddias = this.$dayjs().diff(almes, "d");
            return {
                d: edaddias,
                m: edadmeses,
                y: edadanos,
            };
        },

        suage(fechanac) {
            const date2 = this.$dayjs(fechanac);
            const date1 = this.$dayjs();
            let cuanto = date1.diff(date2, "y");
            return cuanto;
        },

        striphtml(alimpiar) {
            return alimpiar.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", " ");
        },
        normalize(stringotonomral) {
            let a = String(stringotonomral).trim().toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
            let b = a.split(".").join(""); //sacar puntos
            let c = b.split("-").join(""); //sacar dashes
            let d = c.split(" ").join(""); //sacar espacios
            return d;
        },
        avisaOk(optiotext = null) {
            if (!this.avisaCambiosOk) {
                this.avisaCambiosOk = this.$f7.toast.create({
                    text: optiotext || "Changes saved OK",
                    position: "center",
                    closeTimeout: 2000,
                });
            }
            this.avisaCambiosOk.open();
        },
        async commonExecute(dataToSend, cual, conCaptcha = false) {
            dataToSend.dev = this.$store.state.dev;
            let fxtocall = this.$firebase.functions().httpsCallable(cual, { timeout: 540000 });
            let result = await fxtocall(dataToSend);
            return result.data;
        },
        async getMeThese(arrayOfStoreStateNames) {
            try {
                let promesex = [];
                let indexOfRequests = [];
                arrayOfStoreStateNames.forEach((unelem) => {
                    if (
                        !this.$store.state[unelem] ||
                        !Object.keys(this.$store.state[unelem]).length ||
                        !this.$store.state.lastFullLoad[unelem]
                    ) {
                        indexOfRequests.push(unelem);
                        promesex.push(this.$firebase.database().ref(unelem).once("value"));
                    }
                });
                if (!promesex.length) {
                    return true;
                }
                this.$f7.dialog.preloader("Loading settings...");
                let multisnaps = await Promise.all(promesex);
                multisnaps.forEach((onesnap, idx) => {
                    this.$store.commit("setWhatTo", {
                        what: indexOfRequests[idx],
                        to: onesnap.val() || {},
                    });
                });
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async commonfireread({ refa, condialog }) {
            try {
                if (condialog) {
                    this.$f7.dialog.preloader("Loading...");
                }
                let snapshot = await this.$firebase.database().ref(refa).once("value");
                if (condialog) {
                    this.$f7.dialog.close();
                }
                return snapshot;
            } catch (error) {
                if (condialog) {
                    this.$f7.dialog.close();
                }
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
                return null;
            }
        },
        timeouter(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        canBeShown(potrespuestas = {}, depde) {
            // Ensure depde is an array
            if (!Array.isArray(depde)) {
                depde = [depde];
            }

            return depde.every((dep) => {
                if (!potrespuestas[dep.origenid]) {
                    return false;
                }
                if (dep.restricid === "any_value") {
                    return potrespuestas[dep.origenid] || potrespuestas[dep.origenid] === 0;
                } else if (dep.restricid === "contiene") {
                    let myanserMin = potrespuestas[dep.origenid].toLowerCase();
                    let tosearch = dep.contiene.toLowerCase();
                    return myanserMin.includes(tosearch);
                } else if (dep.restricid === "elige_al_menos_estas_ids") {
                    let arregl = dep.elige_al_menos_estas_ids;
                    return arregl.every((unaltidrequerida) => potrespuestas[dep.origenid][unaltidrequerida]);
                } else if (dep.restricid === "elige_exactamente_estas_ids") {
                    let arregl = dep.elige_exactamente_estas_ids;
                    let incluyeesas = arregl.every((unaltidrequerida) => potrespuestas[dep.origenid][unaltidrequerida]);
                    return (
                        Object.values(potrespuestas[dep.origenid]).filter((unas) => unas).length === arregl.length &&
                        incluyeesas
                    );
                } else if (dep.restricid === "elige_alguna_estas_ids") {
                    let arregl = dep.elige_alguna_estas_ids;
                    return arregl.some((unaltidrequerida) => potrespuestas[dep.origenid] === unaltidrequerida);
                } else if (dep.restricid === "equal_to") {
                    return potrespuestas[dep.origenid] == dep.equal_to;
                } else if (dep.restricid === "equal_to_optionid") {
                    return potrespuestas[dep.origenid] == dep.equal_to_optionid;
                } else if (dep.restricid === "mayor_que") {
                    return (
                        potrespuestas[dep.origenid] !== "Invalid Date" &&
                        parseFloat(potrespuestas[dep.origenid]) > parseFloat(dep.mayor_que)
                    );
                } else if (dep.restricid === "menor_que") {
                    return (
                        potrespuestas[dep.origenid] !== "Invalid Date" &&
                        parseFloat(potrespuestas[dep.origenid]) < parseFloat(dep.menor_que)
                    );
                } else {
                    return true;
                }
            });
        },
        createdAtTimezone(projectInFocusInfo, unenti) {
            let institution = projectInFocusInfo?.institutions?.[unenti.instid];
            let createdAd = unenti.createdAd;
            const institutionTimezone = institution?.tz || "America/Chicago";
            const date = this.$dayjs.unix(createdAd).tz(institutionTimezone).format("MM-DD-YYYY");
            return date;
        },
        createdAtUTC(projectInFocusInfo, unenti) {
            let institution = projectInFocusInfo?.institutions?.[unenti.instid];
            const institutionTimezone = institution?.tz || "America/Chicago";
            const utc = ct.getTimezone(institutionTimezone).utcOffsetStr;
            if (this.$store.state.localTimezone != institutionTimezone) return ` (${institutionTimezone} UTC${utc})`;
        },
        setLastUpdate() {
            let lastUpdate = this.$dayjs().valueOf();
            this.$set(this.projectinfo, "lastUpdate", lastUpdate);
            let toUpdate = {};
            toUpdate[`projects/${this.adminprojectFocus}/lastUpdate`] = lastUpdate;
            return toUpdate;
        },
        async exportAccountsReport({ projectid }) {
            try {
                this.$f7.dialog.preloader("Downloading report...");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let endpoint = this.$store.state.dev
                    ? "https://us-central1-cielo-one.cloudfunctions.net"
                    : "https://us-central1-cielo-iddrc-braingeneregistry.cloudfunctions.net"; //'http://localhost:5001/cielo-one/us-central1' 'https://us-central1-cielo-one.cloudfunctions.net 'https://us-central1-cielo-iddrc-braingeneregistry.cloudfunctions.net'
                let url = projectid
                    ? `${endpoint}/v2_exporter/exportAccountsReport/${projectid}/${this.user.uid}`
                    : `${endpoint}/v2_exporter/exportAccountsReport/allProjects/${this.user.uid}`;
                let response = await this.$axios.get(url, {
                    headers: { Authorization: "Bearer " + idToken },
                    responseType: "blob", // important
                });
                console.log("response", response);
                if (response.data.type == "application/json") {
                    this.$f7.dialog.close();
                    var textPromise = await response.data.text();
                    return this.$f7.dialog.alert(textPromise, "Error");
                }
                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    }),
                );
                let fileLink = document.createElement("a");
                document.body.appendChild(fileLink);
                fileLink.href = fileURL;
                fileLink.setAttribute("download", `Accounts-report-${this.$dayjs().format("MM-DD-YYYY")}.xlsx`);
                fileLink.classList.add("external");
                fileLink.click();
                window.URL.revokeObjectURL(fileURL);
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message);
            }
        },
        /*  areOptionsOrderAlphabeticall({ preguntaId, alternativeItems }) {
            if (
                this.$store.state.projects?.[this.projectInFocus]?.entityData?.[preguntaId]?.orderAlphabeticallyOptions
            ) {
                return alternativeItems.sort((a, b) => {
                    if (a.tx > b.tx) {
                        return 1;
                    } else if (a.tx < b.tx) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
            } else return alternativeItems;
        }, */
    },
};
