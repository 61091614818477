<template>
    <div>
        <f7-searchbar
            search-container=".dictionary-list"
            search-in=".item-title,.item-subtitle,.item-text"
            placeholder="Search for dictionary entries, tags, or de-identification types"
            found-el=".dictionary-list-found"
            not-found-el=".dictionary-list-not-found"></f7-searchbar>
        <f7-list class="dictionary-list-not-found searchbar-not-found">
            <f7-list-item title="No entries found"></f7-list-item>
        </f7-list>
        <f7-list
            class="dictionary-list dictionary-list-found"
            media-list
            virtual-list
            :virtual-list-params="{
                items: dictionary,
                renderExternal,
                height: 73,
                searchAll,
            }">
            <ul>
                <f7-list-item
                    v-for="entry in vlData.items"
                    :key="entry.id"
                    :style="`top: ${vlData.topPosition}px`"
                    link="#"
                    @click="openEditEntryPopup(entry)">
                    <template slot="title">
                        <p class="no-margin oneline">{{ entry.columnName }}</p>
                    </template>
                    <template slot="subtitle">
                        <small class="no-margin">{{ entry.deidentificationType }}</small>
                    </template>
                    <f7-badge slot="text" v-if="entry.tagId" color="primary">{{ getTagName(entry.tagId) }}</f7-badge>
                </f7-list-item>
            </ul>
        </f7-list>

        <CreatePHIDictionaryEntryPopup
            :opened="isEditPopupOpened"
            :editing-entry="currentEntry"
            @edited="handleEditedEntry"
            @deleted="handleDeletedEntry"
            @closed="isEditPopupOpened = false" />
    </div>
</template>

<script>
import CreatePHIDictionaryEntryPopup from '../components/CreatePHIDictionaryEntryPopup.vue';
export default {
    components: { CreatePHIDictionaryEntryPopup },
    props: {
        dictionary: {
            type: Array,
            default: [],
        },
    },
    watch: {
        dictionary() {
            if (this.dictionary.length > 0) this.vlData.items = this.dictionary;
        },
    },
    computed: {
        tags() {
            return Object.values(this.$store.getters.projectInFocusInfo.fileTags || {})
                .filter((t) => !t.inct)
                .sort((a, b) => {
                    if (a.orden > b.orden) return 1;
                    else if (a.orden < b.orden) return -1;
                    else return 0;
                });
        },
    },
    data() {
        return {
            vlData: { items: [] },

            currentEntry: null,
            isEditPopupOpened: false,
        };
    },
    methods: {
        renderExternal(vl, vlData) {
            this.vlData = vlData;
        },
        searchAll(query, items) {
            const found = [];
            for (let i = 0; i < items.length; i += 1) {
                const item = items[i];
                if (
                    item.columnName.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
                    item.deidentificationType.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
                    (this.getTagName(item.tagId) || '').toLowerCase().indexOf(query.toLowerCase()) >= 0
                ) {
                    found.push(i);
                }
            }
            return found;
        },
        getTagName(tagId) {
            const tag = this.tags.find((t) => t.id === tagId);
            return tag ? tag.tx : null;
        },
        openEditEntryPopup(entry) {
            this.currentEntry = entry;
            this.isEditPopupOpened = true;
        },
        handleEditedEntry(payload) {
            this.isEditPopupOpened = false;
            this.$emit('edited-entry', payload);
        },
        handleDeletedEntry(entryId) {
            this.isEditPopupOpened = false;
            this.$emit('deleted-entry', entryId);
        },
    },
};
</script>

<style scoped>
.oneline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
