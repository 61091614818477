<template>
    <f7-page @page:beforein="pideProjectos(false)" name="allprojects">
        <!-- Top Navbar -->
        <!-- Top Navbar
        <f7-navbar title="titulo" subtitle="subtitulo" back-link="Back"></f7-navbar>
    -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Latest visible projects" title="CIELO Projects"></f7-nav-title>
            <f7-nav-right>
                <f7-link tooltip="Refresh projects" @click="pideProjectos(true)">
                    <font-awesome-icon :icon="['fad', 'rotate']"></font-awesome-icon>
                </f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-progressbar v-if="loading" infinite></f7-progressbar>
        <f7-block v-if="loading && !visibleProjects.length" class="text-align-center">
            <f7-preloader></f7-preloader>
            <br />
            Loading projects...
        </f7-block>
        <f7-block v-else-if="!visibleProjects.length">There are no public projects in CIELO</f7-block>
        <f7-block>
            <f7-row class="pb-x">
                <f7-col v-for="project in visibleProjects" :key="project.id" width="100" medium="50">
                    <f7-card class="card-project-pic">
                        <f7-card-header
                            class="no-border no-padding-horizontal"
                            valign="bottom"
                            :style="`background-image:url(${project.banner || '../static/heros/loginback_1.jpg'})`"
                        >
                            <div class="pronamexcor padding-horizontal-half">
                                {{ project.name }}
                            </div>
                        </f7-card-header>

                        <f7-card-content class="no-padding-top">
                            <f7-block class="margin-top margin-bottom">
                                <span>
                                    <f7-link
                                        class="text-color-primary nopointer"
                                        tooltip="This project is currently collecting data"
                                        v-if="project.published"
                                    >
                                        <font-awesome-icon class="fa-lg" :icon="['fad', 'shop']"></font-awesome-icon>
                                        Active
                                    </f7-link>
                                    <f7-link
                                        class="text-color-red nopointer"
                                        tooltip="This project is no collecting data anymore"
                                        v-else
                                    >
                                        <font-awesome-icon
                                            class="fa-lg"
                                            :icon="['fad', 'shop-slash']"
                                        ></font-awesome-icon>
                                        Inactive
                                    </f7-link>
                                </span>
                            </f7-block>
                            <f7-block class="descripprocore margin-half">
                                {{ project.descr || "No description set" }}
                            </f7-block>

                            <p>
                                <b>Contact:</b>
                                <f7-link external :href="`mailto:${project.cemail}`" v-if="project.cemail">
                                    <u>{{ project.cemail }}</u>
                                </f7-link>
                                <span v-else>No contact set</span>
                            </p>
                        </f7-card-content>
                        <f7-card-footer class="margin-bottom">
                            <span></span>
                            <span v-if="getProjectAccessLimit(project.id)">
                                <font-awesome-icon color="red" :icon="['fad', 'triangle-exclamation']" />
                                You have exceeded the time limit to access this project. Please contact the project
                                administrator.
                            </span>
                            <f7-button
                                v-else-if="canAccessProject(project)"
                                @click="gotoproject(project.id)"
                                fill
                                raised
                            >
                                <font-awesome-icon
                                    class="fa-lg"
                                    :icon="['fad', 'right-to-bracket']"
                                ></font-awesome-icon>
                                Access
                            </f7-button>
                            <span v-else-if="!project.allowAccess">Project closed for access requests</span>
                            <f7-chip
                                v-else-if="project.allowAccess && privsByProject_req[project.id]"
                                media-bg-color="cielo"
                                text="Access requested"
                            >
                                <font-awesome-icon
                                    :icon="['fad', 'hand-holding-medical']"
                                    slot="media"
                                ></font-awesome-icon>
                            </f7-chip>
                            <f7-button
                                class="margin-bottom"
                                v-else-if="project.allowAccess && !privsByProject_req[project.id]"
                                @click="resquestAccessTo = project"
                                outline
                                raised
                            >
                                <font-awesome-icon class="fa-lg" :icon="['fad', 'hand-point-up']"></font-awesome-icon>
                                Request access
                            </f7-button>
                        </f7-card-footer>
                    </f7-card>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-popup
            class="demo-popup"
            :opened="resquestAccessTo ? resquestAccessTo != null : false"
            @popup:closed="resquestAccessTo = false"
        >
            <f7-page v-if="resquestAccessTo">
                <f7-navbar :title="resquestAccessTo.name" subtitle="Request access"></f7-navbar>
                <f7-block class="text-align-center">
                    <p>Here you can request access to the project '{{ resquestAccessTo.name }}'.</p>
                    <p>
                        You are requesting access with the email
                        <f7-chip media-bg-color="purple" :text="user.email">
                            <font-awesome-icon slot="media" :icon="['fad', 'at']"></font-awesome-icon>
                        </f7-chip>
                    </p>
                </f7-block>
                <f7-block>
                    <f7-list>
                        <f7-list-input
                            label="Affiliation and reason for request"
                            type="textarea"
                            outline
                            :value="requestReason"
                            @input="requestReason = $event.target.value"
                            placeholder="Specify your institution of origin and reason or case description"
                            clear-button
                        ></f7-list-input>
                    </f7-list>

                    <f7-row>
                        <f7-col>
                            <f7-button @click="resquestAccessTo = null" large class="cancelButton">Cancel</f7-button>
                        </f7-col>
                        <f7-col><f7-button @click="reqAccces()" fill raised large>Request</f7-button></f7-col>
                    </f7-row>
                </f7-block>
            </f7-page>
        </f7-popup>
        <f7-popup :opened="enterPhone" @popup:closed="(phoneNumber = null), (enterPhone = null)">
            <f7-page>
                <f7-navbar title="Add your phone number" subtitle="Two-factor authentication">
                    <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
                </f7-navbar>
                <f7-block v-if="preloader" color="black" class="text-align-center">
                    <f7-preloader></f7-preloader>
                    <br />
                    Loading ...
                </f7-block>
                <template v-else>
                    <f7-block-title>
                        Enter the phone number where you want to receive the two-factor code
                    </f7-block-title>
                    <f7-list class="no-margin-top">
                        <f7-list-input
                            outline
                            maxlength="16"
                            label="Phone Number"
                            info="e.g. +1(555)555-5555"
                            :value="formatPhoneNumber(phoneNumber)"
                            @input="phoneNumber = $event.target.value.trim()"
                            id="phoneNumber"
                        ></f7-list-input>
                    </f7-list>
                    <f7-block>
                        <f7-button @click="confirmPhoneNumber()" fill large>Confirm phone number</f7-button>
                    </f7-block>
                </template>
            </f7-page>
        </f7-popup>
        <f7-popup :opened="enterCode" @popup:closed="(code1 = null), (code2 = null), (enterCode = null)">
            <f7-page>
                <f7-navbar title="Verifying user" :subtitle="`Sending code to ${this.phoneNumber || this.user.phone}`">
                    <f7-nav-right><f7-link popup-close>Close</f7-link></f7-nav-right>
                </f7-navbar>
                <f7-block v-if="preloader" color="black" class="text-align-center">
                    <f7-preloader></f7-preloader>
                    <br />
                    Loading ...
                </f7-block>
                <template v-else>
                    <f7-block-title class="whiteBg no-margin padding">
                        To access this project Enter the code we sent you by SMS
                    </f7-block-title>
                    <f7-block class="text-align-center whiteBg">
                        <f7-list class="no-margin-top no-margin-bottom inlineInputs">
                            <f7-list-input
                                id="firstInput"
                                outline
                                maxlength="3"
                                :value="code1"
                                @input="code1 = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                        <span>-</span>
                        <f7-list class="no-margin-top no-margin-bottom inlineInputs">
                            <f7-list-input
                                id="secondInput"
                                outline
                                maxlength="3"
                                :value="code2"
                                @input="code2 = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                    </f7-block>
                    <f7-block>
                        <div class="text-align-right">
                            <span>Did not receive the SMS?</span>
                            <f7-link @click="sendCode()">Send it again</f7-link>
                            OR
                            <f7-link @click="(enterPhone = true), (enterCode = false)">
                                Change your phone number
                            </f7-link>
                        </div>
                        <f7-checkbox
                            :checked="validateOneMonth"
                            @change="validateOneMonth = $event.target.checked"
                        ></f7-checkbox>
                        <span>Validate this device for 1 month</span>
                    </f7-block>
                    <f7-block>
                        <f7-button @click="confirmCode()" fill large>Confirm Code</f7-button>
                    </f7-block>
                </template>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="termsPopupOpened" @popup:closed="termsPopupOpened = false">
            <f7-page>
                <f7-navbar title="Terms and conditions">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="margin-top">
                    <f7c-alert type="info">
                        You have to accept the terms and conditions of this project before continuing.
                    </f7c-alert>

                    <div v-html="viewingProjectTerms" class="scrollable-text margin-bottom"></div>

                    <b class="no-margin" v-if="Object.values(viewingProjectTermsInputs).length">
                        Please fill the following fields
                    </b>
                    <f7-list class="margin-top-half">
                        <ul v-for="field in viewingProjectTermsInputs" :key="field.id">
                            <f7-list-input
                                v-if="field.preexistingType === 'institution'"
                                type="select"
                                label="Institution"
                                outline
                                v-model="field.value"
                                @change="field.value = $event.target.value"
                                :disabled="field.locked"
                            >
                                <option value="">Select an institution</option>
                                <option
                                    v-for="(institution, index) in viewingProjectInstitutions"
                                    :key="index"
                                    :value="institution.id"
                                >
                                    {{ institution.name }}
                                </option>
                            </f7-list-input>
                            <f7-list-input
                                v-else
                                outline
                                :label="
                                    field.type === 'preexisting'
                                        ? field.preexistingType[0].toUpperCase() + field.preexistingType.slice(1)
                                        : field.question
                                "
                                :value="field.value"
                                :readonly="field.locked"
                                @input="termsInputChanged(field, $event.target.value)"
                            ></f7-list-input>
                        </ul>
                    </f7-list>

                    <f7-list class="no-bg-color">
                        <f7-list-item
                            title="I have read and agree to the terms and conditions"
                            checkbox
                            :checked="termsAccepted"
                            @change="termsAccepted = $event.target.checked"
                        ></f7-list-item>
                    </f7-list>

                    <f7-button @click="validateAndAcceptTerms" :disabled="!termsAccepted" fill>Access</f7-button>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-block v-if="!loading" class="elfooter padding no-margin-bottom text-align-left">
            &copy;{{ $dayjs().format("YYYY") }} Washington University in St. Louis
        </f7-block>
    </f7-page>
</template>
<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        data() {
            return {
                loading: true,
                preloader: false,
                resquestAccessTo: null,
                requestReason: "",
                enterPhone: null,
                phoneNumber: null,
                enterCode: null,
                code1: null,
                code2: null,
                validateOneMonth: true,
                // Popup delimitador
                openDelimiterPopup: false,

                // Terms and conditions
                termsPopupOpened: false,
                termsAccepted: false,
                viewingProjectTerms: "",
                viewingProjectId: "",
                viewingProjectTermsInputs: {},
                viewingProjectInstitutions: {},

                isConfirmingPhoneNumberBeforeTerms: false,
                answers: null,
            };
        },

        watch: {
            code1: {
                handler: function (val) {
                    if (val && val.length === 3) {
                        const input = this.$$("#secondInput").find("input")[0];
                        if (input) input.focus();
                    }
                },
            },

            preloader: {
                handler: function (val) {
                    if (!val && this.enterCode === true) {
                        setTimeout(() => {
                            const input = this.$$("#firstInput").find("input")[0];
                            if (input) input.focus();
                        }, 100);
                    }
                },
            },
        },

        computed: {
            ...mapState(["user", "dev", "projectInFocus"]),
            ...mapGetters(["projects", "projectInFocusInfo"]),
            privsByProject() {
                return this.user.privsByProject || {};
            },
            privsByProject_req() {
                return this.user.privsByProject_req || {};
            },
            visibleProjects() {
                return this.projects.filter((unprj) => {
                    return unprj.visible;
                });
            },
            areTermsInputsComplete() {
                const fields = Object.values(this.viewingProjectTermsInputs);

                if (!fields.length) return true;

                let isComplete = true;

                fields.forEach((field) => {
                    if (!field.value || !field.value.length) {
                        isComplete = false;
                    }
                });

                return isComplete;
            },
        },
        methods: {
            getProjectAccessLimit(projectid) {
                let accessLimit = this.privsByProject?.[projectid]?.accessLimit;
                let isAccessLimitExpired = null;
                if (accessLimit) {
                    isAccessLimitExpired = this.$dayjs(`${accessLimit.date} ${accessLimit.time}`).isBefore(
                        this.$dayjs(),
                    );
                }
                return isAccessLimitExpired;
            },
            canAccessProject(project) {
                const privsInProject = this.privsByProject[project.id];
                let myIstitution = Object.keys(privsInProject?.prv || {})[0];
                let restrictAccess = project.institutions?.[myIstitution]?.restrictAccess;
                if (!privsInProject || restrictAccess) return false;
                return true;
            },
            termsInputChanged(field, value) {
                this.$set(field, "value", value);
            },
            async validateAndAcceptTerms() {
                try {
                    if (!this.viewingProjectId) {
                        this.$f7.dialog.alert("There is no current project to accept the terms and conditions.");
                        return;
                    }

                    if (!this.termsAccepted) {
                        this.$f7.dialog.alert("You have to accept the terms and conditions.");
                        return;
                    }

                    if (!this.areTermsInputsComplete) {
                        this.$f7.dialog.alert("You must fill all the input fields.");
                        return;
                    }

                    const termsPhoneInput = Object.values(this.viewingProjectTermsInputs).find(
                        (input) => input.preexistingType === "phone",
                    );
                    const termsNameInput = Object.values(this.viewingProjectTermsInputs).find(
                        (input) => input.preexistingType === "name",
                    );

                    if (termsPhoneInput && this.user.phone !== termsPhoneInput.value) {
                        this.$f7.dialog.preloader("Confirming phone number...");

                        this.phoneNumber = termsPhoneInput.value;

                        this.isConfirmingPhoneNumberBeforeTerms = true;

                        this.$store.commit("setWhatTo", {
                            what: "projectInFocus",
                            to: this.viewingProjectId,
                        });

                        await this.confirmPhoneNumber();

                        this.$f7.dialog.close();
                        return;
                    }

                    if (termsNameInput && this.user.displayName !== termsNameInput.value) {
                        const trimmedName = termsNameInput.value.trim();

                        await this.$firebase.auth().currentUser.updateProfile({
                            displayName: trimmedName,
                        });
                        this.$store.commit("updateUserInfo", {
                            cual: "displayName",
                            valor: trimmedName,
                        });
                    }

                    this.$f7.dialog.preloader("Accepting terms and conditions...");

                    const acceptTime = this.$dayjs().unix();

                    const updates = {};
                    console.log("antes", this.viewingProjectTermsInputs, acceptTime);
                    updates[`privx/${this.user.uid}/terms/${this.viewingProjectId}`] = acceptTime;
                    updates[`termsFieldsResponses/${this.viewingProjectId}/${this.user.uid}`] =
                        this.viewingProjectTermsInputs;

                    await this.$firebase.database().ref().update(updates);
                    console.log("paso");
                    this.$store.commit("setNewAcceptedTerm", {
                        projectId: this.viewingProjectId,
                        acceptTime,
                    });

                    this.$f7.dialog.close();

                    // Clear the variables
                    this.termsPopupOpened = false;
                    this.isConfirmingPhoneNumberBeforeTerms = false;
                    this.phoneNumber = null;

                    this.gotoproject(this.viewingProjectId);
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("There was an error while accepting terms.", error);
                    console.error(error);
                }
            },
            setTermsInputsDefaults() {
                Object.values(this.viewingProjectTermsInputs).forEach((field) => {
                    if (field.type === "preexisting") {
                        if (field.preexistingType === "name") {
                            let name = "";

                            if (this.user.displayName && this.user.displayName.length) {
                                name = this.user.displayName;
                            }

                            this.$set(field, "value", name);
                        }

                        if (field.preexistingType === "email") {
                            let email = "";

                            if (this.user.email && this.user.email.length) {
                                email = this.user.email;
                            }

                            this.$set(field, "value", email);
                            field.locked = true;
                        }

                        if (field.preexistingType === "phone") {
                            let phone = "";

                            if (this.user.phone && this.user.phone.length) {
                                phone = this.user.phone;
                            }

                            this.$set(field, "value", phone);
                        }

                        if (field.preexistingType === "institution") {
                            // ¿PUEDE UN USUARIO TENER MÁS DE UNA INSTITUCION EN UN PROYECTO?
                            let institution = "";

                            const institutionId =
                                Object.values(this.privsByProject?.[this.viewingProjectId]?.prv || {})[0]?.id || null;
                            if (institutionId) {
                                const project = this.projects.find((p) => p.id === this.viewingProjectId);
                                institution = project.institutions[institutionId].id || "";
                                this.viewingProjectInstitutions = project.institutions;
                            }

                            this.$set(field, "value", institution);
                            field.locked = true;
                        }
                    } else {
                        let answered = this.answers?.[field.id];
                        if (answered) this.$set(field, "value", this.answers[field.id].value);
                    }
                });
            },
            async gotoproject(projectid) {
                // If user has not accepted terms and conditions for this project
                try {
                    this.$f7.dialog.preloader("Fetching project data...");

                    // Check if user has restricted access to this project (access time limit)
                    const accessLimitSnap = await this.$firebase
                        .database()
                        .ref(`privsByProject/${this.user.uid}${projectid}/accessLimit`)
                        .once("value");

                    if (accessLimitSnap.exists()) {
                        const accessLimit = accessLimitSnap.val();
                        const isAccessLimitExpired = this.$dayjs(`${accessLimit.date} ${accessLimit.time}`).isBefore(
                            this.$dayjs(),
                        );

                        if (isAccessLimitExpired) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(
                                "You have exceeded the time limit to access this project. Please contact the project administrator.",
                            );
                            return;
                        } else {
                            const remainingSecondsUntilRestrictedAccess = this.$dayjs(
                                `${accessLimit.date} ${accessLimit.time}`,
                            ).diff(this.$dayjs(), "seconds");
                            console.log(
                                "Remaining seconds until restricted access",
                                remainingSecondsUntilRestrictedAccess,
                            );

                            this.$store.commit("setWhatTo", {
                                what: "remainingSecondsUntilRestrictedAccess",
                                to: remainingSecondsUntilRestrictedAccess,
                            });
                        }
                    }

                    const snapshot = await this.$firebase.database().ref(`projects/${projectid}/terms`).once("value");
                    const areTermsActive = snapshot.val() || false;

                    this.$store.commit("setProjectMeta", {
                        projectId: projectid,
                        field: "terms",
                        data: areTermsActive,
                    });

                    const snapshot2 = await this.$firebase.database().ref(`terms/${projectid}`).once("value");
                    const terms = snapshot2.val();
                    const project = this.projects.find((p) => p.id === projectid);
                    const projectHasTerms = project ? project.terms === true : false;
                    let userHasNotAcceptedTerms = !this.user.privx.terms || !this.user.privx.terms[projectid];
                    const updatedTerms = terms?.date > this.user.privx.terms?.[projectid];
                    if (updatedTerms) {
                        let snap = await this.$firebase
                            .database()
                            .ref(`termsFieldsResponses/${projectid}/${this.user.uid}`)
                            .once("value");
                        this.answers = snap.val() || {};
                        if (
                            terms.inputFields &&
                            Object.keys(terms.inputFields)
                                .filter((term) => {
                                    return !term.inact;
                                })
                                .some((id) => !this.answers[id])
                        )
                            userHasNotAcceptedTerms = true;
                    }

                    if (projectHasTerms && userHasNotAcceptedTerms) {
                        if (terms) {
                            this.viewingProjectTerms = terms.htmlText;
                            this.viewingProjectId = projectid;
                            let filteredTermsInputs = {};
                            Object.values(terms.inputFields || {})
                                .filter((term) => {
                                    return !term.inact;
                                })
                                .sort((a, b) => {
                                    if (a.orden > b.orden) {
                                        return 1;
                                    } else if (a.orden < b.orden) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .forEach((term) => {
                                    filteredTermsInputs[term.id] = term;
                                });
                            this.viewingProjectTermsInputs = filteredTermsInputs || {};

                            this.setTermsInputsDefaults();

                            this.$f7.dialog.close();

                            this.termsPopupOpened = true;

                            return;
                        }
                    }
                    if (this.projectInFocus != projectid) {
                        this.$store.commit("setWhatTo", {
                            what: "entityDraft",
                            to: null,
                        });
                        this.$store.commit("setWhatTo", {
                            what: "fetchedPatients",
                            to: [],
                        });
                        this.$store.commit("setWhatTo", {
                            what: "allentities",
                            to: [],
                        });
                        this.$store.commit("setWhatTo", {
                            what: "lastEntityId",
                            to: null,
                        });
                    }
                    this.$store.commit("setWhatTo", {
                        what: "projectInFocus",
                        to: projectid,
                    });

                    //ver donde navegar
                    let privsperprojcet = this.user.privsByProject || {};
                    this.checkTwoSteps(privsperprojcet, projectid);
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                    console.error(error);
                }
            },
            async checkTwoSteps(privsperprojcet, projectid) {
                try {
                    let twoStepsCredentialsPromise = await this.$firebase
                        .database()
                        .ref(`twoStepsCredentials/${this.user.uid}`)
                        .once("value");
                    let twoStepsCredentials = twoStepsCredentialsPromise.val() || {};
                    let clientids = twoStepsCredentials.clientids;
                    let phoneNumber = twoStepsCredentials.phoneNumber;
                    let clientid = localStorage.getItem("clientid");
                    let notExpired = clientids?.[projectid]?.[clientid] > this.$dayjs().unix();
                    if (!this.projectInFocusInfo.twoSteps || notExpired) {
                        this.$f7.dialog.close();
                        return this.canGoToProject(privsperprojcet, projectid);
                    }
                    if (!phoneNumber) {
                        this.enterPhone = true;
                        const input = this.$$("#phoneNumber").find("input")[0];
                        if (input) {
                            setTimeout(() => {
                                input.focus();
                            }, 500);
                        }
                    } else this.sendCode(privsperprojcet, projectid);
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
            async confirmPhoneNumber() {
                this.phoneNumber = this.phoneNumber.replaceAll("(", "");
                this.phoneNumber = this.phoneNumber.replaceAll(")", "");
                this.phoneNumber = this.phoneNumber.replaceAll("-", "");
                this.phoneNumber = this.phoneNumber.replaceAll(" ", "");
                try {
                    if (this.phoneNumber.charAt(0) != "+") {
                        let plus = "+";
                        this.phoneNumber = plus.concat(this.phoneNumber);
                    }
                    let valid = /^[0-9\-\+]{12,15}$/.test(this.phoneNumber);
                    if (!valid) return this.$f7.dialog.alert("You must provide a valid phone number");
                    this.preloader = true;
                    let call = this.$firebase.functions().httpsCallable("v2_updatePhoneNumber");
                    let response = await call({
                        phoneNumber: this.phoneNumber,
                        dev: this.dev,
                    });
                    if (response.error) throw new Error(error.message);
                    this.$store.commit("updateUserInfo", {
                        cual: "phone",
                        valor: this.phoneNumber,
                    });
                    this.enterPhone = false;
                    this.sendCode();
                } catch (error) {
                    this.preloader = false;
                    this.$f7.dialog.alert(error.message);
                    console.log(error);
                }
            },
            async sendCode() {
                try {
                    if (!this.preloader) this.preloader = true;
                    this.enterCode = true;
                    let call = this.$firebase.functions().httpsCallable("v2_sendcodeSMS");
                    let response = await call({ dev: this.dev });
                    if (response.error) throw new Error(response.data.error);
                    this.preloader = false;
                } catch (error) {
                    this.preloader = false;
                    this.$f7.dialog.alert(error.message);
                    console.log(error);
                }
            },
            async confirmCode() {
                try {
                    if (this.code1.length < 3 || this.code2.length < 3)
                        return this.$f7.dialog.alert("A 6 digits code is needed");
                    let code = this.code1.concat(this.code2);
                    this.preloader = true;
                    let call = this.$firebase.functions().httpsCallable("v2_checkCode");
                    let response = await call({
                        code,
                        clientid: localStorage.getItem("clientid"),
                        validateOneMonth: this.validateOneMonth,
                        projectid: this.projectInFocus,
                        dev: this.dev,
                    });
                    if (response.error) throw new Error(error.message);
                    if (response.data.correct) {
                        let privsperprojcet = this.user.privsByProject || {};

                        if (!this.isConfirmingPhoneNumberBeforeTerms) {
                            this.canGoToProject(privsperprojcet, this.projectInFocus);
                        } else {
                            this.validateAndAcceptTerms();
                        }

                        this.enterCode = false;
                        this.preloader = false;
                    } else {
                        this.preloader = false;
                        return this.$f7.dialog.alert("Incorrect Code");
                    }
                } catch (error) {
                    this.preloader = false;
                    this.$f7.dialog.alert(error.message);
                    console.log(error);
                }
            },
            canGoToProject() {
                let target = "/dashboard/";
                this.$store.commit("setWhatTo", { what: "activeTab", to: target });
                this.$store.commit("setWhatTo", {
                    what: "forceProjectSelect",
                    to: false,
                });
                this.$f7.views.main.router.navigate(target);
            },
            async pideProjectos(force) {
                try {
                    if (!this.projects.length || !this.$store.state.lastFullLoad.projects || force) {
                        console.log("force");
                        this.loading = true;
                        let snap = await this.$firebase
                            .database()
                            .ref(`projects`)
                            .orderByChild("visible")
                            .equalTo(true)
                            .once("value");
                        if (snap.exists()) {
                            this.$store.commit("setObjTo", {
                                what: "projects",
                                to: snap.val(),
                            });
                        }
                        // let visibles = snap.val() || {};

                        let privsRef = this.$firebase
                            .database()
                            .ref("privsByProject")
                            .orderByChild("uid")
                            .equalTo(this.user.uid);

                        const privsSnap = await privsRef.once("value");
                        if (privsSnap.exists()) {
                            let locluser = Object.assign({}, this.user);
                            privsSnap.forEach((privSnap) => {
                                const priv = privSnap.val();
                                locluser.privsByProject[priv.pid] = priv;
                            });
                            this.$store.commit("setObjTo", { what: "user", to: locluser });
                        }
                    }

                    this.$store.commit("setObjTo", {
                        what: "savedQueries",
                        to: {},
                    });
                    this.$store.commit("setObjTo", {
                        what: "supportFiles",
                        to: {},
                    });
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    return this.$f7.dialog.alert(error.message, error.code);
                }
            },
            async reqAccces() {
                try {
                    this.$f7.dialog.preloader("Requesting access...");
                    let datous = await this.commonExecute(
                        {
                            projectid: this.resquestAccessTo.id,
                            reason: this.requestReason,
                        },
                        "v2_requestAccess",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let newOne = datous.payload;
                    let user = Object.assign({}, this.user);
                    user.privsByProject_req[newOne.pid] = newOne;
                    this.$store.commit("setObjTo", { what: "user", to: user });
                    // console.log('newOne',newOne);
                    this.resquestAccessTo = null;
                    this.requestReason = "";
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            formatPhoneNumber(phoneNumberString) {
                if (phoneNumberString) {
                    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
                    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                    if (match) {
                        let intlCode = match[1] ? "+1 " : "";
                        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
                    }
                    return phoneNumberString;
                }
            },
        },
    };
</script>
<style>
    .card-project-pic .card-header {
        height: 150px;
        background-size: cover;
        background-position: center;
        color: #fff;
        font-weight: bold;
        text-shadow: 2px 2px #161616;
        font-size: 20px;
    }
    .card-project-pic .card-content-padding .date {
        color: #8e8e93;
    }
    .nopointer {
        cursor: unset !important;
    }
    .descripprocore {
        height: 100px;
        max-height: 100px;
        overflow-y: auto;
        border: 1px solid #cecece;
        background: #f7f7f7;
        border-radius: 10px;
    }
    .pronamexcor {
        background: #00000061;
        width: 100%;
    }
    .elfooter {
        background-color: #3d3d3d;
        color: #fff;
        height: 60px;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .inlineInputs {
        width: 30%;
        display: inline-flex;
    }
    .inlineInputs input {
        text-align: center;
    }
    .whiteBg {
        background-color: white;
    }
    .list ul .no-bg-color {
        background: none;
    }
    .scrollable-text {
        max-height: 300px;
        overflow-y: auto;
    }
    .cancelButton {
        background: white;
        border: solid;
        color: red;
    }
    .pb-x {
        padding-bottom: 45px;
    }
</style>
