<template>
    <f7-page>
        <f7-navbar title="import from REDCAP" back-link="Back"></f7-navbar>
        <f7-card class="padding-bottom">
            <f7-list class="no-margin">
                <f7-list-item header="REDCAP Token" :title="redcapToken">
                    <f7-link @click="openRedcapTokePrompt()" slot="after">
                        <font-awesome-icon :icon="['fad', 'pen']" />
                    </f7-link>
                </f7-list-item>
                <f7-list-item header="REDCAP URL" :title="redcapUrl">
                    <f7-link @click="openRedcapUrlPrompt()" slot="after">
                        <font-awesome-icon :icon="['fad', 'pen']" />
                    </f7-link>
                </f7-list-item>
            </f7-list>
            <f7-list class="no-margin">
                <f7-list-item divider title="ID field"></f7-list-item>
                <f7-list-item header="ID field" :title="entityId">
                    <div slot="after">
                        <f7-link @click="openIdFieldPrompt()"><font-awesome-icon :icon="['fad', 'pen']" /></f7-link>
                    </div>
                </f7-list-item>
            </f7-list>
        </f7-card>
        <f7-list v-if="!entityIdNotFound" class="no-margin">
            <f7-list-item divider title="Import"></f7-list-item>
            <f7-list-item
                radio
                value="instruments"
                @change="setTypeOfImport('instruments')"
                name="typeOfImport-radio"
                :checked="typeOfImport === 'instruments'"
                title="Instruments"
                text="Import forms from redcap"
            ></f7-list-item>
            <f7-list-item
                radio
                value="reports"
                @change="setTypeOfImport('reports')"
                name="typeOfImport-radio"
                :checked="typeOfImport === 'reports'"
                title="Reports"
                text="Import reports from redcap"
            ></f7-list-item>
        </f7-list>
        <template>
            <f7-list v-show="typeOfImport == 'instruments'" class="no-margin">
                <f7-list-item divider title="Forms to import"></f7-list-item>
                <f7-list-item
                    smart-select
                    ref="redcapForms"
                    :smart-select-params="{
                        openIn: 'popup',
                        virtualList: true,
                        searchbar: true,
                        popupCloseLinkText: 'Done',
                        cssClass: 'doneButton',
                        on: {
                            closed: () => {
                                getDataDependingOnForms();
                            },
                            opened: () => {
                                selectedFormsBeforeChange = selectedForms;
                            },
                        },
                    }"
                    title="Select REDCAP forms to import"
                >
                    <select multiple @change="selectForms()">
                        <option
                            v-for="form in redcapForms"
                            :selected="selectedForms.includes(form.instrument_name)"
                            :key="form.instrument_name"
                            :value="form.instrument_name"
                        >
                            {{ form.instrument_label }}
                        </option>
                    </select>
                </f7-list-item>
                <f7-list-item
                    v-if="selectedForms.length"
                    checkbox
                    no-hairlines
                    title="Skip entities that don't have the selected forms completed"
                    :checked="onlyCompletedForms"
                    @change="(onlyCompletedForms = $event.target.checked), getRedcapEntities()"
                ></f7-list-item>
            </f7-list>
            <template v-if="typeOfImport == 'reports'">
                <f7-list class="no-margin">
                    <f7-list-item divider title="Report ID"></f7-list-item>
                    <f7-list-item header="Report ID" :title="reportId">
                        <div slot="after">
                            <f7-link @click="openReportIdPrompt()">
                                <font-awesome-icon :icon="['fad', 'pen']" />
                            </f7-link>
                        </div>
                    </f7-list-item>
                </f7-list>
            </template>
        </template>
        <f7-list
            v-if="
                !entityIdNotFound &&
                ((typeOfImport == 'instruments' && selectedForms.length) || (typeOfImport == 'reports' && reportId))
            "
            class="no-margin"
        >
            <f7-list-item divider title="What to do with imported data"></f7-list-item>
            <f7-list-item
                :class="{ disabled: !InstitutionsICanCreateFrom.length }"
                radio
                value="makeEntities"
                @change="setTypeOfUpload('makeEntities')"
                name="typeOfUpload-radio"
                :checked="typeOfUpload === 'makeEntities'"
                title="Create new entities"
                text="indicating which column corresponds to what entity data"
                :footer="!InstitutionsICanCreateFrom.length ? ' - No privileges for creating patients -' : null"
            ></f7-list-item>
            <f7-list-item
                :class="{ disabled: !InstitutionsICanEditFrom.length }"
                radio
                value="createFiles"
                @change="setTypeOfUpload('createFiles')"
                name="typeOfUpload-radio"
                :checked="typeOfUpload === 'createFiles'"
                title="Add files to existing entities"
                text="indicating the column corresponding to the entity id."
                :footer="!InstitutionsICanEditFrom.length ? ' - No privileges for updating entities - ' : null"
            ></f7-list-item>
        </f7-list>
        <template v-if="typeOfUpload">
            <template v-if="(entityId || reportId) && typeOfUpload == 'makeEntities'">
                <f7-list class="no-margin">
                    <f7-list-item divider title="Institution to upload new entities"></f7-list-item>
                    <f7-list-item
                        radio
                        name="Institutions"
                        :title="`Select ${projectInFocusInfo.grouper || 'Institution'} from CIELO `"
                        @change="getInstitutionsFrom = 'cielo'"
                        :checked="getInstitutionsFrom === 'cielo'"
                    ></f7-list-item>
                    <f7-list-item
                        radio
                        name="Institutions"
                        :title="`Select ${projectInFocusInfo.grouper || 'Institution'} from a REDCAP field`"
                        @change="getInstitutionsFrom = 'redcap'"
                        :checked="getInstitutionsFrom === 'redcap'"
                    ></f7-list-item>
                    <f7-list-input
                        v-if="getInstitutionsFrom === 'cielo' && InstitutionsICanCreateFrom.length > 1"
                        :label="`Select the ${
                            projectInFocusInfo.grouper || 'Institution'
                        } where to create the entities`"
                        type="select"
                        placeholder="Please choose..."
                        outline
                        :value="institIDToCreateAt"
                        @input="institIDToCreateAt = $event.target.value"
                    >
                        <option value="">
                            Select the
                            {{ projectInFocusInfo.grouper || "Institution" }}
                        </option>

                        <option v-for="institux in InstitutionsICanCreateFrom" :key="institux.id" :value="institux.id">
                            {{ institux.name }}
                        </option>
                    </f7-list-input>
                    <f7-list-input
                        v-else-if="getInstitutionsFrom === 'redcap'"
                        :label="`Select the REDCAP field where to find the ${
                            projectInFocusInfo.grouper || 'Institution'
                        }`"
                        type="select"
                        placeholder="Please choose..."
                        outline
                        :value="institIDToCreateAt"
                        @input="setInstitIDToCreateAt($event.target.value)"
                    >
                        <option value="">Select the REDCAP field</option>

                        <option v-for="field in fieldsNames" :key="field" :value="field">
                            {{ field }}
                        </option>
                    </f7-list-input>
                </f7-list>
                <f7-list
                    v-if="getInstitutionsFrom === 'redcap' && institIDToCreateAt"
                    media-list
                    accordion-list
                    class="no-margin"
                >
                    <f7-list-item
                        accordion-item
                        :class="{
                            warnmaps: getThisFieldAnwsers().every((ans) => {
                                return mappedRedcapInstitutions[ans];
                            })
                                ? false
                                : true,
                        }"
                        class="anciliaryx accordion-item-opened"
                        :title="
                            getThisFieldAnwsers().every((ans) => {
                                return mappedRedcapInstitutions[ans];
                            })
                                ? `All ${projectInFocusInfo.grouper || 'Institutions'} mapped OK`
                                : `Please map all ${projectInFocusInfo.grouper || 'Institutions'}`
                        "
                    >
                        <font-awesome-icon
                            v-if="
                                !getThisFieldAnwsers().every((ans) => {
                                    return mappedRedcapInstitutions[ans];
                                })
                            "
                            slot="media"
                            class="fa-lg text-color-red"
                            :icon="['fad', 'triangle-exclamation']"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            slot="media"
                            class="fa-lg text-color-primary"
                            :icon="['fad', 'circle-check']"
                        ></font-awesome-icon>
                        <f7-accordion-content>
                            <f7-list class="bg-color-white" inset media-list>
                                <f7-list-item class="leheaderxmappers" title="Distinct values in field"></f7-list-item>
                                <f7-list-item
                                    v-for="answer in getThisFieldAnwsers()"
                                    :key="answer"
                                    :title="answer"
                                    :class="{
                                        pendingOptionSetWarning: !mappedRedcapInstitutions[answer],
                                    }"
                                >
                                    <f7-input
                                        slot="after"
                                        outline
                                        :label="`map ${projectInFocusInfo.grouper || 'Institutions'}`"
                                        type="select"
                                        placeholder="Please choose..."
                                        @input="mapRedcapInstitutions(answer, $event.target.value)"
                                        :value="mappedRedcapInstitutions[answer]"
                                    >
                                        <option value="">Select potential map</option>
                                        <option
                                            v-for="inst in InstitutionsICanCreateFrom"
                                            :key="inst.id"
                                            :value="inst.id"
                                            :selected="mappedRedcapInstitutions[answer] == inst.id"
                                        >
                                            {{ inst.name }}
                                        </option>
                                        <option
                                            v-for="(newinst, i) in newInstitutions"
                                            :key="i"
                                            :value="newinst"
                                            :selected="mappedRedcapInstitutions[answer] == newinst"
                                        >
                                            {{ newinst }}
                                        </option>
                                        <option value="creaNeo">Create New institution</option>
                                    </f7-input>
                                </f7-list-item>
                            </f7-list>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
            </template>
        </template>
        <template v-if="(entityId || reportId) && typeOfUpload">
            <f7-list v-if="typeOfUpload == 'makeEntities'" class="no-margin">
                <f7-list-item divider title="Skip entities"></f7-list-item>
                <f7-list-item
                    checkbox
                    no-hairlines
                    title="Skip entities that already exist"
                    :checked="skippedExistingEntities"
                    @change="getSkippedExistingEntities($event.target.checked)"
                ></f7-list-item>
            </f7-list>
            <template
                v-if="
                    typeOfUpload == 'makeEntities' &&
                    ((typeOfImport == 'instruments' && selectedForms.length) || (typeOfImport == 'reports' && reportId))
                "
            >
                <f7-card v-for="onedata in enabledData" :key="onedata.id" :padding="false">
                    <f7-card-header class="albolder">
                        {{ `${onedata.encabezado}${onedata.req ? "(*)" : ""}` }}
                    </f7-card-header>
                    <f7-card-content :padding="false">
                        <f7-list media-list accordion-list class="no-margin">
                            <f7-list-item
                                class="inutreal"
                                :class="{
                                    pendingOptionSetWarning: !mappedFields[onedata.id],
                                }"
                                title="Data column"
                                :footer="`Sample data parsed : ${getSample(onedata.id)}`"
                            >
                                <f7-input
                                    slot="after"
                                    label="Data field"
                                    type="select"
                                    outline
                                    placeholder="Please choose..."
                                    :value="mappedFields[onedata.id]"
                                    @input="
                                        mapField({
                                            entityData: onedata,
                                            neoval: $event.target.value,
                                        })
                                    "
                                >
                                    <option value="">Select field</option>
                                    <option
                                        v-for="(unacol, index) in redcapEntityDataFields"
                                        :key="index"
                                        :value="unacol.field_name"
                                        :selected="unacol.field_name == mappedFields[onedata.id]"
                                    >
                                        {{ unacol.field_label }}
                                    </option>
                                    <option v-if="!onedata.req" value="skip">- SKIP, NO DATA IN THIS FILE -</option>
                                </f7-input>
                            </f7-list-item>
                            <template
                                v-if="
                                    pregsMultiChoice.includes(onedata.tipo) &&
                                    mappedFields[onedata.id] &&
                                    mappedFields[onedata.id] != 'skip'
                                "
                            >
                                <f7-list-item
                                    v-if="getRedcapAlternatives(mappedFields[onedata.id]).length > 0"
                                    accordion-item
                                    :class="{
                                        warnmaps: getAllmapOk(onedata) ? false : true,
                                    }"
                                    class="anciliaryx"
                                    :title="
                                        getAllmapOk(onedata)
                                            ? 'All alternatives mapped OK'
                                            : 'Please map all alternatives'
                                    "
                                >
                                    <font-awesome-icon
                                        v-if="!getAllmapOk(onedata)"
                                        slot="media"
                                        class="fa-lg text-color-red"
                                        :icon="['fad', 'triangle-exclamation']"
                                    ></font-awesome-icon>
                                    <font-awesome-icon
                                        v-else
                                        slot="media"
                                        class="fa-lg text-color-primary"
                                        :icon="['fad', 'circle-check']"
                                    ></font-awesome-icon>
                                    <f7-accordion-content>
                                        <f7-list class="bg-color-white" inset media-list>
                                            <f7-list-item class="leheaderxmappers" title="Distinct values in file">
                                                <f7-button slot="after" @click="createNewOptionsForAll(onedata)">
                                                    Create options from all new columns
                                                </f7-button>
                                            </f7-list-item>
                                            <f7-list-item
                                                v-for="inneralt in getRedcapAlternatives(mappedFields[onedata.id])"
                                                :key="inneralt"
                                                :title="inneralt"
                                                :class="{
                                                    wanmapper:
                                                        !mappedAlternatives[onedata.id] ||
                                                        !mappedAlternatives[onedata.id][inneralt] ||
                                                        !mappedAlternatives[onedata.id][inneralt] ||
                                                        mappedAlternatives[onedata.id][inneralt] === '',
                                                }"
                                            >
                                                <f7-input
                                                    slot="after"
                                                    outline
                                                    label="map alternatives"
                                                    type="select"
                                                    placeholder="Please choose..."
                                                    @input="
                                                        setAlterMapVal({
                                                            preguntaIdx: onedata.id,
                                                            normKey: inneralt,
                                                            alternativeId: $event.target.value,
                                                        })
                                                    "
                                                    :value="
                                                        mappedAlternatives[onedata.id] &&
                                                        mappedAlternatives[onedata.id][inneralt]
                                                            ? mappedAlternatives[onedata.id][inneralt] || ''
                                                            : ''
                                                    "
                                                >
                                                    <option value="">Select potential map</option>
                                                    <option
                                                        v-for="alt in filteredAternatives(onedata.alternativas)"
                                                        :key="alt.id"
                                                        :value="alt.id"
                                                    >
                                                        {{ alt.tx }}
                                                    </option>
                                                    <option value="creaNeo">Create New Option</option>
                                                    <option value="noImport">Do not import</option>
                                                </f7-input>
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-accordion-content>
                                </f7-list-item>
                                <f7-list-item
                                    v-else-if="getRedcapAlternatives(mappedFields[onedata.id]).length == 0"
                                    footer="This Redcap field doesn't have alternatives, please select another one"
                                >
                                    <font-awesome-icon
                                        slot="media"
                                        class="fa-lg text-color-red"
                                        :icon="['fad', 'triangle-exclamation']"
                                    ></font-awesome-icon>
                                </f7-list-item>
                            </template>
                        </f7-list>
                    </f7-card-content>
                </f7-card>
            </template>
            <div
                v-show="
                    typeOfUpload == 'createFiles' &&
                    ((typeOfImport == 'instruments' && selectedForms.length) || (typeOfImport == 'reports' && reportId))
                "
            >
                <f7-list class="no-margin">
                    <f7-list-item divider title="Target"></f7-list-item>
                    <f7-list-input
                        label="Target file to generate"
                        type="select"
                        placeholder="Please choose..."
                        :value="targetFile"
                        outline
                        @input="targetFile = $event.target.value"
                        :disabled="shouldCreateNewFileField || !enabledFiles.length"
                    >
                        <option value="">Select target file</option>
                        <option v-for="unfile in enabledFiles" :key="unfile.id" :value="unfile.id">
                            {{ unfile.encabezado }}
                        </option>
                    </f7-list-input>
                    <f7-list-item
                        v-if="typeOfUpload === 'createFiles'"
                        checkbox
                        no-hairlines
                        title="(Optional) Create a new file field for this project"
                        :checked="shouldCreateNewFileField"
                        @change="
                            (shouldCreateNewFileField = $event.target.checked),
                                skippedEntities ? getSkippedEntities(false) : ''
                        "
                        :disabled="!enabledFiles.length"
                    ></f7-list-item>
                    <template v-if="shouldCreateNewFileField">
                        <f7-list-input
                            label="New file field header (required)"
                            type="text"
                            placeholder="Please enter a header"
                            outline
                            :value="newFileField.encabezado"
                            @input="newFileField.encabezado = $event.target.value"
                        ></f7-list-input>
                        <f7-list-item
                            checkbox
                            title="Multiple files (leave empty for single file field)"
                            :checked="newFileField.multiple"
                            @change="newFileField.multiple = $event.target.checked"
                        ></f7-list-item>
                        <f7-list-input
                            type="select"
                            label="Supported type of file"
                            placeholder="Please choose..."
                            outline
                            :value="newFileField.tipo"
                            @input="newFileField.tipo = $event.target.value"
                        >
                            <option v-for="file in availableFileTypes" :key="file.id" :value="file.id">
                                {{ file.id === "a" ? "Any" : file.id }}
                            </option>
                        </f7-list-input>
                    </template>
                    <f7-list-item
                        v-if="typeOfUpload === 'createFiles' && !shouldCreateNewFileField"
                        checkbox
                        :class="{ disabled: !targetFile }"
                        no-hairlines
                        title="Skip entities that already have a file in the target"
                        :checked="skippedEntities"
                        @change="getSkippedEntities($event.target.checked)"
                    ></f7-list-item>
                    <f7-list-item divider title="5.- File tags"></f7-list-item>
                    <f7-list-item
                        :disabled="!tags.length"
                        :after="!tags.length ? 'No tags created or active' : ''"
                        smart-select
                        ref="tagsSelection"
                        :smart-select-params="{
                            openIn: 'popup',
                            virtualList: true,
                            searchbar: true,
                            popupCloseLinkText: 'Done',
                            cssClass: 'doneButton',
                        }"
                        title="Select tags"
                    >
                        <select multiple @change="selectTag()">
                            <option v-for="tag in tags" :selected="selectedTags[tag.id]" :key="tag.id" :value="tag.id">
                                {{ tag.tx }}
                            </option>
                        </select>
                    </f7-list-item>
                </f7-list>
            </div>
        </template>
        <f7-block v-if="entityId && typeOfUpload">
            <f7-button v-if="typeOfUpload == 'createFiles'" @click="uploadRedcapFiles()" fill>Process</f7-button>
            <f7-button v-if="typeOfUpload == 'makeEntities'" @click="uploadRedcapEntities()" fill>Process</f7-button>
        </f7-block>
        <f7-popover class="popover-errors-entityid">
            <f7-list media-list v-if="arrayOfErrors.length > 20">
                <f7-list-item
                    v-for="unerror in onlyFirst20Errors"
                    :key="unerror"
                    popover-close
                    :title="`The ID ${unerror}`"
                    :text="getAllItsErrors(unerror)"
                ></f7-list-item>
                <f7-list-item
                    v-if="arrayOfErrors.length > 21"
                    :title="`There are ${arrayOfErrors.length - 20} more errors`"
                ></f7-list-item>
            </f7-list>
            <f7-list v-else media-list>
                <f7-list-item
                    v-for="unerror in arrayOfErrors"
                    :key="unerror"
                    popover-close
                    :title="`ID: ${unerror}`"
                    :text="getAllItsErrors(unerror)"
                ></f7-list-item>
            </f7-list>
        </f7-popover>
        <f7-toolbar position="bottom">
            <f7-list media-list class="no-margin">
                <f7-list-item
                    link="#"
                    popover-open=".popover-errors-entityid"
                    class="text-color-red"
                    v-if="erroresTotaltes"
                    :title="`${erroresTotaltes} ${erroresTotaltes != 1 ? 'entities' : 'entity'} will be skipped`"
                ></f7-list-item>
                <f7-list-item v-else title="With current config no errors detected"></f7-list-item>
            </f7-list>

            <f7-list media-list class="no-margin">
                <f7-list-item
                    :title="`${aImportar} ${aImportar != 1 ? 'entities' : 'entity'} will be processed`"
                ></f7-list-item>
            </f7-list>
        </f7-toolbar>
    </f7-page>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import stringSimilarity from "string-similarity";
    import getelementsbyclassname from "caniuse-lite/data/features/getelementsbyclassname";
    export default {
        data() {
            return {
                shouldCreateNewFileField: false,
                newFileField: {
                    encabezado: "",
                    tipo: "a",
                    multiple: false,
                },
                skippedEntities: null,
                skippedExistingEntities: null,
                typeOfUpload: "",
                targetFile: "",
                shouldCreateNewFileField: false,
                redcapToken: "",
                redcapUrl: "",
                entityId: "",
                redcapForms: [],
                selectedForms: [],
                redcapForm: "",
                formFields: [],
                fieldsNames: [],
                erroresTotaltes: 0,
                arrayOfErrors: [],
                allRedcapEntities: [],
                notExistingEntities: [],
                existingFile: [],
                aImportar: 0,
                redcapEntities: [],
                selectedTags: {},
                onlyCompletedForms: false,
                incompletedForms: [],
                selectedFormsBeforeChange: [],
                tieneHeaders: null,
                redcapMetadata: [],
                mappedFields: {},
                pregsMultiChoice: ["elige_many", "elige_una"],
                todosMapeosOk: {},
                optionsMapper: {},
                mappedAlternatives: {},
                institIDToCreateAt: "",
                alreadyExistingEntities: [],
                getInstitutionsFrom: "cielo",
                mappedRedcapInstitutions: {},
                fieldWhereToFetchEntityId: "",
                whereToFetchReportId: "",
                entityIdNotFound: false,
                typeOfImport: "instruments",
                reportId: "",
                reportData: [],
            };
        },
        created() {
            if (this.$store.state.activeTab === "/dataimport/") {
                this.getRedcapTokenUrl();
                if (!this.enabledFiles.length) {
                    this.shouldCreateNewFileField = true;
                }
            }
        },
        mounted() {},
        computed: {
            ...mapState(["user", "forms_logic", "projectInFocus"]),
            ...mapGetters(["projectInFocusInfo"]),
            myPrivsHere() {
                let allprivs = this.user.privsByProject || {};
                return allprivs[this.$store.state.projectInFocus] || {};
            },
            InstitutionsICanCreateFrom() {
                if (this.myPrivsHere.admin && this.myPrivsHere.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere?.prv) {
                    let cuales = Object.values(this.myPrivsHere?.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.create || potinsti.det.update) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            newInstitutions() {
                return Object.values(this.mappedRedcapInstitutions).filter((value, i) => {
                    //  let id = Object.keys(this.mappedRedcapInstitutions)[i]
                    return !this.InstitutionsICanCreateFrom.find((inst) => {
                        return value == inst.id;
                    });
                });
            },
            InstitutionsICanEditFrom() {
                if (this.myPrivsHere.admin && this.myPrivsHere.admin.full) {
                    return Object.values(this.projectInFocusInfo.institutions).filter((uninst) => {
                        return !uninst.inactive;
                    });
                } else if (this.myPrivsHere?.prv) {
                    let cuales = Object.values(this.myPrivsHere?.prv).filter((potinsti) => {
                        return (
                            potinsti.det &&
                            (potinsti.det.update || potinsti.det.create) &&
                            !this.projectInFocusInfo.institutions[potinsti.id].inactive
                        );
                    });
                    return cuales;
                } else {
                    return [];
                }
            },
            enabledFiles() {
                return this.projectInFocusInfo.entityFiles ? this.allEnabler(this.projectInFocusInfo.entityFiles) : [];
            },
            enabledData() {
                return this.projectInFocusInfo.entityData ? this.allEnabler(this.projectInFocusInfo.entityData) : [];
            },
            onlyFirst20Errors() {
                if (this.arrayOfErrors.length == 21) return this.arrayOfErrors.slice(0, 20);
                return this.arrayOfErrors.slice(0, 19);
            },
            tags() {
                let tags = Object.values(this.projectInFocusInfo?.fileTags || {}) || [];
                return tags
                    .filter((tag) => {
                        return !tag.inct;
                    })
                    .sort((a, b) => {
                        let alow = a.tx || a.tx;
                        let blow = b.tx || b.tx;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            availableFileTypes() {
                return Object.values(this.forms_logic.tipos_files || {}).filter((fileType) => {
                    return fileType.id === "a" || fileType.id === "csv";
                });
            },
            redcapEntityDataFields() {
                return this.redcapMetadata;
            },
        },
        methods: {
            allEnabler(esteObj) {
                return Object.values(esteObj || {})
                    .filter((unet) => {
                        return !unet.disabled;
                    })
                    .sort((a, b) => {
                        let alow = a.orden;
                        let blow = b.orden;
                        if (alow > blow) {
                            return 1;
                        } else if (alow < blow) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
            },
            async getRedcapEntities() {
                if (!this.redcapToken || !this.redcapUrl) return this.$f7.dialog.alert("A token and an Url are needed");
                if (!this.entityId) return this.$f7.dialog.alert("Select the field where the Entity ID is");
                let call = this.$firebase.functions().httpsCallable("v2_getRedcapEntities");
                try {
                    if (this.typeOfImport == "reports" && !this.reportId) return;
                    let params = {
                        projectid: this.projectInFocusInfo.id,
                        entityId: this.entityId,
                        dev: this.$store.state.dev,
                        token: this.redcapToken,
                        url: this.redcapUrl,
                        targetFile: this.targetFile,
                        skippedEntities: this.skippedEntities,
                        skippedExistingEntities: this.skippedExistingEntities,
                        onlyCompletedForms: this.onlyCompletedForms,
                        selectedForms: this.selectedForms,
                        typeOfUpload: this.typeOfUpload,
                        typeOfImport: this.typeOfImport,
                        reportId: this.reportId,
                    };
                    // params.typeOfUpload = this.typeOfUpload;
                    // params.entityId = this.entityId;
                    this.$f7.dialog.preloader("Conecting with REDCAP...");

                    let response = await call(params);
                    console.log("getRedcapEntities", response, response.data);
                    if (response.data.error) throw new Error(response.data.error.error || response.data.error);
                    this.notExistingEntities = response.data.notExistingEntities;
                    this.existingFile = response.data.existingFile;
                    this.incompletedForms = response.data.incompletedForms;
                    this.alreadyExistingEntities = response.data.alreadyExistingEntities;
                    let preArrayOfErrors = this.notExistingEntities.concat(
                        this.existingFile,
                        this.incompletedForms,
                        this.alreadyExistingEntities,
                    );
                    this.arrayOfErrors = [];
                    preArrayOfErrors.forEach((error) => {
                        if (!this.arrayOfErrors.includes(error)) this.arrayOfErrors.push(error);
                    });
                    this.allRedcapEntities = response.data.allRedcapEntities;
                    this.erroresTotaltes = this.arrayOfErrors.length;
                    this.redcapEntities = response.data.correctEntities;
                    this.aImportar = response.data.correctEntities.length;

                    return this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                    if (
                        error.message.startsWith("The record") ||
                        error.message == "The entity id field can not be Empty"
                    )
                        this.entityIdNotFound = true;
                    return { error };
                }
                return error;
            },
            openRedcapTokePrompt() {
                this.$f7.dialog.prompt(
                    "Redcap Token",
                    (value) => {
                        this.setRedcapTokenUrl("token", value);
                    },
                    () => {},
                    this.redcapToken,
                );
            },
            openRedcapUrlPrompt() {
                this.$f7.dialog.prompt(
                    "Redcap Url",
                    (value) => {
                        this.setRedcapTokenUrl("url", value);
                    },
                    () => {},
                    this.redcapUrl,
                );
            },
            openIdFieldPrompt() {
                this.$f7.dialog.prompt(
                    "EntIty ID field",
                    (value) => {
                        this.fieldWhereToFetchEntityId = value;
                        this.fetchEntityIdField();
                    },
                    () => {},
                    this.entityId,
                );
            },
            openReportIdPrompt() {
                this.$f7.dialog.prompt(
                    "Report ID",
                    (value) => {
                        this.whereToFetchReportId = value;
                        this.fetchReportId();
                    },
                    () => {},
                    this.reportId,
                );
            },
            async getRedcapForms() {
                if (!this.redcapToken || !this.redcapUrl) return this.$f7.dialog.alert("A token and an Url are needed");
                let call = this.$firebase.functions().httpsCallable("v2_getRedcapForms");
                try {
                    this.$f7.dialog.preloader("Loading...");
                    let response = await call({
                        token: this.redcapToken,
                        url: this.redcapUrl,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.redcapForms = response.data;
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            async getRedcapFieldsNames() {
                if (!this.redcapToken || !this.redcapUrl) return this.$f7.dialog.alert("A token and an Url are needed");
                let call = this.$firebase.functions().httpsCallable("v2_getRedcapFieldsNames");
                try {
                    this.$f7.dialog.preloader("Loading...");
                    let response = await call({
                        token: this.redcapToken,
                        url: this.redcapUrl,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.fieldsNames = response.data;
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            async setEntityId(entityId) {
                this.entityId = entityId;
                if (!entityId) return;
                try {
                    let response = await this.getRedcapEntities();
                    if (response.error) throw new Error(error);

                    this.getRedcapForms();
                } catch (error) {
                    console.log(error);
                }
            },
            async uploadRedcapFiles() {
                if (!this.redcapEntities.length) return this.$f7.dialog.alert("There are no entities to import");
                if (!this.redcapToken || !this.redcapUrl) return this.$f7.dialog.alert("A token and an Url are needed");
                if (!this.entityId) return this.$f7.dialog.alert("Select the field where the Entity ID is");
                if (!this.enabledFiles.length && !this.shouldCreateNewFileField) {
                    return this.$f7.dialog.alert(
                        "There are no enabled file fields in the project. You must create a new file field to import data",
                    );
                }
                if (!this.targetFile && !this.shouldCreateNewFileField)
                    return this.$f7.dialog.alert("Select a target file");
                if (this.shouldCreateNewFileField) {
                    if (!this.newFileField.encabezado) {
                        return this.$f7.dialog.alert("You must specify a name for the new file field");
                    }
                    if (!this.newFileField.tipo) {
                        return this.$f7.dialog.alert("You must specify a type for the new file field");
                    }
                }
                if (!this.selectedForms.length && this.typeOfImport == "instruments")
                    return this.$f7.dialog.alert("Select at least one form to import");
                let call = this.$firebase.functions().httpsCallable("v2_uploadRedcapFiles");
                try {
                    this.$f7.dialog.preloader("Uploading...");
                    let response = await call({
                        token: this.redcapToken,
                        url: this.redcapUrl,
                        projectid: this.projectInFocusInfo.id,
                        entityId: this.entityId,
                        dev: this.$store.state.dev,
                        targetFile: this.targetFile,
                        redcapEntities: this.redcapEntities,
                        forms: this.selectedForms,
                        shouldCreateNewFileField: this.shouldCreateNewFileField,
                        newFileField: this.newFileField,
                        targetFileFieldId: this.targetFile,
                        tags: this.selectedTags,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.$f7.dialog.alert(response.data.message);
                    this.$f7.views.main.router.back();
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            setTypeOfUpload(type) {
                this.typeOfUpload = type;
                if (type == "makeEntities") {
                    if (this.typeOfImport == "reports") this.getRedcapMetadata();
                    this.getRedcapFieldsNames();
                }
                this.getRedcapEntities();
            },
            setRedcapForm(form) {
                this.redcapForm = form;
                if (this.typeOfUpload == "makeEntities") this.getRedcapFieldsNames();
            },
            selectForms() {
                let array = this.$refs.redcapForms.f7SmartSelect.getValue() || [];
                let selectedForms = [];
                array.forEach((selected) => {
                    selectedForms.push(selected);
                });
                this.selectedForms = selectedForms;
            },
            selectTag() {
                let array = this.$refs["tagsSelection"].f7SmartSelect.getValue() || [];
                array.forEach((selected) => {
                    this.selectedTags[selected] = true;
                });
                if (this.selectedTags) {
                    Object.keys(this.selectedTags).forEach((tag) => {
                        if (!array.includes(tag)) {
                            delete this.selectedTags[tag];
                        }
                    });
                }
            },
            getSkippedEntities(checked) {
                if (!checked) {
                    this.skippedEntities = null;
                } else {
                    this.skippedEntities = true;
                }
                this.getRedcapEntities();
            },
            getSkippedExistingEntities(checked) {
                if (!checked) {
                    this.skippedExistingEntities = null;
                } else {
                    this.skippedExistingEntities = true;
                }
                this.getRedcapEntities();
            },
            async getRedcapTokenUrl() {
                let call = this.$firebase.functions().httpsCallable("v2_getRedcapTokenUrl");
                try {
                    this.$f7.dialog.preloader("Getting REDCAP Token...");
                    let response = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.$store.state.dev,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.redcapToken = response.data.token || "";
                    this.redcapUrl = response.data.url || "";
                    this.fieldWhereToFetchEntityId = response.data.fieldWhereToFetchEntityId || "";
                    this.reportId = response.data.whereToFetchReportId || "";
                    if (this.fieldWhereToFetchEntityId) this.setEntityId(this.fieldWhereToFetchEntityId);
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            async setRedcapTokenUrl(wich, value) {
                if (!value) return this.$f7.dialog.alert(`${wich} can't be empty`);
                let call = this.$firebase.functions().httpsCallable("v2_setRedcapTokenUrl");
                try {
                    this.$f7.dialog.preloader("Saving...");
                    let response = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.$store.state.dev,
                        wich,
                        value,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    if ((wich == "token" && this.redcapToken != value) || (wich == "url" && this.redcapUrl != value)) {
                        if (wich == "token") this.redcapToken = value;
                        else if (wich == "url") this.redcapUrl = value;
                        if (this.redcapToken && this.redcapUrl && this.fieldWhereToFetchEntityId)
                            this.setEntityId(this.fieldWhereToFetchEntityId);
                    }
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            getDataDependingOnForms() {
                if (this.selectedForms == this.selectedFormsBeforeChange) return;
                this.getRedcapMetadata();
                this.getRedcapEntities();
            },
            async getRedcapMetadata() {
                if (!this.redcapToken || !this.redcapUrl) return this.$f7.dialog.alert("A token and an Url are needed");
                let call = this.$firebase.functions().httpsCallable("v2_getRedcapMetadata");
                try {
                    this.$f7.dialog.preloader("Loading...");
                    let response = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.$store.state.dev,
                        token: this.redcapToken,
                        url: this.redcapUrl,
                        forms: this.selectedForms,
                        typeOfImport: this.typeOfImport,
                        reportId: this.reportId,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    this.redcapMetadata = response.data.toReturn;
                    this.automapFields();
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            mapField({ entityData, neoval }) {
                let reset = this.mappedFields[entityData.id] != neoval;

                this.mappedFields[entityData.id] = neoval;
                this.mappedFields = Object.assign({}, this.mappedFields);
                if ((entityData.tipo == "elige_una" || entityData.tipo == "elige_many") && entityData.alternativas) {
                    if (reset) {
                        delete this.mappedAlternatives[entityData.id];
                    }
                    let cieloAlternatives = this.filteredAternatives(entityData.alternativas) || [];
                    let redcapAlternatives = this.getRedcapAlternatives(neoval) || [];

                    this.autoMapColumns({
                        entityDataId: entityData.id,
                        cieloAlternatives,
                        redcapAlternatives,
                    });
                }
            },
            getSample(id) {
                return this.redcapEntities[0]?.[this.mappedFields?.[id]] || "";
            },
            filteredAternatives(alts) {
                return Object.values(alts || {}).filter((alt) => {
                    return !alt.inct;
                });
            },
            getRedcapAlternatives(field) {
                let fieldMetadata = this.redcapMetadata.find((meta) => {
                    return meta.field_name == field;
                });
                if (!fieldMetadata || !fieldMetadata.select_choices_or_calculations) return [];
                let alternativesString = fieldMetadata.select_choices_or_calculations;
                let arrayOfAlternativesPair = alternativesString.split("| ");
                let alternatives = arrayOfAlternativesPair.map((pair) => {
                    return pair.substring(pair.indexOf(",") + 1).trim();
                });
                return alternatives || [];
            },
            setAlterMapVal({ preguntaIdx, normKey, alternativeId }) {
                if (!this.mappedAlternatives[preguntaIdx]) this.mappedAlternatives[preguntaIdx] = {};
                this.mappedAlternatives[preguntaIdx][normKey] = alternativeId;
                //this.mappedFields = Object.assign({}, this.mappedFields);
                this.mappedAlternatives = Object.assign({}, this.mappedAlternatives);
            },
            async uploadRedcapEntities() {
                if (!this.institIDToCreateAt)
                    return this.$f7.dialog.alert("You must indicate an Institution where to create the entities");
                if (!this.redcapEntities.length) return this.$f7.dialog.alert("There are no entities to import");
                if (!this.redcapToken || !this.redcapUrl) return this.$f7.dialog.alert("A token and an Url are needed");
                if (!this.entityId) return this.$f7.dialog.alert("Select the field where the Entity ID is");
                if (
                    this.getInstitutionsFrom == "redcap" &&
                    !this.getThisFieldAnwsers().every((ans) => {
                        return this.mappedRedcapInstitutions[ans];
                    })
                ) {
                    return this.$f7.dialog.alert("Some institutions are not mapped");
                }
                if (!this.selectedForms.length && this.typeOfImport == "instruments")
                    return this.$f7.dialog.alert("Select at least one form to import");
                if (
                    this.enabledData.some((entityData) => {
                        return entityData.req && !this.mappedFields[entityData.id];
                    })
                )
                    return this.$f7.dialog.alert("Some required fields are missing");
                if (
                    this.enabledData.some((entityData) => {
                        return (
                            entityData.req &&
                            this.pregsMultiChoice.includes(entityData.tipo) &&
                            !this.getAllmapOk(entityData)
                        );
                    })
                )
                    return this.$f7.dialog.alert("Some alternatives are not mapped");

                let call = this.$firebase.functions().httpsCallable("v2_uploadRedcapEntities");
                try {
                    let redcapTypes = {};
                    this.redcapMetadata.forEach((field) => {
                        let entityDataId = Object.keys(this.mappedFields).find((id) => {
                            return this.mappedFields[id] == field["field_name"];
                        });
                        if (entityDataId) redcapTypes[entityDataId] = field["field_type"];
                    });

                    this.$f7.dialog.preloader("Uploading...");
                    let response = await call({
                        token: this.redcapToken,
                        url: this.redcapUrl,
                        projectid: this.projectInFocusInfo.id,
                        entityId: this.entityId,
                        dev: this.$store.state.dev,
                        redcapEntities: this.redcapEntities,
                        fieldsNames: this.fieldsNames,
                        forms: this.selectedForms,
                        mappedFields: this.mappedFields,
                        mappedAlternatives: this.mappedAlternatives,
                        institIDToCreateAt: this.institIDToCreateAt,
                        getInstitutionsFrom: this.getInstitutionsFrom,
                        redcapTypes,
                        mappedRedcapInstitutions: this.mappedRedcapInstitutions,
                    });
                    if (response.data.error) throw new Error(response.data.error);
                    if (
                        response.data.newEntitiesByQuestion &&
                        Object.values(response.data.newEntitiesByQuestion).length
                    ) {
                        let projectinfo = Object.assign({}, this.projectInFocusInfo);
                        Object.keys(response.data.newEntitiesByQuestion).forEach((entitidataid) => {
                            if (!projectinfo.entityData[entitidataid].alternativas)
                                projectinfo.entityData[entitidataid].alternativas = {};
                            projectinfo.entityData[entitidataid].alternativas = Object.assign(
                                projectinfo.entityData[entitidataid].alternativas,
                                response.data.newEntitiesByQuestion[entitidataid],
                            );
                        });

                        this.$store.commit("addItemNivethree", {
                            a1: "projects",
                            b2: this.projectInFocus,
                            c3: "entityData",
                            d4: projectinfo.entityData,
                        });
                    }
                    if (Object.keys(response.data.newInstitutions).length > 0) {
                        let projectinfo = Object.assign({}, this.projectInFocusInfo);
                        projectinfo.institutions = Object.assign(
                            {},
                            projectinfo.institutions,
                            response.data.newInstitutions,
                        );
                        this.$store.commit("addItemNivethree", {
                            a1: "projects",
                            b2: this.projectInFocus,
                            c3: "institutions",
                            d4: projectinfo.institutions,
                        });
                    }
                    this.$f7.views.main.router.back();
                    this.$f7.toast.show({
                        text: "Successful upload!",
                        position: "bottom",
                        horizontalPosition: "center",
                        closeTimeout: 2000,
                    });
                    this.$f7.views.main.router.back();
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            getAllmapOk(entityData) {
                let id = entityData.id;
                if (!this.mappedAlternatives[id]) return false;
                let alternatives = Object.keys(this.mappedAlternatives[id] || {});
                let redcapAlternatives = this.getRedcapAlternatives(this.mappedFields[id]);
                if (
                    redcapAlternatives.some((alt) => {
                        return !alternatives.includes(alt);
                    })
                )
                    return false;
                return true;
            },
            leNormalize(str) {
                let lstring = String(str || "");
                return lstring.trim().toUpperCase();
            },
            autoMapColumns({ entityDataId, cieloAlternatives, redcapAlternatives }) {
                let allPairScores = [];
                redcapAlternatives.forEach((redcapAlt) => {
                    if (redcapAlt !== "") {
                        cieloAlternatives.forEach((cieloAlt) => {
                            let redcapAltLowerCase = redcapAlt.toLowerCase();
                            let tx = cieloAlt.tx.toLowerCase() || "";
                            let score = 0;
                            if (tx === redcapAltLowerCase) {
                                score = 100;
                            } else if (this.leNormalize(tx) === this.leNormalize(redcapAltLowerCase)) {
                                score = 90;
                            } else {
                                score = stringSimilarity.compareTwoStrings(tx, redcapAltLowerCase) * 100;
                            }
                            if (score > 70) {
                                allPairScores.push({
                                    score,
                                    redcapAlt,
                                    cieloAlt: cieloAlt.id,
                                });
                            }
                        });
                    }
                });
                allPairScores.sort((a, b) => {
                    if (a.score < b.score) {
                        return 1;
                    } else if (a.score > b.score) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                allPairScores.forEach((onePair) => {
                    if (!this.mappedAlternatives?.[entityDataId]?.[onePair.redcapAlt])
                        this.setAlterMapVal({
                            preguntaIdx: entityDataId,
                            normKey: onePair.redcapAlt,
                            alternativeId: onePair.cieloAlt,
                        });
                });
            },
            createNewOptionsForAll(onedata) {
                let redcapAlternatives = this.getRedcapAlternatives(this.mappedFields[onedata.id]);
                redcapAlternatives.forEach((alt) => {
                    if (this.mappedAlternatives[onedata.id]?.[alt]) return;
                    this.setAlterMapVal({
                        preguntaIdx: onedata.id,
                        normKey: alt,
                        alternativeId: "creaNeo",
                    });
                });
            },
            automapFields() {
                let allPairScores = [];
                this.enabledData.forEach((dataEntity) => {
                    this.redcapMetadata.forEach((redcapField) => {
                        let encabezado = dataEntity.encabezado.toLowerCase();
                        let fieldName = redcapField.field_name.toLowerCase() || "";
                        let fieldLabel = redcapField.field_label.toLowerCase() || "";
                        let score = 0;
                        if (encabezado === redcapField.field_name) {
                            score = 100;
                        } else if (this.leNormalize(encabezado) === this.leNormalize(fieldName)) {
                            score = 90;
                        } else {
                            let nameScore = stringSimilarity.compareTwoStrings(encabezado, fieldName) * 100;
                            let labelScore = stringSimilarity.compareTwoStrings(encabezado, fieldLabel) * 100;
                            score = nameScore >= labelScore ? nameScore : labelScore;
                        }
                        if (score > 70) {
                            allPairScores.push({
                                score,
                                fieldName: redcapField.field_name,
                                entityId: dataEntity.id,
                            });
                        }
                    });
                });
                allPairScores.sort((a, b) => {
                    if (a.score < b.score) {
                        return 1;
                    } else if (a.score > b.score) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                allPairScores.forEach((onePair) => {
                    if (!this.mappedFields?.[onePair.entityId])
                        this.mapField({
                            entityData: this.projectInFocusInfo.entityData[onePair.entityId],
                            neoval: onePair.fieldName,
                        });
                });
            },
            getAllItsErrors(unerror) {
                let errors = [];
                if (this.existingFile.includes(unerror)) errors.push(`File already exists in this entity`);
                if (this.incompletedForms.includes(unerror)) errors.push("Entity has an incompleted form");
                if (this.alreadyExistingEntities.includes(unerror)) errors.push("Entity already exists");
                if (this.notExistingEntities.includes(unerror)) errors.push(`ID doesn't exist in this project`);
                return errors.join(", ");
            },
            mapRedcapInstitutions(answer, value) {
                if (value == "creaNeo") {
                    return this.$f7.dialog.prompt(
                        "Name the new Institution",
                        (newValue) => {
                            if (!newValue) return this.$f7.alert("Name can't be empty");
                            this.mappedRedcapInstitutions[answer] = newValue;
                            this.mappedRedcapInstitutions = Object.assign({}, this.mappedRedcapInstitutions);
                        },
                        () => {},
                        answer,
                    );
                } else {
                    if (!value) {
                        let local = Object.assign({}, this.mappedRedcapInstitutions);
                        delete local[answer];
                        this.mappedRedcapInstitutions = Object.assign({}, local);
                    } else {
                        this.mappedRedcapInstitutions[answer] = value;
                        this.mappedRedcapInstitutions = Object.assign({}, this.mappedRedcapInstitutions);
                    }
                }
            },
            getThisFieldAnwsers() {
                let allAnswers = [];
                this.allRedcapEntities.forEach((ent) => {
                    if (ent[this.institIDToCreateAt] && !allAnswers.includes(ent[this.institIDToCreateAt]))
                        allAnswers.push(ent[this.institIDToCreateAt]);
                });
                return allAnswers;
            },
            automapInstitutions(allAnswers) {
                let allPairScores = [];
                allAnswers.forEach((ans) => {
                    this.InstitutionsICanCreateFrom.forEach((inst) => {
                        let score = 0;
                        if (ans === inst.name) {
                            score = 100;
                        } else if (this.leNormalize(ans) === this.leNormalize(inst.name)) {
                            score = 90;
                        } else {
                            score = stringSimilarity.compareTwoStrings(ans, inst.name) * 100;
                        }
                        if (score > 70) {
                            allPairScores.push({
                                score,
                                ansName: ans,
                                instId: inst.id,
                            });
                        }
                    });
                });
                allPairScores.sort((a, b) => {
                    if (a.score < b.score) {
                        return 1;
                    } else if (a.score > b.score) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                allPairScores.forEach((onePair) => {
                    if (!this.mappedRedcapInstitutions?.[onePair.ansName])
                        this.mapRedcapInstitutions(onePair.ansName, onePair.instId);
                });
            },
            setInstitIDToCreateAt(value) {
                this.institIDToCreateAt = value;
                this.automapInstitutions(this.getThisFieldAnwsers());
            },
            async fetchEntityIdField() {
                if (!this.fieldWhereToFetchEntityId) return this.$f7.dialog.alert("A field name is needed");
                let call = this.$firebase.functions().httpsCallable("v2_fetchEntityIdField");
                try {
                    this.$f7.dialog.preloader("Searching...");

                    let response = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.$store.state.dev,
                        token: this.redcapToken,
                        url: this.redcapUrl,
                        typeOfUpload: this.typeOfUpload,
                        fieldWhereToFetchEntityId: this.fieldWhereToFetchEntityId,
                    });
                    if (response.data.error) throw new Error(response.data.error.error || response.data.error);
                    this.setEntityId(response.data.entityId);
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
            setTypeOfImport(typeOfImport) {
                this.typeOfImport = typeOfImport;
                this.getRedcapEntities();
            },
            async fetchReportId() {
                if (!this.whereToFetchReportId) return this.$f7.dialog.alert("A report ID is needed");
                if (this.whereToFetchReportId == this.reportId) return;
                let call = this.$firebase.functions().httpsCallable("v2_fetchReportId");
                try {
                    this.$f7.dialog.preloader("Searching...");

                    let response = await call({
                        projectid: this.projectInFocusInfo.id,
                        dev: this.$store.state.dev,
                        token: this.redcapToken,
                        url: this.redcapUrl,
                        typeOfUpload: this.typeOfUpload,
                        whereToFetchReportId: this.whereToFetchReportId,
                    });
                    if (response.data.error) throw new Error(response.data.error.error || response.data.error);
                    this.reportId = this.whereToFetchReportId;
                    this.reportData = response.data;
                    this.getRedcapEntities();
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error);
                }
            },
        },
    };
</script>

<style>
    .centeredButton {
        align-self: center;
        margin-top: 20px;
    }
</style>
