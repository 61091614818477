<template>
    <f7-popup :opened="opened" @popup:opened="handlePopupOpened" @popup:closed="$emit('closed')">
        <f7-page>
            <f7-navbar title="Access time limit">
                <f7-nav-right>
                    <f7-link popup-close>Cancel</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block>
                <f7-list>
                    <f7-list-input
                        id="date-limit-input"
                        type="datepicker"
                        label="Date limit"
                        placeholder="Click here to select the date limit"
                        :calendar-params="{
                            minDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                            value: accessLimit.date ? [accessLimit.date] : [],
                            dateFormat: 'yyyy-mm-dd',
                        }"
                        @change="(e) => (accessLimit.date = e.target.value)"
                    ></f7-list-input>
                    <f7-list-input
                        type="time"
                        label="Time limit"
                        placeholder="Select the time limit"
                        @input="(e) => (accessLimit.time = e.target.value)"
                        :value="accessLimit.time"
                    ></f7-list-input>
                </f7-list>

                <f7-button fill @click="$emit('set', accessLimit)" class="margin-bottom-half">
                    Set access limit
                </f7-button>
                <f7-button outline @click="reset">Reset limit</f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
    import { commonmixin } from "../mixins/common";
    export default {
        mixins: [commonmixin],

        props: {
            opened: {
                type: Boolean,
                default: false,
            },

            userId: {
                type: String,
                default: null,
            },

            currentAccessLimit: {
                type: Object,
                default: null,
                required: false,
            },
        },

        data() {
            return {
                accessLimit: {
                    date: null,
                    time: "00:00",
                },
            };
        },

        methods: {
            handlePopupOpened() {
                if (this.currentAccessLimit) {
                    this.$set(this.accessLimit, "date", this.currentAccessLimit.date);
                    this.$set(this.accessLimit, "time", this.currentAccessLimit.time);

                    const input = this.$$("#date-limit-input").find("input")[0];
                    input.value = this.currentAccessLimit.date;
                }
            },
            reset() {
                try {
                    this.$f7.dialog.confirm(
                        "Are you sure you want to reset the access limit time for this user?",
                        async () => {
                            this.$f7.dialog.preloader("Resetting access limit...");

                            const response = await this.commonExecute(
                                { projectId: this.$store.state.projectInFocus, userId: this.userId },
                                "v2_resetUserAccessTimeLimitInProject",
                                false,
                            );

                            console.log("response", response);

                            if (response.error) {
                                throw new Error(response.error.message);
                            }

                            this.$set(this.accessLimit, "date", null);
                            this.$set(this.accessLimit, "time", "00:00");

                            // Reset the datepicker input
                            const input = this.$$("#date-limit-input").find("input")[0];
                            input.value = "";

                            this.$f7.dialog.close();
                            this.$emit("reset");
                        },
                    );
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            },
        },
    };
</script>
