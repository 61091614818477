<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Data import"></f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-row>
                <f7-col width="50">
                    <f7-button @click="$f7.views.main.router.navigate('/bulkupload/')" fill>Import from file</f7-button>
                </f7-col>
                <f7-col v-if="canUseRedcap" width="50">
                    <f7-button @click="$f7.views.main.router.navigate('/redcap/')" fill>Import from REDCAP</f7-button>
                </f7-col>
                <f7-col v-else width="50">
                    <f7-button disabled fill>Import from REDCAP</f7-button>
                    <f7-block-title>You don't have admin privileges to use this feature</f7-block-title>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
    created() {
        this.$f7.dialog.close();
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters(['projectInFocusInfo']),
        canUseRedcap() {
            return (
                this.user?.privx?.admin ||
                this.user?.privsByProject?.[this.projectInFocusInfo?.id]?.admin?.['project-admin']
            );
        },
    },
};
</script>

<style></style>
