<template>
    <f7-page
        @page:beforein="gettholog({})"
        @page:beforeout="infiniteScroll = false"
        :infinite="infiniteScroll"
        @infinite="gettholog({ loadMore: true, filtered })"
        name="prolog"
    >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Project log" title="Activity Feed"></f7-nav-title>
            <f7-nav-right>
                <f7-link
                    v-if="projectInFocusInfo.isDeidentificationEnabled"
                    tooltip="View de-identification logs for columns not in key"
                    @click="openedDeidLogsPopup = true"
                >
                    <font-awesome-icon :icon="['fad', 'question']"></font-awesome-icon>
                </f7-link>
                <f7-link tooltip="Download logs" @click="attemptTodownloadLogs()">
                    <font-awesome-icon
                        :color="log.length ? 'teat' : 'gray'"
                        :icon="['fad', 'cloud-arrow-down']"
                    ></font-awesome-icon>
                </f7-link>
                <f7-link @click="clearFilter()" tooltip="Clear filter">
                    <font-awesome-icon
                        :color="!filtered ? 'grey' : 'primary'"
                        :icon="['fad', 'rotate-left']"
                    ></font-awesome-icon>
                </f7-link>
                <f7-link class="margin-right" @click="openedFilterLogsPopup = true" tooltip="Filter">
                    <font-awesome-icon
                        color="primary"
                        class="position-absolute"
                        :icon="['fad', 'filter']"
                    ></font-awesome-icon>
                    <f7-badge v-if="filtered" color="primary" class="badgeTop no-margin">
                        <font-awesome-icon class="fa-sm" :icon="['fad', 'check']" />
                    </f7-badge>
                </f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-popup :opened="openedFilterLogsPopup" @popup:closed="openedFilterLogsPopup = false">
            <f7-page>
                <f7-navbar title="Filter logs">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-card>
                    <f7-list>
                        <f7-list-item checkbox :checked="filterByUser" @change="filterByUser = $event.target.checked">
                            Filter by user actions
                        </f7-list-item>
                        <f7-list-input
                            v-show="filterByUser"
                            outline
                            label="User email"
                            :value="userToFilterBy"
                            @input="userToFilterBy = $event.target.value"
                            @keyup.native.enter="getFilteredLogs()"
                            clear-button
                        ></f7-list-input>
                    </f7-list>
                </f7-card>
                <f7-card>
                    <f7-list>
                        <f7-list-item
                            checkbox
                            :checked="filterByEntity"
                            @change="filterByEntity = $event.target.checked"
                        >
                            Filter by {{ projectInFocusInfo.entity || "entity" }}
                        </f7-list-item>
                        <f7-list-input
                            v-show="filterByEntity"
                            label="Entity ID"
                            outline
                            :value="entityToFilterBy"
                            @input="entityToFilterBy = $event.target.value"
                            @keyup.native.enter="getFilteredLogs()"
                            clear-button
                        ></f7-list-input>
                    </f7-list>
                </f7-card>
                <f7-card>
                    <f7-card-content>
                        <f7-list>
                            <f7-list-item
                                checkbox
                                :checked="filterByDate"
                                @change="filterByDate = $event.target.checked"
                            >
                                Filter by date
                            </f7-list-item>
                            <f7-list-input
                                v-show="filterByDate"
                                outline
                                placeholder="Pick a Date range"
                                type="datepicker"
                                :calendar-params="{
                                    dateFormat: 'mm/dd/yyyy',
                                    locale: 'en-US',
                                    rangePicker: true,
                                }"
                                @calendar:change="setDateRange($event)"
                            ></f7-list-input>
                        </f7-list>
                    </f7-card-content>
                </f7-card>
                <f7-row class="margin">
                    <f7-col>
                        <f7-button :disabled="!filtered" @click="clearFilter()" class="fit-content" outline>
                            <font-awesome-icon :icon="['fad', 'rotate-left']"></font-awesome-icon>
                            Clear filter
                        </f7-button>
                    </f7-col>
                    <f7-col>
                        <f7-button :disabled="noFiltersSelected" fill @click="getFilteredLogs()">
                            <font-awesome-icon :icon="['fad', 'right-to-bracket']"></font-awesome-icon>
                            Filter
                        </f7-button>
                    </f7-col>
                </f7-row>
            </f7-page>
        </f7-popup>
        <f7-block-title v-if="!loading && !log.length">No logs found</f7-block-title>
        <f7-card v-for="(alog, idx) in log" :key="idx">
            <f7-card-header>
                <f7-list class="no-margin" media-list>
                    <f7-list-item class="no-left-padding" :header="alog.w" :title="alog.displayName">
                        <img :src="alog.photoURL || '../static/heros/avatar.jpg'" slot="media" class="locavtr" />
                    </f7-list-item>
                </f7-list>
                <div>
                    {{ $dayjs.unix(alog.x).format("DD") }}
                    <small>{{ $dayjs.unix(alog.x).format("MMM") }}</small>
                    <small>{{ $dayjs.unix(alog.x).format("YYYY") }}</small>
                    <br />
                    <span class="ahicame float-right">{{ $dayjs.unix(alog.x).format("HH:mm:ss") }}</span>
                </div>
            </f7-card-header>
            <f7-card-content>
                <strong>{{ alog.i }}</strong>
                <div v-if="alog.ju" class="overflow-wrap-break-word">to {{ alog.ju_displayName }}</div>
                <div v-if="alog.e" class="overflow-wrap-break-word">Target: {{ alog.e }}</div>
                <div v-if="alog.f" class="overflow-wrap-break-word">Field: {{ encabezado(alog.f) || alog.f }}</div>
                <div v-if="alog.fn" class="overflow-wrap-break-word">Name: {{ alog.fn }}</div>
                <div v-if="alog.sa" class="overflow-wrap-break-word">Details: {{ alog.sa }}</div>
            </f7-card-content>
        </f7-card>

        <f7-popup
            :opened="openedDeidLogsPopup"
            @popup:opened="getColumnsNotInKeyLogs"
            @popup:closed="openedDeidLogsPopup = false"
        >
            <f7-page>
                <f7-navbar title="De-identification columns not in key logs">
                    <f7-nav-right>
                        <f7-link popup-close>Close</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-row v-if="loadingColumnNotInKeyLogs" class="text-align-center margin">
                    <f7-col>
                        <f7-preloader></f7-preloader>
                    </f7-col>
                </f7-row>
                <template v-else-if="logsColumnsNotInKey.length">
                    <f7-block-title class="margin-vertical-half">
                        Displaying {{ logsColumnsNotInKey.length }} logs
                    </f7-block-title>
                    <f7-list v-for="(log, index) in logsColumnsNotInKey" :key="index">
                        <f7-list-item>
                            <span slot="header">
                                {{ $dayjs.unix(log.timestamp / 1000).format("YYYY-MM-DD HH:mm") }} ({{
                                    $dayjs.unix(log.timestamp / 1000).fromNow()
                                }})
                            </span>

                            <div slot="title">
                                <small v-for="(file, index) in log.columnsNotInKey" :key="index">
                                    <b>Entity ID</b>
                                    : {{ file.entityId }}
                                    <br />
                                    <b>File folder</b>
                                    : {{ file.folderName }}
                                    <br />
                                    <b>File name</b>
                                    : {{ file.fileName }}
                                    <br />
                                    <b>Columns not in key</b>
                                    :
                                    <ul>
                                        <li
                                            v-for="(unrecognizedColumn, index) in file.unrecognizedColumns"
                                            :key="index"
                                        >
                                            {{ unrecognizedColumn }}
                                        </li>
                                    </ul>
                                </small>
                            </div>
                        </f7-list-item>
                    </f7-list>
                </template>
                <f7-block-title v-else>No logs found</f7-block-title>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import { mapGetters, mapState } from "vuex";

    export default {
        data() {
            return {
                infiniteScroll: true,
                log: [],
                limit: 40,
                openedFilterLogsPopup: false,
                filterByUser: null,
                userToFilterBy: null,
                filterByEntity: null,
                entityToFilterBy: null,
                filterByDate: null,
                dateToFilterBy: null,
                loading: false,
                filtered: false,
                openedDeidLogsPopup: false,
                loadingColumnNotInKeyLogs: true,
                logsColumnsNotInKey: [],
                reachedEndOnce: false,
            };
        },
        computed: {
            ...mapState(["user", "dev", "projectInFocus"]),
            ...mapGetters(["privxInfo", "projectInFocusInfo"]),
            noFiltersSelected() {
                return (
                    (!this.filterByUser && !this.filterByEntity && !this.filterByDate) ||
                    (this.filterByUser && !this.userToFilterBy) ||
                    (this.filterByEntity && !this.entityToFilterBy) ||
                    (this.filterByDate && !this.dateToFilterBy)
                );
            },
        },
        methods: {
            async getColumnsNotInKeyLogs() {
                try {
                    this.loadingColumnNotInKeyLogs = true;
                    const response = await this.commonExecute(
                        { projectId: this.projectInFocus },
                        "v2_getColumnsNotInKeyLogs",
                    );
                    this.logsColumnsNotInKey = response.logs;
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                } finally {
                    this.loadingColumnNotInKeyLogs = false;
                }
            },
            encabezado(f) {
                return (
                    this.projectInFocusInfo?.entityData?.[f]?.encabezado ||
                    this.projectInFocusInfo?.entityFiles?.[f]?.encabezado ||
                    this.projectInFocusInfo?.apiKeys?.[f]?.name ||
                    this.projectInFocusInfo?.institutions?.[f]?.name ||
                    this.projectInFocusInfo?.fileTags?.[f]?.tx
                );
            },
            async gettholog({ loadMore, newFilter, reset, filtered }) {
                try {
                    if (reset || newFilter) {
                        this.log = [];
                        this.limit = 40;
                        this.reachedEndOnce = false;
                    }
                    if (loadMore) {
                        if (filtered) return;
                        if (this.log.length && this.limit > this.log.length) {
                            if (this.reachedEndOnce) {
                                console.log("Reached end of logs. Do not retry.");
                                return setTimeout(() => {
                                    this.infiniteScroll = false;
                                }, 1000);
                            } else {
                                console.log("Reached end of logs (1st time). Retrying...");
                                this.reachedEndOnce = true;
                            }
                        }
                        this.limit += 40;
                    }
                    this.loading = true;
                    this.infiniteScroll = true;
                    if (newFilter) this.filtered = true;
                    else this.filtered = false;
                    let datous = await this.commonExecute(
                        {
                            key: "p",
                            value: this.projectInFocus,
                            limit: this.limit,
                            userToFilterBy: this.filtered && this.filterByUser ? this.userToFilterBy : null,
                            entityToFilterBy: this.filtered && this.filterByEntity ? this.entityToFilterBy : null,
                            dateToFilterBy: this.filtered && this.filterByDate ? this.dateToFilterBy : null,
                        },
                        "v2_getlog",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let linfo = datous.payload;
                    linfo.sort((a, b) => {
                        let alow = a.x;
                        let blow = b.x;
                        if (alow > blow) {
                            return -1;
                        } else if (alow < blow) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    this.log = linfo;
                    this.loading = false;
                    if (newFilter) {
                        //    if (this.log.length && this.limit > this.log.length) {
                        return setTimeout(() => {
                            this.infiniteScroll = false;
                        }, 1000);
                        //   }
                        //   this.limit += 40;
                    }
                } catch (error) {
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                    this.loading = false;
                }
            },
            clearFilter() {
                if (!this.filtered) return;
                this.filtered = false;
                this.entityToFilterBy = null;
                this.userToFilterBy = null;
                this.dateToFilterBy = null;
                this.filterByEntity = null;
                this.filterByUser = null;
                this.filterByDate = null;
                this.gettholog({ reset: true });
            },
            getFilteredLogs() {
                if (this.noFiltersSelected) return;
                this.openedFilterLogsPopup = false;
                this.gettholog({ newFilter: true });
            },
            setDateRange(date) {
                this.dateToFilterBy = date.map((day) => {
                    return this.$dayjs(day).unix();
                });
            },
            attemptTodownloadLogs() {
                if (this.filtered) this.downloadLogs({});
                else
                    this.$f7.dialog
                        .create({
                            title: "Download project logs",
                            text: `Do you want to download all logs from this project or just the ones you see on the list?`,
                            buttons: [
                                {
                                    text: "Download all logs",
                                    onClick: () => {
                                        this.downloadLogs({ getAllLogs: true });
                                    },
                                },
                                {
                                    text: "Download only these ones",
                                    onClick: () => {
                                        this.downloadLogs({});
                                    },
                                },
                                {
                                    text: "Cancel",
                                    color: "red",
                                },
                            ],
                            verticalButtons: true,
                        })
                        .open();
            },
            async downloadLogs({ getAllLogs }) {
                try {
                    this.$f7.dialog.preloader("Downloading logs...");

                    let datous = await this.commonExecute(
                        {
                            key: "p",
                            value: this.projectInFocus,
                            limit: this.limit,
                            userToFilterBy: this.filtered && this.filterByUser ? this.userToFilterBy : null,
                            entityToFilterBy: this.filtered && this.filterByEntity ? this.entityToFilterBy : null,
                            dateToFilterBy: this.filtered && this.filterByDate ? this.dateToFilterBy : null,
                            getAllLogs,
                            downloadLogs: true,
                        },
                        "v2_getlog",
                        false,
                    );
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let idToken = await this.$firebase.auth().currentUser.getIdToken();
                    let endpoint = this.$store.state.dev
                        ? "https://us-central1-cielo-one.cloudfunctions.net"
                        : "https://us-central1-cielo-iddrc-braingeneregistry.cloudfunctions.net"; //'http://localhost:5001/cielo-one/us-central1' 'https://us-central1-cielo-one.cloudfunctions.net 'https://us-central1-cielo-iddrc-braingeneregistry.cloudfunctions.net'
                    let url = `${endpoint}/v2_exporter/downloadLogs/${this.projectInFocus}/${this.user.uid}/${
                        this.$store.state.dev ? "dev" : null
                    }/${datous.downloadId}`;
                    let response = await this.$axios.get(url, {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob", // important
                    });
                    if (response.data.type == "application/json") {
                        this.$f7.dialog.close();
                        var textPromise = await response.data.text();
                        return this.$f7.dialog.alert(textPromise, "Error");
                    }
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        }),
                    );
                    let fileLink = document.createElement("a");
                    document.body.appendChild(fileLink);
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", `logs-${this.$dayjs().format("MM-DD-YYYY")}.xlsx`);
                    fileLink.classList.add("external");
                    fileLink.click();
                    window.URL.revokeObjectURL(fileURL);
                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message);
                }
            },
        },
        components: { FontAwesomeIcon },
    };
</script>
<style>
    .locavtr {
        width: 40px;
        border-radius: 50%;
    }

    .ahicame {
        font-size: 12px !important;
    }

    .no-left-padding .item-content {
        padding-left: 0px !important;
    }

    .badgeTop {
        top: -7px !important;
        left: 15px;
    }

    .position-absolute {
        position: absolute !important;
    }

    .list ul {
        list-style: circle;
    }
    .overflow-wrap-break-word {
        overflow-wrap: break-word;
    }
</style>
