// Import Vue
import Vue from "vue";
//CLIENTE
// Import Framework7
import Framework7 from "framework7/framework7-lite.esm.bundle.js";

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.css";
import "@fontsource/source-sans-pro";

// Import App Component
import App from "../components/app.vue";

//papaparse
import VuePapaParse from "vue-papa-parse";

//custom hicapps
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import { store } from "../store/store";
//font-awesome-icons stuff
import { library } from "@fortawesome/fontawesome-svg-core";
import { dom } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faPowerOff,
    faCaretDown,
    faWarning,
    faAt,
    faCircleInfo,
    faKey,
    faArrowCircleLeft,
    faEye,
    faEyeSlash,
    faTrash,
    faCheck,
    faCircleXmark,
    faDoorOpen,
    faFileSignature,
    faSyncAlt,
    faStoreAlt,
    faStoreAltSlash,
    faSignInAlt,
    faHandHoldingMedical,
    faHandPointUp,
    faCheckCircle,
    faUserCheck,
    faUserLock,
    faUserClock,
    faUserCircle,
    faListAlt,
    faDiagramProject,
    faUsers,
    faPencilAlt,
    faTimes,
    faPen,
    faAdd,
    faSortAsc,
    faSort,
    faSortDown,
    faHand,
    faCircle,
    faHistory,
    faFileArchive,
    faCloudDownloadAlt,
    faTrashAlt,
    faSave,
    faFolderPlus,
    faFolderMinus,
    faFileImport,
    faTags,
    faArrowCircleUp,
    faEraser,
    faSearch,
    faCamera,
    faDownload,
    faClipboard,
    faSortNumericUp,
    faCodeBranch,
    faArrowLeft,
    faUserSlash,
    faCheckDouble,
    faFilter,
    faUpload,
    faClock,
    faClockRotateLeft,
    faLock,
    faLink,
    faGear,
    faRotateLeft,
    faQuestion,
} from "@fortawesome/pro-duotone-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

//dates library
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

import axios from "axios";
Vue.prototype.$axios = axios;
//init firebase
//process.env.DB_HOST
const firebaseConfig = {
    apiKey:
        process.env.NODE_ENV === "production" ? process.env.API_KEY : process.env.FBAPIKEYLOCAL || process.env.API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    databaseURL:
        process.env.NODE_ENV === "production"
            ? process.env.DATABASE_URL_PROD
            : window.location.hostname == "test.cielo.health"
            ? process.env.DATABASE_URL_TEST
            : process.env.DATABASE_URL_DEV,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: process.env.MEASUREMENT_ID,
};
//console.log('FBAPIKEYLOCAL',process.env.FBAPIKEYLOCAL,firebaseConfig.apiKey)
firebase.initializeApp(firebaseConfig);
const defaultAnalytics = firebase.analytics();

//firebase.functions().useEmulator("localhost", 5001);

Vue.prototype.$firebase = firebase;
//init fontawesome
dom.watch();
library.add(
    faPowerOff,
    faCaretDown,
    faWarning,
    faAt,
    faCircleInfo,
    faKey,
    faArrowCircleLeft,
    faEye,
    faEyeSlash,
    faTrash,
    faCheck,
    faCircleXmark,
    faDoorOpen,
    faFileSignature,
    faSyncAlt,
    faStoreAlt,
    faStoreAltSlash,
    faSignInAlt,
    faHandHoldingMedical,
    faHandPointUp,
    faCheckCircle,
    faUserCheck,
    faUserLock,
    faUserClock,
    faUserCircle,
    faListAlt,
    faDiagramProject,
    faUsers,
    faPencilAlt,
    faTimes,
    faPen,
    faAdd,
    faSortAsc,
    faSort,
    faSortDown,
    faHand,
    faCircle,
    faHistory,
    faFileArchive,
    faCloudDownloadAlt,
    faTrashAlt,
    faSave,
    faFolderPlus,
    faFolderMinus,
    faFileImport,
    faTags,
    faArrowCircleUp,
    faEraser,
    faSearch,
    faCamera,
    faDownload,
    faClipboard,
    faSortNumericUp,
    faCodeBranch,
    faArrowLeft,
    faUserSlash,
    faCheckDouble,
    faFilter,
    faUpload,
    faClock,
    faClockRotateLeft,
    fas,
    faLock,
    faLink,
    faGear,
    faRotateLeft,
    faQuestion,
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
//dates library
Vue.prototype.$dayjs = dayjs;

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

//Papaparse
Vue.use(VuePapaParse);

// Alert component
import Alert from "../components/Alert.vue";
Vue.component("f7c-alert", Alert);

//cargar el mixin comun
import { commonmixin } from "../mixins/common";
Vue.mixin(commonmixin);
// Init App
new Vue({
    el: "#app",
    render: (h) => h(App),
    store,
    // Register App Component
    components: {
        app: App,
    },
});
