<template>
    <f7-page name="about">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="What is CIELO" title="About"></f7-nav-title>
        </f7-navbar>
        <f7-block>
            <f7-block-title>User guide</f7-block-title>
            <f7-list media-list>
                <f7-list-item
                    v-for="(card, index) in suscards"
                    :key="index"
                    :link="card.href"
                    :title="card.title"
                >
                    <div slot="media">
                        <font-awesome-icon
                            style="color: #a51417"
                            :icon="card.icon"
                            size="2x"
                            class="margin"
                        ></font-awesome-icon>
                    </div>
                    <div slot="text">{{ card.sections.join(', ') + '.' }}</div>
                </f7-list-item>
            </f7-list>
        </f7-block>

        <f7-block
            >Brief description of the CIELO platform here.<br />CIELO &copy;
            {{ $dayjs().format('YYYY') }} - {{ ledomain }}
        </f7-block>
        <f7-block-title>User agreement</f7-block-title>
        <f7-block>
            <p>
                This is a user agreement ("Agreement") between You and The
                Washington University ("WU") and governs Your use of the
                computer code provided here (the "Software"). "You" and "Your"
                means the individual or legal entity licensing the Software
                under this Agreement.
            </p>
            <ol>
                <li>
                    WU hereby grants to You a non-exclusive, royalty-free
                    license to use the computer code provided here (the
                    "Software"), specifically excluding commercial use or sales.
                    This license does not grant any rights or licenses to any
                    other patents, copyrights, or other forms of intellectual
                    property owned or controlled by WU. Further, this license
                    does not grant any rights to data that is accessible via the
                    Software. Access to data shall be in accordance with the
                    terms of the applicable data use agreement for each project.
                    You agree to include this Agreement and the copyright notice
                    below in all copies or substantial portions of the Software.
                </li>
                <br />
                <li>
                    <br />
                    YOU AGREE THAT THE SOFTWARE PROVIDED HEREUNDER IS
                    EXPERIMENTAL AND IS PROVIDED "AS IS", WITHOUT ANY WARRANTY
                    OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING WITHOUT
                    LIMITATION WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY
                    PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF ANY THIRD-PARTY
                    PATENT, COPYRIGHT, OR ANY OTHER THIRD-PARTY RIGHT. IN NO
                    EVENT SHALL THE CREATORS OF THE SOFTWARE OR WU BE LIABLE FOR
                    ANY DIRECT, INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES
                    ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE SOFTWARE,
                    THE USE OF THE SOFTWARE, OR THIS AGREEMENT, WHETHER IN
                    BREACH OF CONTRACT, TORT OR OTHERWISE, EVEN IF SUCH PARTY IS
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </li>
                <br />
                <li>
                    Except to the extent prohibited by law, You agree to
                    indemnify, defend, reimburse and hold harmless WU, WU
                    personnel, WU's Affiliates, and each of their respective
                    trustees, faculty, staff, employees, students, directors,
                    officers, agents, successors and assigns (altogether the "WU
                    Indemnitees") from, for and against any and all judgments,
                    settlements, losses, expenses, damages and/or liabilities
                    and any and all court costs, attorneys' fees, and expert
                    witness fees and expenses that a WU Indemnitee may incur
                    from any and all allegations, claims, suits, actions or
                    proceedings (the "Claims") arising out of, relating to, or
                    incidental to Your breach of this Agreement or Your use,
                    development, commercialization, or other exploitation of the
                    Software, whether by or through You, and including all
                    Claims for infringement, injury to business, personal
                    injury, and product liability. The obligations set forth in
                    this Section shall survive termination of this Agreement,
                    shall continue even after assignment of rights and
                    responsibilities, and shall not be limited by any provision
                    of this Agreement outside this Section.
                </li>
                <br />
                <li>
                    You may not use the trademarks or name of WU for any
                    commercial, advertisement, or promotional purposes without
                    the prior consent of WU.
                </li>
            </ol>
        </f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            cards: [
                {
                    icon: 'graduation-cap',
                    title: 'Getting Started',
                    sections: [
                        'About CIELO',
                        'Access CIELO',
                        'Register',
                        'Login',
                    ],
                    href: '/about/guide/getting-started',
                },
                {
                    icon: ['fad','door-open'],
                    title: 'Access and Exit a CIELO project',
                    sections: [
                        'Request Access to the project',
                        'Access the project',
                        'Exit the project',
                    ],
                    href: '/about/guide/access-and-exit',
                },
                {
                    icon: ['fad','file-signature'],
                    title: 'Manage the Project via Project Data',
                    sections: [
                        'Project Data',
                        'Add New Entity',
                        'Update Entity',
                        'Download Data',
                    ],
                    adminOnly: true,
                    href: '/about/guide/manage-via-project-data',
                },
            ],
        };
    },
    computed: {
        ...mapState(['user']),
        adminSomeWhere() {
            let susprivx = this.user.privx || {};
            let privsByProject = this.user.privsByProject || {};
            return (
                susprivx.admin ||
                Object.values(privsByProject).some((unpro) => {
                    return unpro.admin;
                })
            );
        },
        suscards() {
            return this.cards.filter((unacard) => {
                return !unacard.adminOnly || this.adminSomeWhere;
            });
        },
        ledomain() {
            return window.location.hostname;
        },
    },
};
</script>
<style></style>
