var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline nomrg"},[(_vm.queryniv.r)?_c('div',{staticClass:"timeline-item"},[_c('div',{staticClass:"timeline-item-content nobordx"},[_c('f7-list',{staticClass:"no-margin nelist",attrs:{"media-list":""}},[_c('f7-list-item',{attrs:{"subtitle":_vm.getConditionName,"title":_vm.getFieldName,"text":_vm.getCriteriaNames}},[_c('f7-link',{staticClass:"margin-right-half",attrs:{"slot":"after"},on:{"click":function($event){return _vm.borraeste({ rutar: _vm.rutar })}},slot:"after"},[_c('font-awesome-icon',{attrs:{"color":"red","icon":['fad', 'circle-xmark']}})],1),_vm._v(" "),(_vm.queryniv.c.length || _vm.queryniv.n)?_c('f7-button',{attrs:{"slot":"media"},on:{"click":function($event){return _vm.creaone({
                                cond_rutar: _vm.rutar,
                                cond_t: '',
                                cond_mkgroup: true,
                            })}},slot:"media"},[_vm._v("\n                        + group\n                    ")]):_vm._e()],1)],1),_vm._v(" "),(!_vm.queryniv.c.length && !_vm.queryniv.n)?_c('f7-button',{class:`margin-top font_${_vm.queryniv.t.toLowerCase()}`,attrs:{"raised":""},on:{"click":function($event){return _vm.creaone({
                        cond_rutar: _vm.rutar,
                        cond_t: '',
                        cond_mkgroup: true,
                    })}}},[_vm._v("\n                Add condition\n            ")]):_vm._e()],1)]):(_vm.queryniv.c.length)?[_c('div',{class:`absolutamente absolutamente_${_vm.rutar} div_${_vm.queryniv.t.toLowerCase()}`},[_vm._v("\n            "+_vm._s(_vm.queryniv.t)+"\n        ")]),_vm._v(" "),_vm._l((_vm.queryniv.c),function(unc,idx){return _c('div',{key:idx,staticClass:"timeline-item buskqdx"},[_c('div',{staticClass:"timeline-item-date",class:{ ultimx: idx + 1 == _vm.queryniv.c.length }}),_vm._v(" "),_c('div',{class:`div_${_vm.queryniv.t.toLowerCase()} timeline-item-divider`}),_vm._v(" "),_c('div',{staticClass:"timeline-item-content"},[_c('queryx',{attrs:{"queryniv":unc,"rutar":`${_vm.rutar}_${unc.n}-${idx}`}}),_vm._v(" "),(idx + 1 == _vm.queryniv.c.length)?_c('f7-button',{class:`margin-top font_${_vm.queryniv.t.toLowerCase()}`,attrs:{"raised":""},on:{"click":function($event){return _vm.creaone({
                            cond_rutar: _vm.rutar,
                            cond_t: _vm.queryniv.t,
                            cond_mkgroup: false,
                        })}}},[_vm._v("\n                    Add condition\n                ")]):_vm._e()],1)])})]:_c('f7-button',{class:`margin-top`,attrs:{"large":"","raised":""},on:{"click":function($event){return _vm.creaone({ cond_rutar: _vm.rutar, cond_t: '', cond_mkgroup: false })}}},[_vm._v("\n        Add first condition\n    ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }