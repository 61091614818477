import Vuex from "vuex";
import Vue from "vue";

import firebase from "firebase/app";

Vue.use(Vuex);
//store CIELO
export const store = new Vuex.Store({
    state: {
        activeTab: "/",
        adminprojectFocus: null,
        appVersion: "BETA 0.41.0",
        appUrl: "cielo.health",
        cerrandoSesion: false,
        dev:
            process.env.NODE_ENV === "production"
                ? null
                : window.location.hostname == "test.cielo.health"
                ? "test"
                : "dev",
        forms_logic: {},
        forceUsersReload: false,
        forceProjectSelect: false,
        goToProyectDataFromMenu: true,
        bundlesHistory: null,
        header: true,
        lastFullLoad: {},
        loggingin: false,
        profiles: {},
        projectInFocus: null,
        projects: {},
        user: {
            privx: {},
            privsByProject: {},
        },
        cond_rutar: "",
        cond_t: "",
        cond_mkgroup: false,
        cond_abierto: false,
        cond_borrar: null,
        leQuery: {
            n: 0,
            c: [],
            t: "",
            r: null,
        },
        selectedQueryId: null,
        savedQueries: {},
        fetchedPatients: [],
        allFetchedPatientsIds: [],
        currentProjectTerms: {},
        localTimezone: null,
        entityDraft: null,
        allentities: [],
        lastEntityId: null,
        shouldForceRefreshEntities: false,
        institofil: [],
        cieloConfigurations: {
            isMaintenanceModeEnabled: false,
        },

        didLoadMaintenanceModeForFirstTime: false,
        bulkUploadStatusesRef: null,
        lastUpdateRef: null,
        userPrivilegesRef: null,
        privsByProjectRef: null,
        bundleHistoryRef: null,

        remainingSecondsUntilRestrictedAccess: null,

        searchableEntityData: [],
        searchableEntityFiles: [],
        canFilterByInstitution: true,

        DEIDENTIFICATION_PROJECTS_IDS: ["-NL2-z2MkNPL2wvKD5kr", "-MkmnJWRYUOVH_wImDF3", "-MqzJdbedUN6kmdAtond"], // real BGR project id: -MqzJdbedUN6kmdAtond

        referenceProjectInstitutions: [],
        referenceProject: null,
        institutionsFromReferenceProject: [],
    },
    getters: {
        muestromenuProjecto: (state) => {
            return state.projectInFocus && !state.forceProjectSelect;
        },
        privxInfo: (state) => {
            return state.user?.privx || {};
        },
        projectInFocusInfo: (state) => {
            return state.projects[state.projectInFocus] || {};
        },
        projects: (state) => {
            let cuales = Object.values(state.projects).filter((project) => {
                return project.id;
            });
            cuales.sort((a, b) => {
                let alow = a.name.toLowerCase();
                let blow = b.name.toLowerCase();
                if (alow > blow) {
                    return 1;
                } else if (alow < blow) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return cuales;
        },
        userPrivsSelectedProject: (state) => {
            if (!state.projectInFocus) {
                return null;
            }

            return state.user?.privsByProject[state.projectInFocus] || {};
        },
        userHasPrivileges:
            (state) =>
            ({ requiredAdminPrivs = null, requiredInstitutionPrivs = null, project = null }) => {
                if (state.user?.privx?.admin) {
                    // User is super admin
                    return true;
                }

                const projectId = project || state.projectInFocus;

                if (!projectId) {
                    return false;
                }

                const userPrivs = state.user?.privsByProject?.[projectId];

                if (!userPrivs) {
                    return false;
                }

                const adminPrivs = Object.keys(userPrivs?.admin || {});

                if (requiredAdminPrivs) {
                    if (userPrivs?.admin) {
                        return requiredAdminPrivs.every((priv) => {
                            if (priv === "accounts") {
                                return adminPrivs.includes("project-admin") || adminPrivs.includes("accounts");
                            }

                            return adminPrivs.includes(priv);
                        });
                    }
                } else if (requiredInstitutionPrivs) {
                    if (userPrivs?.prv) {
                        const userInstitutionId = Object.keys(userPrivs?.prv)[0];

                        if (!userInstitutionId) {
                            return false;
                        }

                        const institutionPrivs = Object.keys(userPrivs?.prv?.[userInstitutionId]?.det || {});

                        return requiredInstitutionPrivs.every((priv) => {
                            // Create and update privileges were merged into one, so if the user has one of them, we let it pass
                            if (priv === "create" || priv === "update") {
                                return institutionPrivs.includes("create") || institutionPrivs.includes("update");
                            } else if (priv === "read") {
                                return true;
                            }

                            return institutionPrivs.includes(priv);
                        });
                    }
                }

                return false;
            },

        hasEntitiesFromAnotherInstitution:
            (state) =>
            ({ projectId, entities = [] }) => {
                const userPrivs = state.user?.privsByProject[projectId];

                if (!userPrivs) {
                    return false;
                }

                const userInstitutionId = Object.keys(userPrivs?.prv || {})[0];

                if (!userInstitutionId) {
                    return false;
                }

                console.log("in store hasEntitiesFromAnotherInstitution", entities);

                return entities.some((entity) => entity.instid !== userInstitutionId);
            },
    },
    mutations: {
        mapInstitutionToReferenceProject: (state, payload) => {
            const { projectId, institutionId, referencedInstitutionId, selected } = payload;

            const currentInstitutions = Object.values(state.projects[projectId].institutions || {});

            if (selected) {
                Vue.set(
                    state.projects[projectId].institutions[institutionId],
                    "referencedInstitutionId",
                    referencedInstitutionId,
                );

                currentInstitutions.forEach((institution) => {
                    if (
                        institution.id !== institutionId &&
                        institution.referencedInstitutionId === referencedInstitutionId
                    ) {
                        Vue.delete(state.projects[projectId].institutions[institution.id], "referencedInstitutionId");
                    }
                });
            } else {
                Vue.delete(state.projects[projectId].institutions[institutionId], "referencedInstitutionId");
            }
        },
        setSearchableEntityData: (state, entityData) => {
            state.searchableEntityData = entityData;
        },
        setSearchableEntityFiles: (state, entityFiles) => {
            state.searchableEntityFiles = entityFiles;
        },
        setUserCanFilterByInstitution: (state, canFilterByInstitution) => {
            state.canFilterByInstitution = canFilterByInstitution;
        },
        setCurrentProjectTerms: (state, payload) => {
            const { projectId, terms } = payload;

            Vue.set(state.currentProjectTerms, projectId, terms);
        },
        setProjectMeta: (state, payload) => {
            const { projectId, field, data } = payload;

            Vue.set(state.projects[projectId], field, data);
        },

        setNewAcceptedTerm: (state, payload) => {
            const { projectId, acceptTime } = payload;

            if (!state.user?.privx.terms) {
                Vue.set(state.user?.privx, "terms", {});
            }

            Vue.set(state.user?.privx.terms, projectId, acceptTime);
        },

        meteraprop: (state, pyld) => {
            Vue.set(state[pyld.cual], pyld.id, pyld.val);
        },

        addItemNivethree: (state, p) => {
            let lojbs = {
                [p.a1]: {
                    [p.b2]: {
                        [p.c3]: p.d4,
                    },
                },
            };

            if (!state[p.a1][p.b2]) {
                state[p.a1] = Object.assign({}, state[p.a1], lojbs[p.a1]);
            } else if (!state[p.a1][p.b2][p.c3]) {
                state[p.a1][p.b2] = Object.assign({}, state[p.a1][p.b2], lojbs[p.a1][p.b2]);
            } else {
                state[p.a1][p.b2][p.c3] = Object.assign({}, lojbs[p.a1][p.b2][p.c3]);
            }
        },
        addItemNivefour: (state, p) => {
            let lojbs = {
                [p.a1]: {
                    [p.b2]: {
                        [p.c3]: {
                            [p.d4]: p.e5,
                        },
                    },
                },
            };

            if (!state[p.a1][p.b2]) {
                state[p.a1] = Object.assign({}, state[p.a1], lojbs[p.a1]);
            } else if (!state[p.a1][p.b2][p.c3]) {
                state[p.a1][p.b2] = Object.assign({}, state[p.a1][p.b2], lojbs[p.a1][p.b2]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4]) {
                state[p.a1][p.b2][p.c3] = Object.assign({}, state[p.a1][p.b2][p.c3], lojbs[p.a1][p.b2][p.c3]);
            } else {
                state[p.a1][p.b2][p.c3][p.d4] = Object.assign({}, lojbs[p.a1][p.b2][p.c3][p.d4]);
            }
        },
        addItemNiveFive: (state, p) => {
            let lojbs = {
                [p.a1]: {
                    [p.b2]: {
                        [p.c3]: {
                            [p.d4]: {
                                [p.e5]: p.f6,
                            },
                        },
                    },
                },
            };

            if (!state[p.a1][p.b2]) {
                state[p.a1] = Object.assign({}, state[p.a1], lojbs[p.a1]);
            } else if (!state[p.a1][p.b2][p.c3]) {
                state[p.a1][p.b2] = Object.assign({}, state[p.a1][p.b2], lojbs[p.a1][p.b2]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4]) {
                state[p.a1][p.b2][p.c3] = Object.assign({}, state[p.a1][p.b2][p.c3], lojbs[p.a1][p.b2][p.c3]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4][p.e5]) {
                state[p.a1][p.b2][p.c3][p.d4] = Object.assign(
                    {},
                    state[p.a1][p.b2][p.c3][p.d4],
                    lojbs[p.a1][p.b2][p.c3][p.d4],
                );
            } else {
                if (p.merge) {
                    state[p.a1][p.b2][p.c3][p.d4][p.e5] = Object.assign(
                        {},
                        state[p.a1][p.b2][p.c3][p.d4][p.e5],
                        lojbs[p.a1][p.b2][p.c3][p.d4][p.e5],
                    );
                } else {
                    state[p.a1][p.b2][p.c3][p.d4][p.e5] = Object.assign({}, lojbs[p.a1][p.b2][p.c3][p.d4][p.e5]);
                }
            }
        },
        addItemNiveSix: (state, p) => {
            let lojbs = {
                [p.a1]: {
                    [p.b2]: {
                        [p.c3]: {
                            [p.d4]: {
                                [p.e5]: {
                                    [p.f6]: p.g7,
                                },
                            },
                        },
                    },
                },
            };

            if (!state[p.a1][p.b2]) {
                state[p.a1] = Object.assign({}, state[p.a1], lojbs[p.a1]);
            } else if (!state[p.a1][p.b2][p.c3]) {
                state[p.a1][p.b2] = Object.assign({}, state[p.a1][p.b2], lojbs[p.a1][p.b2]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4]) {
                state[p.a1][p.b2][p.c3] = Object.assign({}, state[p.a1][p.b2][p.c3], lojbs[p.a1][p.b2][p.c3]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4][p.e5]) {
                state[p.a1][p.b2][p.c3][p.d4] = Object.assign(
                    {},
                    state[p.a1][p.b2][p.c3][p.d4],
                    lojbs[p.a1][p.b2][p.c3][p.d4],
                );
            } else if (!state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6]) {
                state[p.a1][p.b2][p.c3][p.d4][p.e5] = Object.assign(
                    {},
                    state[p.a1][p.b2][p.c3][p.d4][p.e5],
                    lojbs[p.a1][p.b2][p.c3][p.d4][p.e5],
                );
            } else {
                state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6] = Object.assign(
                    {},
                    lojbs[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6],
                );
            }
        },
        addItemNiveSeven: (state, p) => {
            let lojbs = {
                [p.a1]: {
                    [p.b2]: {
                        [p.c3]: {
                            [p.d4]: {
                                [p.e5]: {
                                    [p.f6]: {
                                        [p.g7]: p.h8,
                                    },
                                },
                            },
                        },
                    },
                },
            };

            if (!state[p.a1][p.b2]) {
                state[p.a1] = Object.assign({}, state[p.a1], lojbs[p.a1]);
            } else if (!state[p.a1][p.b2][p.c3]) {
                state[p.a1][p.b2] = Object.assign({}, state[p.a1][p.b2], lojbs[p.a1][p.b2]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4]) {
                state[p.a1][p.b2][p.c3] = Object.assign({}, state[p.a1][p.b2][p.c3], lojbs[p.a1][p.b2][p.c3]);
            } else if (!state[p.a1][p.b2][p.c3][p.d4][p.e5]) {
                state[p.a1][p.b2][p.c3][p.d4] = Object.assign(
                    {},
                    state[p.a1][p.b2][p.c3][p.d4],
                    lojbs[p.a1][p.b2][p.c3][p.d4],
                );
            } else if (!state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6]) {
                state[p.a1][p.b2][p.c3][p.d4][p.e5] = Object.assign(
                    {},
                    state[p.a1][p.b2][p.c3][p.d4][p.e5],
                    lojbs[p.a1][p.b2][p.c3][p.d4][p.e5],
                );
            } else if (!state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6][p.g7]) {
                state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6] = Object.assign(
                    {},
                    state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6],
                    lojbs[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6],
                );
            } else {
                state[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6][p.g7] = Object.assign(
                    {},
                    lojbs[p.a1][p.b2][p.c3][p.d4][p.e5][p.f6][p.g7],
                );
            }
        },
        setObjTo: (state, pyld) => {
            state[pyld.what] = Object.assign({}, pyld.to);
            state.lastFullLoad[pyld.what] = Date.now();
        },
        //validar de eaqui a abajo
        setWhatTo: (state, pyld) => {
            state[pyld.what] = pyld.to;
            state.lastFullLoad[pyld.what] = Date.now();
        },
        pushToArray: (state, pyld) => {
            state[pyld.array].push(pyld.value);
        },
        delObje: (state, pyld) => {
            let clonao = Object.assign({}, state[pyld.what]);
            delete clonao[pyld.cual];
            state[pyld.what] = Object.assign({}, clonao);
        },
        removeFromArray: (state, pyld) => {
            let indiexist = state[pyld.array].findIndex((unitem) => unitem[pyld.attr] == pyld.value);
            if (indiexist != -1) {
                state[pyld.array].splice(indiexist, 1);
            }
        },
        updtObjProperty: (state, pyld) => {
            let nueo = {};
            if (!pyld.idx) {
                nueo[pyld.to.id] = pyld.to;
            } else {
                nueo[pyld.idx] = pyld.to;
            }
            state[pyld.what] = Object.assign({}, state[pyld.what], nueo);
        },
        mergeObjTo: (state, pyld) => {
            state[pyld.what] = Object.assign({}, state[pyld.what], pyld.to);
        },

        removeFromSimpleArray(state, pyld) {
            let index = state[pyld.array].findIndex((item) => item === pyld.value);
            if (index !== -1) {
                state[pyld.array].splice(index, 1);
            }
        },
        updateMyPrivx: (state, newPrivx) => {
            if (!state.user?.privx) {
                let neo = {
                    privx: newPrivx,
                };
                state.user = Object.assign({}, state.user, neo);
            } else {
                state.user.privx = Object.assign({}, state.user?.privx, newPrivx);
            }
        },
        updateUserInfo: (state, p) => {
            state.user[p.cual] = p.valor;
        },
        inactivateMultiple: (state, payload) => {
            payload.altsToDelete.forEach((altid) => {
                state.projects[state.adminprojectFocus].entityData[payload.pregid].alternativas[altid].inct = true;
            });
            state.projects = Object.assign({}, state.projects);
        },
        turnOffObservables: (state, payload) => {
            if (state.bulkUploadStatusesRef) state.bulkUploadStatusesRef.off();
            if (state.lastUpdateRef) state.lastUpdateRef.off();
            if (state.bundleHistoryRef) state.bundleHistoryRef.off();
            if (payload.fromLogout) {
                if (state.userPrivilegesRef) state.userPrivilegesRef.off();
                if (state.privsByProjectRef) state.privsByProjectRef.off();
            }
        },
        modifyProjectBanner(state, payload) {
            const { projectId, bannerURL } = payload;
            Vue.set(state.projects[projectId], "banner", bannerURL);
        },
    },
    actions: {
        async getCieloConfigurations({ commit, state }) {
            try {
                const response = await firebase.functions().httpsCallable("v2_getCieloConfigurations")({
                    dev: state.dev,
                });

                commit("setObjTo", { what: "cieloConfigurations", to: response.data.cieloConfigurations || {} });

                return response.data.cieloConfigurations;
            } catch (error) {
                console.error(error);
            }
        },

        turnOnMaintenanceModeObservable({ commit, state }) {
            firebase
                .database()
                .ref("cieloConfigurations/isMaintenanceModeEnabled")
                .on(
                    "value",
                    (snap) => {
                        commit("updtObjProperty", {
                            what: "cieloConfigurations",
                            to: snap.val(),
                            idx: "isMaintenanceModeEnabled",
                        });
                        commit("setWhatTo", { what: "didLoadMaintenanceModeForFirstTime", to: true });
                    },
                    (error) => {
                        console.error(error);
                    },
                );
        },

        async setMaintenanceMode({ commit, state }, isMaintenanceModeEnabled) {
            await firebase.functions().httpsCallable("v2_updateCieloConfigurations")({
                cieloConfigurations: { isMaintenanceModeEnabled },
                dev: state.dev,
            });
            commit("updtObjProperty", {
                what: "cieloConfigurations",
                to: isMaintenanceModeEnabled,
                idx: "isMaintenanceModeEnabled",
            });
        },

        async sendEmailToUsers({ state }, email) {
            const response = await firebase.functions().httpsCallable("v2_sendEmailToUsers")({
                email,
                dev: state.dev,
            });
            return response;
        },

        async getAvailableEmailRecipients({ state }) {
            const response = await firebase.functions().httpsCallable("v2_getAvailableEmailRecipients")({
                dev: state.dev,
            });
            return response;
        },
    },
});
